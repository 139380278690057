import { AsyncStorage } from '../../asyncStorage'

const Header = async (title = 'TAX INVOICE', extraInfo) => {
  let businessDetails = await AsyncStorage.getBusiness()
  return new Promise((resolve) => {
    let html = `<div class="row header">
        <div class="col-md-6 b font14">
                GSTIN : ${businessDetails.gstIn}
                <!--GSTIN : 06AAAHY4071R1ZI-->
        </div>
        <div class="col-md-12 center border-bottom headerBox">
            <div class="taxInvoice b">${title}</div>
            <div class="title upperCase">
                ${businessDetails.name}
            </div>
            <div class="font12 upperCase">${businessDetails.address}, ${businessDetails.city}</div>
            <div class="font10 b i"><i>Tel : ${businessDetails.mobile} email : ${businessDetails.email}}</i></div>
            ${extraInfo ? `<div class="font10">${extraInfo}</div>` : ''}
        </div>
    </div>`

    resolve(html)
  })
}

export default Header
