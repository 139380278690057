import React from 'react'
import styles from './login.less'
import {
  Form, Icon, Input, Button, Checkbox, notification
} from 'antd'

import Request from '../../request'
import { showLoader, hideLoader } from '../../modules/actions'

import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { HotKeys, GlobalHotKeys } from 'react-hotkeys'
import { GlobalHandlers, GlobalKeyMap } from '../../components/_utils/globalShortCut'

const keyMap = {
  EXPAND: { name: 'Expand square area', sequence: 'alt+up' }

}

class NormalLoginForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      showOtpScreen: false,
      username: ''
    }
  }

  handleSubmit = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        dispatch(showLoader())

        let x = await Request.login(values)
        dispatch(hideLoader())
        console.log(x)
        if (x.type) {

          /*localStorage.setItem('token', x.token)
          localStorage.setItem('user', JSON.stringify(x.userObj))

          dispatch({
            type: 'SET_CURRENT_USER',
            user: x.userObj
          })

          setTimeout(() => {
            dispatch(push('/'))
          }, 300)*/

          this.setState({ showOtpScreen: true, username: values.username })

        } else {
          notification.error({
            message: 'Invalid Login',
            description: x.message
          })

        }
      }
    })

  }


  _submitOtp = (e) => {

    const { dispatch } = this.props
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        if (!this.state.username) {
          notification.warn({
            message: 'Please Enter User Name'
          })
          return
        }

        dispatch(showLoader())
        let x = await Request.submitLoginOtp({ ...values, username: this.state.username })
        dispatch(hideLoader())
        if (x.type) {

          localStorage.setItem('token', x.token)
          localStorage.setItem('user', JSON.stringify(x.userObj))

          dispatch({
            type: 'SET_CURRENT_USER',
            user: x.userObj
          })

          setTimeout(() => {
            dispatch(push('/'))
          }, 300)

        } else {
          notification.error({
            message: 'Invalid Login',
            description: x.message
          })

        }
      }
    })


  }

  render() {
    const { loading, form: { getFieldDecorator } } = this.props
    const { showOtpScreen } = this.state

    return (
      <div>
        <GlobalHotKeys
          keyMap={GlobalKeyMap}
          handlers={GlobalHandlers}
          global
        />
        {!showOtpScreen ?
          <Form onSubmit={this.handleSubmit} className={styles.main}>
            <Form.Item>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Please input your 10 digit mobile no!' }]
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }}/>} placeholder="Email address"/>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your Password!' }]
              })(
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }}/>} type="password"
                       placeholder="Password"/>
              )}
            </Form.Item>
            <Form.Item>

              <Button type="primary" loading={loading} htmlType="submit" className={styles.button}>
                Log in
              </Button>
            </Form.Item>
          </Form> :
          null}

        {showOtpScreen ? <Form onSubmit={this._submitOtp} className={styles.main}>

          <Form.Item>
            {getFieldDecorator('otp', {
              rules: [{ required: true, message: 'Enter Your OTP' }]
            })(
              <Input prefix={<Icon type="key" style={{ color: 'rgba(0,0,0,.25)' }}/>} placeholder="Enter OTP"
                     minLength={4} maxLength={4} type={'number'}/>
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" loading={loading} htmlType="submit" className={styles.button}>
              Submit OTP
            </Button>
          </Form.Item>
        </Form> : null}

      </div>
    )
  }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm)


const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNormalLoginForm)
