import React, { Component } from 'react'
import moment from 'moment'
import Request from '../../request'
import { showLoader, hideLoader } from '../../modules/actions'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { Card, Row, Col, Menu, DatePicker, Dropdown, Button } from 'antd'
import { connect } from 'react-redux'
import Styles from './styles.less'
import { InputBox } from '../webComponent/appDes'
import PrintHtml from '../html/sheet_report/paymentAndReceiptAccount'
import PrintHeader from '../html/header'
import { HtmlPrint } from '../html/print'

class ProfitAndLoss extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      obj: {},
      isModalVisible: false,
      userType: '',
      title: '',
      date: moment(),
      isDateTimePickerVisible: false
    }

  }

  loadBalanceSheet = () => {
    let { dispatch } = this.props
    let { date } = this.state
    dispatch(showLoader())
    Request.FullBalanceSheetAjax({ date })
      .then((testData) => {
        let { PAYMENT_IN, PAYMENT_OUT, balancePaymentOut, balancePaymentIn, totalPaymentOut, totalPaymentIn } = testData
        this.setState({
          paymentInObj: PAYMENT_IN,
          paymentOutObj: PAYMENT_OUT,
          balancePaymentOut,
          balancePaymentIn,
          totalPaymentOut,
          totalPaymentIn
        })
        dispatch(hideLoader())
        return
      })
      .catch((e) => {
        dispatch(hideLoader())
        return
      })

  }

  onChooseDate = (date, dateString) => {
    this.setState({ date: date }, () => {
      this.loadBalanceSheet()
    })
  }

  componentDidMount() {
    this.loadBalanceSheet()
  }

  createPdf = async () => {
    let { paymentInObj, paymentOutObj, balancePaymentOut, balancePaymentIn, totalPaymentOut, totalPaymentIn } = this.state
    let data = {
      title: 'P A Y M E N T & R E C E I P T A C C O U N T',
      paymentInObj, paymentOutObj, balancePaymentOut, balancePaymentIn, totalPaymentOut, totalPaymentIn
    }
    let html = await PrintHtml(data)
    HtmlPrint(html)
  }

  render() {
    let { obj, paymentInObj, paymentOutObj, balancePaymentOut, balancePaymentIn, totalPaymentOut, totalPaymentIn, date } = this.state

    return (
      <PageHeaderWrapper title={'Payment & Receipt Account'}>
        <Card bordered={true}>
          <Row gutter={16}>
            <Col span={'8'}>
              <InputBox title={'Filter By Date'} className={'rowFlex'}>
                <DatePicker onChange={this.onChooseDate} value={date} placeholder={'Choose Date'}/>
              </InputBox>
            </Col>
            <Col span={'16'} className={'alignRight'}>
              <InputBox title={''} className={'mt20'}>
                <Button onClick={() => {
                  this.createPdf()
                }}>
                  Print
                </Button>
              </InputBox>
            </Col>
          </Row>
          <Card title={'BALANCE SHEET'}>
            <Row gutter={16}>
              <Col span={12}>
                <div className={'box'}>
                  <h2 className={'evenColor'}>C A S H {' '} I N ( Rs. )</h2>

                  {paymentInObj && Object.entries(paymentInObj).map(([key, value]) => {
                    let flexColumn = value.arr && value.arr.length
                    return (
                      <div>
                        <Row>
                          <Col span={8}>{key}</Col>
                          <Col span={8}></Col>
                          <Col span={8}></Col>
                        </Row>
                        {
                          value.arr && value.arr.length ? value.arr.map(function(item, index) {
                              return (
                                <Row className={'grayRow'}>
                                  <Col span={8}>{item.name}</Col>
                                  <Col span={8}>{item.amount}</Col>
                                  <Col
                                    span={8}>{(value.arr.length - 1 == index) ? value.total ? value.total : null : null}</Col>
                                </Row>
                              )
                            }) :
                            <Row>
                              <Col span={8}></Col>
                              <Col span={8}></Col>
                              <Col span={8}>{value.total ? value.total : 0}</Col>
                            </Row>
                        }
                      </div>
                    )
                  })}


                  <Row>
                    <Col span={8}></Col>
                    <Col span={8}>Total</Col>
                    <Col span={8}>
                      <div className={'borderV'}>
                        {totalPaymentIn ? (totalPaymentIn) : 0}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={12}>
                <div className={'box'}>
                  <h2 className={'oddColor'}>
                    C A S H {' '} O U T ( Rs. )
                  </h2>
                  {paymentOutObj && Object.entries(paymentOutObj).map(([key, value]) => {
                    let flexColumn = value.arr && value.arr.length
                    return (
                      <div>
                        <Row>
                          <Col span={8}>{key}</Col>
                          <Col span={8}></Col>
                          <Col span={8}></Col>
                        </Row>
                        {
                          value.arr && value.arr.length ? value.arr.map(function(item, index) {
                            return (
                              <Row className={'grayRow'}>
                                <Col span={8}>{item.name}</Col>
                                <Col span={8}>{item.amount}</Col>
                                <Col
                                  span={8}>{(value.arr.length - 1 == index) ? value.total ? value.total : null : null}</Col>
                              </Row>
                            )
                          }) : null
                        }
                      </div>
                    )
                  })}

                  <Row>
                    <Col span={8}></Col>
                    <Col span={8}>Total</Col>
                    <Col span={8}>
                      <div className={'borderV'}>
                        {totalPaymentOut ? (totalPaymentOut) : 0}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Card>
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfitAndLoss)
