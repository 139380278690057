import async from 'async'
import _ from 'lodash'
import PrintStyle from '../html/style'

const getInnerData = (arr, day) => {
  return new Promise((resolve) => {
    let fff = ''
    let totalCredit = 0
    let totalDebit = 0
    async.eachSeries(arr, (ff, lcbb) => {


      ff.ba = ''
      ff.ca = ''
      ff.da = ''
      ff.amt = ''

      if (ff.type == 'SALE' || ff.type == 'PAYMENT_IN' || ff.type == 'DEBIT_OUT') {
        if (ff.paidReceivedAmount) {
          if (ff.paidReceivedAmount != null) {
            ff.ca = ff.paidReceivedAmount
            totalCredit = totalCredit + ff.paidReceivedAmount
            ff.da = ''

          }
        }
      } else {
        if (ff.paidReceivedAmount) {
          if (ff.paidReceivedAmount != null) {
            ff.da = ff.paidReceivedAmount
            totalDebit = totalDebit + ff.paidReceivedAmount
            ff.ca = ''
          }
        }
      }

      if (ff.amount) {
        if (ff.amount != null) {
          ff.amt = ff.amount
        }
      }
      if (ff.balance) {
        if (ff.balance != null) {
          ff.ba = ff.balance
        }
      }
      fff += `<tr class="borderBNone alignTx">` +
        `<th> Name </th>` +
        `<th>Transaction </th>` +
        `<th>Total Amount</th>` +
        `<th>Received </th>` +
        `<th>Paid  </th>` +
        `<th>Balance</th>` +
        `</tr>`

      fff += `<tr>` +
        `<td style="margin-top: 30px;" colspan="6"><strong ><i> (Daybook ${day})</i></strong></td>` +

        '</tr>'

      fff += `<tr>` +
        `<td> ${ff.clientVendorName}</td>` +
        `<td>${ff.type} </td>  ` +
        `<td> ${ff.amt ? '₹' + ff.amt : ''} </td>` +
        `<td> ${ff.ca ? '₹' + ff.ca : ''} </td>` +
        `<td> ${ff.da ? '₹' + ff.da : ''} </td>` +
        `<td> ${ff.ba ? '₹' + ff.ba : ''} </td>` +
        '</tr>'
      if (ff.arr && ff.arr.length) {

        let itemArr = ''
        let totalQt = 0
        let subtotal = 0

        ff.showDisc = false
        let showDisc = _.findIndex(ff.arr, function(o) {
          return o.discountAmount > 0
        })
        if (showDisc != '-1') {
          ff.showDisc = true
        }


        async.eachSeries(ff.arr, (ll, opp) => {
          ll.gst = ll.gst ? ll.gst : ''
          ll.gstAmount = ll.gstAmount ? ll.gstAmount : ''
          ll.discount = ll.discount ? ll.discount : ''
          ll.discountAmount = ll.discountAmount ? ll.discountAmount : ''

          totalQt += ll.quantity
          subtotal += ll.netAmount


          if (ff.showDisc) {
            itemArr += `<tr>` +
              `<td>${ff.arr.indexOf(ll) + 1}</td>` +
              `<td style="width: 150px;">${ll.name}</td>` +
              `<td>${ll.quantity}</td>` +
              `<td>${ll.price ? '₹' + ll.price : ''}</td>` +
              `<td>${ll.discountAmount ? '₹' + ll.discountAmount : ''} <small> ${ll.discount ? '(' + ll.discount + '%) ' : ''} </small></td>` +
              `<td>${ll.gstAmount ? '₹' + ll.gstAmount : ''} <br> ${ll.gst ? (ll.gst + '%') : ''}</td>` +
              `<td>${ll.netAmount ? '₹' + ll.netAmount : ''}</td>` +
              `</tr> `
          } else {
            itemArr += `<tr>` +
              `<td>${ff.arr.indexOf(ll) + 1}</td>` +
              `<td style="width: 150px;">${ll.name}</td>` +
              `<td>${ll.quantity}</td>` +
              `<td>${ll.price ? '₹' + ll.price : ''}</td>` +
              `<td>${ll.netAmount ? '₹' + ll.netAmount : ''}</td>` +
              `</tr> `
          }

          opp()
        }, () => {

          subtotal = parseFloat(subtotal).toFixed(2)
          totalQt = parseInt(totalQt)

          if (ff.showDisc) {
            fff += `<tr  style="width: 100%;">` +
              `<td colspan="1"></td>` +
              `<td colspan="6">` +
              `<table class="itemTable font10 alternate_table zIndex99">` +
              `<tr class="alignTx">` +
              `<th>#</th>` +
              `<th>Item dName</th>` +
              `<th>Item Quantity</th>` +
              `<th>Price</th> ` +
              `<th>Discount</th> ` +
              `<th>GST</th> ` +
              `<th>Amount</th> ` +
              `</tr> ${itemArr}                                      
                                          <tr>
                                           <td></td>
                                           <td>Total</td>
                                           <td>${totalQt}</td>
                                           <td></td>
                                           <td></td>
                                           <td></td>
                                           <td>${subtotal ? '₹' + subtotal : ''}</td>
                                           </tr>  
                                            <tr >
                                           <td></td>
                                           <td></td>
                                           <td></td>
                                           <td></td>
                                           <td></td>
                                           <td>Sub Total
                                             <br> 
                                            ${ff.invoiceDiscount ? 'Discount' : ''}
                                           </td>
                                           <td>₹${subtotal}                                      
                                           <br>                     
                                           ${ff.invoiceDiscount ? '₹ ' + ff.invoiceDiscount : ''}                                           
                                           </td>
                                           
                                           </tr> 
                                            
                                                                            
                                       </table>
                                    </td></tr>`
            lcbb()
          } else {

            fff += `<tr  style="width: 100%;">` +
              `<td colspan="1"></td>` +
              `<td colspan="7">` +
              `<table class="itemTable font10 alternate_table zIndex99">` +
              `<tr class="alignTx">` +
              `<th>#</th>` +
              `<th>Item Name</th>` +
              `<th>Item Quantity</th>` +
              `<th>Price</th> ` +
              `<th>Amount</th> ` +
              `</tr> 
                                            ${itemArr}                                      
                                          <tr >
                                           <td></td>
                                           <td>Total</td>
                                           <td>${totalQt}</td>
                                           <td></td>
                                        
                                           <td>${subtotal ? '₹' + subtotal : ''}</td>
                                           </tr>  
                                           
                                          <tr >
                                           <td></td>
                                           <td></td>
                                           <td></td>
                                        
                                           <td>Sub Total
                                             <br> 
                                            ${ff.invoiceDiscount ? 'Discount' : ''}
                                           </td>
                                           <td>₹${subtotal}                                      
                                           <br>                     
                                           ${ff.invoiceDiscount ? '₹ ' + ff.invoiceDiscount : ''}                                           
                                           </td>                                           
                                           </tr> 
                                            
                                                                            
                                       </table>
                                    </td></tr>`
            lcbb()
          }


        })


      } else {
        lcbb()
      }


    }, () => {
      fff += `<tr >` +
        `<td></td>` +
        `<td> </td>  ` +
        `<td> </td>` +
        `<td><b>${totalCredit ? '₹' + totalCredit : '₹0'}</b></td>` +
        `<td><b>${totalDebit ? '₹' + totalDebit : '₹0'}</b></td>` +
        `<td> </td>` +
        '</tr>'

      resolve(fff)
    })
  })

}

const DayBookDetailPrint = ({ arr, day }) => {
  let html = null


  return new Promise(async (resolve) => {
    html = `<!doctype html>
                               <html>
                 <head>
   <meta charset="utf-8">
   <title>Day Book</title>
   <style>
   
   

   
      @media print {
      
      @page{
       margin: 10px;
       } 
body, html {
margin-top:0px;
padding-top:0px;
}
}
   table { border-collapse:collapse }
    
   
       .invoice-box {
           max-width: 100%;
           margin: 0px;           
           padding: 10px;         
           /*border: 1px solid #eee;*/
           /*box-shadow: 0 0 10px rgba(0, 0, 0, .15);*/
           font-size: 12px;
           line-height: 14px;
           font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
           /*color: #0c0c0c;*/
       }
       .invoice-box table {
           width: 100%;
           line-height: inherit;
           text-align: left;
           cell=s
       }
       .invoice-box table td {
           padding: 5px;
           vertical-align: top;
           
       }

       .invoice-box table tr td:nth-child(2) {
           text-align: left;
       }
        .invoice-box table tr td:nth-child(1) {
           text-align: left;
       }
         .invoice-box table tr td {
           text-align: right;
       }

       .invoice-box table tr.top table td {
           padding-bottom: 0px;
       }

       .invoice-box table tr.top table td.title {
           /*font-size: 14px;*/
           /*line-height: 20px;*/
           /*color: #333;*/
       }

       .invoice-box table tr.information table td {
           padding-bottom: 20px;
       }

       .invoice-box table tr.heading td {
           /*background: #d1d2d3;*/
           /*border-bottom: 1px solid #ddd;*/
           font-weight: bold;
       }

       .invoice-box table tr.details td {
           padding-bottom: 20px;
       }

       .invoice-box table tr.item td{
           /*border-bottom: 1px solid #2b2e33;*/
        
           /*font-size: 11px;*/
           line-height: 15px;
        
       }

       .invoice-box table tr.item.last td {
           border-bottom: none;

           
       }

       .invoice-box table tr.total td:nth-child(2) {
           /*border-top: 2px solid #eee;*/
           /*font-weight: bold;*/
       }


            tr.page-break  { display: block; page-break-before: always; }


        

       @media only screen and (max-width: 600px) {
           .invoice-box table tr.top table td {
               width: 100%;
               display: block;
               text-align: center;
           }

           .invoice-box table tr.information table td {
               width: 100%;
               display: block;
               text-align: center;
           }
       }

       /** RTL **/
       .rtl {
           direction: rtl;
           font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
       }

       .rtl table {
           text-align: right;
       }

       .rtl table tr td:nth-child(2) {
           text-align: left;
       }
       
   </style>
   ${PrintStyle}
   <style>
   
       table td, table th {
           border-right: none!important;
           border-left: none!important;
           
       }
       tr.borderBNone th{
        border-bottom: none!important;
       }
       tr.alignTx th{
        text-align: right!important;
       }
         tr.alignTx th:first-child, tr.alignTx th:nth-child(2){
            text-align: left!important;
       }
    </style>
</head>
<body>
<div class="invoice-box">   
   <table class="itemTable font10 alternate_table">
  
       
 <tr class="top">          
           <td colspan="7">
           <div style="text-align: center;"> <b>Day Book</b>      </div>
             <div>Day ${day}</div>                   
              </td>         
       </tr>
            ${await getInnerData(arr, day)}
      </table>
   
</div>

</body>
</html>`
    resolve(html)
  })


}

export default DayBookDetailPrint
