import React, { Component } from 'react'
import {
  Form,
  Select,
  Button,
  Card,
  Table, Icon, Tooltip, Row, Col, Input
} from 'antd'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader } from '../../../modules/actions'
import { connect } from 'react-redux'
import AddSubBrand from './addSubBrand'
import EditSubBrand from './editSubBrand'
import { subBrandListFxn } from './action'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination } from '../../../components/_utils/_utils'
import Request from '../../../request'

let { Option } = Select

@Form.create()
class All extends Component {
  events = {
    brandCallback: (data) => {
      this.setState({ visibleAddSubBrand: false }, () => {
        this.tableRef.current.reload()
      })
    },
    onBrandClose: () => {
      this.setState({ visibleAddSubBrand: false }, () => {
        this.tableRef.current.reload()
      })
    },
    addSubBrandFxn: () => {
      let { dispatch } = this.props
      dispatch(hideLoader())
      this.setState({ visibleAddSubBrand: true })
    },
    editSubmit: () => {
      this.setState({ subBrandId: '', visibleEditSubBrand: false }, () => {
        this.tableRef.current.reload()
      })
    },
    editClose: () => {
      this.setState({ subBrandId: '', visibleEditSubBrand: false })
    },
    editShow: (subBrandId) => {
      this.setState({ subBrandId: subBrandId, visibleEditSubBrand: true })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      visibleAddSubBrand: false,
      visibleEditSubBrand: false,
      subBrandId: ''
    }
    this.tableRef = React.createRef()
  }

  componentDidMount() {
    this.loadAllBrands()
  }


  loadAllBrands = async () => {
    let data = await Request.loadBrands()
    this.setState({ brandArr: data.brandArr })
  }

  apiRequest = async (params) => {
    return new Promise(async (resolve) => {
      let { dispatch } = this.props
      let { name, brandUid } = this.state
      if (name) {
        params.name = name
      }
      if (brandUid) {
        params.brandUid = brandUid
      }
      let data = await dispatch(subBrandListFxn({ ...params, regExFilters: ['name'] }))
      resolve(data)
    })
  }

  render() {
    let { visibleAddSubBrand, visibleEditSubBrand, subBrandId, brandArr, brandUid, name } = this.state
    const columns = [
      {
        title: 'Sr No',
        key: '_id',
        dataIndex: '_id',
        width: 100,
        render: (item, record, index) => {
          return index + 1
        }
      },
      {
        title: 'Brand',
        key: 'brandId',
        dataIndex: 'brandId',
        width: 400,
        render: (item) => {
          return item && item.name ? item.name : null
        }
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name'
      },
      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username',
        width: 200
      },
      {
        title: 'Image',
        dataIndex: 'image',
        key: 'image',
        width: 100,
        render: (item) => {
          return (
            <>
              {item && item.path ?
                <a onClick={() => {
                  window.open(item.originalPath, '_blank')
                }}>
                  <img src={item.path} style={{ height: 30, width: 'auto' }}/>
                </a> : null
              }
            </>
          )
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 100,
        render: (item, record) => {
          return (
            <>
              <Tooltip title={'Edit Sub Brand'}>
                <a className={'ant-btn'} onClick={() => {
                  this.events.editShow(record._id)
                }}>
                  <Icon type={'edit'}/>
                </a>
              </Tooltip>

            </>
          )
        }
      }
    ]

    return (
      <PageHeaderWrapper
        title={'Sub Brand Setting'}>
        <Card bordered={true} title={'Sub Brand Setting'}
              extra={<Button type="primary" onClick={this.events.addSubBrandFxn}>Add Sub Brand</Button>}>

          <Row gutter={16}>
            <Col span={6}>
              <Select
                showSearch={true}
                style={{ width: '100%' }}
                allowClear={true}
                optionFilterProp="children"
                value={brandUid}
                onChange={(e => {
                  this.setState({
                    brandUid: e
                  })
                })}
                filterOption={(input, options) => {
                  return options.props.children.match(new RegExp(input, 'ig'))
                }}
                placeholder="Choose Brand">
                {brandArr && brandArr.length ? brandArr.map((item) => {
                  return (
                    <Option key={item.uid} value={item.uid}>{item.name}</Option>
                  )
                }) : null}
              </Select>
            </Col>
            <Col span={6}>
              <Input onChange={(e => {
                this.setState({
                  name: e.target.value
                })
              })}
                     placeholder={'Name'}
                     value={name}
                     name={'name'}/>
            </Col>


            <Col span={6}>
              <Button onClick={() => this.tableRef.current.reload()}>
                Search
              </Button>
            </Col>
          </Row>


          <TableComp columns={columns} bordered={true} size={'small'}
                     ref={this.tableRef}
                     apiRequest={this.apiRequest}
                     pagination={DefaultTablePagination()}/>

        </Card>
        {visibleAddSubBrand ?
          <AddSubBrand
            brandCallback={this.events.brandCallback}
            onBrandClose={this.events.onBrandClose}
            visible={visibleAddSubBrand}
            {...this.props}
          />
          : null}
        {visibleEditSubBrand ?
          <EditSubBrand
            onClose={this.events.editClose}
            onSubmit={this.events.editSubmit}
            visible={visibleEditSubBrand}
            subBrandId={this.state.subBrandId}
            {...this.props}
          />
          : null}
      </PageHeaderWrapper>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(All)
