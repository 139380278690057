import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input,
  Button,
  Icon,
  Row,
  Col,
  Tag, Divider, Select, Form
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import lodash from 'lodash'
import styles from './styles.less'
import { connect } from 'react-redux'
import { hideLoader, showLoader } from '../../../modules/actions'
import AddItem from '../add'
import { getPushPathWrapper } from '../../../routes'

let searchStr = ''
let categoryId = ''
let catArr = []

let itemArr = ''
let chcat = ''

const FormItem = Form.Item
const { Option } = Select


class AllUsers extends Component {

  constructor(props) {
    super(props)
    this.state = {
      brandId: '',
      disType: 'default',
      name: '',
      catArr: [],
      brandArr: [],
      addItemVisible: false
    }
    this.table = React.createRef()
  }

  componentDidMount() {
    this.loadCategory()
    this.loadBrands()
  }

  apiRequest = () => {

    return new Promise(async (resolve) => {
      this.setState({ total: 0 })

      itemArr = ''

      this.props.dispatch(showLoader())

      Request.AllItemsOnly({
        name: this.state.name,
        categoryId: this.state.categoryId,
        brandId: this.state.brandId,
        type: this.state.disType
      }).then((testData) => {


        this.props.dispatch(hideLoader())
        resolve({ data: testData.arr, total: testData.total })
        return
      })
        .catch((e) => {
          this.props.dispatch(hideLoader())

          return
        })

    })
  }

  setValue(e) {
    let { name, value } = e.target
    this.setState({ [name]: value })
  }

  loadCategory = () => {
    Request.AllCategory()
      .then((testData) => {
        let catArr = []
        lodash.each(testData, (ff) => {
          catArr.push({ categoryId: ff.uid, categoryName: ff.name })
        })
        this.setState({ catArr: catArr })
      })
      .catch((e) => {
        return
      })

  }

  loadBrands = () => {
    let brandArr = []
    Request.loadBrands()
      .then((testData) => {
        if (testData.success) {
          let brandArr = []
          lodash.each(testData.brandArr, (ff) => {
            brandArr.push({ brandId: ff.uid, brandName: ff.name })
          })
          this.setState({ brandArr })
        }

      })
      .catch((e) => {
        return
      })

  }

  chooseCategory(e) {

    this.setState({ ...e })
  }

  chooseBrands(e) {
    this.setState({ ...e })
  }

  addItemFxn = () => {
    this.setState({ addItemVisible: true })
  }

  addItemCallback = (data) => {
    this.table.current.reload()
    this.setState({ addItemVisible: false })
  }
  onItemClose = () => {
    this.setState({ addItemVisible: false })
  }

  render() {
    let { name, catArr, categoryId, categoryName, brandArr, brandName, brandId, addItemVisible } = this.state
    const columns = [
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name'
      },
      {
        title: 'Stock',
        dataIndex: 'stock',
        key: 'stock'
      },

      {
        title: 'Price',
        dataIndex: 'salePrice',
        key: 'salePrice',
        width: 300,
        render: (val, record) => {
          return (
            <div className={'rowFlex'} style={{ flexWrap: 'wrap' }}>
              <a className={'smBtn'}>
                Sale Price : {record.salePrice}
              </a>
              <a className={'smBtn'}>
                Purchase Price : {record.purchasePrice}
              </a>

              <a className={'smBtn'}>
                MRP Price : {record.mrp}
              </a>
              <a className={'smBtn'}>
                Whole Sale Price : {record.wholesalePrice}
              </a>
              <a className={'smBtn'}>
                Avg Price : {record.avgPrice}
              </a>
              <a className={'smBtn'}>
                Avg Sale Price : {record.avgSalePrice}
              </a>
            </div>
          )
        }
      },

      {
        title: 'Unit Name',
        dataIndex: 'unitName',
        key: 'unitName'
      },
      {
        title: 'HSN Code',
        dataIndex: 'hsnCode',
        key: 'hsnCode'
      },
      {
        title: 'GST',
        dataIndex: 'gstInclusive',
        key: 'gstInclusive',
        render: (val) => {
          return (
            `(${val ? 'Inclusive GST' : 'Exclusive GST'})`
          )
        }
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location'
      },
      {
        title: 'Category',
        dataIndex: 'categoryName',
        key: 'categoryName'
      },
      {
        title: 'Brand',
        dataIndex: 'brandName',
        key: 'brandName'
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (val, record) => {
          return (
            <div className={'actionGroup'}>
              <Tooltip title="Edit">
                <Button
                  shape="circle"
                  icon="edit"
                  onClick={() => {
                    this.props.dispatch(
                      getPushPathWrapper('editProduct', {
                        id: record._id
                      })
                    )
                  }}
                />
              </Tooltip>
            </div>
          )
        }
      }
    ]

    return (
      <PageHeaderWrapper
        title={'All Items'}>
        <Card bordered={true} title={'All Items'}
              extra={<Button type="primary" onClick={this.addItemFxn}>Add Item</Button>}>
          <Row gutter={16}>
            <Col span={6}>
              <Input onChange={(e => this.setValue(e))}
                     placeholder={'Name'}
                     value={name}
                     name={'name'}/>
            </Col>
            <Col span={6}>
              <Select
                showSearch={true}
                style={{ width: '100%' }}
                allowClear={true}
                value={categoryName}
                optionFilterProp="children"

                onChange={(e => this.chooseCategory(e))}
                filterOption={(input, options) => {
                  return options.props.children.match(new RegExp(input, 'ig'))
                }}
                placeholder="Choose Category">
                {catArr.map((item) => {
                  return (
                    <Option key={item.categoryId} value={item}>{item.categoryName}</Option>
                  )
                })}

              </Select>
            </Col>
            <Col span={6}>
              <Select
                showSearch={true}
                style={{ width: '100%' }}
                allowClear={true}
                optionFilterProp="children"

                value={brandName}
                onChange={(e => this.chooseBrands(e))}
                filterOption={(input, options) => {
                  return options.props.children.match(new RegExp(input, 'ig'))
                }}
                placeholder="Choose Brand">
                {brandArr.map((item) => {
                  return (
                    <Option key={item.brandId} value={item}>{item.brandName}</Option>
                  )
                })}

              </Select>
            </Col>
            <Col span={6}>
              <Button onClick={() => this.table.current.reload()}>
                Search
              </Button>
            </Col>
          </Row>
          <TableComp columns={columns}
                     ref={this.table}
                     apiRequest={this.apiRequest}
                     pagination={{
                       defaultPageSize: 100
                     }}
          />
        </Card>

        {addItemVisible ?
          <AddItem visible={addItemVisible}
                   onItemClose={this.onItemClose}
                   addItemCallback={this.addItemCallback}/> : null}

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllUsers)
