import _ from 'lodash'
import moment from 'moment'

export const TaxTypeList = [
  { label: 'Exclusive GST', value: false },
  { label: 'Inclusive GST', value: true }
]

export const ReloadSaleItemsOld = (rr, allIt, callback) => {

  _.each(allIt, (itr) => {

    if (rr == 'sp') {
      itr.salePrice = itr.actualSalePrice
    }
    if (rr == 'pp') {
      itr.salePrice = itr.actualPurchasePrice
    }
    if (rr == 'ws') {
      itr.salePrice = itr.wholesalePrice
    }
    if (rr == 'mrp') {
      itr.salePrice = itr.mrp
    }


    let quantity = parseFloat(itr.quantity ? parseFloat(itr.quantity).toFixed(3) : 0)
    let salePrice = parseFloat(itr.salePrice ? parseFloat(itr.salePrice).toFixed(2) : 0)
    let discount = parseFloat(itr.discount ? parseFloat(itr.discount).toFixed(2) : 0)
    let secondDiscountPercent = parseFloat(itr.secondDiscountPercent ? parseFloat(itr.secondDiscountPercent).toFixed(2) : 0)

    let gst = itr.gst == 'Exempted' ? 0 : parseFloat(itr.gst ? parseInt(itr.gst) : 0)


    let subTotal = parseFloat(parseFloat(quantity * salePrice).toFixed(2))


    let discountAmount = 0
    let afterDisc = 0
    let gstAmount = 0
    let netAmount = 0
    let taxableAmount = 0
    let secondDiscountAmount = 0

    if (itr.gstInclusive) {

      let totalAmountValue = itr.salePrice * itr.quantity
      if (discount) {
        discountAmount = parseFloat(parseFloat((discount / 100) * totalAmountValue).toFixed(2))
      } else {
        discountAmount = itr.discountAmount ? parseFloat(itr.discountAmount) : 0
      }
      afterDisc = parseFloat(totalAmountValue) - (discountAmount ? parseFloat(discountAmount) : 0)


      if (secondDiscountPercent) {
        secondDiscountAmount = parseFloat(parseFloat((secondDiscountPercent / 100) * afterDisc).toFixed(2))
      } else {
        secondDiscountAmount = itr.secondDiscountAmount ? parseFloat(itr.secondDiscountAmount) : 0
      }
      if (secondDiscountAmount) {
        afterDisc = parseFloat(afterDisc) - (secondDiscountAmount ? parseFloat(secondDiscountAmount) : 0)
      }

      if (gst !== 'Exempted') {
        gstAmount = parseFloat(((afterDisc / (100 + gst)) * gst).toFixed(2))
      }


      taxableAmount = parseFloat(((afterDisc / (100 + gst)) * 100).toFixed(2))
      netAmount = parseFloat(totalAmountValue - (discountAmount + secondDiscountAmount)).toFixed(2)

    } else {
      if (discount) {
        discountAmount = parseFloat(parseFloat((discount / 100) * subTotal).toFixed(2))
      } else {
        discountAmount = itr.discountAmount ? parseFloat(itr.discountAmount) : 0
      }
      afterDisc = parseFloat(subTotal) - (discountAmount ? parseFloat(discountAmount) : 0)

      if (secondDiscountPercent) {
        secondDiscountAmount = parseFloat(parseFloat((secondDiscountPercent / 100) * afterDisc).toFixed(2))
      } else {
        secondDiscountAmount = itr.secondDiscountAmount ? parseFloat(itr.secondDiscountAmount) : 0
      }

      if (secondDiscountAmount) {
        afterDisc = parseFloat(afterDisc) - (secondDiscountAmount ? parseFloat(secondDiscountAmount) : 0)
      }

      if (gst !== 'Exempted') {
        gstAmount = parseFloat(parseFloat((gst / 100) * (afterDisc)).toFixed(2))
      }
      taxableAmount = parseFloat(afterDisc).toFixed(2)
      netAmount = parseFloat(afterDisc + gstAmount).toFixed(2)

    }


    itr.discountAmount = discountAmount.toString()
    itr.secondDiscountAmount = secondDiscountAmount.toString()
    itr.subTotal = subTotal.toString()
    itr.gstAmount = gstAmount.toString()
    itr.netAmount = netAmount.toString()
    itr.taxableAmount = taxableAmount.toString()

  })


  return callback(allIt)

}

export const ReloadSaleItems = (rr, allIt, callback) => {

  _.each(allIt, (itr) => {

    if (rr == 'sp') {
      itr.salePrice = itr.actualSalePrice
    }
    if (rr == 'pp') {
      itr.salePrice = itr.actualPurchasePrice
    }
    if (rr == 'ws') {
      itr.salePrice = itr.wholesalePrice
    }
    if (rr == 'mrp') {
      itr.salePrice = itr.mrp
    }


    let quantity = parseFloat(itr.quantity ? parseFloat(itr.quantity).toFixed(3) : 0)
    let salePrice = parseFloat(itr.salePrice ? parseFloat(itr.salePrice).toFixed(2) : 0)
    let discount = parseFloat(itr.discount ? parseFloat(itr.discount).toFixed(2) : 0)
    let secondDiscountPercent = parseFloat(itr.secondDiscountPercent ? parseFloat(itr.secondDiscountPercent).toFixed(2) : 0)

    let gst = itr.gst == 'Exempted' ? 0 : parseFloat(itr.gst ? parseInt(itr.gst) : 0)


    // let subTotal = parseFloat(parseFloat(quantity * salePrice).toFixed(2));
    let subTotal = 0

    let discountAmount = 0
    let afterDisc = 0
    let gstAmount = 0
    let netAmount = 0
    let taxableAmount = 0
    let secondDiscountAmount = 0

    if (itr.disType == 'disInPer') {
      discountAmount = parseFloat(parseFloat((discount / 100) * salePrice).toFixed(2))
    } else {
      discountAmount = fixed2Digit(itr.discountAmount)
    }
    afterDisc = parseFloat(salePrice) - (discountAmount ? parseFloat(discountAmount) : 0)
    if (itr.disType == 'disInPer') {
      secondDiscountAmount = parseFloat(parseFloat((secondDiscountPercent / 100) * afterDisc).toFixed(2))

    } else {
      secondDiscountAmount = fixed2Digit(itr.secondDiscountAmount)
    }

    if (secondDiscountAmount) {
      afterDisc = parseFloat(afterDisc) - (secondDiscountAmount ? parseFloat(secondDiscountAmount) : 0)
    }

    if (itr.gstInclusive) {

      /* let totalAmountValue = _.clone(salePrice);
       if (itr.disType == "disInPer") {
           discountAmount = parseFloat(parseFloat((discount / 100) * salePrice).toFixed(2));
       } else {
           discountAmount = parseFloat(itr.discountAmount);
       }

       afterDisc = parseFloat(salePrice) - (discountAmount ? parseFloat(discountAmount) : 0);

       if (itr.disType == "disInPer") {
           secondDiscountAmount = parseFloat(parseFloat((secondDiscountPercent / 100) * afterDisc).toFixed(2));
       } else {
           secondDiscountAmount = parseFloat(itr.secondDiscountAmount);
       }
       if (secondDiscountAmount) {
           afterDisc = parseFloat(afterDisc) - (secondDiscountAmount ? parseFloat(secondDiscountAmount) : 0);
       }*/

      netAmount = fixed2Digit(afterDisc * quantity)
      if (gst !== 'Exempted') {
        gstAmount = parseFloat((((netAmount / (100 + gst)) * gst)).toFixed(2))
      }
      taxableAmount = parseFloat((((netAmount / (100 + gst)) * 100)).toFixed(2))
      subTotal = parseFloat((((netAmount / (100 + gst)) * 100)).toFixed(2))


    } else {
      /*  if (itr.disType == "disInPer") {
            discountAmount = parseFloat(parseFloat((discount / 100) * salePrice).toFixed(2));
        } else {
            discountAmount = parseFloat(itr.discountAmount);
        }
        afterDisc = parseFloat(salePrice) - (discountAmount ? parseFloat(discountAmount) : 0);

        if (itr.disType == "disInPer") {
            secondDiscountAmount = parseFloat(parseFloat((secondDiscountPercent / 100) * afterDisc).toFixed(2));
        } else {
            secondDiscountAmount = itr.secondDiscountAmount ? parseFloat(itr.secondDiscountAmount) : 0;
        }

        if (secondDiscountAmount) {
            afterDisc = parseFloat(afterDisc) - (secondDiscountAmount ? parseFloat(secondDiscountAmount) : 0);
        }*/

      subTotal = parseFloat(parseFloat(quantity * afterDisc).toFixed(2))

      if (gst !== 'Exempted') {
        gstAmount = parseFloat(parseFloat((gst / 100) * (subTotal)).toFixed(2))
      }
      taxableAmount = parseFloat(quantity * afterDisc).toFixed(2)

      netAmount = parseFloat(subTotal + gstAmount).toFixed(2)

    }

    itr.discountAmount = discountAmount.toString()
    itr.secondDiscountAmount = secondDiscountAmount.toString()
    itr.subTotal = subTotal.toString()
    itr.gstAmount = gstAmount.toString()
    itr.netAmount = netAmount.toString()
    itr.taxableAmount = taxableAmount.toString()

  })


  return callback(allIt)

}

export const PaymentType = [
  { label: 'None', value: '' },
  { label: 'Credit Card', value: 'Credit Card' },
  { label: 'Cheque', value: 'Cheque' },
  { label: 'Bhim', value: 'BHIM UPI' },
  { label: 'PayTm', value: 'PayTm' },
  { label: 'NEFT', value: 'NEFT' },
  { label: 'IMPS', value: 'IMPS' },
  { label: 'RTGS', value: 'RTGS' }
]


export const ConTwoDecDigit = (digit) => {
  return digit.indexOf('.') > 0 ?
    digit.split('.').length >= 2 ?
      digit.split('.')[0] + '.' + digit.split('.')[1].substring(-1, 2)
      : digit
    : digit
}

export const DateInString = (value) => {
  if (value) {
    return moment(value).format('DD-MMM-YYYY')
  } else {
    return null
  }
}

export const fixed2Digit = (value) => {
  if (value) {
    return parseFloat(parseFloat(value).toFixed(2))
  } else {
    return 0
  }
}

export const AmountInDigit = (value, digit = 2) => {
  if (value) {
    value = value.toString()
    return value.indexOf('.') > 0 ?
      value.split('.').length >= 2 ?
        value.split('.')[0] + '.' + value.split('.')[1].substring(-1, digit)
        : value
      : value
  }
}

export const ItemsReCalculate = (allIt, callback) => {

  _.each(allIt, (itr) => {
    /*
            if (rr == "sp") {
                itr.salePrice = itr.actualSalePrice;
            }
            if (rr == "pp") {
                itr.salePrice = itr.actualPurchasePrice;
            }
            if (rr == "ws") {
                itr.salePrice = itr.wholesalePrice;
            }
            if (rr == "mrp") {
                itr.salePrice = itr.mrp;
            }*/


    let quantity = parseFloat(itr.quantity ? parseFloat(itr.quantity).toFixed(3) : 0)
    let salePrice = parseFloat(itr.salePrice ? parseFloat(itr.salePrice).toFixed(2) : 0)
    let discount = parseFloat(itr.discount ? parseFloat(itr.discount).toFixed(3) : 0)
    let secondDiscountPercent = parseFloat(itr.secondDiscountPercent ? parseFloat(itr.secondDiscountPercent).toFixed(3) : 0)

    let gst = itr.gst == 'Exempted' ? 0 : parseFloat(itr.gst ? parseInt(itr.gst) : 0)


    let subTotal = 0

    let discountAmount = 0
    let afterDisc = 0
    let gstAmount = 0
    let netAmount = 0
    let taxableAmount = 0
    let secondDiscountAmount = 0

    if (!itr.disType) {
      itr.disType = 'disInPer'
    }
    if (itr.gstInclusive) {

      let totalAmountValue = _.clone(salePrice)
      if (itr.disType == 'disInPer') {
        discountAmount = parseFloat(parseFloat((discount / 100) * salePrice).toFixed(2))
      } else {
        discountAmount = parseFloat(itr.discountAmount)
      }

      afterDisc = parseFloat(salePrice) - (discountAmount ? parseFloat(discountAmount) : 0)

      if (itr.disType == 'disInPer') {
        secondDiscountAmount = parseFloat(parseFloat((secondDiscountPercent / 100) * afterDisc).toFixed(2))
      } else {
        secondDiscountAmount = parseFloat(itr.secondDiscountAmount)
      }
      if (secondDiscountAmount) {
        afterDisc = parseFloat(afterDisc) - (secondDiscountAmount ? parseFloat(secondDiscountAmount) : 0)
      }
      netAmount = fixed2Digit(afterDisc * quantity)
      if (gst !== 'Exempted') {
        gstAmount = parseFloat((((netAmount / (100 + gst)) * gst)).toFixed(2))
      }
      taxableAmount = parseFloat((((netAmount / (100 + gst)) * 100)).toFixed(2))
      subTotal = parseFloat((((netAmount / (100 + gst)) * 100)).toFixed(2))


    } else {
      if (itr.disType == 'disInPer') {
        discountAmount = parseFloat(parseFloat((discount / 100) * salePrice).toFixed(2))
      } else {
        discountAmount = parseFloat(itr.discountAmount)
      }
      let discount1 = discountAmount ? parseFloat(discountAmount) : 0
      // console.log(discount1, 'discount1', itr.salePrice)
      // afterDisc = parseFloat(salePrice) - (discountAmount ? parseFloat(discountAmount) : 0);

      if (itr.disType == 'disInPer') {
        secondDiscountAmount = parseFloat(parseFloat((secondDiscountPercent / 100) * afterDisc).toFixed(2))
      } else {
        secondDiscountAmount = itr.secondDiscountAmount ? parseFloat(itr.secondDiscountAmount) : 0
      }

      let discount2 = secondDiscountAmount ? parseFloat(secondDiscountAmount) : 0
      /*if (secondDiscountAmount) {
          afterDisc = parseFloat(afterDisc) - (secondDiscountAmount ? parseFloat(secondDiscountAmount) : 0);
      }*/

      afterDisc = parseFloat((parseFloat(salePrice) - (discount1 + discount2)).toFixed(2))
      subTotal = parseFloat((parseFloat(quantity * afterDisc)).toFixed(2))

      /*
                  afterDisc = parseFloat(afterDisc) - (secondDiscountAmount ? parseFloat(secondDiscountAmount) : 0);

                  subTotal = parseFloat(parseFloat(quantity * afterDisc).toFixed(2));*/

      if (gst !== 'Exempted') {
        gstAmount = parseFloat(parseFloat((gst / 100) * (subTotal)).toFixed(2))
      }
      taxableAmount = parseFloat(quantity * afterDisc).toFixed(2)

      netAmount = parseFloat(subTotal + gstAmount).toFixed(2)

    }

    itr.discountAmount = discountAmount.toString()
    itr.secondDiscountAmount = secondDiscountAmount.toString()
    itr.subTotal = subTotal.toString()
    itr.gstAmount = gstAmount.toString()
    itr.netAmount = netAmount.toString()
    itr.taxableAmount = taxableAmount.toString()

  })

  return callback(allIt)

}

export const SingleItemReCalculate = (itr, callback) => {


  let quantity = parseFloat(itr.quantity ? parseFloat(itr.quantity).toFixed(3) : 0)
  let salePrice = parseFloat(itr.salePrice ? parseFloat(itr.salePrice).toFixed(2) : 0)
  let discount = parseFloat(itr.discount ? parseFloat(itr.discount).toFixed(2) : 0)
  let secondDiscountPercent = parseFloat(itr.secondDiscountPercent ? parseFloat(itr.secondDiscountPercent).toFixed(2) : 0)

  let gst = itr.gst == 'Exempted' ? 0 : parseFloat(itr.gst ? parseInt(itr.gst) : 0)


  let subTotal = 0

  let discountAmount = 0
  let afterDisc = 0
  let gstAmount = 0
  let netAmount = 0
  let taxableAmount = 0
  let secondDiscountAmount = 0

  if (!itr.disType) {
    itr.disType = 'disInPer'
  }
  if (itr.gstInclusive) {

    let totalAmountValue = _.clone(salePrice)
    if (itr.disType == 'disInPer') {
      discountAmount = parseFloat(parseFloat((discount / 100) * salePrice).toFixed(2))
    } else {
      discountAmount = parseFloat(itr.discountAmount)
    }

    afterDisc = parseFloat(salePrice) - (discountAmount ? parseFloat(discountAmount) : 0)

    if (itr.disType == 'disInPer') {
      secondDiscountAmount = parseFloat(parseFloat((secondDiscountPercent / 100) * afterDisc).toFixed(2))
    } else {
      secondDiscountAmount = parseFloat(itr.secondDiscountAmount)
    }
    if (secondDiscountAmount) {
      afterDisc = parseFloat(afterDisc) - (secondDiscountAmount ? parseFloat(secondDiscountAmount) : 0)
    }
    if (gst !== 'Exempted') {
      gstAmount = parseFloat((((afterDisc / (100 + gst)) * gst) * quantity).toFixed(2))
    }
    taxableAmount = parseFloat((((afterDisc / (100 + gst)) * 100) * quantity).toFixed(2))
    subTotal = parseFloat((((afterDisc / (100 + gst)) * 100) * quantity).toFixed(2))
    // netAmount = parseFloat(totalAmountValue - (discountAmount + secondDiscountAmount)).toFixed(2);
    netAmount = parseFloat(gstAmount + (taxableAmount)).toFixed(2)


  } else {
    if (itr.disType == 'disInPer') {
      discountAmount = parseFloat(parseFloat((discount / 100) * salePrice).toFixed(2))
    } else {
      discountAmount = parseFloat(itr.discountAmount)
    }
    afterDisc = parseFloat(salePrice) - (discountAmount ? parseFloat(discountAmount) : 0)

    if (itr.disType == 'disInPer') {
      secondDiscountAmount = parseFloat(parseFloat((secondDiscountPercent / 100) * afterDisc).toFixed(2))
    } else {
      secondDiscountAmount = itr.secondDiscountAmount ? parseFloat(itr.secondDiscountAmount) : 0
    }

    if (secondDiscountAmount) {
      afterDisc = parseFloat(afterDisc) - (secondDiscountAmount ? parseFloat(secondDiscountAmount) : 0)
    }

    subTotal = parseFloat(parseFloat(quantity * afterDisc).toFixed(2))

    if (gst !== 'Exempted') {
      gstAmount = parseFloat(parseFloat((gst / 100) * (subTotal)).toFixed(2))
    }
    taxableAmount = parseFloat(quantity * afterDisc).toFixed(2)

    netAmount = parseFloat(subTotal + gstAmount).toFixed(2)

  }

  itr.discountAmount = discountAmount.toString()
  itr.secondDiscountAmount = secondDiscountAmount.toString()
  itr.subTotal = subTotal.toString()
  itr.gstAmount = gstAmount.toString()
  itr.netAmount = netAmount.toString()
  itr.taxableAmount = taxableAmount.toString()


  return callback(itr)

}

export const UnitCodeList = [
  'BAG',
  'BAL',
  'BDL',
  'BKL',
  'BOU',
  'BOX',
  'BTL',
  'BUN',
  'CAN',
  'CBM',
  'CCM',
  'CMS',
  'CTN',
  'DOZ',
  'DRM',
  'GGR',
  'GRS',
  'GYD',
  'KGS',
  'KLR',
  'KME',
  'MLT',
  'MTR',
  'NOS',
  'PAC',
  'PCS',
  'PRS',
  'QTL',
  'ROL',
  'SET',
  'SQF',
  'SQM',
  'SQY',
  'TBS',
  'TGM',
  'THD',
  'TON',
  'TUB',
  'UGS',
  'UNT',
  'YDS',
  'OTH'
]

export const TaxList = [
  { tax: 'Exempted' },
  { tax: 5 },
  { tax: 12 },
  { tax: 18 },
  { tax: 28 }
]

export const StateList = [
  'Select',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Chandigarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Orissa',
  'Punjab',
  'Pondicherry',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal']

export const filterOption = (input, options) => {
  return options.props.children.match(new RegExp(`^${input}`, 'ig'))
}
export const DefaultTablePagination = (
  newParams,
  defaultPageSize = 50,
  pageSizeOptions = ['20', '50', '100', '200'],
  position = 'bottom'
) => {
  let params = {
    defaultPageSize,
    pageSizeOptions,
    ...newParams,
    position: position
  }
  return params
}
