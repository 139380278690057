import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Table,
  notification,
  Drawer
} from 'antd'
import Request from '../../../request'

import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader } from '../../../modules/actions'
import { connect } from 'react-redux'
import { TaxList } from '../../../components/_utils/_utils'
import lodash from 'lodash'

const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AddBrand extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      taxArr: []
    }
    this.input = React.createRef()
  }

  componentDidMount() {
    let taxArr = []
    lodash.each(TaxList, (item) => {
      taxArr.push(item.tax.toString())
    })
    this.setState({ taxArr })
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (!valData.name) {
          notification.warn({
            message: 'Enter brand',
            description: ''
          })
          return
        }

        dispatch(showLoader())

        let x = await Request.AddBrand(valData)
        dispatch(hideLoader())
        if (x.success) {
          notification.success({
            message: x.msg
          })
          this.props.form.setFieldsValue({})
          this.props.brandCallback(x.brand)
        } else {
          notification.error({
            message: 'Error Saving',
            description: x.msg
          })
        }

      }
    })
  }


  render() {
    const { taxArr } = this.state
    const { submitting } = this.props
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props

    const { editorState } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 }
      }
    }

    let { visible } = this.props


    let inputTypes = {
      fields: [
        {
          label: 'Name',
          key: 'name',
          required: true,
          placeholder: 'Name'
        }
      ]
    }

    return (
      <Drawer
        title="Add Brand"
        width={800}
        closable={false}
        onClose={this.props.onBrandClose}
        visible={visible}>
        <Card bordered={true}>
          <Form hideRequiredMark style={{ marginTop: 8 }}>
            <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
                              getFieldDecorator={getFieldDecorator}/>

            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit" loading={this.props.loading} onClick={this.handleSubmit}>
                SAVE
              </Button>
            </Form.Item>

          </Form>
        </Card>
      </Drawer>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBrand)
