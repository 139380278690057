import React from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Divider,
  Tooltip,
  Checkbox, Modal, Alert, Descriptions
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader, showPageLoad, hidePageLoad } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { AsyncStorage } from '../../../asyncStorage'
import { createMatchSelector } from 'connected-react-router'
import { StateList, ReloadSaleItems } from '../../../components/_utils/_utils'
import ChooseClientComponent from '../../webComponent/ChooseClientComponent'
import ChooseTransport from '../../webComponent/ChooseTransport'
import ItemListComponent from '../../webComponent/ItemListComponent'
import ChooseBank from '../../webComponent/ChooseBank'
import { InputBox } from '../../webComponent/appDes'
import debounce from 'lodash/debounce'
import PrintHtml from '../../html/index'
import { HtmlPrint } from '../../html/print'
import { v4 as uuid } from 'uuid'
import { getUrlParams } from '../../../routes'


const { TextArea } = Input

// inputSchema.fields


const FormItem = Form.Item
const { Option } = Select


let html = null
let oldItems = []
let oldBalance = 0
let saleObj = {}
let saleData = {} // for store after save sale data
let fff = ''
let removeId = null
let editItem = {}
let printType = 'Simple'


@Form.create()
class AddPurchase extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      state: 'Select',
      clientBalance: '0',
      purchaseDate: moment(),
      arr: [],
      isDateTimePickerVisible: false,
      paymentMode: 'Cash',
      hideTypeahead: true,
      addClientModal: false,
      totalQuantity: null,
      totalAmount: null,
      billNo: null,
      countStr: null,
      removeItemModal: false,
      items: [],
      arrData: [],
      clientId: null,
      clientName: null,
      refNo: null,
      image: {},
      imageSelect: false,
      checkConnection: false,
      checkedBy: null,
      packedBy: null,
      deliveredBy: null,
      cashBy: null,
      reverseCharge: false,
      clientInfo: {},
      entryTime: moment().format('LLL'),
      bankKey: moment(),
      vehicleKey: moment() + 1,
      qpp: true,
      taxableAmount: 0


    }


  }


  componentDidMount() {
  }

  componentWillMount() {
    this.initFxn()
  }

  async initFxn() {
    let data = await getUrlParams(
      'purchase.editPurchase',
      this.props.pathname
    )
    if (data && data.id) {
      this.getItems(data.id)
    }
  }

  getItems(tempId) {
    saleObj = {}
    oldItems = []
    oldBalance = 0
    Request.GetPurchaseItems({ linkId: tempId })
      .then((saleData) => {


        saleData = saleData.saleData

        _.each(saleData, (val, k) => {
          saleObj[k] = val
        })

        if (saleObj.purchaseDate) {
          saleObj.purchaseDate = moment(saleObj.purchaseDate).format('DD-MMM-YYYY')
        }


        _.each(saleData.items, (sdfs) => {
          sdfs.tid = uuid()
          oldItems.push({
            productUid: sdfs.productUid,
            quantity: sdfs.quantity,
            uid: sdfs.uid,
            price: sdfs.price,
            linkId: sdfs.purchaseId
          })
        })


        oldBalance = saleData.balance

        this.props.dispatch(showPageLoad())
        setTimeout(() => {
          this.setState({
            uid: saleData.uid,
            stateOfSupply: saleData.stateOfSupply,
            vendorInvoiceNo: saleData.vendorInvoiceNo,
            invoiceNo: saleData.invoiceNo,
            currentBalance: saleData.currentBalance,
            purchaseDate: moment(saleData.purchaseDate),
            isDateTimePickerVisible: false,
            paymentMode: saleData.paymentMode,
            hideTypeahead: false,
            qpp: saleData.qpp,
            qmrp: saleData.qmrp,
            addClientModal: false,
            items: saleData.items,
            totalQuantity: saleData.totalQuantity,
            totalAmount: saleData.totalAmount ? saleData.totalAmount.toString() : null,
            totalTempAmount: saleData.totalAmount,
            paidAmount: saleData.paidAmount ? saleData.paidAmount.toString() : null,
            billNo: saleData.billNo ? saleData.billNo.toString() : null,
            balance: saleData.balance,
            clientId: saleData.clientId,
            clientName: saleData.clientName,
            refNo: saleData.refNo,
            description: saleData.description,
            imageSelect: saleData.document ? true : false,
            image: {
              uri: saleData.document ? saleData.document : null
            },
            checkedBy: saleData.checkedBy,
            packedBy: saleData.packedBy,
            deliveredBy: saleData.deliveredBy,
            cashBy: saleData.cashBy,
            entryTime: saleData.entryTime,
            bankId: saleData.bankId,
            paymentType: saleData.paymentType,
            bankType: saleData.bankType,
            paymentDetails: saleData.paymentDetails,
            bankKey: moment(),
            transportType: saleData.transportType,
            transportId: saleData.transportId,
            transportName: saleData.transportName,
            vehicleId: saleData.vehicleId,
            vehicleNo: saleData.vehicleNo,
            station: saleData.station,
            grNo: saleData.grNo,
            reverseCharge: saleData.reverseCharge,
            eWayBillNo: saleData.eWayBillNo,
            taxableAmount: saleData.taxableAmount,
            defaultTaxType: saleData.defaultTaxType,
            vehicleKey: moment() + 4,
            itemListKey: moment() + 2,
            clientKey: moment() + 3

          })
          this.props.dispatch(hidePageLoad())
        }, 1000)
      }).catch((e) => {

    })
  }

  calculation = () => {


    let totalQt = 0
    let totalAMt = 0
    let taxableAmount = 0
    _.each(this.state.items, (item) => {
      if (item.quantity) {
        totalQt = totalQt + parseFloat(item.quantity)
      }
      if (item.netAmount) {
        totalAMt = totalAMt + parseFloat(item.netAmount)
      }
      if (item.taxableAmount) {
        taxableAmount = taxableAmount + parseFloat(item.taxableAmount)
      }
    })

    this.setState({
      totalQuantity: totalQt ? totalQt.toFixed(3) : null,
      subTotal: totalAMt ? parseFloat(totalAMt).toFixed(2) : null,
      totalAmount: totalAMt ? parseFloat(totalAMt).toFixed(2) : null,
      taxableAmount: taxableAmount ? parseFloat(taxableAmount).toFixed(2) : null
      /* receivedAmount: this.state.cashSale ? parseFloat(totalAMt).toFixed(2) : null,
       balance: this.state.cashSale ? "0" : parseFloat(totalAMt).toFixed(2)*/
    }, () => {
      this.calBalance()
    })


  }

  choosemrp = () => {
    this.setState({ qmrp: true, qpp: false })
    this.reloadItems('mrp')
  }
  choosePP = () => {
    this.setState({ qmrp: false, qpp: true })
    this.reloadItems('pp')
  }
  reloadItems = (rr) => {
    this.props.dispatch(showLoader())
    let allIt = this.state.items
    ReloadSaleItems(rr, allIt, (resp) => {
      this.setState({ items: resp }, () => {
        this.calculation()
        this.props.dispatch(hideLoader())
      })

    })


  }

  async createPdf(pptype) {
    let shortGst = saleObj.clientGstNo ? saleObj.clientGstNo.substring(0, 2) : ''
    if (shortGst) {
      shortGst = `(${shortGst})`
    }
    let data = {
      ...saleObj,
      printType: 'Simple',
      title: 'Purchase Invoice',
      pageType: 'PURCHASE',
      invoice: {
        address: {
          shopName: saleObj.clientName,
          address1: saleObj.clientAddress
        },
        customer: {
          'gst/in': saleObj.clientGstNo ? saleObj.clientGstNo : ''
          /* "name": "",
           "address": ""*/
        },
        invoiceLeftInfo: {
          'gr/rr no': saleObj.grNo,
          'E-way bill no': saleObj.eWayBillNo
        },
        invoiceInfo: {
          'bill no': saleObj.invoiceNo,
          'dated': saleObj.purchaseDate ? moment(saleObj.purchaseDate).format('DD-MMM-YYYY') : '',
          'entry time': saleObj.entryTime,
          'place of supply': `${saleObj && saleObj.stateOfSupply ? saleObj.stateOfSupply : ''} ${shortGst}`,
          'reverse charge': saleObj.reverseCharge ? 'Y' : 'N',
          'transport by': saleObj.transportType ? saleObj.transportType : '',
          'transport': saleObj.transportName ? saleObj.transportName : '',
          'vehicle no': saleObj.vehicleNo

        }
      }
    }

    let html = await PrintHtml(data)

    let doubleHtml = html + `<p style="page-break-after: always;">&nbsp;</p>
<p style="page-break-before: always;">&nbsp;</p>` + html

    if (pptype == 'share') {
      HtmlPrint(doubleHtml)
    } else {
      HtmlPrint(doubleHtml, '/purchase/all')
    }

  };

  calBalance(value) {

    setTimeout(() => {
      let ta = this.state.totalAmount ? this.state.totalAmount : 0
      let ra = this.state.paidAmount ? this.state.paidAmount : 0
      this.setState({ balance: parseFloat(ta - ra).toFixed(2) })
    }, 100)
  }

  save(type) {

    AsyncStorage.getBusiness().then((value) => {

      if (value !== null) {


        this.setState({ businessId: value.uid })

        let cloneState = _.clone(this.state)


        delete cloneState.arr
        delete cloneState.arrData

        cloneState.businessId = value.uid
        if (cloneState.date) {
          cloneState.purchaseDate = new Date(cloneState.date)
        }


        if (!cloneState.businessId) {
          notification.error({
            message: 'Business Setting Not Set '
          })
          return
        }

        if (!cloneState.clientName || !cloneState.clientId) {
          notification.warn({
            message: 'Choose Party'
          })
          return
        }
        if (cloneState.transportType) {
          if (cloneState.transportType == 'Transport' && !cloneState.transportId) {
            notification.warn({
              message: 'Choose transport'
            })
            this.props.dispatch(hidePageLoad())
            return
          }

        }
        if (!cloneState.totalAmount) {
          notification.warn({
            message: 'Enter Total Amount'
          })
          return
        }


        if (cloneState.paidAmount && parseFloat(cloneState.paidAmount) > 0) {
          if (!cloneState.bankId) {
            notification.warn({
              message: 'Please choose bank'
            })
            this.props.dispatch(hidePageLoad())
            return
          }
          if (cloneState.bankType == 'Bank' && !cloneState.paymentType) {
            notification.warn({
              message: 'Please choose payment type'
            })
            this.props.dispatch(hidePageLoad())
            return
          }
        } else {
          cloneState.bankId = ''
          cloneState.bankType = ''
        }


        cloneState.oldItems = oldItems
        cloneState.oldBalance = oldBalance

        this.props.dispatch(showPageLoad())
        Request.UpdatePurchase(cloneState)
          .then((testData) => {

            if (testData && testData.type) {
              notification.success({
                message: 'Purchase Saved Successfully'
              })
              this.props.dispatch(hidePageLoad())

              let smsobj = {
                uid: cloneState.uid,
                type: 'PURCHASE',
                name: cloneState.clientName,
                msg: `Edit Purchase:  \n Client = ${cloneState.clientName} \n Total Amount = ${cloneState.totalAmount}`
              }

              // Request.SendSms(smsobj);

              if (this.state.image && this.state.image.name) {
                AsyncStorage.getUserData().then((userResp) => {

                  if (userResp) {
                    let imageObj = {
                      businessId: value.uid,
                      linkId: testData.sale.uid,
                      type: 'PURCHASE',
                      date: new Date(),
                      userId: userResp.uid

                    }


                    Request.uploadDocument(imageObj, this.state.image).then((resp1) => {

                    }).catch((err) => {


                    })
                  }
                })
              }

              if (type == 'save') {
                window.location.href = '/purchase/all'
              } else {
                this.createPdf('print')
              }

              return
            } else {

              notification.error({
                message: testData.msg
              })
              this.props.dispatch(hidePageLoad())
            }


          })
          .catch((e) => {

            notification.error({
              message: 'Try again'
            })
            this.props.dispatch(hidePageLoad())
            return
          })

      } else {
        notification.error({
          message: 'Business Setting Not Set'
        })
        return
      }
    })


  }


  fieldUpdate = (localState) => {
    this.setState({ ...localState })
  }

  _setDigitValue(value, type) {
    if (value) {
      let splitX = value.split('.')
      if (splitX.length > 2) {
        return true
      }
      if (splitX[1]) {
        if (splitX[1].length > 2) {
          return true
        }
      }
      this.setState({ [type]: value }, () => {
        this.calBalance()
      })
    } else {
      this.setState({ [type]: null }, () => {
        this.calBalance()
      })
    }

  }


  getBackItems = (obj) => {
    let { items, defaultTaxType } = obj
    this.setState({ items, defaultTaxType }, () => {
      this.calculation()
    })
  }

  onChooseDate = (date, dateString) => {
    this.setState({ purchaseDate: date })
  }
  _callBackChooseVehicle = (localState) => {
    this.setState({ ...this.state, ...localState })
  }

  setValue = (e) => {
    let { name, value } = e.target
    this.setState({ [name]: value })
  }

  _callBackBank = (data) => {
    this.setState({ ...data })
  }

  _callbackClient = (data) => {
    this.setState({ ...data })
  }

  render() {
    const handleChange = () => {

    }
    let { items, saleCatArr, purchaseDate, vehicleKey, clientName, clientId, subClient } = this.state

    const formData = (
      <Row gutter={16}>

        <Col span={8}>
          <InputBox title={'Vendor Invoice No'}>
            <Input onChange={this.setValue} name={'vendorInvoiceNo'} value={this.state.vendorInvoiceNo}
                   placeholder={'Vendor Invoice No'}
            ></Input>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Date of Purchase'}>
            <DatePicker onChange={this.onChooseDate} value={purchaseDate} placeholder={'Date of Purchase'}/>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'GR/RR No'}>

            <Input onChange={this.setValue} name={'grNo'} placeholder={'GR/RR no'} value={this.state.grNo}/>
          </InputBox>
        </Col>
      </Row>
    )
    const formData1 = (
      <Row gutter={16}>

        <Col span={8}>
          <InputBox title={'Reverse Charge'}>
            <Select value={this.state.reverseCharge}>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Eway bill no'}>
            <Input onChange={this.setValue} name={'eWayBillNo'} placeholder={'Eway bill no'}
                   value={this.state.eWayBillNo}/>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Station'}>
            <Input onChange={this.setValue} name={'station'} placeholder={'Station'}
                   value={this.state.station}/>
          </InputBox>
        </Col>


      </Row>
    )

    const amountFields = (
      <Row gutter={16}>
        <Col span={8}>
          <InputBox title={'Total Amount'}>
            <div className={'ant-input'}>
              {this.state.totalAmount}
            </div>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Paid Amount'}>
            <Input onChange={(e) => {
              this._setDigitValue(e.target.value, 'paidAmount')
            }} name={'paidAmount'} placeholder={'Paid Amount'}
                   value={this.state.paidAmount}/>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Balance'}>
            <div className={'ant-input'}>
              {this.state.balance}
            </div>
          </InputBox>
        </Col>
      </Row>
    )

    const otherFields = (
      <Row gutter={16}>

        <Col span={8}> <InputBox title={'Checked By '}>
          <Input onChange={this.setValue} name={'checkedBy'} placeholder={'Checked By'}
                 value={this.state.checkedBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'PackedBy By '}>
          <Input onChange={this.setValue} name={'packedBy'} placeholder={'Packed By'}
                 value={this.state.packedBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'Delivered By '}>
          <Input onChange={this.setValue} name={'deliveredBy'} placeholder={'Delivered By'}
                 value={this.state.deliveredBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'Cash By '}>
          <Input onChange={this.setValue} name={'cashBy'} placeholder={'Cash By'}
                 value={this.state.cashBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'Entry Time'}>
          <Input onChange={this.setValue} name={'entryTime'} placeholder={'Entry Time'}
                 value={this.state.entryTime}/>
        </InputBox></Col>
      </Row>
    )


    return (
      <PageHeaderWrapper
        title={'Update Purchase'}
        content={''}>
        <Card bordered={true}
              extra={<div className={'actionGroup'}>
                <Button type="primary" onClick={
                  debounce(() => {
                    this.save('save')
                  }, 1000)
                }>SAVE PURCHASE</Button>

                <Button type="success" onClick={
                  debounce(() => {
                    this.save('share')
                  }, 1000)
                }>SAVE & PRINT</Button>
                <Button type="success" onClick={
                  debounce(() => {
                    this.createPdf('share')
                  }, 1000)
                }>PRINT</Button>


              </div>}>
          <Form hideRequiredMark style={{ marginTop: 8 }} className={'newForm'}>
            <Row>
              <Col span={8}>
                <InputBox title={''}>
                  <Checkbox checked={this.state.qmrp}
                            onChange={this.choosemrp}>MRP</Checkbox>
                  <Checkbox checked={this.state.qpp}
                            onChange={this.choosePP}>PP</Checkbox>
                </InputBox>
              </Col>
            </Row>

            <ChooseClientComponent {...this.props} _callbackClient={this._callbackClient} key={this.state.clientKey}
                                   clientName={this.state.clientName} clientId={this.state.clientId}
                                   stateOfSupply={this.state.stateOfSupply}/>

            {formData}

            {formData1}

            <ChooseTransport _callBackChooseVehicle={this._callBackChooseVehicle} {...this.props}
                             fieldUpdate={this.fieldUpdate}
                             key={vehicleKey} {...this.state} />

            <ItemListComponent
              key={this.state.itemListKey}
              {...this.props}
              items={this.state.items}
              clientId={this.state.clientId}
              subTotal={this.state.subTotal}
              totalQuantity={this.state.totalQuantity}
              getBackItems={this.getBackItems}
              pageParams={{
                pageType: 'Purchase',
                defaultTaxType: this.state.defaultTaxType,
                textEditAble: false
              }}
            />
            {amountFields}
            <ChooseBank {...this.props} key={this.state.bankKey}
                        callBackBank={this._callBackBank} {...this.state}/>
            <Row gutter={16}>
              <Col span={8}>
                <InputBox title={'Reference No'}>
                  <Input onChange={this.setValue} name={'refNo'} value={this.state.refNo}
                         placeholder={'Reference No'}></Input>
                </InputBox>
              </Col>
              <Col span={8}>
                <InputBox title={'Description (Optional)'}>
                  <Input onChange={this.setValue} name={'description'} value={this.state.description}
                         placeholder={'Description (Optional)'}></Input>
                </InputBox>
              </Col>
              <Col span={8}>
                <InputBox title={'Choose Picture'} className={'rowFlex'}>
                  <Input type={'file'} accept="image/*" onChange={(e) => {
                    if (e.target.files && e.target.files.length) {
                      this.setState({ image: e.target.files[0] })
                    }
                  }}/>
                  {this.state.image && this.state.image.uri ?
                    <a className={'linkBtn'} target={'_blank'} href={this.state.image.uri}>
                      <Icon type={'eye'}></Icon>
                    </a> : null}
                </InputBox>
              </Col>
            </Row>
            {otherFields}
          </Form>

        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPurchase)
