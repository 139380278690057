import React, { Component } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import {
  Table,
  Card, Menu, Modal,
  Input, Button, Icon, Tag, Row, Col, DatePicker, notification, Dropdown
} from 'antd'
import Request from '../../request'
import _ from 'lodash'
import moment from 'moment'
import debounce from 'lodash/debounce'
import styles from './styles.less'
import { connect } from 'react-redux'
import { hideLoader, showLoader, showPageLoad, hidePageLoad } from '../../modules/actions'
import { getUrlPushWrapper, getPushPathWrapper } from '../../routes'
import { InputBox } from '../webComponent/appDes'
import PrintStyle from '../html/style'
import PrintHeader from '../html/header'
import { HtmlPrint } from '../html/print'
import DetailPrintHtml from './DayBookDetailPrint'

let itemArr = ''

class DayBook extends Component {

  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      arrData: [],

      fromDate: moment(),
      toDate: moment(),
      saleDate: moment(),
      clientName: '',
      isSpinner: false,
      lengthOfArr: 0,
      total: 10,
      totalDebit: 0,
      totalCredit: 0,
      showMultiCopy: false,
      count: null

    }
    this.AllTransactionsReport()
  }

  componentDidMount() {
  }

  AllTransactionsReport() {
    this.props.dispatch(showPageLoad())
    let params = {
      clientId: this.state.clientId,
      type: this.state.type,
      from: this.state.fromDate,
      to: this.state.toDate,
      total: this.state.total
    }
    Request.AllTransactions(params)
      .then((testData) => {
        this.props.dispatch(hidePageLoad())
        let totalDebit = 0
        let totalCredit = 0

        itemArr = ''
        _.each(testData.result, (ff) => {
          let { subClient } = ff
          if (ff.type == 'SALE' || ff.type == 'PAYMENT_IN' || ff.type == 'DEBIT_OUT' || ff.type == 'DEBIT_NOTE') {
            ff.credit = true
            totalCredit = totalCredit + ff.paidReceivedAmount
            ff.debit = false
          } else {
            if (ff.type == 'JOURNAL_ENTRY') {
              if (ff.transactionType == 'Credit') {
                ff.credit = true
                totalCredit = totalCredit + ff.paidReceivedAmount
                ff.debit = false
              }
              if (ff.transactionType == 'Debit') {
                ff.credit = false
                totalDebit = totalDebit + ff.paidReceivedAmount
                ff.debit = true
              }
              ff.type = 'JE'
            } else {
              totalDebit = totalDebit + ff.paidReceivedAmount
              ff.credit = false
              ff.debit = true
            }
          }

          ff.amt = ''
          if (ff.amount) {
            if (ff.amount != null) {
              ff.amt = ff.amount
            }
          }

          if (ff.credit) {
            ff.ca = ''
            if (ff.paidReceivedAmount) {
              if (ff.paidReceivedAmount != null) {
                ff.ca = ff.paidReceivedAmount
              }
            }
          } else {
            ff.ca = ''
          }

          if (ff.debit) {
            ff.da = ''
            if (ff.paidReceivedAmount) {
              if (ff.paidReceivedAmount != null) {
                ff.da = ff.paidReceivedAmount
              }
            }
          } else {
            ff.da = ''
          }

          itemArr += '<tr>' +
            '<td>' + ff.dateString + '</td>' +
            '<td>' + ff.type + '</td>  ' +
            '<td>' + ff.username + '</td>' +
            '<td>' + `${ff.clientVendorName} ${subClient && subClient.name ? `(${subClient.name})` : ''}` + '</td>' +
            '<td>' + ff.amt + '</td>' +
            '<td>' + ff.ca + '</td>' +
            '<td>' + ff.da + '</td>' +
            '</tr>'

        })


        itemArr += '<tr>' +
          '<td><b>Total</b> </td>' +
          '<td> </td>' +
          '<td> </td>' +
          '<td> </td>' +
          '<td> </td>' +
          '<td><b>' + totalCredit + '</b></td>' +
          '<td><b>' + totalDebit + '</b></td>' +
          '</tr>'

        this.setState({
          arrData: testData.result,
          lengthOfArr: testData.total,
          isSpinner: false,
          totalDebit: totalDebit,
          totalCredit: totalCredit
        })
        return
      })
      .catch((e) => {
        this.props.dispatch(showPageLoad())
        this.setState({
          isSpinner: false
        })
        return
      })
  };

  openLink(type, linkId) {
    const { dispatch } = this.props
    if (type == 'SALE') {
      window.location.href = `/sale/add?linkId=${linkId}`
    }
    if (type == 'CREDIT_NOTE') {
      window.location.href = `/creditNote/add?linkId=${linkId}`
    }
    if (type == 'PURCHASE') {
      window.location.href = `/purchase/add?linkId=${linkId}`
    }
    if (type == 'ESTIMATE') {
      window.location.href = `/estimate/add?linkId=${linkId}`
    }
    if (type == 'DEBIT_NOTE') {
      window.location.href = `/debitNote/add?linkId=${linkId}`
    }
  }


  openPage = (val) => {
    let { dispatch } = this.props
    if (val.type == 'SALE') {
      dispatch(
        getPushPathWrapper('sale.editSale', {
          id: val.linkId
        })
      )
    }
    if (val.type == 'PURCHASE') {
      dispatch(
        getPushPathWrapper('purchase.editPurchase', {
          id: val.linkId
        })
      )
    }
    if (val.type == 'CREDIT_NOTE') {
      dispatch(
        getPushPathWrapper('creditNote.editCreditNote', {
          id: val.linkId
        })
      )
    }
    if (val.type == 'PAYMENT_IN') {

    }
    if (val.type == 'PAYMENT_OUT') {

    }
    if (val.type == 'ESTIMATE') {
      dispatch(
        getPushPathWrapper('estimate.editEstimate', {
          id: val.linkId
        })
      )
    }
    if (val.type == 'DEBIT_NOTE') {
      dispatch(
        getPushPathWrapper('debitNote.editDebitNote', {
          id: val.linkId
        })
      )
    }
    if (val.type == 'JE') {

    }
  }

  createPdf = async (typestr) => {


    let html = `<!doctype html>
                               <html>
                 <head>
   <meta charset="utf-8">
   <title>Day Book</title>
   <style>
   
   
      @media print {
      
      @page{
       margin: 20px;
       } 
body, html {
margin-top:0px;
padding-top:0px;
}
}
   
thead {display: table-header-group;}

      
   table {  
    border-collapse: collapse;
}
   
       .invoice-box {
           /*max-width: 800px;*/
           margin: 0px;           
           padding: 10px;         
           border: 1px solid #eee;
           box-shadow: 0 0 10px rgba(0, 0, 0, .15);
           font-size: 12px;
           line-height: 14px;
           font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
           color: #555;
       }
       .invoice-box table {
           width: 100%;
           line-height: inherit;
           text-align: left;
       }
       .invoice-box table td {
           padding: 5px;
           vertical-align: top;
           
       }

       .invoice-box table tr td:nth-child(2) {
           text-align: right;
       }
         .invoice-box table tr td {
           text-align: left;
       }

       .invoice-box table tr.top table td {
           padding-bottom: 0px;
       }

       .invoice-box table tr.top table td.title {
           font-size: 14px;
           line-height: 20px;
           color: #333;
       }

       .invoice-box table tr.information table td {
           padding-bottom: 20px;
       }

       .invoice-box table thead.heading th {
           background: #eee;
           border-bottom: 1px solid #ddd;
           font-weight: bold;
       }

       .invoice-box table tr.details td {
           padding-bottom: 20px;
       }

       .invoice-box table tr.item td{
           border-bottom: 1px solid #2b2e33;
         
           font-size: 12px;
       }

       .invoice-box table tr.item.last td {
           border-bottom: none;
           
       }

       .invoice-box table tr.total td:nth-child(2) {
           border-top: 2px solid #eee;
           font-weight: bold;
       }




       @media only screen and (max-width: 600px) {
           .invoice-box table tr.top table td {
               width: 100%;
               display: block;
               text-align: center;
           }

           .invoice-box table tr.information table td {
               width: 100%;
               display: block;
               text-align: center;
           }
       }

       /** RTL **/
       .rtl {
           direction: rtl;
           font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
       }

       .rtl table {
           text-align: right;
       }

       .rtl table tr td:nth-child(2) {
           text-align: left;
       }
       .tableBody tr:nth-child(even){
            background-color: #f2f2f2;
       }
       
   </style>
   ${PrintStyle}
</head>
<body>
<div class="invoice-box">   
   <table class="itemTable font10 alternate_table">
       <thead >
           <th> Date </th>
           <th>Transaction</th>
           <th>Username</th>
           <th>Party</th>
           <th>Amount</th>
           <th>Credit</th>
           <th>Debit</th>             
       </thead>        
       <tbody >   
      ` + itemArr + `
       </tbody>
   </table>   
</div>
</body>
</html>`

    html = await PrintHeader('Day Book ') + html

    /* if (typestr == 'print') {

       HtmlPrint(html)

       return
     } else {


     }*/
    HtmlPrint(html)


  }

  printStateFxn = (v) => {


    if (v == 'Share Simple Day Book') {
      this.createPdf('share')
    }
    if (v == 'Share Detailed Day Book') {

      let params = {

        from: this.state.fromDate,
        to: this.state.toDate

      }

      this.props.dispatch(showPageLoad())

      Request.DetailedDayBook(params)
        .then((testData) => {
          this.props.dispatch(hidePageLoad())
          let obj = {
            arr: testData,
            day: moment(this.state.fromDate).format('DD-MMM-YYYY')
          }
          DetailPrintHtml(obj).then(async (resp) => {
            console.log(resp)
            let html = await PrintHeader('Day Book ') + resp
            HtmlPrint(html)
          })

          /*this.props.navigation.navigate('DayBookDetailPrint', {
            arr: testData,
            day: moment(this.state.fromDate).format('DD-MMM-YYYY')
          })*/

        })
        .catch((e) => {
          this.props.dispatch(hidePageLoad())

          return
        })


    }

  }

  openReminder(val) {
    // this.props.navigation.navigate('SetReminder', { uid: val.clientVendorId, name: val.clientVendorName })

  }

  onChooseDate = (date, dateString) => {
    this.setState({ fromDate: date }, () => {
      this.AllTransactionsReport()
    })
  }

  onChooseSaleDate = (date, dateString) => {
    this.setState({ saleDate: date })
  }

  _chooseType(type) {
    this.setState({ type })
    setTimeout(() => {
      this.AllTransactionsReport()
    }, 500)
  }

  async multiCopy() {
    let { count, saleId, saleDate } = this.state
    if (!count || (count && parseInt(count) <= 0)) {
      notification.warn({
        message: 'Please enter value.'
      })
      return
    }
    if (!saleId) {
      notification.warn({
        message: 'Please try again.'
      })
      return
    }

    if (saleDate) {
      saleDate = new Date(saleDate)
    }

    this.props.dispatch(showPageLoad())
    let resp = await Request.saleMultiCopy({ saleId, count, saleDate })
    if (resp && resp.type) {
      notification.success({
        message: 'Sale copy successfully.'
      })
      this.AllTransactionsReport()
    } else {
      notification.error({
        message: 'Please try again.'
      })
    }

    this.setState({
      showMultiCopy: false,
      saleId: '',
      count: '',
      saleDate: moment()
    })

    this.props.dispatch(hidePageLoad())


  }

  closeMultiModal() {
    this.setState({ showMultiCopy: false, saleId: null })
  }

  confirmMultiCopy = () => {
    Modal.confirm({
      title: 'Multi Sale',
      content: (
        <h3>Are you sure, You want to generate multiple sales?</h3>
      ),
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        this.multiCopy()
      }
    })

  }

  render() {
    let { dispatch } = this.props
    let { arrData, fromDate, showMultiCopy } = this.state
    const menu = (
      <Menu onClick={({ key }) => this.printStateFxn(key)}>
        <Menu.Item key="Share Simple Day Book">Share Simple Day Book</Menu.Item>
        <Menu.Item key="Share Detailed Day Book">Share Detailed Day Book</Menu.Item>
      </Menu>
    )

    const columns = [
      {
        title: 'INVOICE NO',
        key: 'invoiceNo',
        dataIndex: 'invoiceNo',
        render: (val, record) => {
          return (
            <div>
              <Tag color="#108ee9">{val}</Tag><br/>
            </div>
          )
        }
      },
      {
        title: 'DATE',
        key: 'dateString',
        dataIndex: 'dateString',
        render: (val, record) => {
          return (
            <div>
              {record.dateString}<br/>

            </div>
          )
        }
      },
      {
        title: 'TYPE',
        key: 'type',
        dataIndex: 'type',
        render: (val, record) => {
          return (
            <div>
              {record.type}
              <small>
                {record.username ? ` ( By ${record.username} ) ` : null}
              </small>
            </div>
          )
        }
      },
      {
        title: 'NAME',
        key: 'clientVendorName',
        dataIndex: 'clientVendorName',
        render: (val, record) => {
          return (
            <div>
              {val}<br/>
              {record.subClient && record.subClient.name ?
                <span> Sub Client : ({record.subClient.name})</span> : null}
            </div>
          )
        }
      },
      {
        title: 'AMOUNT',
        key: 'amount',
        dataIndex: 'amount',
        render: (val, record) => {
          return (
            <div>
              {val}<br/>
              {record.debit ? `(Debit)` : null}
              {record.credit ? `(Credit)` : null}
            </div>
          )
        }
      },
      {
        title: 'IN',
        key: 'credit',
        dataIndex: 'credit',
        render: (val, record) => {
          return (
            <div>
              {record.credit ? record.paidReceivedAmount : null}
            </div>
          )
        }
      },
      {
        title: 'OUT',
        key: 'debit',
        dataIndex: 'debit',
        render: (val, record) => {
          return (
            <div>
              {record.debit ? record.paidReceivedAmount : null}
            </div>
          )
        }
      },
      {
        title: 'COPY',
        dataIndex: 'copy',
        key: 'copy',
        render: (val, record) => {
          return (
            <div className={'actionGroup'}>
              <Button type={'primary'} size={'small'}
                      onClick={() => this.openPage(record)}>
                Edit
              </Button>
              <Button type={'warning'} size={'small'}>
                Set Reminder <Icon type="clock-circle"/>
              </Button>

              {record.type == 'SALE' || record.type == 'CREDIT_NOTE' || record.type == 'DEBIT_NOTE' || record.type == 'PURCHASE' || record.type == 'ESTIMATE'
                ? <Button type={'info'} size={'small'}
                          onClick={() => this.openLink(record.type, record.linkId)}>Copy</Button> : null
              }
              {record.type == 'SALE' ?
                <Button type={'success'} size={'small'}
                        onClick={() => {
                          this.setState({
                            showMultiCopy: true,
                            saleId: record.linkId
                          })
                        }}>Multi Copy</Button> : null}

            </div>
          )
        }
      }
    ]
    return (
      <PageHeaderWrapper
        title={'Day Book'}>
        <Card bordered={true}>
          <Row gutter={16}>
            <Col span={'8'}>
              <InputBox title={'Filter By Date'} className={'rowFlex'}>
                <DatePicker onChange={this.onChooseDate} value={fromDate} placeholder={'Choose Date'}/>
              </InputBox>
            </Col>
            <Col span={'16'} className={'alignRight'}>
              <InputBox title={''} className={'mt20'}>
                <Dropdown.Button overlay={menu}>Print</Dropdown.Button>
              </InputBox>
            </Col>
          </Row>
          <Table columns={columns} dataSource={arrData} size={'small'} bordered={true}
                 pagination={false}
          />

          <Modal
            title="Generate Multi Sale"
            visible={this.state.showMultiCopy}
            onOk={this.confirmMultiCopy}
            okText={'Submit'}
            onCancel={() => this.closeMultiModal()}>
            <InputBox title={'Number of sales'}>
              <Input type={'number'}
                     value={this.state.count}
                     onChange={(e) => {
                       let count = e.target.value
                       if (count) {
                         let splitX = count.split('.')
                         if (splitX.length > 1) {
                           return true
                         }
                         this.setState({ count })
                       } else {
                         this.setState({ count: null })
                       }
                     }}/>
            </InputBox>
            <InputBox title={'Sale Date'}>
              <DatePicker onChange={this.onChooseSaleDate} value={this.state.saleDate} placeholder={'Date of sale'}/>
            </InputBox>
          </Modal>

        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DayBook)
