import React, { Component } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import {
  Table, Select,
  Card, Menu, Modal,
  Input, Button, Icon, Tag, Row, Col, DatePicker, notification, Dropdown
} from 'antd'
import Request from '../../request'
import _ from 'lodash'
import moment from 'moment'
import debounce from 'lodash/debounce'
import styles from './styles.less'
import { connect } from 'react-redux'
import { hideLoader, showLoader, showPageLoad, hidePageLoad } from '../../modules/actions'
import { getUrlPushWrapper, getPushPathWrapper } from '../../routes'
import { InputBox } from '../webComponent/appDes'
import PrintStyle from '../html/style'
import PrintHeader from '../html/header'
import { HtmlPrint } from '../html/print'
import { AsyncStorage } from '../../asyncStorage'
import { FilterChooseClient } from '../webComponent/appDes'

let { Option } = Select

let itemArr = ''

class CustomReport extends Component {

  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      arrData: [],

      fromDate: moment(),
      toDate: moment(),
      saleDate: moment(),
      clientName: '',
      isSpinner: false,
      lengthOfArr: 0,
      total: 10,
      totalDebit: 0,
      totalCredit: 0,
      count: null,
      type: null
    }
  }

  componentDidMount() {
    this.AllTransactionsReport()
    AsyncStorage.getUserData().then((value) => {
      if (value !== null) {
        this.setState({
          userType: value.type
        })
      } else {
      }
    })
  }

  AllTransactionsReport() {
    let params = {
      clientId: this.state.clientId,
      type: this.state.type,
      from: this.state.fromDate,
      to: this.state.toDate,
      total: this.state.total
    }

    this.props.dispatch(showPageLoad())
    itemArr = ''
    Request.AllTransactions(params)
      .then((testData) => {

        this.props.dispatch(hidePageLoad())
        _.each(testData.result, (ff) => {
          let { subClient } = ff
          ff.dtstr = moment(ff.createdOn).format('DD-MMM-YYYY')
          ff.ammt = 0
          if (ff.paidReceivedAmount) {
            if (ff.paidReceivedAmount == null) {
              ff.ammt = 0
            } else {
              ff.ammt = ff.paidReceivedAmount
            }
          }


          itemArr += '<tr>' +
            '<td>' + ff.dtstr + '</td>' +
            '<td>' + ff.type + '</td>  ' +
            '<td>' + ff.username + '</td>  ' +
            '<td>' + `${ff.clientVendorName} ${subClient && subClient.name ? `(${subClient.name})` : ''}` + '</td>' +
            '<td>' + ff.ammt + '</td>' +
            '</tr>'
        })
        this.setState({ arrData: testData.result, lengthOfArr: testData.total, isSpinner: false })
        return
      })
      .catch((e) => {
        this.props.dispatch(hidePageLoad())
        return
      })
  };

  createPdf = async (typestr) => {


    let html = `<!doctype html>
                               <html>
                 <head>
   <meta charset="utf-8">
   <title>Custom Reports</title>
   <style>
   
   
      @media print {
      
      @page{
       margin: 20px;
       } 
body, html {
margin-top:0px;
padding-top:0px;
}
}
   
thead {display: table-header-group;}

      
   
       .invoice-box {
           /*max-width: 800px;*/
           margin: 0px;           
           padding: 10px;         
           border: 1px solid #eee;
           box-shadow: 0 0 10px rgba(0, 0, 0, .15);
           font-size: 12px;
           line-height: 14px;
           font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
           color: #555;
       }
       .invoice-box table {
           width: 100%;
           line-height: inherit;
           text-align: left;
       }
       .invoice-box table td {
           padding: 5px;
           vertical-align: top;
           
       }

       .invoice-box table tr td:nth-child(2) {
           text-align: right;
       }
         .invoice-box table tr td {
           text-align: left;
       }

       .invoice-box table tr.top table td {
           padding-bottom: 0px;
       }

       .invoice-box table tr.top table td.title {
           font-size: 14px;
           line-height: 20px;
           color: #333;
       }

       .invoice-box table tr.information table td {
           padding-bottom: 20px;
       }

       .invoice-box table thead.heading th {
           background: #eee;
           border-bottom: 1px solid #ddd;
           font-weight: bold;
       }

       .invoice-box table tr.details td {
           padding-bottom: 20px;
       }

       .invoice-box table tr.item td{
           border-bottom: 1px solid #eee;
           font-size: 12px;
       }

       .invoice-box table tr.item.last td {
           border-bottom: none;
           
       }

       .invoice-box table tr.total td:nth-child(2) {
           border-top: 2px solid #eee;
           font-weight: bold;
       }




       @media only screen and (max-width: 600px) {
           .invoice-box table tr.top table td {
               width: 100%;
               display: block;
               text-align: center;
           }

           .invoice-box table tr.information table td {
               width: 100%;
               display: block;
               text-align: center;
           }
       }

       /** RTL **/
       .rtl {
           direction: rtl;
           font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
       }

       .rtl table {
           text-align: right;
       }

       .rtl table tr td:nth-child(2) {
           text-align: left;
       }
       .tableBody tr:nth-child(even){
            background-color: #f2f2f2;
       }
       
   </style>
   ${PrintStyle}
</head>
<body>
<div class="invoice-box">   
   <table class="itemTable font10 alternate_table">
       <tr>
           <th> Date </th>
           <th>Transaction</th>
           <th>Username</th>
           <th>Party-Client</th>
           <th>Amount</th>
       </tr>        
       <tbody class="tableBody">   
      ` + itemArr + `
       </tbody>
   </table>   
</div>
</body>
</html>`

    html = await PrintHeader('Custom Report') + html

    /* if (typestr == 'print') {

       HtmlPrint(html)

       return
     } else {


     }*/
    HtmlPrint(html)


  }

  printStateFxn = (v) => {


    if (v == 'Share Simple Day Book') {
      this.createPdf('share')
    }
    if (v == 'Share Detailed Day Book') {

      let params = {

        from: this.state.fromDate,
        to: this.state.toDate

      }

      this.props.dispatch(showPageLoad())

      Request.DetailedDayBook(params)
        .then((testData) => {
          this.props.dispatch(hidePageLoad())
          /*this.props.navigation.navigate('DayBookDetailPrint', {
            arr: testData,
            day: moment(this.state.fromDate).format('DD-MMM-YYYY')
          })*/

        })
        .catch((e) => {
          this.props.dispatch(hidePageLoad())

          return
        })


    }

  }

  onChooseDate = (date, dateString) => {
    this.setState({ fromDate: date }, () => {
      this.AllTransactionsReport()
    })
  }

  onChooseSaleDate = (date, dateString) => {
    this.setState({ saleDate: date })
  }


  _chooseType(type) {
    this.setState({ type })
    setTimeout(() => {
      this.AllTransactionsReport()
    }, 500)
  }

  openPage = (val) => {
    let { dispatch } = this.props
    if (this.state.userType == 'Admin') {
      let { dispatch } = this.props
      if (val.type == 'SALE') {
        dispatch(
          getPushPathWrapper('sale.editSale', {
            id: val.linkId
          })
        )
      }
      if (val.type == 'PURCHASE') {
        dispatch(
          getPushPathWrapper('purchase.editPurchase', {
            id: val.linkId
          })
        )
      }
      if (val.type == 'CREDIT_NOTE') {
        dispatch(
          getPushPathWrapper('creditNote.editCreditNote', {
            id: val.linkId
          })
        )
      }
      if (val.type == 'PAYMENT_IN') {

      }
      if (val.type == 'PAYMENT_OUT') {

      }
      if (val.type == 'ESTIMATE') {

      }
      if (val.type == 'DEBIT_NOTE') {
        dispatch(
          getPushPathWrapper('debitNote.editDebitNote', {
            id: val.linkId
          })
        )
      }
      if (val.type == 'JE') {

      }

    } else {
      notification.error({
        message: 'You are not allowed.'
      })
    }

  }


  _callbackClient = (val) => {
    this.setState({ ...val }, () => {
      this.AllTransactionsReport()
    })
  }


  _refreshTable = () => {
    this.setState({
      clientId: null,
      clientName: null,
      sType: 'Client',
      arr: []
    })
    setTimeout(() => {
      this.AllTransactionsReport()
    }, 100)
  }


  render() {
    let { dispatch } = this.props
    let { arrData, fromDate, toDate } = this.state

    const columns = [
      {
        title: 'DATE',
        key: 'dateString',
        dataIndex: 'dateString',
        render: (val, record) => {
          return (
            <div>
              {record.dateString}

            </div>
          )
        }
      },
      {
        title: 'NAME',
        key: 'clientVendorName',
        dataIndex: 'clientVendorName',
        render: (val, record) => {
          return (
            <div>
              {val}<br/>
              {record.subClient && record.subClient.name ?
                <span> Sub Client : ({record.subClient.name})</span> : null}
            </div>
          )
        }
      },

      {
        title: 'TYPE',
        key: 'type',
        dataIndex: 'type',
        render: (val, record) => {
          return (
            <div>
              {record.type}
              <small>
                {record.username ? ` (By ${record.username}) ` : null}
              </small>
            </div>
          )
        }
      },
      {
        title: 'AMOUNT',
        key: 'amount',
        dataIndex: 'amount',
        render: (val, record) => {
          return (
            <div>
              <Tag color="green">Amount : {val}</Tag>
              <Tag color="blue">Paid/Received : {record.paidReceivedAmount ? record.paidReceivedAmount : 0}</Tag>
              <Tag color="magenta">Balance : {record.balance ? record.balance : 0}</Tag>

            </div>
          )
        }
      },
      {
        title: 'COPY',
        dataIndex: 'copy',
        key: 'copy',
        render: (val, record) => {
          return (
            <div className={'actionGroup'}>
              <Button type={'primary'} size={'small'}
                      onClick={() => this.openPage(record)}>Edit</Button>
            </div>
          )
        }
      }
    ]
    return (
      <PageHeaderWrapper
        title={'Custom Report'}>
        <Card bordered={true}>
          <Row gutter={16}>
            <Col span={'5'}>
              <InputBox title={'FROM DATE'}>
                <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'fromDate')}
                            value={fromDate} placeholder={'From Date'}/>
              </InputBox>
            </Col>
            <Col span={'7'}>
              <InputBox title={'TO DATE'} className={'rowFlex'}>
                <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'toDate')}
                            value={toDate} placeholder={'To Date'}/>
                <Button onClick={this._refreshTable}>Refresh</Button>
              </InputBox>
            </Col>
            <Col span={'5'}>
              <InputBox title={'CLIENT NAME'}>
                <FilterChooseClient _callbackClient={this._callbackClient}/>
              </InputBox>
            </Col>
            <Col span={'7'}>
              <InputBox title={'TRANSACTIONS TYPE'} className={'rowFlex'}>
                <Select onChange={(type) => this._chooseType(type)}>
                  <Option value={''}>All Transactions</Option>
                  <Option value={'SALE'}>Sale</Option>
                  <Option value={'PURCHASE'}>Purchase</Option>
                  <Option value={'PAYMENT_IN'}>Payment In</Option>
                  <Option value={'PAYMENT_OUT'}>Payment Out</Option>
                  <Option value={'CREDIT_NOTE'}>Credit Note</Option>
                  <Option value={'DEBIT_NOTE'}>Debit Note</Option>
                  <Option value={'ESTIMATE'}>Estimate</Option>
                </Select>

                <Button onClick={() => this.createPdf('print')}>Print</Button>
              </InputBox>
            </Col>
          </Row>
          <Table columns={columns} dataSource={arrData} size={'small'} bordered={true}
                 pagination={false}
          />


        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomReport)
