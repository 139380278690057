import _ from 'lodash'

const customerInfo = (data) => {
  return new Promise((resolve) => {
    let customerTable = ''
    let customerAddress = ''
    let invoiceLeftTable = ''
    let invoiceTable = ''

    _.each(data.address, (item, key) => {
      customerAddress += `${data.address[key]} <br/>`
    })

    _.each(data.customer, (item, key) => {
      customerTable += `<tr>
                        <td class='upperCase'>${key}</td>
                        <td>:</td>
                        <td>${data.customer[key] ? `<b>${data.customer[key]}</b>` : ''}</td>
                    </tr>`
    })

    _.each(data.invoiceInfo, (item, key) => {
      invoiceTable += `<tr>
                        <td class='capCase'>${key}</td>
                        <td>:</td>
                        <td>${data.invoiceInfo[key] ? data.invoiceInfo[key] : ''}</td>
                    </tr>`
    })

    _.each(data.invoiceLeftInfo, (item, key) => {
      invoiceLeftTable += `<tr>
                        <td class='capCase'>${key}</td>
                        <td>:</td>
                        <td>${data.invoiceLeftInfo[key] ? key == 'E-way bill no' ? `<b>${data.invoiceLeftInfo[key]}</b>` : data.invoiceLeftInfo[key] : ''}</td>
                    </tr>`
    })

    let html = `<div class="row border-bottom box2">
        <div class="col-md-6 border-right">
           <div class="padding10 border-bottom">
                <i class="b font14">
                    Party Details :
                </i>
                <div class="font10">
                   ${customerAddress}
                </div>
                <br/>
               <table class="font10">
                    ${customerTable}
                </table>
           </div>
           <div class="padding10">
                <table class="font10">
                    ${invoiceLeftTable}
                </table>
           </div> 
        </div>
        <div class="col-md-6">
            <table class="font10">
            ${invoiceTable}
            </table>
        </div>
    </div>`

    resolve(html)
  })

}

export default customerInfo
