import React, { Component } from 'react'
import {
  Drawer,
  Input,
  notification,
  Card,
  Icon,
  Row,
  Col,
  Select,
  Button,
  Popconfirm,
  Alert,
  Modal,
  Descriptions
} from 'antd'
import { InputBox } from '../webComponent/appDes'
import debounce from 'lodash/debounce'
import moment from 'moment'
import Request from '../../request'
import { showPageLoad, hidePageLoad } from '../../modules/actions'

const { Option } = Select

const taxTypeList = [
  { value: null, label: '--Choose--' },
  { value: 1, label: 'Road' },
  { value: 2, label: 'Rail' },
  { value: 3, label: 'Air' },
  { value: 4, label: 'Ship' }
]

const subList = [
  { value: null, label: '--Choose--' },
  { value: 1, label: 'Supply' },
  { value: 3, label: 'Export' },
  { value: 4, label: 'Job Work' },
  { value: 9, label: 'SKD/CKD/Lots' }
]

const transList = [
  { value: null, label: '--Choose--' },
  { value: 1, label: 'Regular' },
  { value: 2, label: 'Bill To - Ship To' },
  { value: 3, label: 'Bill From - Dispatch From' },
  { value: 4, label: 'Combination of 2 and 3' }
]

class generateEwayBill extends React.Component {
  events = {
    chooseMode: (data) => {
      this.setState({
        mode: data && data.value ? data.value : '',
        modeName: data && data.label ? data.label : ''
      })
    },
    chooseSubType: (data) => {
      this.setState({
        subType: data && data.value ? data.value : '',
        subTypeName: data && data.label ? data.label : ''
      })
    },
    chooseTransaction: (data) => {
      this.setState({
        transType: data && data.value ? data.value : '',
        transTypeName: data && data.label ? data.label : ''
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = {

      item: {},
      transType: null,
      mode: null,
      subType: null,
      loadNow: false,
      errorMsg: '',
      showError: false,
      showResponse: false,
      infoObj: {},
      cancelModal: false,
      generateEwayModal: false,
      transTypeName: null,
      modeName: null,
      subTypeName: null
    }
  }

  componentDidMount() {
    const { generateEwayBillObj } = this.props

    this.setState({ item: generateEwayBillObj })

  }

  findDis = async () => {

    let { distance } = await Request.findDistance({ to: this.state.item && this.state.item.pinCode })
    if (distance) {
      this.setState({ distance })
    }

  }

  cancelBill = async () => {
    const { item } = this.state
    let obj = {
      ...item
    }

    this.props.dispatch(showPageLoad())
    Request.cancelEWayBill(obj)
      .then((resp) => {
        this.props.dispatch(hidePageLoad())


        if (resp && resp.type) {
          notification.success({
            message: resp.msg
          })
          this.props.callbackEwayBill()

          return
        } else {

          this.props.dispatch(hidePageLoad())
          this.setState({
            errorMsg: resp.msg, showError: true
          })
          return

        }

      })
      .catch((e) => {
        this.props.dispatch(hidePageLoad())
        return
      })

  }

  generateBill = () => {

    const { item, distance, mode, subType, transType } = this.state
    if (item.transportType && item.transportType == 'Transport') {
      if (!item.transportId) {
        return notification.error({
          message: 'Choose Transport'
        })
      }
    }


    if (!distance) {
      return notification.error({

        message: 'Enter Distance'
      })
    }


    if (!mode) {
      return notification.error({

        message: 'Choose Mode'
      })
    }

    if (!subType) {
      return notification.error({

        message: 'Choose Sub Type'
      })
    }

    if (!transType) {
      return notification.error({

        message: 'Choose Trans Type'
      })
    }


    if (item.totalAmount < 50000) {
      return notification.error({

        message: 'Minimum Invoice Amount is 50000'
      })
    }

    let obj = {
      ...item,
      distance, mode, subType, transType
    }

    this.props.dispatch(showPageLoad())
    Request.generateEWayBill(obj)
      .then((resp) => {
        this.setState({ loadNow: false })


        if (resp && resp.type) {
          notification.success({
            message: resp.msg
          })
          this.setState({ infoObj: resp.obj }, () => {
            this.props.dispatch(hidePageLoad())
            this.showModal()
          })

          return
        } else {
          this.props.dispatch(hidePageLoad())
          this.setState({
            errorMsg: resp.msg, showError: true
          })
          return
          /*return notification.error({
              message: resp.msg
          });*/

        }

      })
      .catch((e) => {
        this.props.dispatch(hidePageLoad())
        return
      })

  }

  showModal() {
    let { infoObj } = this.state
    Modal.info({
      title: 'E-way Bill Details',
      content: (
        <div className={'ewayInfo'}>
          <Row>
            <Col span={12}>
              E-way Bill no
            </Col>
            <Col span={12}>
              {infoObj.eWayBillNo}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              Eway Bill Date
            </Col>
            <Col span={12}>
              {infoObj.ewayBillDate}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              Valid Upto
            </Col>
            <Col span={12}>
              {infoObj.validUpto}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {infoObj.alert ? <Alert
                message={infoObj.alert}
                type="info"
                showIcon
              /> : null}
            </Col>
          </Row>


        </div>
      ),
      onOk: () => {
        this.props.callbackEwayBill()
      }
    })
  }


  render() {
    let { visible } = this.props
    let { item, infoObj, showError } = this.state
    return (
      <Drawer
        title="Generate Eway"
        width={800}
        closable={false}
        onClose={this.props.onCloseEwayBill}
        visible={visible}>
        <Card>

          {item && item.jsonCreated && <Alert
            message="Eway JSON already created for this invoice"
            type="success"
            showIcon
          />}

          <Row gutter={16} className={'mt20'}>
            <Col span={12}>
              <InputBox title={'Transport'}>
                <div className={'ant-input'}>
                  {item && item.transportName}
                </div>
              </InputBox>
            </Col>

            <Col span={12}>
              <InputBox title={'Vehicle Number'}>
                <div className={'ant-input'}>
                  {item && item.vehicleNo}
                </div>
              </InputBox>
            </Col>
            <Col span={12}>
              <InputBox title={'To Place'}>
                <div className={'ant-input'}>
                  {item && item.pinCode} , {item && item.stateOfSupply}
                </div>
              </InputBox>
            </Col>
            <Col span={12}>
              <InputBox title={'Distance'} className={'rowFlex'}>
                <Input type={'number'} onChange={(distance) => {
                  this.setState({ distance })
                }} value={this.state.distance}/>
                <a className={'linkBtn'} onClick={() => this.findDis()}>
                  Find
                </a>
              </InputBox>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <InputBox title={'Mode Of Transport'}>
                <Select onChange={this.events.chooseMode} value={this.state.modeName}>
                  {taxTypeList.length ? taxTypeList.map((item) => {
                    return (
                      <Option value={item} key={item.value}>
                        {item.label}
                      </Option>
                    )
                  }) : null}
                </Select>
              </InputBox>
            </Col>
            <Col span={8}>
              <InputBox title={'Sub Type'}>
                <Select onChange={this.events.chooseSubType} value={this.state.subTypeName}>
                  {subList.length ? subList.map((item) => {
                    return (
                      <Option value={item} key={item.value}>
                        {item.label}
                      </Option>
                    )
                  }) : null}
                </Select>
              </InputBox>
            </Col>
            <Col span={8}>
              <InputBox title={'Transaction Type'}>
                <Select onChange={this.events.chooseTransaction}
                        value={this.state.transTypeName}>
                  {transList.length ? transList.map((item) => {
                    return (
                      <Option value={item} key={item.value}>
                        {item.label}
                      </Option>
                    )
                  }) : null}
                </Select>
              </InputBox>
            </Col>
          </Row>
          <Row gutter={16} className={'mt10'}>
            <Col span={12}>
              <InputBox title={''}>
                <div className={'ant-input'}>From Place: Karnal ; Pin Code: 132001</div>
              </InputBox>
            </Col>
            <Col span={12}>
              <InputBox title={''}>
                <div className={'ant-input'}>Bill Amt : <b>Rs. {item.totalAmount}</b></div>
              </InputBox>
            </Col>
          </Row>
          <Row className={'mt10'}>
            <Col>

              {item && !item.cancelled && item.eWayBillNo ?
                <Popconfirm placement="top" title={'Are you sure to cancel E-way Bill ? '}
                            onConfirm={debounce(() => {
                              this.cancelBill()
                            }, 400)}
                            okText="Yes" cancelText="No">
                  <Button type={'danger'} block>
                    Cancel E-way Bill
                  </Button>
                </Popconfirm> :
                item.eWayBillNo ? null :
                  <Popconfirm placement="top" title={'Are you sure, you want to Generate E-Way bill? '}
                              onConfirm={debounce(() => {
                                this.generateBill()
                              }, 400)}
                              okText="Yes" cancelText="No">
                    <Button type={'success'} block size={'large'}>
                      Generate E way bill
                    </Button>
                  </Popconfirm>
              }

            </Col>
            <Col className={'mt20'}>
              {showError ?
                <Alert
                  message="Error"
                  description={this.state.errorMsg}
                  type="error"
                  showIcon
                /> : null}
            </Col>
          </Row>

        </Card>
      </Drawer>
    )
  }
}

export default generateEwayBill
