import React, { Component } from 'react'
import { Input, Button, notification, Card, Row, Col, Table, Modal, Popconfirm, List, Select, Divider } from 'antd'
import { InputBox } from '../../webComponent/appDes'
import { AsyncStorage } from '../../../asyncStorage'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import Request from '../../../request'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import BusinessBankDetails from '../businessBankDetails'
import AllStates from '../../../components/locationJson/state'
import AllCitites from '../../../components/locationJson/cities'
import { connect } from 'react-redux'
import lodash from 'lodash'

const { TextArea } = Input
const { Option } = Select

class EditBusiness extends React.Component {
  events = {
    showBankDrawerFxn: () => {
      this.setState({ visibleBankDrawer: true })
    },
    showConditionDrawerFxn: () => {
      this.setState({ visibleConditionDrawer: true })
    },
    onBankClose: () => {
      this.setState({ visibleBankDrawer: false })
    },
    onConditionClose: () => {
      this.setState({ visibleConditionDrawer: false })
    },
    callbackBank: (data) => {
      let cloneBankDetails = lodash.clone(this.state.bankDetails)
      if (cloneBankDetails && cloneBankDetails.length == 2) {
        return notification.error({
          message: 'You can add only 2 banks.'
        })
      }
      cloneBankDetails.push(data)
      this.setState({ bankDetails: cloneBankDetails, visibleBankDrawer: false })
    },
    callbackCondition: (data) => {
      let { conditions, condition, conditionKey } = this.state
      if (conditionKey) {
        conditions[conditionKey] = condition
      } else {
        conditions.push(condition)
      }
      this.setState({ conditions, visibleConditionDrawer: false, condition: '', conditionKey: '' })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      mobile: '',
      address: '',
      gstIn: '',
      city: '',
      bankDetails: [],
      conditions: [],
      selectedCondition: {},
      visibleBankDrawer: false,
      visibleConditionDrawer: false,
      citiesArr: [],
      arr: []

    }
  }

  async componentDidMount() {
    let businessDetails = await AsyncStorage.getBusiness()
    if (businessDetails && businessDetails.uid) {
      this.getBusiness(businessDetails.uid)
    }
    this._allState()
  }

  async getBusiness(id) {
    this.props.dispatch(showPageLoad())
    let resp = await Request.singleBusiness({ uid: id })
    console.log(resp)
    if (resp && resp.type) {
      this.setState({ ...resp.doc })
      this.props.dispatch(hidePageLoad())
    } else {
      this.props.dispatch(hidePageLoad())
    }
  }

  save = () => {


    if (!this.state.name) {
      notification.warn({
        message: 'Enter Business Name'
      })
      return
    }

    if (!this.state.mobile) {
      notification.warn({
        message: 'Enter Business Mobile No'
      })
      return
    }
    if (!this.state.state) {
      notification.warn({
        message: 'Choose state'
      })
      return
    }
    if (!this.state.city) {
      notification.warn({
        message: 'Choose city'
      })
      return
    }
    if (!this.state.pinCode) {
      notification.warn({
        message: 'Enter pincode.'
      })
      return
    }
    if (!this.state.address) {
      notification.warn({
        message: 'Enter Business Address'
      })
      return
    }
    this.props.dispatch(showPageLoad())
    Request.UpdateBusiness(this.state)
      .then((testData) => {
        if (testData.type) {
          notification.success({
            message: testData.msg
          })
          AsyncStorage.setBusiness(testData.doc)
          this.props.dispatch(hidePageLoad())
        } else {
          notification.error({
            message: testData.msg
          })
        }
      })
      .catch((e) => {
        notification.error({
          message: e
        })
        this.props.dispatch(hidePageLoad())
        return
      })


  }

  _allState = () => {
    let stateArr = lodash.clone(AllStates)
    this.setState({ arr: stateArr })

  }

  removeBank(key) {
    let { bankDetails } = this.state
    bankDetails.splice(key, 1)
    this.setState({ bankDetails })
  }

  removeCondition(key) {
    let { conditions } = this.state
    conditions.splice(key, 1)
    this.setState({ conditions })
  }

  editCondition(condition, key) {
    this.setState({ condition, conditionKey: key, visibleConditionDrawer: true })
  }

  updateField = (e) => {
    let { name, value } = e.target
    this.setState({ [name]: value })
  }

  chooseState = (data) => {
    let obj = {
      state: '',
      stateId: '',
      stateCode: ''
    }
    if (data) {
      obj = {
        state: data.name,
        stateId: data.id,
        stateCode: data.stateCode
      }
    }
    this.setState({ ...obj }, () => {
      this.loadCities()
    })
  }
  chooseCity = (data) => {
    this.setState({ city: data, cityChoosed: true })
  }


  render() {
    let { bankDetails, visibleBankDrawer, conditions, visibleConditionDrawer, selectedCondition, arr } = this.state
    let columns = [
      {
        title: 'Bank Name',
        key: 'bankName',
        dataIndex: 'bankName'
      },
      {
        title: 'Account No',
        key: 'accountNo',
        dataIndex: 'accountNo',
        type: 'number'
      },
      {
        title: 'IFSC Code',
        key: 'ifsc',
        dataIndex: 'ifsc'
      },
      {
        title: 'Bank Address',
        key: 'bankAddress',
        dataIndex: 'bankAddress'
      },
      {
        title: 'Action',
        key: 'action',
        dataIndex: 'action',
        render: (val, record, index) => {
          return (
            <div>
              <Popconfirm title={'Are you sure, your want to remove Bank?'}
                          onConfirm={() => this.removeBank(index)}
                          okText="Yes" cancelText="No">
                <Button
                  type="danger"
                  shape="circle"
                  icon="delete"
                />
              </Popconfirm>
            </div>
          )
        }
      }

    ]
    return (
      <PageHeaderWrapper
        title={'Edit Business'}>
        <Card title={`${this.state.name}`}
              extra={<Button type="success" onClick={this.save}>Update</Button>}>
          <Row gutter={16}>
            <Col span={12}>
              <Card title={'Basic Details'} size={'small'}>
                <Row gutter={16}>
                  <Col>
                    <InputBox title={'Business Name'}>
                      <Input value={this.state.name} onChange={this.updateField} placeholder={'Business Name'}
                             name={'name'}/>
                    </InputBox>
                  </Col>
                  <Col>
                    <InputBox title={'Business Display Name'}>
                      <Input value={this.state.displayName} onChange={this.updateField}
                             placeholder={'Business Display Name'}
                             name={'displayName'}/>
                    </InputBox>
                  </Col>
                  <Col span={12}>
                    <InputBox title={'Contact Number'}>
                      <Input type={'number'} value={this.state.mobile} onChange={this.updateField} maxLength={10}
                             placeholder={'Contact Number'}
                             name={'mobile'}/>
                    </InputBox>
                  </Col>
                  <Col span={12}>
                    <InputBox title={'Business Email'}>
                      <Input type={'email'} value={this.state.mobile} onChange={this.updateField}
                             placeholder={'Business Email'}
                             name={'email'}/>
                    </InputBox>
                  </Col>
                  <Col span={12}>
                    <InputBox title={'Choose State'}>
                      <Select
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, options) => {
                          return options.props.children.match(new RegExp(input, 'ig'))
                        }}
                        allowClear={true}
                        value={this.state.state}
                        onChange={this.chooseState}
                        placeholder="Choose state">
                        {arr.map((item) => {
                          return (
                            <Option key={item.uid} value={item}>{item.name}</Option>
                          )
                        })}
                      </Select>
                    </InputBox>
                  </Col>
                  <Col span={12}>
                    <InputBox title={'Choose City'}>
                      <Select
                        showSearch={true}
                        allowClear={true}
                        value={this.state.city}
                        onChange={this.chooseCity}
                        placeholder="Choose City">
                        {this.state.citiesArr.map((item) => {
                          return (
                            <Option key={item.uid} value={item.name}>{item.name}</Option>
                          )
                        })}
                      </Select>
                    </InputBox>
                  </Col>

                  <Col span={24}>
                    <InputBox title={'Business Address'}>
                  <TextArea value={this.state.address} onChange={this.updateField} row={2}
                            placeholder={'Business Address'}
                            name={'address'}></TextArea>
                    </InputBox>
                  </Col>

                  <Col span={12}>

                    <InputBox title={'Pin Code'}>
                      <Input name={'pinCode'} value={this.state.pinCode} type={'number'}
                             onChange={this.updateField}
                             placeholder={'Enter Pincode'}/>
                    </InputBox>
                  </Col>
                  <Col span={12}>
                    <InputBox title={'Business GSTIN'}>
                      <Input value={this.state.gstIn} onChange={this.updateField}
                             placeholder={'Business GSTIN'}
                             name={'gstIn'}/>
                    </InputBox>
                  </Col>
                  <Col span={24}>
                    <Divider orientation="left" plain style={{ top: 5 }}>
                      Ewaybill Login Details
                    </Divider>
                  </Col>
                  <Col span={12}>
                    <InputBox title={'Ewaybill Username'}>
                      <Input value={this.state.ewayUsername} onChange={this.updateField}
                             placeholder={'Ewaybill Username'}
                             name={'ewayUsername'}/>
                    </InputBox>
                  </Col>
                  <Col span={12}>
                    <InputBox title={'Ewaybill Password'}>
                      <Input value={this.state.ewayPassword} onChange={this.updateField}
                             placeholder={'Ewaybill Password'}
                             name={'ewayPassword'}/>
                    </InputBox>
                  </Col>
                </Row>
              </Card>

            </Col>
            <Col span={12}>

              <Card title={'Banks'} size={'small'}
                    extra={
                      <Button onClick={this.events.showBankDrawerFxn} type={'info'}>Add Bank</Button>
                    }>
                <Table columns={columns} size={'small'} bordered={true}
                       dataSource={bankDetails} pagination={false}/>

              </Card>
              <Card title={'Business Condition'} size={'small'} className={'mt20'}
                    extra={<Button onClick={this.events.showConditionDrawerFxn} type={'info'}>Add Business
                      Condition</Button>}>
                <List
                  size="small"
                  title={'Business Conditions'}
                  dataSource={conditions}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[<a key="list-loadmore-edit" onClick={() => this.editCondition(item, index)}>edit</a>,
                        <a key="list-loadmore-more" onClick={() => this.removeCondition(index)}>Remove</a>]}
                    >
                      <List.Item.Meta
                        title={<a>{item}</a>}
                      />
                    </List.Item>
                  )}

                />
              </Card>


            </Col>
          </Row>
          {visibleBankDrawer ?
            <BusinessBankDetails
              callbackBank={this.events.callbackBank}
              onBankClose={this.events.onBankClose}
              // bankDetails={bankDetails}
              visible={visibleBankDrawer}
              {...this.props}
            />
            : null}

          {visibleConditionDrawer ?
            <Modal
              title="Title"
              visible={visibleConditionDrawer}
              onOk={this.events.callbackCondition}
              onCancel={this.events.onConditionClose}>
              <p>
                <InputBox title={'Conditions'}>
                   <TextArea name={'condition'}
                             onChange={this.updateField}
                             value={this.state.condition}>
                                </TextArea>
                </InputBox>
              </p>
            </Modal>
            : null}
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBusiness)
