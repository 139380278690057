import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input,
  Button,
  Icon,
  Row,
  Col,
  Tag, Divider, Select, Form
} from 'antd'
import Request from '../../../request'
import { connect } from 'react-redux'
import { hideLoader, showLoader, showPageLoad, hidePageLoad } from '../../../modules/actions'
import AddJournalEntry from '../add/index'
import EditJournalEntry from '../edit/index'

const FormItem = Form.Item
const { Option } = Select


class AllJournalEntry extends Component {

  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      journalEntryObj: {}
    }
    this.table = React.createRef()
  }


  componentDidMount() {
    this.AllJournalEntry()

  }

  AllJournalEntry = () => {
    this.props.dispatch(showPageLoad())
    Request.GetJournalEntry({})
      .then((testData) => {
        this.setState({ arr: testData.result })
        this.props.dispatch(hidePageLoad())
        return
      })
      .catch((e) => {
        this.props.dispatch(hidePageLoad())
        return
      })

  }
  events = {
    onJournalEntryClose: () => {
      this.setState({ addJournalEntryVisible: false, editJournalEntryVisible: false })
    },
    addJournalEntryCallback: () => {
      this.setState({ addJournalEntryVisible: false, editJournalEntryVisible: false }, () => {
        this.AllJournalEntry()
      })
    },
    eddJournalEntry: (journalEntryObj) => {
      this.setState({ editJournalEntryVisible: true, journalEntryObj })
    }
  }


  render() {
    let { pageType, title, arr, addJournalEntryVisible, editJournalEntryVisible, journalEntryObj } = this.state
    const columns = [
      {
        title: 'Voucher',
        key: 'invoiceNo',
        dataIndex: 'invoiceNo'
      },
      {
        title: 'From Client',
        dataIndex: 'toClient',
        key: 'toClient',
        render: (val, record) => {
          return (
            <div>
              {val && val.clientName ? val.clientName : null}
            </div>
          )
        }
      },
      {
        title: 'To Client',
        dataIndex: 'fromClient',
        key: 'fromClient',
        render: (val, record) => {
          return (
            <div>
              {val && val.clientName ? val.clientName : null}
            </div>
          )
        }
      },


      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (val) => {
          return (
            <div>
              Rs. {val ? val.toFixed(2) : null}
            </div>
          )
        }
      },
      {
        title: 'By',
        dataIndex: 'username',
        key: 'username',
        render: (val) => {
          return (
            <div>
              By {val}
            </div>
          )
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (val, record) => {
          return (
            <Button onClick={() => this.events.eddJournalEntry(record)}>
              Edit
            </Button>
          )
        }
      }
    ]


    return (
      <PageHeaderWrapper
        title={`Journal Entry`}>
        <Card bordered={true}
              extra={<Button onClick={() => this.setState({ addJournalEntryVisible: true })}>Add Journal
                Entry</Button>}>

          <Table columns={columns} size={'small'} bordered
                 ref={this.table}
                 dataSource={arr}
                 pagination={false}
          />
        </Card>

        {addJournalEntryVisible ?
          <AddJournalEntry
            addJournalEntryCallback={this.events.addJournalEntryCallback}
            onJournalEntryClose={this.events.onJournalEntryClose}
            visible={addJournalEntryVisible}
            {...this.props}
          />
          : null}

        {editJournalEntryVisible ?
          <EditJournalEntry
            addJournalEntryCallback={this.events.addJournalEntryCallback}
            onJournalEntryClose={this.events.onJournalEntryClose}
            journalEntryObj={journalEntryObj}
            visible={editJournalEntryVisible}
            {...this.props}
          />
          : null}
      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllJournalEntry)
