import { getUrlPushWrapper } from '../../routes'

export default {
  GlobalKeyMap: {
    CONTRA_ENTRY: { name: 'Display keyboard shortcuts', sequence: 'shift+f4', action: 'keydown' },
    PAYMENT_OUT: { name: 'Display keyboard shortcuts', sequence: 'shift+f5', action: 'keydown' },
    PAYMENT_IN: { name: 'Display keyboard shortcuts', sequence: 'ctrl+f6', action: 'keydown' },
    JOURNAL_ENTRY: { name: 'Display keyboard shortcuts', sequence: 'ctrl+f7', action: 'keydown' },
    SALE: { name: 'Display keyboard shortcuts', sequence: 'ctrl+f10', action: 'keydown' },
    PURCHASE: { name: 'Display keyboard shortcuts', sequence: 'ctrl+f11', action: 'keydown' },
    DEBIT_NOTE: { name: 'Display keyboard shortcuts', sequence: 'ctrl+f9', action: 'keydown' },
    CREDIT_NOTE: { name: 'Display keyboard shortcuts', sequence: 'ctrl+f8', action: 'keydown' }
  },
  GlobalHandlers: (dispatch) => ({
    CONTRA_ENTRY: () => {
      dispatch(getUrlPushWrapper('contraEntry.allContraEntry'))
    },
    PAYMENT_OUT: () => {

    },
    PAYMENT_IN: () => {
      dispatch(getUrlPushWrapper('paymentIn.addPaymentIn'))
    },
    JOURNAL_ENTRY: () => {
      dispatch(getUrlPushWrapper('journalEntry.allJournalEntry'))
    },
    SALE: () => {
      dispatch(getUrlPushWrapper('sale.addSale'))
    },
    PURCHASE: () => {
      dispatch(getUrlPushWrapper('purchase.addPurchase'))
    },
    DEBIT_NOTE: () => {
      dispatch(getUrlPushWrapper('debitNote.addDebitNote'))
    },
    CREDIT_NOTE: () => {
      dispatch(getUrlPushWrapper('creditNote.addCreditNote'))
    }
  })
}
