import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Table, notification
} from 'antd'
import Request from '../../../request'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader } from '../../../modules/actions'
import { connect } from 'react-redux'
import AddTransport from './Add'


const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AllTransport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      addUnitVisible: false
    }
  }

  componentDidMount() {
    this.loadAllTransport()
  }

  async loadAllTransport() {
    let data = await Request.AllTransportList({})
    this.setState({ arr: data })
  }


  transportCallback = (data) => {
    this.setState({ addUnitVisible: false }, () => {
      this.loadAllTransport()
    })
  }
  onTransportClose = () => {
    this.setState({ addUnitVisible: false })
  }

  AddTransportFxn = () => {
    let { dispatch } = this.props
    dispatch(hideLoader())
    this.setState({ addUnitVisible: true })
  }


  render() {
    let { arr, addUnitVisible } = this.state
    const columns = [
      {
        title: 'Transport',
        key: 'transport',
        dataIndex: 'transport'
      },
      {
        title: 'Transport No',
        key: 'transportNo',
        dataIndex: 'transportNo'
      },
      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username'
      }
    ]

    return (
      <PageHeaderWrapper
        title={'Transport Setting'}>
        <Card bordered={true} title={'Transport Setting'}
              extra={<Button type="primary" onClick={this.AddTransportFxn}>Add Transport</Button>}>
          <Table columns={columns} bordered={true} size={'small'}
                 dataSource={arr}
                 pagination={false}/>
        </Card>
        {addUnitVisible ?
          <AddTransport
            transportCallback={this.transportCallback}
            onTransportClose={this.onTransportClose}
            visible={addUnitVisible}
            {...this.props}
          />
          : null}
      </PageHeaderWrapper>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllTransport)
