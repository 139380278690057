import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Table, notification
} from 'antd'
import Request from '../../../request'
import { UnitCodeList } from '../../../components/_utils/_utils'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader } from '../../../modules/actions'
import { connect } from 'react-redux'
import AddVehicle from './Add'


const FormItem = Form.Item
const { Option } = Select

@Form.create()
class All extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      addUnitVisible: false
    }
  }

  componentDidMount() {
    this.loadAllVehicle()
  }

  async loadAllVehicle() {
    let data = await Request.AllVehicleList()
    this.setState({ arr: data })
  }


  vehicleCallback = (data) => {
    this.setState({ addUnitVisible: false }, () => {
      this.loadAllVehicle()
    })
  }
  onVehicleClose = () => {
    this.setState({ addUnitVisible: false })
  }

  AddVehicleFxn = () => {
    let { dispatch } = this.props
    dispatch(hideLoader())
    this.setState({ addUnitVisible: true })
  }


  render() {
    let { arr, addUnitVisible } = this.state
    const columns = [
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type'
      },
      {
        title: 'Transport Name',
        key: 'transportName',
        dataIndex: 'transportName'
      },
      {
        title: 'Vehicle No',
        key: 'vehicleNo',
        dataIndex: 'vehicleNo'
      },
      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username'
      }
    ]

    return (
      <PageHeaderWrapper
        title={'Vehicle Setting'}>
        <Card bordered={true} title={'Vehicle Setting'}
              extra={<Button type="primary" onClick={this.AddVehicleFxn}>Add Vehicle</Button>}>
          <Table columns={columns} bordered={true} size={'small'}
                 dataSource={arr}
                 pagination={false}/>
        </Card>
        {addUnitVisible ?
          <AddVehicle
            vehicleCallback={this.vehicleCallback}
            onVehicleClose={this.onVehicleClose}
            visible={addUnitVisible}
            {...this.props}
          />
          : null}
      </PageHeaderWrapper>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(All)
