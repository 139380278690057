import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col
} from 'antd'
import styles from './styles.less'
import { AsyncStorage } from '../../asyncStorage'
import { connect } from 'react-redux'
import { getUrlPushWrapper } from '../../routes'

class Dashboard extends Component {
  constructor(props) {
    super(props)
  }

  openParty(route) {
    const { dispatch } = this.props
    AsyncStorage.getBusiness().then((resp) => {
      if (resp && resp.uid) {
        dispatch(getUrlPushWrapper(`${route}`))
      } else {
        dispatch({
          type: 'CHOOSE_BUSINESS'
        })
      }
    })
  }

  openBankRoute(route) {
    const { dispatch } = this.props
    AsyncStorage.getBusiness().then((resp) => {
      if (resp && resp.uid) {
        dispatch(getUrlPushWrapper(route, { pageType: 'dayBook', title: 'DAY BOOK' }))
      } else {
        dispatch({
          type: 'CHOOSE_BUSINESS'
        })
      }
    })

  }


  render() {
    return (
      <div className={'asdf'}>
        <Row>
          <Col span={6}>
            <a className={styles.section}
               onClick={() =>
                 this.openParty('AllReport')
               }>
              <img src={'../images/calculator.png'}/>
              <span style={styles.txt}>REPORTS</span>
            </a>
          </Col>
          <Col span={6}><a className={styles.section}
                           onClick={() => this.openParty('AllProduct')
                           }>
            <img src={'../images/basket.png'}/>
            <span style={styles.txt}>ALL ITEMS</span>
          </a></Col>

          <Col span={6}> <a className={styles.section}
                            onClick={() => this.openParty('sale.addSale')}>
            <img src={'../images/bags.png'}/>
            <span style={styles.txt}>SALES</span>
          </a></Col>
          <Col span={6}> <a className={styles.section}
                            onClick={() => this.openParty('paymentIn.addPaymentIn')}>
            <img src={'../images/delivery-man.png'}/>
            <span style={styles.txt}>PAYMENT IN</span>
          </a></Col>
        </Row>
        <Row>
          <Col span={6}>
            <a className={styles.section}
               onClick={() => this.openParty('creditNote.addCreditNote')}>
              <img src={'../images/delivery-man.png'}/>
              <span style={styles.txt}>CREDIT NOTE </span>
            </a>
          </Col>
          <Col span={6}>
            <a className={styles.section}
               onClick={() => this.openParty('estimate.addEstimate')}>
              <img src={'../images/bills.png'}/>
              <span style={styles.txt}>QUOTATION</span>
            </a>
          </Col>
          <Col span={6}>
            <a className={styles.section}
               onClick={() => this.openParty('AllParty')}>
              <img src={'../images/delivery-man.png'}/>
              <span style={styles.txt}>PARTIES</span>
            </a>
          </Col>
          <Col span={6}>
            <a className={styles.section}
               onClick={() => this.openParty('purchase.addPurchase')}>
              <img src={'../images/bills.png'}/>
              <span style={styles.txt}>PURCHASE</span>
            </a>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <a className={styles.section}
               onClick={() => this.openParty('debitNote.addDebitNote')}>
              <img src={'../images/delivery-man.png'}/>
              <span style={styles.txt}>DEBIT NOTE </span>
            </a>
          </Col>
          <Col span={6}>
            <a className={styles.section} onClick={() => this.openBankRoute('reports.bankDayBook')}>
              <img src={'../images/delivery-man.png'}/>
              <span style={styles.txt}>BANK DAY BOOK</span>
            </a>
          </Col>
          <Col span={6}>
            <a className={styles.section}
               onClick={() => this.openParty('contraEntry.allContraEntry')}>
              <img src={'../images/delivery-man.png'}/>
              <span style={styles.txt}>CONTRA ENTRY</span>
            </a>
          </Col>
          <Col span={6}>

            <a className={styles.section}
               onClick={() => this.openParty('journalEntry.allJournalEntry')}>
              <img src={'../images/delivery-man.png'}/>
              <span style={styles.txt}>JOURNAL ENTRY</span>
            </a>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <a className={styles.section}
               onClick={() => this.openParty('AllProduct')}>
              <img src={'../images/delivery-man.png'}/>
              <span style={styles.txt}>ADD ITEM</span>
            </a>
          </Col>
          <Col span={6}>
            <a className={styles.section}
               onClick={() => this.openParty('AddGallery')}>
              <img src={'../images/gallery.png'}/>
              <span style={styles.txt}>ADD GALLERY</span>
            </a>
          </Col>
          <Col span={6}>
            <a className={styles.section}
               onClick={() => this.openParty('AssignGalleryImages')}>
              <img src={'../images/assignGallery.png'}/>
              <span style={styles.txt}>ASSIGN GALLERY</span>
            </a>
          </Col>
          <Col span={6}></Col>
        </Row>
      </div>
    )
  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)

