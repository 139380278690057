import React from 'react'
import { Button, Card, Col, DatePicker, Input, Row, Select, Table, Tag } from 'antd'
import moment from 'moment'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import Request from '../../../request'
import async from 'async'
import { getUrlParams } from '../../../routes'
import { connect } from 'react-redux'
import _ from 'lodash'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import { InputBox } from '../../webComponent/appDes'

let uid = ''
let html = null

let saleObj = {}
let fff = ''
let BUTTONS = ['Mini Statement', 'Mini Statement + Narration', 'Full Statement', 'Full Statement + Narration', 'Full Statement + Narration +  Discount', 'Print Images', 'Export Excel', 'Cancel']
let PL_BUTTONS = ['MINI PL REPORT', 'MINI PL REPORT + Narration', 'FULL PL REPORT', 'FULL PL REPORT + Narration', 'Cancel']


class ClientActivity extends React.Component {
  constructor() {
    super()
    this.state = {
      arr: [],
      printArr: [],
      total: 0,
      isModalVisible: false,
      search: '',
      uid: null,
      name: null,
      currentBalance: 0,
      userType: null,
      clientGroupId: '',
      fromDate: moment().startOf('year'),
      toDate: moment(),
      showCheckIcon: false,
      refreshing: false

    }
    this.table = React.createRef()
  }

  componentWillMount() {
    this.fetchData()
  }

  reloadTab() {
    // this.table.current.reload()
    this.AllProducts()
  }


  async fetchData() {
    let data = await getUrlParams(
      'hiddenPath.clientActivity',
      this.props.pathname
    )
    if (data && data.id) {
      this.setState({ uid: data.id }, () => {
        this.AllProducts()
      })
    }
  }


  AllProducts = () => {
    this.props.dispatch(showPageLoad())
    Request.ClientActivityList({
      uid: this.state.uid,
      from: this.state.fromDate,
      to: this.state.toDate,
      search: this.state.search,
      archive: 0
    }).then(async (testData) => {

      let openingQuery = await Request.openingQuery({
        uid: this.state.uid,
        to: this.state.fromDate,
        search: this.state.search,
        archive: 0
      })
      if (openingQuery && openingQuery.type) {
        testData.unshift(openingQuery)
      }


      let anotherTest = _.clone(testData)
      let showCheckIcon = false
      async.each(anotherTest, (item, cb) => {
        if (!showCheckIcon) {
          let findNetAmount = _.find(item.arr, (item1) => {
            return !item1.netAmount || item1.netAmount <= 0
          })
          if (findNetAmount) {
            showCheckIcon = true
          }
          cb()
        } else {
          cb()
        }
      }, () => {
        this.setState({
          printArr: testData,
          arr: anotherTest.reverse(),
          showCheckIcon: showCheckIcon,
          refreshing: false
        })
        this.props.dispatch(hidePageLoad())

        return
      })


    })
      .catch((e) => {
        this.props.dispatch(hidePageLoad())
        return
      })

  }

  onChooseDate(date, stringDate, type) {
    this.setState({ [type]: date }, () => {
      this.reloadTab()
    })
  }

  _renderRow(data, index, indent, expanded) {
    let innerColumns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        render: (val, itr) => {
          return (
            <div>
              {itr.name}
              ({itr.gstInclusive ? 'Inc.' : 'Exc.'} {itr.gst}%)
            </div>
          )
        }
      },
      {
        title: 'Price',
        key: 'price',
        dataIndex: 'price'
      },
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity'
      },
      {
        title: 'Disc Amt',
        key: 'discountAmount',
        dataIndex: 'discountAmount'
      },
      {
        title: 'Sec Disc Amt',
        key: 'secondDiscountAmount',
        dataIndex: 'secondDiscountAmount'
      },
      {
        title: 'Gst Amount',
        key: 'gstAmount',
        dataIndex: 'gstAmount'
      },
      {
        title: 'Net Amount',
        key: 'netAmount',
        dataIndex: 'netAmount'
      }
    ]
    return (
      <Table columns={innerColumns} size={'small'} bordered
             ref={this.table}
             dataSource={data}
             pagination={false}
      />
    )
  }


  render() {
    let { printArr, toDate, fromDate } = this.state
    const columns = [
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        render: (val, record) => {
          return (
            <div>
              {val} {record.saleCategory ? `(Sale Category : ${record.saleCategory})` : null}
            </div>
          )
        }
      },
      {
        title: 'Date',
        key: 'dateString',
        dataIndex: 'dateString'
      },
      {
        title: 'Invoice No',
        key: 'invoiceNo',
        dataIndex: 'invoiceNo',
        render: (val, record) => {
          return (
            <div>
              <Tag color="#5cb85c">Invoice No : {val}</Tag><br/>
              {record.vendorInvoiceNo ?
                <Tag color="#108ee9">Vendor Invoice No : {record.vendorInvoiceNo}</Tag> : null}
            </div>
          )
        }
      },
      {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount',
        render: (val, record) => {
          return (
            <div>
              Amount : {val} (Balance
              :{record.balance ? -(record.balance) : 0} {-(record.balance) < 0 ? '(Dr)' : '(Cr)'})
            </div>
          )
        }
      }
    ]

    return (
      <PageHeaderWrapper
        title={'Client Activity'}>
        <Card bordered={true} title={'Client Activity'}>
          <Row gutter={16}>
            <Col span={'5'}>
              <InputBox title={'FROM DATE'}>
                <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'fromDate')}
                            value={fromDate}
                            placeholder={'From'}/>
              </InputBox>
            </Col>
            <Col span={'5'}>
              <InputBox title={'TO DATE '}>
                <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'toDate')}
                            value={toDate}
                            placeholder={'To'}/>
              </InputBox>
            </Col>

          </Row>

          <Table columns={columns} size={'small'} bordered
                 ref={this.table}
                 expandedRowRender={(record, index, indent, expanded) => this._renderRow(record.arr, index, indent, expanded)}
                 dataSource={printArr}
                 defaultExpandAllRows={true}
                 expandRowByClick={true}
                 pagination={false}
          />
        </Card>

      </PageHeaderWrapper>
    )
  }
}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientActivity)
