export const HtmlPrint = (data, url = '') => {
  let printWindow = window.open('', 'PRINT')
  printWindow.document.write(data)
  setTimeout(() => { // Needed for large documents
    printWindow.document.body.style.margin = '0 0'
    printWindow.document.close() // necessary for IE >= 10
    printWindow.focus() // necessary for IE >= 10*/
    printWindow.print()
    printWindow.close()
    if (url) {
      window.location.href = url
    }
  }, 1000)
  return
}
