import React, { PureComponent, Suspense } from 'react'
import { Button, Layout, Tooltip } from 'antd'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import styles from './index.less'
import PageLoading from '../PageLoading'
import { getDefaultCollapsedSubMenus } from './SiderMenuUtils'
import { AsyncStorage } from '../../asyncStorage'
import { connect } from 'react-redux'

const BaseMenu = React.lazy(() => import('./BaseMenu'))
const { Sider } = Layout

class SiderMenu extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      openKeys: getDefaultCollapsedSubMenus(props),
      business: {}
    }
    this.getSelectedBusiness()
  }


  static getDerivedStateFromProps(props, state) {
    const { pathname } = state
    if (props.location.pathname !== pathname) {
      return {
        pathname: props.location.pathname,
        openKeys: getDefaultCollapsedSubMenus(props)
      }
    }
    return null
  }

  isMainMenu = key => {
    const { menuData } = this.props
    return menuData.some(item => {
      if (key) {
        return item.key === key || item.path === key
      }
      return false
    })
  }

  handleOpenChange = openKeys => {
    const moreThanOne = openKeys.filter(openKey => this.isMainMenu(openKey)).length > 1
    this.setState({
      openKeys: moreThanOne ? [openKeys.pop()] : [...openKeys]
    })
  }


  getSelectedBusiness() {
    AsyncStorage.getBusiness().then((resp) => {
      if (resp && resp.uid) {
        this.setState({ business: resp })
      }
    })
  }

  showBusiness() {
    const { dispatch } = this.props
    dispatch({
      type: 'CHOOSE_BUSINESS'
    })
  }


  render() {
    const { logo, collapsed, onCollapse, fixSiderbar, theme, user } = this.props
    const { openKeys, business, visibleBusiness } = this.state
    const defaultProps = collapsed ? {} : { openKeys }

    const siderClassName = classNames(styles.sider, {
      [styles.fixSiderbar]: fixSiderbar,
      [styles.light]: theme === 'light'
    })

    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        onCollapse={onCollapse}
        width={256}
        theme={theme}
        className={siderClassName}>

        <div className={styles.logo} id="logo">
          <Link to="/">
            <img src={logo} alt="logo"/>
            <h1>{this.props.title}</h1>
          </Link>
        </div>
        <Tooltip placement="topLeft" title={business && business.name ? business.name : 'Choose Business'}>
          <div onClick={() => this.showBusiness()} className={'businessLabel'}>
            {business && business.name ? business.name : 'Choose Business'}
          </div>
        </Tooltip>
        <Suspense fallback={<PageLoading/>}>
          <BaseMenu
            {...this.props}
            mode="inline"
            user={user}
            handleOpenChange={this.handleOpenChange}
            onOpenChange={this.handleOpenChange}
            style={{ padding: '16px 0', width: '100%' }}
            {...defaultProps}
          />
        </Suspense>

      </Sider>
    )
  }
}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiderMenu)
