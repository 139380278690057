import React from 'react'
import {
  notification, Collapse, Card, Button,
  Table, Row, Col, Popconfirm, Select, Icon, Input, Modal, DatePicker
} from 'antd'
import _ from 'lodash'
import Async from 'async'
import { ItemsReCalculate, fixed2Digit, TaxTypeList } from '../../components/_utils/_utils'
import AddItems from './AddItems'
import ChooseItems from './ChooseItems'
import { v4 as uuid } from 'uuid'
import Styles from './styles.less'
import { InputBox } from './appDes'
import { showPageLoad, hidePageLoad } from '../../modules/actions'
import Request from '../../request'
import moment from 'moment'

const { Panel } = Collapse
const { Option } = Select


class InvoiceReference extends React.Component {
  events = {
    showModal: (event) => {
      if (!this.props.clientId) {
        notification.error({
          message: 'Please choose client.'
        })
      } else {
        this.setState({
          method: 'Adjustment',
          dueDate: null,
          linkId: '',
          amount: null,
          modalVisible: true,
          refNo: ''
        })
      }
      event.stopPropagation()
    },
    hideModal: () => {
      this.setState({ modalVisible: false })
    },
    removeReference: (key) => {
      let { referenceArr } = this.state
      referenceArr.splice(key, 1)
      this.setState({ referenceArr })
    }
    ,
    onChooseDate: (date, dateString) => {
      this.setState({ dueDate: date })
    }
  }

  constructor() {
    super()
    this.state = {
      method: 'Adjustment',
      invoiceArr: [],
      referenceArr: [],
      filterArr: [],
      dueDate: null,
      linkId: '',
      amount: null,
      modalVisible: false,
      refNo: ''
    }
  }

  componentDidMount() {
    this.loadClientInvoice()
  }

  async loadClientInvoice() {
    let { clientId, referenceArr, pageType } = this.props
    let resp = await Request.getClientInvoices({ clientId, pageType })
    let invoiceArr = []
    _.each(resp, (item) => {
      invoiceArr.push({
        amount: item.refAmount,
        linkId: item.uid,
        invoiceNo: item.invoiceNo
      })
    })
    this.setState({ invoiceArr: invoiceArr, referenceArr })
  }


  _save() {
    let cloneState = _.clone(this.state)
    let { method, refNo, referenceArr, linkId, amount } = cloneState
    if (method == 'New Ref') {
      cloneState.linkId = ''
      cloneState.invoiceNo = ''
      cloneState.refAmount = ''
      if (!refNo) {
        notification.error({
          message: 'Enter ref no.'
        })
        return
      }
    }
    if (method == 'Adjustment') {
      cloneState.refNo = ''
      if (!linkId) {
        notification.error({
          message: 'Choose invoice'
        })
        return
      }
      let findExist = _.find(referenceArr, (item) => {
        return item.linkId == linkId
      })
      if (findExist) {
        notification.error({
          message: 'Invoice already exists.'
        })
        return
      }
    }
    if (!amount || amount < 0) {
      notification.error({
        message: 'Enter amount'
      })
      return
    }
    let obj = {
      linkId: cloneState.linkId,
      invoiceNo: cloneState.invoiceNo,
      amount: cloneState.amount,
      refAmount: cloneState.refAmount,
      method: cloneState.method,
      dateString: cloneState.dueDate ? moment(cloneState.dueDate).format('LLL') : null,
      dueDate: cloneState.dueDate ? moment(cloneState.dueDate) : null,
      refNo: cloneState.refNo
    }
    this.props.submitRef(obj)
    this.events.hideModal()
  }


  chooseInvoiceNo(linkId) {
    let { invoiceArr } = this.state
    let findInvoice = _.find(invoiceArr, (item) => {
      return item.linkId == linkId
    })
    this.setState({
      linkId: linkId,
      invoiceNo: findInvoice ? findInvoice.invoiceNo : '',
      refAmount: findInvoice ? findInvoice.amount : ''
    })
  }

  setLocalState = (e) => {
    let { value, name } = e.target
    this.setState({ [name]: value })
  }


  render() {
    let { referenceArr, method, amount, dueDate, linkId, invoiceArr, refNo } = this.state
    let columns = [
      {
        title: 'Method',
        key: 'method',
        dataIndex: 'method'
      },
      {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount'
      },
      {
        title: 'Invoice/Ref',
        key: 'refAmount',
        dataIndex: 'refAmount',
        render: (value, item) => {
          return (
            <React.Fragment>
              {item.method == 'Adjustment' ? item.invoiceNo : item.refNo}
              {'\n'}
              {item.refAmount ? `(Rs. ${item.refAmount})` : null}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Due Date',
        key: 'dateString',
        dataIndex: 'dateString'
      },
      {
        title: '',
        key: 'action',
        dataIndex: 'action',
        render: (item, record, index) => {
          return (
            <Button size={'small'} onClick={() => this.events.removeReference(index)}>Remove</Button>
          )
        }
      }
    ]
    return (
      <div>
        <Collapse className={'padding0'} defaultActiveKey={['1']}>
          <Panel header={`Invoice Reference (${referenceArr.length})`} key="1"
                 extra={
                   <Button size={'small'} onClick={(e) => this.events.showModal(e)}>Choose Invoice Reference </Button>
                 }>
            <Table dataSource={referenceArr} columns={columns} size={'small'} className={'alternateTable'}
                   pagination={false} bordered={true}/>
          </Panel>


        </Collapse>
        <Modal
          title="Invoice Reference"
          visible={this.state.modalVisible}
          onOk={() => this._save()}
          okText={'Submit'}
          onCancel={() => this.events.hideModal()}>
          <InputBox title={'Method'}>
            <Select value={method} onChange={(e) => this.setState({ method: e })}>
              <Option value={'Adjustment'}>Adjustment</Option>
              <Option value={'New Ref'}>New Ref</Option>
            </Select>
          </InputBox>
          {method == 'Adjustment' ?
            <InputBox title={'Invoices'}>
              <Select value={linkId} onChange={(e) => this.chooseInvoiceNo(e)}>
                {invoiceArr && invoiceArr.length ? invoiceArr.map((item, key) => {
                  return (
                    <Option value={item.linkId} key={key}>{`${item.invoiceNo} (${item.amount})`}</Option>
                  )
                }) : null}
              </Select>
            </InputBox> :
            <InputBox title={'Ref No'}>
              <Input name={'refNo'} value={refNo} onChange={this.setLocalState}/>
            </InputBox>
          }
          <InputBox title={'Amount'}>
            <Input onChange={this.setLocalState} type={'number'} value={amount} name={'amount'}
                   placeholder={'Amount'}/>
          </InputBox>
          <InputBox title={'Received Amount'}>
            <DatePicker onChange={this.events.onChooseDate} value={dueDate} placeholder={'Date of sale'}/>
          </InputBox>
        </Modal>
      </div>
    )
  }
}

export default InvoiceReference
