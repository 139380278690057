import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import {
  Table,
  Badge,
  Card,
  Checkbox,
  Input, Button, Icon, Row, Col, Tabs, Tooltip
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getPushPathWrapper } from '../../../routes'
import AddClient from '../add'

const { TabPane } = Tabs

class AllParty extends Component {

  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      hideZero: false,
      startLetter: false,
      groupArr: [],
      groupObj: {}
    }
  }

  componentDidMount() {
    this.loadClient()
    this.AllGroups()
  }

  AllGroups = () => {
    let groupObj = {}
    Request.AllGroupsList({ archive: 0 })
      .then((groupData) => {
        _.each(groupData, (item) => {
          groupObj[item.uid] = item.name
        })

        this.setState({ groupArr: groupData, groupObj })
        return
      })
      .catch((e) => {

        return
      })

  }

  async loadClient() {
    let obj = {
      name: this.state.name,
      startLetter: this.state.startLetter,
      hideZero: this.state.hideZero,
      archive: 0
    }
    let data = await Request.AllClientList(obj)
    this.setState({ arr: data })
  }

  setValue = (e) => {
    let { name, value } = e.target
    this.setState({ [name]: value })
  }

  setCheckbox(e) {
    this.setState({ [e]: !this.state[e] })
  }

  clientCallback = (data) => {
    this.setState({ visibleAdd: false }, () => {
      this.loadClient()
    })
  }
  onClientClose = (data) => {
    this.setState({ visibleAdd: false })
  }

  render() {
    let { arr, groupArr, visibleAdd, groupObj } = this.state
    let { dispatch } = this.props
    const columns = [
      {
        title: 'Parties',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Group',
        dataIndex: 'clientGroupId',
        key: 'clientGroupId',
        render: (val) => {
          return (
            <div>
              {groupObj[val] ? groupObj[val] : ''}
            </div>
          )
        }
      },
      {
        title: 'Current Balance',
        dataIndex: 'currentBalance',
        key: 'currentBalance',
        render: (val, record) => {
          return (
            <React.Fragment>
              {val} {val == 0 ? null : (val) < 0 ? '(Cr)' : '(Dr)'}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Action',
        dataIndex: 'uid',
        key: 'uid',
        render: (val, record) => {
          return (
            <div className={'actionGroup'}>
              <Tooltip title="Client Activity">
                <Button
                  onClick={() => {
                    dispatch(
                      getPushPathWrapper('hiddenPath.clientActivity', {
                        id: val
                      })
                    )
                  }}
                >Client Activity</Button>
              </Tooltip>
            </div>
          )
        }
      }

    ]
    const groupColumns = [
      {
        title: 'Groups',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count'
      }
    ]
    return (
      <PageHeaderWrapper
        title={'All Party'}>
        <Card bordered={true}>
          <Tabs defaultActiveKey="1"
                tabBarExtraContent={<Button type="primary" onClick={() => this.setState({ visibleAdd: true })}>Add
                  Client</Button>}>
            <TabPane tab="All Parties" key="1">
              <Row gutter={16}>
                <Col span={6}>
                  <Input name={'name'} onChange={this.setValue} allowClear={true}
                         value={this.state.name} placeholder={'Search Party'}/>
                </Col>
                <Col span={4}>
                  <Checkbox onChange={() => this.setCheckbox('startLetter')} value={this.state.startLetter}>Start with
                    name</Checkbox>
                </Col>
                <Col span={4}>
                  <Checkbox onChange={() => this.setCheckbox('hideZero')} value={this.state.hideZero}>Hide zero
                    balance</Checkbox>
                </Col>
                <Col span={4}>
                  <Button onClick={() => this.loadClient()}>
                    Search
                  </Button>
                </Col>
              </Row>
              <Table columns={columns} bordered={true} size={'small'} style={{ marginTop: 10 }}
                     dataSource={arr}
                     pagination={false}
              />
            </TabPane>
            <TabPane tab="Groups" key="2">
              <Table columns={groupColumns} bordered={true} size={'small'}
                     dataSource={groupArr}
                     pagination={false}
              />
            </TabPane>

          </Tabs>


        </Card>

        {visibleAdd ? <AddClient
          visible={visibleAdd}
          {...this.props}
          clientCallback={this.clientCallback}
          onClientClose={this.onClientClose}/> : ''}

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllParty)
