import React from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Drawer,
  Row,
  Col,
  notification
} from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request, { getBusinessId } from '../../../request'
import { connect } from 'react-redux'
import { TaxTypeList } from '../../../components/_utils/_utils'
import { AsyncStorage } from '../../../asyncStorage'
import { CheckGstAvailable } from '../../webComponent/appDes'
import AddBrand from '../../Setting/brands/Add'
import AddUnit from '../../Setting/units/AddUnit'
import AddCategory from '../../Setting/category/Add'
import { subBrandListFxn } from '../../Setting/subBrand/action'
import AddSubBrand from '../../Setting/subBrand/addSubBrand'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { getSingleProductFxn, updateProductFxn } from '../actions'
import { getUrlParams } from '../../../routes'

const FormItem = Form.Item
const { Option } = Select
const { TextArea } = Input

@Form.create()
class EditProduct extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      'openType': 'Modal',
      'type': 'Product',
      'name': null,
      'code': null,
      'hsnCode': null,
      'purchasePrice': null,
      'salePrice': null,
      'stock': null,
      'gst': null,
      'taxArr': [{}],
      'unitArr': [],
      'catArr': [],
      'brandArr': [],
      'load': 'No',
      'unitId': null,
      'unitName': null,
      'unitCode': null,
      'businessId': null,
      'brandId': null,
      'brandName': null,
      'addUnitModal': false,
      'addTaxModal': false,
      'addCategoryModal': false,
      'location': null,
      'image': {},
      'imageSelect': false,
      'checkConnection': false,
      'category': null,
      'warranty': null,
      'categoryName': null,
      'categoryId': null,
      'mrp': null,
      'wholesalePrice': null,
      'gstInclusive': false,
      'gstPurchaseInclusive': false,
      'gstAvailable': false,
      'subBrandList': [],
      'subBrandId': '',
      'openingStock': '',
      'uid': '',
      brand_Id: ''
    }
    setTimeout(() => {
      this.loadBusiness()
    }, 100)

  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {

        dispatch(showLoader())

        let x = await Request.addUser(valData)

        dispatch(hideLoader())

        if (!x.error) {
          notification.success({
            message: 'Users added successfully'
          })
          this.props.form.setFieldsValue({})

        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message
          })
        }

      }
    })
  }

  async componentDidMount() {
    let gstAvailable = await AsyncStorage.getGstAvailable()
    this.setState({ gstAvailable })
    this.loadProductData()
  }

  loadTaxes = () => {
    Request.AllTaxes()
      .then((testData) => {
        let qqq = []
        lodash.each(testData, (ff) => {
          qqq.push(ff.tax.toString())
        })

        this.setState({ taxArr: qqq, gst: qqq[0] })
      })
      .catch((e) => {
        return
      })
  }

  loadBrands = async () => {
    let { success, brandArr } = await Request.loadBrands()
    if (success) {
      // {brandId: "", brandName: "Choose"}
      let catArr = []
      lodash.each(brandArr, (ff) => {
        catArr.push({ id: ff.uid, name: ff.name, _id: ff._id })
      })


      this.setState({ brandArr: catArr })
    }
  }

  loadUnits = () => {
    let unitArr = []

    Request.AllUnits()
      .then((testData) => {
        lodash.each(testData, (ff) => {
          unitArr.push({ unitId: ff.uid, name: ff.name, code: ff.code })
        })

        this.setState({ unitArr: unitArr, unitId: unitArr[0].unitId })
      })
      .catch((e) => {
        return
      })
  }
  loadBusiness = () => {
    AsyncStorage.getBusiness().then((resp) => {
      if (resp) {
        this.setState({
          businessId: resp.uid
        })
        this.loadTaxes()

        this.loadUnits()
        this.loadCategory()
        this.loadBrands()
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  loadCategory = () => {
    let catArr = []
    Request.AllCategory()
      .then((testData) => {
        lodash.each(testData, (ff) => {
          catArr.push({
            id: ff.uid,
            uid: ff.uid,
            categoryId: ff.uid,
            categoryName: ff.name,
            name: ff.name,
            hsnCode: ff.hsnCode,
            gst: ff.gst,
            taxExempted: ff.taxExempted
          })
        })

        this.setState({
          catArr: catArr
          /* categoryId: catArr[0].id,
           categoryName: catArr[0].name*/
        })
      })
      .catch((e) => {
        return
      })
  }

  componentWillMount() {
    if (this.props.navigation && this.props.navigation.state) {
      let paramsObj = this.props.navigation.state.params
      if (paramsObj.openType) {
        this.setState({
          openType: paramsObj.openType
        })
      }
    }

  }

  componentWillReceiveProps(nextProps) {


  }

  pickMultiple() {

  }

  pickSingleWithCamera() {


  }

  removeImage() {
    this.setState({
      image: {},
      imageSelect: false
    })
  }

  addProduct = async () => {
    let { businessId } = await getBusinessId()

    let { catArr, unitArr } = this.state
    let { dispatch } = this.props
    let obj = {
      name: this.state.name,
      code: this.state.code,
      hsnCode: this.state.hsnCode,
      salePrice: this.state.salePrice ? this.state.salePrice : 0,
      purchasePrice: this.state.purchasePrice ? this.state.purchasePrice : 0,
      mrp: this.state.mrp ? this.state.mrp : 0,
      wholesalePrice: this.state.wholesalePrice ? this.state.wholesalePrice : 0,
      openingStock: this.state.openingStock ? this.state.openingStock : 0,
      stock: this.state.stock ? this.state.stock : 0,
      gst: this.state.gst && this.state.gst !== 'Exempted' ? this.state.gst : 0,
      taxExempted: this.state.gst == 'Exempted' ? true : false,
      gstInclusive: this.state.gstInclusive,
      gstPurchaseInclusive: this.state.gstPurchaseInclusive,
      unitId: this.state.unitId,
      unitName: null,
      unitCode: null,
      location: this.state.location,
      warranty: this.state.warranty,
      image: this.state.image,
      categoryId: this.state.categoryId,
      categoryName: this.state.categoryName,
      brandId: this.state.brandId,
      brandName: this.state.brandName,
      subBrandId: this.state.subBrandId,
      subBrandName: this.state.subBrandName,
      uid: this.state.uid
    }


    if (!obj.name) {
      notification.warn({
        message: 'Enter Product Name'
      })
      return
    }

    if (!obj.categoryId) {
      notification.warn({
        message: 'Choose Category'
      })
      return
    }

    if (!obj.brandId) {
      notification.warn({
        message: 'Choose Brand'
      })
      return
    }
    if (obj.stock && parseInt(obj.stock) > 0) {
      if (!obj.purchasePrice) {
        notification.warn({
          message: 'Enter Purchase Price.'
        })
        return
      }
    }


    this.setState({ load: 'Yes' })
    let findUnitObj = lodash.find(unitArr, (yu) => {
      return yu.unitId == obj.unitId
    })
    if (findUnitObj) {
      obj.unitName = findUnitObj.name
      obj.unitCode = findUnitObj.code
    }


    let priceT = obj.purchasePrice ? parseFloat(obj.purchasePrice) : 0
    let stockT = obj.stock ? parseFloat(obj.stock) : 0
    let totalAmtT = priceT * stockT
    let avgPrice = isNaN(totalAmtT / stockT) ? 0 : totalAmtT / stockT
    obj.avgQuantity = stockT
    obj.avgAmount = totalAmtT
    obj.avgPrice = avgPrice

    let testData = await dispatch(updateProductFxn(obj))

    if (testData.type) {

      if (this.state.image && this.state.image.uri) {
        AsyncStorage.getUserData().then((respUser) => {
          if (respUser) {
            let imageObj = {
              businessId: businessId,
              linkId: testData.product.uid,
              type: 'PRODUCT',
              date: new Date(),
              userId: respUser.uid,
              saleUid: testData.product.uid
            }
            Request.uploadDocument(imageObj, this.state.image).then((resp1) => {

            }).catch((err) => {
              console.log(err)
            })
          }
        })

      }

      setTimeout(() => {
        this.setState({ load: 'No' })
      }, 200)

    } else {
      this.setState({ load: 'No' })

    }
  }

  _setDigitValue(value, type) {
    if (value) {
      let splitX = value.split('.')
      if (splitX.length > 2) {
        return true
      }
      if (splitX[1]) {
        if (splitX[1].length > 2) {
          return true
        }
      }
      this.setState({ [type]: value })
    } else {
      this.setState({ [type]: null })
    }

  }

  _selectedCategory(item) {
    if (item && item.uid) {
      let hsnCode = null
      let gst = null
      if (item.taxExempted) {
        gst = 'Exempted'
      } else {
        gst = item.gst ? item.gst.toString() : 0
      }
      hsnCode = item.hsnCode
      this.setState({
        categoryName: item.name,
        categoryId: item.uid,
        hsnCode,
        gst
      })
    } else {
      this.setState({
        categoryName: '',
        categoryId: '',
        hsnCode: '',
        gst: ''
      })
    }
    // this.setState({categoryName: item.categoryName});
  }

  setValue = (e) => {
    let { name, value } = e.target
    this.setState({ [name]: value })
  }

  filterOption = (input, options) => {
    return options.props.children.match(new RegExp(`^${input}`, 'ig'))
  }

  loadSubBrands = async () => {
    let { dispatch } = this.props
    let params = {
      results: 10000,
      count: 10000,
      brandUid: this.state.brandId
    }
    let { data } = await dispatch(subBrandListFxn(params))
    this.setState({
      subBrandList: data
    })
  }

  events = {
    showBrandDrawer: () => {
      this.setState({ visibleAddBrand: true })
    },
    showUnitDrawer: () => {
      this.setState({ visibleAddUnit: true })
    },
    showCategoryDrawer: () => {
      this.setState({ visibleAddCategory: true })
    },
    onBrandClose: () => {
      this.setState({ visibleAddBrand: false })
    },
    onUnitClose: () => {
      this.setState({ visibleAddUnit: false })
    },
    onCategoryClose: () => {
      this.setState({ visibleAddCategory: false })
    },
    brandCallback: (data) => {
      this.loadBrands()
      if (data && data.uid) {
        setTimeout(() => {
          this.setState({
            brandId: data.uid,
            brandName: data.name,
            visibleAddBrand: false
          })
        }, 500)
      }
    },
    unitCallback: (data) => {
      this.loadUnits()
      if (data && data.uid) {
        setTimeout(() => {
          this.setState({
            unitId: data.uid,
            unitName: data.name,
            unitCode: data.code,
            visibleAddUnit: false
          })
        }, 500)
      }
    },
    categoryCallback: (data) => {
      console.log(data)
      this.loadCategory()
      if (data && data.uid) {
        setTimeout(() => {
          this.setState({ visibleAddCategory: false })
          this._selectedCategory(data)
        }, 500)
      }
    },
    addSubBrandFxn: () => {
      this.setState({ visibleAddSubBrand: true })
    },
    onSubBrandClose: () => {
      this.setState({ visibleAddSubBrand: false })
    },
    subBrandCallback: (data) => {
      this.loadSubBrands()
      if (data && data.uid) {
        setTimeout(() => {
          this.setState({
            subBrandId: data.uid,
            subBrandName: data.name,
            visibleAddSubBrand: false
          })
        }, 500)
      }
    }
  }

  loadProductData = async () => {
    let pathName = await getUrlParams('editProduct', this.props.pathname)
    let productId = pathName.id
    let { dispatch } = this.props
    let { data, success } = await dispatch(getSingleProductFxn({ productId }))
    if (success) {
      this.setState({
        ...data

      }, () => {
        this.loadSubBrands()
      })
    }
  }

  render() {
    let {
      visibleAddSubBrand,
      catArr,
      brandArr,
      gstAvailable,
      visibleAddUnit,
      visibleAddBrand,
      visibleAddCategory
    } = this.state


    return (
      <PageHeaderWrapper title={'Edit Item'}>
        <Card bordered={true}
              actions={[
                <Button onClick={() => this.addProduct()} type="primary">SAVE ITEM</Button>
              ]} className={'customCard'}>

          <Form className={'newForm'}>
            <Row gutter={[16, 10]}>
              <Col span={12}>
                <label>Item Name</label>
                <Input name={'name'} onChange={this.setValue} placeholder={'Item Name'} value={this.state.name}/>
              </Col>
              <Col span={12}>
                <label>Item Category</label>
                <div className={'rowFlex'}>
                  <Select value={this.state.categoryName}
                          allowClear={true}
                          showSearch={true}
                          filterOption={this.filterOption}
                          optionFilterProp="children"
                          onChange={(e) => this._selectedCategory(e)}>
                    {this.state.catArr.map((item) => {
                      return (
                        <Option key={item.uid} value={item}>{item.name}</Option>
                      )
                    })}
                  </Select>
                  <Button onClick={() => this.events.showCategoryDrawer()}>
                    <Icon type="plus"/>
                  </Button>
                </div>
              </Col>
              <Col span={12}>
                <label>Item Brand</label>
                <div className={'rowFlex'}>
                  <Select value={this.state.brandName}
                          allowClear={true}
                          showSearch={true}
                          filterOption={this.filterOption}
                          optionFilterProp="children"
                          onChange={(e) => {
                            this.setState({
                              brandId: e && e.id ? e.id : '',
                              brand_Id: e && e._id ? e._id : '',
                              brandName: e && e.name ? e.name : '',
                              subBrandId: '',
                              subBrandName: ''
                            }, () => {
                              this.loadSubBrands()
                            })
                          }}>
                    {this.state.brandArr.map((item) => {
                      return (
                        <Option key={item.uid} value={item}>{item.name}</Option>
                      )
                    })}
                  </Select>
                  <Button onClick={() => this.events.showBrandDrawer()}>
                    <Icon type={'plus'}></Icon>
                  </Button>
                </div>
              </Col>
              <Col span={12}>
                <label>Item Sub Brand</label>
                <div className={'rowFlex'}>
                  <Select value={this.state.subBrandName}
                          allowClear={true}
                          showSearch={true}
                          filterOption={this.filterOption}
                          optionFilterProp="children"
                          onChange={(e) => {
                            this.setState({
                              subBrandId: e && e.uid ? e.uid : '',
                              subBrandName: e && e.name ? e.name : ''
                            })
                          }}>
                    {this.state.subBrandList && this.state.subBrandList.length ? this.state.subBrandList.map((item) => {
                      return (
                        <Option key={item.uid} value={item}>{item.name}</Option>
                      )
                    }) : null}
                  </Select>
                  <Button onClick={() => this.events.addSubBrandFxn()}>
                    <Icon type={'plus'}></Icon>
                  </Button>
                </div>
              </Col>
              <Col span={24}>
                <Row gutter={16}>
                  <Col span={12}>
                    <label>Item Code</label>
                    <Input name={'code'} onChange={this.setValue} placeholder={'Item Code'} value={this.state.code}/>
                  </Col>
                  <Col span={12}>
                    <label>HSN Code</label>
                    <Input name={'hsnCode'} onChange={this.setValue} placeholder={'HSN Code'}
                           value={this.state.hsnCode}/>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={16}>
                  <Col span={gstAvailable ? 12 : 24}>
                    <label>Purchase Price</label>
                    <Input name={'purchasePrice'} onChange={this.setValue} placeholder={'Purchase Price'}
                           value={this.state.purchasePrice}
                           type={'number'}/>
                  </Col>
                  <CheckGstAvailable>
                    <Col span={12}>
                      <div className={'h20'}></div>
                      <Select value={this.state.gstPurchaseInclusive}
                              onChange={(e) => this.setState({ gstPurchaseInclusive: e })}>
                        {TaxTypeList.map((item) => {
                          return (
                            <Option value={item.value} key={item.label}>{item.label}</Option>
                          )
                        })}
                      </Select>
                    </Col>
                  </CheckGstAvailable>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={16}>
                  <Col span={gstAvailable ? 12 : 24}>
                    <label>Sale Price</label>
                    <Input name={'salePrice'} onChange={this.setValue} placeholder={'Sale Price'}
                           value={this.state.salePrice}
                           type={'number'}/>
                  </Col>
                  <CheckGstAvailable>
                    <Col span={12}>
                      <div className={'h20'}></div>
                      <Select value={this.state.gstInclusive}
                              onChange={(e) => this.setState({ gstInclusive: e })}>
                        {TaxTypeList.map((item) => {
                          return (
                            <Option value={item.value} key={item.label}>{item.label}</Option>
                          )
                        })}
                      </Select>
                    </Col>
                  </CheckGstAvailable>
                </Row>
              </Col>

              <Col span={24}>
                <Row gutter={16}>
                  <Col span={12}>
                    <label>MRP</label>
                    <Input name={'mrp'} onChange={this.setValue} placeholder={'MRP'}
                           value={this.state.mrp}
                           type={'number'}/>
                  </Col>
                  <Col span={12}>
                    <label>Whole sale price</label>
                    <Input name={'wholesalePrice'} onChange={this.setValue} placeholder={'Whole sale price'}
                           value={this.state.wholesalePrice}
                           type={'number'}/>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <label>Opening stock</label>
                <Input name={'openingStock'} onChange={this.setValue} placeholder={'Opening stock'}
                       value={this.state.openingStock}
                       type={'number'}/>
              </Col>
              <Col span={24}>
                <label>Location</label>
                <Input name={'location'} onChange={this.setValue} placeholder={'Location'}
                       value={this.state.location}/>
              </Col>

              <Col span={24}>
                <label>Warranty</label>
                <TextArea name={'Warranty'} onChange={this.setValue} placeholder={'Warranty'}
                          value={this.state.warranty} rows={3}/>
              </Col>
              <Col span={24}>
                <Row gutter={16}>
                  <Col span={12}>
                    <label>GST</label>
                    <Input name={'gst'} onChange={this.setValue} placeholder={'GST'}
                           disabled={true}
                           value={this.state.gst}/>
                  </Col>
                  <Col span={12}>
                    <label>Unit</label>
                    <div className={'rowFlex'}>
                      <Select value={this.state.unitName}
                              allowClear={true}
                              showSearch={true}
                              filterOption={this.filterOption}
                              optionFilterProp="children"
                              onChange={(e) => {
                                this.setState({
                                  'unitId': e && e.uid ? e.uid : '',
                                  'unitName': e && e.name ? e.name : '',
                                  'unitCode': e && e.code ? e.code : ''
                                })
                              }}>
                        {this.state.unitArr.map((item) => {
                          return (
                            <Option key={item.uid} value={item}>{item.name}</Option>
                          )
                        })}
                      </Select>
                      <Button onClick={() => this.events.showUnitDrawer()}>
                        <Icon type={'plus'}></Icon>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Form>
        </Card>

        {visibleAddUnit && <AddUnit visible={visibleAddUnit} {...this.props}
                                    unitCallback={this.events.unitCallback}
                                    onUnitClose={this.events.onUnitClose}/>}
        {visibleAddBrand && <AddBrand visible={visibleAddBrand} {...this.props}
                                      brandCallback={this.events.brandCallback}
                                      onBrandClose={this.events.onBrandClose}/>}
        {visibleAddCategory && <AddCategory visible={visibleAddCategory} {...this.props}
                                            categoryCallback={this.events.categoryCallback}
                                            onCategoryClose={this.events.onCategoryClose}/>}

        {visibleAddSubBrand ?
          <AddSubBrand
            brandUid={this.state.brandId}
            brandCallback={this.events.subBrandCallback}
            onBrandClose={this.events.onSubBrandClose}
            visible={visibleAddSubBrand}
            {...this.props}
          />
          : null}

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProduct)
