import React from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Divider,
  Tooltip,
  Checkbox, Modal, Alert, Descriptions
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader, showPageLoad, hidePageLoad } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { AsyncStorage } from '../../../asyncStorage'
import { createMatchSelector } from 'connected-react-router'
import { StateList, ReloadSaleItems } from '../../../components/_utils/_utils'
import ChooseClientComponent from '../../webComponent/ChooseClientComponent'
import ItemListComponent from '../../webComponent/ItemListComponent'
import ChooseBank from '../../webComponent/ChooseBank'
import { InputBox } from '../../webComponent/appDes'
import debounce from 'lodash/debounce'
import PrintHtml from '../../html/index'
import { HtmlPrint } from '../../html/print'
import ChooseTransport from '../../webComponent/ChooseTransport'

const { TextArea } = Input

// inputSchema.fields


const FormItem = Form.Item
const { Option } = Select


let html = null
let oldItems = []
let oldBalance = 0
let saleObj = {}
let saleData = {} // for store after save sale data
let fff = ''
let removeId = null
let editItem = {}
let printType = 'Simple'


@Form.create()
class AddCreditNote extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      qmrp: false,
      qws: false,
      qsp: true,
      state: 'Select',
      clientBalance: '0',
      saleDate: moment(),
      arr: [],
      isDateTimePickerVisible: false,
      paymentMode: '',
      hideTypeahead: true,
      warmodal: false,
      accordionOpen: true,
      addClientModal: false,
      addSubClientModal: false,
      invoiceDiscount: null,
      totalQuantity: '0',
      subTotal: '0',
      // items: [],
      invoiceNo: null,
      arrData: [],
      clientId: null,
      clientName: null,
      refNo: null,
      cashSale: false,
      image: {},
      imageSelect: false,
      checkConnection: false,
      warranty: 'None',
      checkedBy: null,
      packedBy: null,
      deliveredBy: null,
      cashBy: null,
      reverseCharge: false,
      clientInfo: {},
      bankArr: [{ name: 'None', uid: '' }],
      entryTime: moment().format('LLL'),
      vehicleKey: moment(),
      bankKey: moment() + 1,
      itemListKey: moment() + 2,
      clientKey: moment() + 3,
      showEwayBillNote: false,
      displayInvoiceNo: '',
      saveType: '',
      items: []

    }


  }


  componentDidMount() {
    this.loadInvoiceCount()
  }


  calculation = () => {


    let totalQt = 0
    let totalAMt = 0
    let taxableAmount = 0
    _.each(this.state.items, (item) => {
      if (item.quantity) {
        totalQt = totalQt + parseFloat(item.quantity)
      }
      if (item.netAmount) {
        totalAMt = totalAMt + parseFloat(item.netAmount)
      }
      if (item.taxableAmount) {
        taxableAmount = taxableAmount + parseFloat(item.taxableAmount)
      }
    })

    this.setState({
      totalQuantity: totalQt ? totalQt.toFixed(3) : null,
      subTotal: totalAMt ? parseFloat(totalAMt).toFixed(2) : null,
      totalAmount: totalAMt ? parseFloat(totalAMt).toFixed(2) : null,
      taxableAmount: taxableAmount ? parseFloat(taxableAmount).toFixed(2) : null
    }, () => {
      this.calBalance()
    })


  }


  loadInvoiceCount = () => {
    Request.getInventoryPrefix('CREDIT_NOTE').then((testData) => {
      this.setState({ ...testData })
    }).catch((e) => {

    })
  }


  reloadItems = (rr) => {
    this.props.dispatch(showLoader())
    let allIt = this.state.items
    ReloadSaleItems(rr, allIt, (resp) => {
      this.setState({ items: resp }, () => {
        this.calculation()
        this.props.dispatch(hideLoader())
      })

    })


  }

  removeImage() {
    this.setState({
      image: {},
      imageSelect: false
    })
  }

  async createPdf() {
    let shortGst = saleObj.clientGstNo ? saleObj.clientGstNo.substring(0, 2) : ''
    if (shortGst) {
      shortGst = `(${shortGst})`
    }
    let data = {
      ...saleObj,
      title: 'credit note',
      printType: 'Simple',
      invoice: {
        address: {
          shopName: saleObj.clientName,
          address1: saleObj.clientAddress
        },
        customer: {
          'gst/in': saleObj.clientGstNo ? saleObj.clientGstNo : ''
        },
        invoiceLeftInfo: {
          'gr/rr no': saleObj.grNo,
          'E-way bill no': saleObj.eWayBillNo
        },
        invoiceInfo: {
          'invoice no': saleObj.invoiceNo,
          'dated': moment(saleObj.saleDate).format('DD-MMM-YYYY'),
          'entry time': saleObj.entryTime,
          'place of supply': `${saleObj.stateOfSupply} ${shortGst}`,
          'reverse charge': saleObj.reverseCharge ? 'Y' : 'N',
          'transport by': saleObj.transportType ? saleObj.transportType : '',
          'transport': saleObj.transportName ? saleObj.transportName : '',
          'vehicle no': saleObj.vehicleNo

        }
      }
    }

    html = await PrintHtml(data)

    let doubleHtml = html + `<p style="page-break-after: always;">&nbsp;</p>
<p style="page-break-before: always;">&nbsp;</p>` + html

    HtmlPrint(doubleHtml, '/dashboard')
  };

  calDiscount(type) {
    if (type == 'per') {
      setTimeout(() => {
        let dp = this.state.discPer ? this.state.discPer : 0
        let st = this.state.subTotal ? this.state.subTotal : 0

        let invd = (dp / 100) * st
        if (invd) {
          invd = parseFloat(invd).toFixed(2)
          this.setState({ invoiceDiscount: invd, totalAmount: parseFloat(st - invd).toFixed(2) }, () => {
            this.calBalance()
          })

        } else {

          this.setState({ invoiceDiscount: null, totalAmount: st.toString() }, () => {
            this.calBalance()
          })
        }
      }, 240)
    } else {
      setTimeout(() => {

        let st = this.state.subTotal ? this.state.subTotal : 0
        let invd = this.state.invoiceDiscount ? this.state.invoiceDiscount : 0
        let discPer = (invd / st) * 100
        if (discPer) {
          discPer = parseInt(discPer)
          this.setState({ discPer: discPer.toString(), totalAmount: parseFloat(st - invd).toFixed(2) }, () => {
            this.calBalance()
          })

        } else {
          this.setState({ discPer: null, totalAmount: st.toString() }, () => {
            this.calBalance()
          })

        }
      }, 240)
    }

  }

  calBalance() {
    setTimeout(() => {
      let ta = this.state.totalAmount ? this.state.totalAmount : 0
      let ra = this.state.paidAmount ? this.state.paidAmount : 0
      this.setState({ balance: parseFloat(ta - ra).toFixed(2) })
    }, 100)
  }

  componentWillMount() {
    this.initFxn()
  }


  initFxn() {
    let searchParams = new URLSearchParams(window.location.search)
    let linkId = searchParams.get('linkId')
    if (linkId) {
      this.getOldCreditItems(linkId)
    } else {
      this.resetAfterSave()
    }
  }

  getOldCreditItems(tempId) {


    this.props.dispatch(showPageLoad())
    Request.GetCreditItems({ linkId: tempId })
      .then((saleData) => {


        if (saleData && saleData.type) {
          saleData = saleData.saleData
          _.each(saleData, (value, key) => {
            if (typeof value == 'number') {
              value = value.toString()
            }
          })
          setTimeout(() => {
            this.setState({
              uid: saleData.uid,
              stateOfSupply: saleData.stateOfSupply,
              city: saleData.city,
              clientGroupId: saleData.clientGroupId,
              clientBalance: saleData.clientBalance,
              date: moment(),
              isDateTimePickerVisible: false,
              paymentMode: saleData.paymentMode,
              hideTypeahead: false,
              addClientModal: false,
              invoiceDate: saleData.invoiceDate ? moment(saleData.invoiceDate) : moment(),
              items: saleData.items,
              refNo: saleData.refNo,
              totalQuantity: saleData.totalQuantity,
              totalAmount: saleData.totalAmount ? saleData.totalAmount.toString() : null,
              subTotal: saleData.subTotal ? saleData.subTotal : saleData.totalAmount,
              paidAmount: saleData.paidAmount ? saleData.paidAmount.toString() : null,
              discPer: saleData.discPer ? saleData.discPer.toString() : null,
              invoiceDiscount: saleData.invoiceDiscount ? saleData.invoiceDiscount.toString() : null,
              // invoiceNo: saleData.invoiceNo ? saleData.invoiceNo.toString() : null,
              balance: saleData.balance,
              clientId: saleData.clientId,
              clientName: saleData.clientName,
              description: saleData.description,
              imageSelect: saleData.document ? true : false,
              image: {
                uri: saleData.document ? saleData.document : null
              },
              transportType: saleData.transportType,
              transportId: saleData.transportId,
              transportName: saleData.transportName,
              vehicleId: saleData.vehicleId,
              vehicleNo: saleData.vehicleNo,
              reverseCharge: saleData.reverseCharge,
              bankId: saleData.bankId,
              paymentType: saleData.paymentType,
              bankType: saleData.bankType,
              paymentDetails: saleData.paymentDetails,
              taxableAmount: saleData.taxableAmount,
              clientState: saleData.state,
              clientGstNo: saleData.clientGstNo,
              clientAddress: saleData.clientAddress,
              stateCode: saleData.stateCode,
              pinCode: saleData.pinCode,
              vehicleKey: moment(),
              bankKey: moment() + 1,
              itemListKey: moment() + 2,
              clientKey: moment() + 3,
              entryTime: moment().format('LLL')

            })
            this.props.dispatch(hidePageLoad())
          }, 1000)
        }

      }).catch((e) => {
      this.props.dispatch(hidePageLoad())
    })
  }

  save(type) {

    saleData = {}
    this.props.dispatch(showPageLoad())

    AsyncStorage.getBusiness().then((value) => {
      if (value !== null) {

        this.setState({ businessId: value.uid })
        let cloneState = _.clone(this.state)
        delete cloneState.arr
        delete cloneState.warrArr
        delete cloneState.arrData
        delete cloneState.bankArr
        cloneState.businessId = value.uid


        if (!cloneState.clientName || !cloneState.clientId) {
          notification.warn({
            message: 'Client name can not be left empty'
          })
          this.props.dispatch(hidePageLoad())
          return
        }
        /* if (!cloneState.stateOfSupply || cloneState.stateOfSupply == "Select") {
             notification.warn({
                 message: "Choose state of supply"
             });
             this.props.dispatch(hideLoader());
             return;
         }
         if (cloneState.stateOfSupply !== cloneState.clientState) {
             notification.warn({
                 message: `Choose state of supply : ${cloneState.clientState}`
             });
             this.props.dispatch(hideLoader());
             return;
         }*/

        if (cloneState.transportType) {
          /*notification.warn({
              message: "Choose transport type"
          });
          this.props.dispatch(hideLoader());
          return;*/

          if (cloneState.transportType == 'Transport' && !cloneState.transportId) {
            notification.warn({
              message: 'Choose transport'
            })
            this.props.dispatch(hidePageLoad())
            return
          }
          /* if (!cloneState.vehicleId) {
               notification.warn({
                   message: "Choose Vehicle no"
               });
               this.props.dispatch(hideLoader());
               return;
           }*/
        }


        if (!cloneState.invoiceNo) {
          notification.warn({
            message: 'Enter invoice no'
          })
          this.props.dispatch(hidePageLoad())
          return
        }


        if (!cloneState.totalAmount) {
          notification.warn({
            message: 'Enter Total Amount'
          })
          this.props.dispatch(hidePageLoad())
          return
        }


        if (cloneState.paidAmount && parseFloat(cloneState.paidAmount) > 0) {
          if (!cloneState.bankId) {
            notification.warn({
              message: 'Please choose bank'
            })
            this.props.dispatch(hidePageLoad())
            return
          }
          if (cloneState.bankType == 'Bank' && !cloneState.paymentType) {
            notification.warn({
              message: 'Please choose payment type'
            })
            this.props.dispatch(hidePageLoad())
            return
          }
        } else {
          cloneState.bankId = ''
          cloneState.bankType = ''
        }

        Request.AddCreateNote(cloneState)
          .then(async (testData) => {
            if (testData.type) {


              this.props.dispatch(hidePageLoad())

              let smsobj = {
                uid: testData.uid,
                type: 'CREDIT_NOTE',
                name: cloneState.clientName,
                msg: `Add Credit Note :  \n Client = ${cloneState.clientName} \n Total Amount = ${cloneState.totalAmount}`
              }
              if (this.state.image && this.state.image.name) {
                AsyncStorage.getUserData().then((userResp) => {
                  if (userResp) {
                    let imageObj = {
                      businessId: value.uid,
                      linkId: testData.uid,
                      type: 'CREDIT_NOTE',
                      date: new Date(),
                      userId: userResp.uid
                    }
                    Request.uploadDocument(imageObj, this.state.image).then((resp1) => {
                    }).catch((err) => {
                      console.log(err)
                    })
                  }
                })
              }

              // let rrr = await Request.SendSms(smsobj)

              if (type == 'new') {
                notification.success({
                  message: 'Credit Note Saved Successfully'
                })
                this.props.dispatch(hidePageLoad())
                this.initFxn()
                return
              }

              if (type == 'print') {
                notification.success({
                  message: 'Credit Note Saved Successfully'
                })
                this.props.dispatch(hidePageLoad())

                return this.getItems(testData.uid)
              }


            } else {
              notification.error({
                message: testData.msg
              })
              this.props.dispatch(hidePageLoad())
              return
            }
          })
          .catch((e) => {
            notification.error({
              message: 'Try again'
            })
            this.props.dispatch(hidePageLoad())
            return
          })
      } else {
        this.props.dispatch(hidePageLoad())
        notification.error({
          message: 'Business Setting Not Set'
        })
        return
      }
    })


  }

  resetAfterSave() {
    this.setState({
      state: 'Select',
      clientBalance: '0',
      date: moment().format('DD-MMM-YYYY'),
      paymentMode: '',
      items: [],
      clientId: '',
      clientName: '',
      refNo: '',
      cashSale: false,
      image: {},
      imageSelect: false,
      saleCategory: '',
      checkedBy: null,
      packedBy: null,
      deliveredBy: null,
      cashBy: null,
      station: null,
      eWayBillNo: null,
      grNo: null,
      receivedAmount: null,
      bankId: null,
      paymentType: null,
      bankType: null,
      paymentDetails: null,
      transportType: null,
      transportId: null,
      transportName: null,
      vehicleId: null,
      vehicleNo: null,
      bankKey: moment() + 1,
      clientKey: moment() + 3,
      itemListKey: moment() + 2,
      entryTime: moment().format('LLL'),
      reverseCharge: false,
      clientInfo: {},
      bankArr: [{ name: 'None', uid: '' }],
      displayInvoiceNo: '',
      clientGroupId: '',
      clientState: '',
      stateOfSupply: '',
      city: '',
      clientGstNo: '',
      clientAddress: '',
      stateCode: '',
      pinCode: ''
    })
    saleData = {}
    setTimeout(() => {
      this.calculation()
    }, 1000)
  }

  getItems(tempId) {

    saleObj = {}

    this.props.dispatch(showPageLoad())
    Request.GetCreditItems({ linkId: tempId })
      .then((saleData) => {


        this.props.dispatch(hidePageLoad())
        if (saleData.type) {

          saleData = saleData.saleData
          _.each(saleData, (val, k) => {
            saleObj[k] = val
          })

          saleObj.saleDate = moment(saleObj.saleDate)
          saleObj.showDisc = false


          return this.createPdf()
        }


      }).catch((e) => {

    })
  }


  fieldUpdate = (localState) => {
    this.setState({ ...localState })
  }


  _setDigitValue(value, type) {
    if (value) {
      let splitX = value.split('.')
      if (splitX.length > 2) {
        return true
      }
      if (splitX[1]) {
        if (splitX[1].length > 2) {
          return true
        }
      }
      this.setState({ [type]: value }, () => {
        this.calBalance()
      })
    } else {
      this.setState({ [type]: null }, () => {
        this.calBalance()
      })
    }

  }


  getBackItems = (obj) => {
    let { items, defaultTaxType } = obj
    this.setState({ items, defaultTaxType }, () => {
      this.calculation()
    })
  }


  onChooseDate = (date, dateString, fieldName) => {
    this.setState({ [fieldName]: date })
  }
  setValue = (e) => {
    let { name, value } = e.target
    this.setState({ [name]: value })
  }

  _callBackBank = (data) => {
    this.setState({ ...data })
  }

  _callbackClient = (data) => {
    this.setState({ ...data })
  }

  _callBackChooseVehicle = (localState) => {
    this.setState({ ...this.state, ...localState })
  }


  render() {
    const handleChange = () => {

    }
    let { items, saleDate, vehicleKey, clientName, clientId } = this.state

    const formData = (
      <Row gutter={16}>
        <Col span={8}>
          <InputBox title={'Date'}>
            <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'saleDate')}
                        value={saleDate} placeholder={'Date'}/>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Invoice Date'}>
            <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'invoiceDate')}
                        value={this.state.invoiceDate} placeholder={'Invoice Date'}/>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'GR/RR No'}>

            <Input onChange={this.setValue} name={'grNo'} placeholder={'GR/RR no'} value={this.state.grNo}/>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Reverse Charge'}>
            <Select value={this.state.reverseCharge}>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Eway bill no'}>
            <Input onChange={this.setValue} name={'eWayBillNo'} placeholder={'Eway bill no'}
                   value={this.state.eWayBillNo}/>
          </InputBox>
        </Col>


      </Row>
    )

    const amountFields = (
      <Row gutter={16}>
        <Col span={8}>
          <InputBox title={'Total Amount'}>
            <div className={'ant-input'}>
              {this.state.totalAmount}
            </div>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Paid Amount'}>
            <Input onChange={(e) => {
              this._setDigitValue(e.target.value, 'paidAmount')
            }} name={'paidAmount'} placeholder={'Paid Amount'}
                   value={this.state.paidAmount}/>
          </InputBox>
        </Col>
        {!this.state.cashSale ?
          <Col span={8}>
            <InputBox title={'Balance'}>
              <div className={'ant-input'}>
                {this.state.balance}
              </div>
            </InputBox>
          </Col> : null}
      </Row>
    )

    const otherFields = (
      <Row gutter={16}>
        <Col span={8}> <InputBox title={'Description (Optional)'}>
          <Input onChange={this.setValue} name={'description'} placeholder={'Description'}
                 value={this.state.description}/>
        </InputBox></Col>
        <Col span={8}>
          <InputBox title={'Choose Picture'} className={'rowFlex'}>
            <Input type={'file'} accept="image/*" onChange={(e) => {
              if (e.target.files && e.target.files.length) {
                this.setState({ image: e.target.files[0] })
              }
            }}/>
            {this.state.image && this.state.image.uri ?
              <a className={'linkBtn'} target={'_blank'} href={this.state.image.uri}>
                <Icon type={'eye'}></Icon>
              </a> : null}
          </InputBox>
        </Col>
        <Col span={8}> <InputBox title={'Checked By '}>
          <Input onChange={this.setValue} name={'checkedBy'} placeholder={'Checked By'}
                 value={this.state.checkedBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'PackedBy By '}>
          <Input onChange={this.setValue} name={'packedBy'} placeholder={'Packed By'}
                 value={this.state.packedBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'Delivered By '}>
          <Input onChange={this.setValue} name={'deliveredBy'} placeholder={'Delivered By'}
                 value={this.state.deliveredBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'Cash By '}>
          <Input onChange={this.setValue} name={'cashBy'} placeholder={'Cash By'}
                 value={this.state.cashBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'Entry Time'}>
          <Input onChange={this.setValue} name={'entryTime'} placeholder={'Entry Time'}
                 value={this.state.entryTime}/>
        </InputBox></Col>
      </Row>
    )


    return (
      <PageHeaderWrapper
        title={'Add Credit Note'}
        content={''}>
        <Card bordered={true}
              extra={<div className={'actionGroup'}>
                <Button type="primary" onClick={
                  debounce(() => {
                    this.save('new')
                  }, 200)
                }>SAVE CREDIT NOTE</Button>
                <Button type="success" onClick={
                  debounce(() => {
                    this.save('print')
                  }, 200)
                }>SAVE & PRINT</Button>

              </div>}>
          <Form hideRequiredMark style={{ marginTop: 8 }} className={'newForm'}>

            <ChooseClientComponent {...this.props} _callbackClient={this._callbackClient} key={this.state.clientKey}
                                   clientName={this.state.clientName} clientId={this.state.clientId}
                                   stateOfSupply={this.state.stateOfSupply}/>


            {formData}

            <ChooseTransport _callBackChooseVehicle={this._callBackChooseVehicle} {...this.props}
                             fieldUpdate={this.fieldUpdate}
                             key={vehicleKey} {...this.state} />

            <ItemListComponent
              key={this.state.itemListKey}
              {...this.props}
              items={this.state.items}
              isCreditNote={true}
              showRef={true}
              clientId={this.state.clientId}
              subTotal={this.state.subTotal}
              totalQuantity={this.state.totalQuantity}
              getBackItems={this.getBackItems}
              pageParams={{
                pageType: 'Sale',
                defaultTaxType: this.state.defaultTaxType
              }}
            />
            {amountFields}
            <ChooseBank {...this.props} key={this.state.bankKey}
                        callBackBank={this._callBackBank} {...this.state}/>

            {otherFields}
          </Form>


        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCreditNote)
