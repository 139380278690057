import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Table, notification, Drawer, List
} from 'antd'
import Request from '../../../request'
import { UnitCodeList } from '../../../components/_utils/_utils'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader } from '../../../modules/actions'
import { connect } from 'react-redux'
import { AsyncStorage } from '../../../asyncStorage'


const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AllUnits extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      addUnitVisible: false,
      selectedBusiness: {}
    }
    this.loadSelectedBusiness()
  }

  async loadSelectedBusiness() {
    let resp = await AsyncStorage.getBusiness()
    if (resp && resp.uid) {
      this.setState({ selectedBusiness: resp })
    }
  }


  componentDidMount() {
    this.loadAllBusiness()
  }

  async loadAllBusiness() {
    let data = await Request.AllBusiness()
    this.setState({ arr: data })
  }


  callback = (data) => {
    this.setState({ addUnitVisible: false }, () => {
      this.loadAllBusiness()
    })
  }
  onClose = () => {
    this.setState({ addUnitVisible: false })
  }

  addUnit = () => {
    let { dispatch } = this.props
    dispatch(hideLoader())
    this.setState({ addUnitVisible: true })
  }

  async selectBusiness(business) {
    let resp = await AsyncStorage.setBusiness(business)
    window.location.reload()
    // setBusiness
  }

  render() {
    let { arr, addUnitVisible, selectedBusiness } = this.state
    let { visible } = this.props
    const columns = [
      {
        title: 'Business',
        key: 'name',
        dataIndex: 'name',
        render: (item, record) => {
          let { selectedBusiness } = this.state
          return (
            <Row onClick={() => this.selectBusiness(record)}>
              <Col span={16}><a>{item}</a></Col>
              <Col span={8}>
                {selectedBusiness && selectedBusiness.uid && selectedBusiness.uid == record.uid ?
                  <Button type={'success'}>Active</Button> : ''}
              </Col>
            </Row>

          )
        }
      }
    ]

    return (
      <Drawer
        title="All Business"
        width={800}
        closable={false}
        placement={'left'}
        onClose={this.props.onClose}
        visible={visible}>
        <Card bordered={true}>
          <Table
            bordered
            size={'small'}
            columns={columns}
            dataSource={arr}
            pagination={false}
          />

        </Card>
      </Drawer>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllUnits)
