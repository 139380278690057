import PrintStyle from '../style'
import PrintHeader from '../header'
import _ from 'lodash'
import { AsyncStorage } from '../../../asyncStorage'

const PrintHtml = async (obj) => {
  let businessDetails = await AsyncStorage.getBusiness()

  let { paymentInObj, paymentOutObj, balancePaymentOut, balancePaymentIn, totalPaymentOut, totalPaymentIn } = obj
  return new Promise(async (resolve) => {
    let paymentInTable = ''
    let paymentOutTable = ''

    paymentInObj && Object.entries(paymentInObj).map(([key, value]) => {
      let flexColumn = value.arr && value.arr.length
      let innerItem = ''
      if (value.arr && value.arr.length) {
        _.each(value.arr, (item, index) => {
          innerItem += ` <tr>
                            <td>${item.name}</td>
                            <td>${item.amount}</td>
                            <td>${(value.arr.length - 1 == index) ? value.total ? value.total : '' : ''}</td>
                        </tr>`
        })
      }
      paymentInTable += `<div class="padding10">
                    <table class="sheetTable font10">
                        <tr>
                            <td><i class="b font14">${key}:</i></td>
                            <td>${flexColumn ? '' : value.total ? value.total : 0}</td>
                        </tr>
                    </table>
                    
                    <table class="sheetTable font10 marginTop10">
                        ${innerItem}
                    </table>
                </div>`
    })

    paymentOutObj && Object.entries(paymentOutObj).map(([key, value]) => {
      let flexColumn = value.arr && value.arr.length
      let innerItem = ''

      if (value.arr && value.arr.length) {
        _.each(value.arr, (item, index) => {
          innerItem += ` <tr>
                            <td>${item.name}</td>
                            <td>${item.amount}</td>
                            <td>${(value.arr.length - 1 == index) ? value.total ? value.total : '' : ''}</td>
                        </tr>`
        })
      }
      paymentOutTable += `<div class="padding10">
                    <table class="sheetTable font10">
                        <tr>
                            <td><i class="b font14">${key}:</i></td>
                            <td>${flexColumn ? '' : value.total ? value.total : 0}</td>
                        </tr>
                    </table>
                    <table class="sheetTable font10 marginTop10">
                        ${innerItem}
                    </table>
                </div>`
    })


    const html = `
    <!doctype html>
   <html>
   <head>
   <meta charset="utf-8">
   <title>Trading Sheet</title>
    ${PrintStyle}
</head>
<body class="container">
    <page size="A4">
        ${await PrintHeader(obj.title, `( For the period ending )`)}
       
        <div class="row border-bottom font16 center padding20 letter-sp">
        <div class="col-md-6  border-right">
            CASH IN (Rs.)
        </div>
        <div class="col-md-6">
            CASH OUT (Rs.)
        </div>
    </div>
    <div class="row ">
        <div class="col-md-6 border-right-dashed">
            ${paymentInTable}
            
            
        </div>
        <div class="col-md-6">
            ${paymentOutTable}
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 border-right-dashed">
            <table class="sheetTable font10  b">
                <tr>
                    <td></td>
                    <td>Total</td>
                    <td><span class="border-top-bottom">${totalPaymentIn}</span></td>
                </tr>
            </table>
        </div>
        <div class="col-md-6 border-right-dashed">
            <table class="sheetTable font10  b">
                <tr>
                    <td></td>
                    <td>Total</td>
                    <td><span class="border-top-bottom">${totalPaymentOut}</span></td>
                </tr>
            </table>
        </div>
    </div>
        </page>
    </body>
    </html>`
    resolve(html)
  })

}

export default PrintHtml
