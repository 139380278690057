import React from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Divider,
  Tooltip,
  Checkbox, Modal, Alert, Descriptions, notification
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css'
import { hideLoader, showLoader, showPageLoad, hidePageLoad } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { AsyncStorage } from '../../../asyncStorage'
import { ReloadSaleItems } from '../../../components/_utils/_utils'
import ChooseClientComponent from '../../webComponent/ChooseClientComponent'
import ItemListComponent from '../../webComponent/ItemListComponent'
import { InputBox } from '../../webComponent/appDes'
import debounce from 'lodash/debounce'
import PrintHtml from '../../html/index'
import { HtmlPrint } from '../../html/print'
import { getUrlParams } from '../../../routes'
import { v4 as uuid } from 'uuid'

const { TextArea } = Input


const FormItem = Form.Item
const { Option } = Select


let html = null
let oldItems = []
let oldBalance = 0
let saleObj = {}
let saleData = {} // for store after save sale data
let fff = ''
let removeId = null
let editItem = {}
let printType = 'Simple'


@Form.create()
class EditEstimate extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      clientBalance: '0',
      estimateDate: moment(),
      arr: [],
      isDateTimePickerVisible: false,
      totalQuantity: 0,
      totalAmount: 0,
      items: [],
      arrData: [],
      clientId: null,
      clientName: null,
      refNo: null,
      cashSale: false,
      image: {},
      imageSelect: false,
      itemListKey: moment(),
      clientKey: moment() + 1

    }


  }


  componentDidMount() {
    this.loadInvoiceCount()
  }


  calculation = () => {


    let totalQt = 0
    let totalAMt = 0
    let taxableAmount = 0
    _.each(this.state.items, (item) => {
      if (item.quantity) {
        totalQt = totalQt + parseFloat(item.quantity)
      }
      if (item.netAmount) {
        totalAMt = totalAMt + parseFloat(item.netAmount)
      }
      if (item.taxableAmount) {
        taxableAmount = taxableAmount + parseFloat(item.taxableAmount)
      }
    })

    this.setState({
      totalQuantity: totalQt ? totalQt.toFixed(3) : null,
      subTotal: totalAMt ? parseFloat(totalAMt).toFixed(2) : null,
      totalAmount: totalAMt ? parseFloat(totalAMt).toFixed(2) : null,
      taxableAmount: taxableAmount ? parseFloat(taxableAmount).toFixed(2) : null
    }, () => {
      this.calBalance()
    })


  }


  loadInvoiceCount = () => {
    Request.getInventoryPrefix('CREDIT_NOTE').then((testData) => {
      this.setState({ ...testData })
    }).catch((e) => {

    })
  }


  reloadItems = (rr) => {
    this.props.dispatch(showLoader())
    let allIt = this.state.items
    ReloadSaleItems(rr, allIt, (resp) => {
      this.setState({ items: resp }, () => {
        this.calculation()
        this.props.dispatch(hideLoader())
      })

    })


  }

  removeImage() {
    this.setState({
      image: {},
      imageSelect: false
    })
  }

  async createPdf(type) {
    let saleData = _.clone(saleObj)
    let shortGst = saleData.clientGstNo ? saleData.clientGstNo.substring(0, 2) : ''
    if (shortGst) {
      shortGst = `(${shortGst})`
    }
    let data = {
      ...saleData,
      printType: type,
      title: 'Quotation',
      showDiscount: true,
      invoice: {
        address: {
          shopName: saleData.clientName,
          address1: saleData.clientAddress
        },
        customer: {

          /* "name": "",
           "address": ""*/
        },
        invoiceLeftInfo: {
          'gst/in': saleData.clientGstNo
        },
        invoiceInfo: {
          'dated': saleData.estimateDate ? moment(saleData.estimateDate).format('DD-MMM-YYYY') : '',
          'place of supply': `${saleData.stateOfSupply} ${shortGst}`
        }
      }
    }
    html = await PrintHtml(data)

    let doubleHtml = html + `<p style="page-break-after: always;">&nbsp;</p>
<p style="page-break-before: always;">&nbsp;</p>` + html


    if (type == 'share') {
      HtmlPrint(doubleHtml)
    } else {
      HtmlPrint(doubleHtml, '/dashboard')
    }
  };

  calDiscount(type) {
    if (type == 'per') {
      setTimeout(() => {
        let dp = this.state.discPer ? this.state.discPer : 0
        let st = this.state.subTotal ? this.state.subTotal : 0

        let invd = (dp / 100) * st
        if (invd) {
          invd = parseFloat(invd).toFixed(2)
          this.setState({ invoiceDiscount: invd, totalAmount: parseFloat(st - invd).toFixed(2) }, () => {
            this.calBalance()
          })

        } else {

          this.setState({ invoiceDiscount: null, totalAmount: st.toString() }, () => {
            this.calBalance()
          })
        }
      }, 240)
    } else {
      setTimeout(() => {

        let st = this.state.subTotal ? this.state.subTotal : 0
        let invd = this.state.invoiceDiscount ? this.state.invoiceDiscount : 0
        let discPer = (invd / st) * 100
        if (discPer) {
          discPer = parseInt(discPer)
          this.setState({ discPer: discPer.toString(), totalAmount: parseFloat(st - invd).toFixed(2) }, () => {
            this.calBalance()
          })

        } else {
          this.setState({ discPer: null, totalAmount: st.toString() }, () => {
            this.calBalance()
          })

        }
      }, 240)
    }

  }


  componentWillMount() {
    this.initFxn()
  }

  async initFxn() {
    let data = await getUrlParams(
      'estimate.editEstimate',
      this.props.pathname
    )
    if (data && data.id) {
      this.getItems(data.id)
    }
  }

  save(type) {


    AsyncStorage.getBusiness().then((value) => {
      if (value !== null) {

        this.setState({ businessId: value.uid })

        let cloneState = _.clone(this.state)

        delete cloneState.arr
        delete cloneState.arrData

        cloneState.businessId = value.uid

        if (!cloneState.businessId) {
          notification.error({
            message: 'Business Setting Not Set '
          })
          return
        }

        if (!cloneState.clientName || !cloneState.clientId) {
          notification.warn({
            message: 'Client name can not be left empty'
          })
          return
        }


        cloneState.oldItems = oldItems
        this.props.dispatch(showPageLoad())
        Request.UpdateEstimate(cloneState)
          .then((testData) => {

            if (testData.type) {

              notification.success({
                message: 'Estimate Saved Successfully'
              })
              if (this.state.image && this.state.image.uri) {
                AsyncStorage.getUserData().then((userResp) => {
                  if (userResp) {
                    let imageObj = {
                      businessId: value.uid,
                      linkId: testData.sale.uid,
                      type: 'ESTIMATE',
                      date: new Date(),
                      userId: userResp.uid

                    }
                    Request.uploadDocument(imageObj, this.state.image).then((resp1) => {

                    }).catch((err) => {
                      console.log(err)
                    })
                  }
                })
              }
              this.props.dispatch(hidePageLoad())

              if (type == 'save') {
                window.location.href = '/dashboard'
              } else {
                this.createPdf('print')
              }
              return

            } else {
              notification.error({
                message: 'Try again'
              })
            }
          })
          .catch((e) => {
            notification.error({
              message: 'Try again'
            })
            this.props.dispatch(hidePageLoad())
            return
          })

      } else {
        notification.error({
          message: 'Business Setting Not Set'
        })
        return
      }
    })


  }

  resetAfterSave() {
    saleData = {}
    this.setState({
      clientBalance: '0',
      estimateDate: moment(),
      arr: [],
      isDateTimePickerVisible: false,
      totalQuantity: 0,
      totalAmount: 0,
      items: [],
      arrData: [],
      clientId: null,
      clientName: null,
      refNo: null,
      cashSale: false,
      image: {},
      imageSelect: false,
      itemListKey: moment(),
      clientKey: moment() + 1,
      clientGroupId: '',
      clientState: '',
      stateOfSupply: '',
      city: '',
      clientGstNo: '',
      clientAddress: '',
      stateCode: '',
      pinCode: ''
    })
    setTimeout(() => {
      this.calculation()
    }, 1000)
  }

  onChooseDate = (date, dateString, fieldName) => {
    this.setState({ [fieldName]: date })
  }

  setValue = (e) => {
    let { name, value } = e.target
    this.setState({ [name]: value })
  }


  calBalance(value) {

    this.setState({
      receivedAmount: value,
      balance: value ? parseFloat((parseFloat(this.state.totalAmount) - parseFloat(value))).toFixed(2) : '0'
    })
  }

  getItems(tempId) {
    oldItems = []
    saleObj = {}

    Request.GetEstimateItems({ linkId: tempId })
      .then((saleData) => {
        if (saleData) {
          _.each(saleData, (value, key) => {
            if (typeof value == 'number') {
              value = value.toString()
            }
          })

          _.each(saleData, (val, k) => {
            saleObj[k] = val
          })


          _.each(saleData.items, (sdfs) => {
            sdfs.tid = uuid()
            oldItems.push({
              productUid: sdfs.productUid,
              quantity: sdfs.quantity,
              uid: sdfs.uid,
              linkId: sdfs.saleId
            })
          })

          this.props.dispatch(showPageLoad())
          setTimeout(() => {
            this.setState({
              uid: saleData.uid,
              stateOfSupply: saleData.stateOfSupply,
              estimateDate: saleData.estimateDate ? moment(saleData.estimateDate) : moment().format('DD-MMM-YYYY'),
              isDateTimePickerVisible: false,
              paymentMode: saleData.paymentMode,
              items: saleData.items,
              totalQuantity: saleData.totalQuantity,
              totalAmount: saleData.totalAmount,
              clientId: saleData.clientId,
              clientName: saleData.clientName,
              refNo: saleData.refNo,
              description: saleData.description,
              invoiceNo: saleData.invoiceNo,
              imageSelect: saleData.document ? true : false,
              image: {
                uri: saleData.document ? saleData.document : null
              },
              itemListKey: moment() + 2,
              clientGroupId: saleData.clientGroupId,
              clientState: saleData.state,
              city: saleData.city,
              clientKey: moment() + 3,
              clientGstNo: saleData.clientGstNo,
              clientAddress: saleData.clientAddress,
              stateCode: saleData.stateCode,
              pinCode: saleData.pinCode
            })
            this.props.dispatch(hidePageLoad())
          }, 1000)
        }

      }).catch((e) => {

    })
  }

  getBackItems = (obj) => {
    let { items, defaultTaxType } = obj
    this.setState({ items, defaultTaxType }, () => {
      this.calculation()
    })
  }


  _callbackClient = (data) => {
    this.setState({ ...data })
  }

  render() {
    let { items, estimateDate, vehicleKey, clientName, clientId } = this.state
    const formData = (
      <Row gutter={16}>
        <Col span={8}>
          <InputBox title={'Date'}>
            <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'estimateDate')}
                        value={estimateDate} placeholder={'Date'}/>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Reference Number'}>
            <Input onChange={this.setValue} name={'refNo'} placeholder={'Reference Number'} value={this.state.refNo}/>
          </InputBox>
        </Col>
      </Row>
    )

    const amountFields = (
      <Row gutter={16}>
        <Col span={8}>
          <InputBox title={'Total Amount'}>
            <div className={'ant-input'}>
              {this.state.totalAmount}
            </div>
          </InputBox>
        </Col>
        <Col span={8}> <InputBox title={'Description (Optional)'}>
          <Input onChange={this.setValue} name={'description'} placeholder={'Description'}
                 value={this.state.description}/>
        </InputBox></Col>
        <Col span={8}>
          <InputBox title={'Choose Picture'} className={'rowFlex'}>
            <Input type={'file'} accept="image/*" onChange={(e) => {
              if (e.target.files && e.target.files.length) {
                this.setState({ image: e.target.files[0] })
              }
            }}/>
            {this.state.image && this.state.image.uri ?
              <a className={'linkBtn'} target={'_blank'} href={this.state.image.uri}>
                <Icon type={'eye'}></Icon>
              </a> : null}
          </InputBox>
        </Col>
      </Row>
    )

    return (
      <PageHeaderWrapper
        title={'Edit Quotation'}
        content={''}>
        <Card bordered={true}
              extra={<div className={'actionGroup'}>
                <Button type="primary" onClick={
                  debounce(() => {
                    this.save('save')
                  }, 1000)
                }>SAVE QUOTATION</Button>

                <Button type="success" onClick={
                  debounce(() => {
                    this.save('share')
                  }, 1000)
                }>SAVE & PRINT</Button>
                <Button type="success" onClick={
                  debounce(() => {
                    this.createPdf('share')
                  }, 1000)
                }>PRINT</Button>


              </div>}>
          <Form hideRequiredMark style={{ marginTop: 8 }} className={'newForm'}>

            <ChooseClientComponent {...this.props} _callbackClient={this._callbackClient} key={this.state.clientKey}
                                   clientName={this.state.clientName} clientId={this.state.clientId}
                                   stateOfSupply={this.state.stateOfSupply}/>


            {formData}


            <ItemListComponent
              key={this.state.itemListKey}
              {...this.props}
              items={this.state.items}
              title={'Item Details'}
              clientId={this.state.clientId}
              subTotal={this.state.totalAmount}
              totalQuantity={this.state.totalQuantity}
              getBackItems={this.getBackItems}
              pageParams={{
                defaultTaxType: this.state.defaultTaxType
              }}
            />
            {amountFields}

          </Form>


        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEstimate)
