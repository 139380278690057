import React from 'react'
import {
  Form, Divider, Select, Icon, Row
} from 'antd'
import Request from '../../request'
import _ from 'lodash'
import debounce from 'lodash/debounce'
import { InputBox } from './appDes'
import Styles from './styles.less'
import AddClient from '../Party/add/index'
import moment from 'moment'

const FormItem = Form.Item
const { Option } = Select

@Form.create()
class filterChooseClient extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      arrData: [],
      clientName: '',
      clientId: '',
      visibleAdd: false,
      clientBalance: 0
    }
    this.fetchClient = debounce(this.fetchClient, 500)

  }

  componentWillMount() {
    let { clientId, clientName } = this.props
    this.setState({
      clientId, clientName
    })
  }

  fetchClient = (data) => {
    let arrData = []
    Request.AllClient({ name: data })
      .then((testData) => {
        _.each(testData, (item) => {
          arrData.push(item)
        })
        console.log(arrData)
        this.setState({ arrData })
        return
      })
      .catch((e) => {
        return
      })
  }


  handleChange = (uid) => {
    let { arrData } = this.state
    let item = _.find(arrData, (value) => {
      return value.uid == uid
    })
    let obj = {
      clientName: '',
      clientId: ''
    }
    if (item) {
      obj = {
        clientName: item.name,
        clientId: item.uid,
        ...item

      }
    }
    this.setState({ ...obj }, () => {
      this.callbackFxn()
    })
  }


  clientCallback = (item) => {
    if (item && item.uid) {
      let obj = {
        clientName: item.name,
        clientGroupId: item.clientGroupId,
        clientState: item.clientState,
        stateOfSupply: item.state,
        state: item.state,
        city: item.city,
        clientBalance: item.currentBalance ? parseInt(item.currentBalance) : 0,
        currentBalance: item.currentBalance ? parseInt(item.currentBalance) : 0,
        clientId: item.uid,
        uid: item.uid,
        name: item.name,
        subClient: {},
        clientGstNo: item.clientGstNo,
        gstNumber: item.clientGstNo,
        clientAddress: item.address,
        address: item.address,
        stateCode: item.stateCode,
        pinCode: item.pinCode
      }
      this.setState({ ...obj, visibleAdd: false }, () => {
        this.callbackFxn()
      })
    }
  }

  callbackFxn() {
    let cloneState = _.clone(this.state)
    delete cloneState['arrData']
    this.props._callbackClient(cloneState)
  }


  addClient() {
    this.setState({ visibleAdd: true })
  }

  onClientClose = () => {
    this.setState({ visibleAdd: false })
  }


  render() {
    let { arrData, clientName, clientId, visibleAdd, currentBalance } = this.state
    let { allowNew, showBalance } = this.props

    return (
      <div>

        {showBalance ?
          <div>
            <label> Choose Client </label>
            {clientId ?
              <span className={Styles.balanceField}>
             Balance : Rs. {currentBalance ? currentBalance : 0}/-
            </span> : null}
          </div> : null}
        <Select
          showSearch={true}
          onSearch={this.fetchClient}
          filterOption={false}
          autoClearSearchValue={true}
          allowClear={true}
          value={clientName}
          showArrow={true}
          onChange={this.handleChange}
          placeholder="Choose Client"
          dropdownRender={menu => {
            return (
              <div key={arrData}>
                {menu}
                {allowNew ?
                  <div key={moment() + 1}>
                    <Divider style={{ margin: '4px 0' }}/>
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer' }}
                      onMouseDown={e => e.preventDefault()}
                      onClick={() => this.addClient()}>
                      <Icon type="plus"/> Add New Client
                    </div>
                  </div> : null}
              </div>
            )
          }}>
          {arrData.map((item, index) => {
            return (
              <Option key={item} value={item.uid}>{item.name}</Option>
            )
          })}

        </Select>
        {visibleAdd ? <AddClient
          visible={visibleAdd}
          {...this.props}
          clientCallback={this.clientCallback}
          onClientClose={this.onClientClose}/> : ''}

      </div>
    )
  }
}

export default filterChooseClient
