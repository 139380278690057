import React, { Component } from 'react'
import { Input, Drawer, Card, Select, Row, Col, Button, notification } from 'antd'
import { InputBox } from '../webComponent/appDes'
import AllStates from '../../components/locationJson/state'
import lodash from 'lodash'
import { filterOption } from '../../components/_utils/_utils'

const { Option } = Select

class addSubClient extends React.Component {
  constructor() {
    super()
    this.state = {
      name: '',
      address: '',
      state: '',
      mobile: '',
      email: '',
      itPan: '',
      aadharNo: '',
      stateId: '',
      stateCode: '',
      arr: []
    }
  }

  setValue = (e) => {
    let { name, value } = e.target
    this.setState({ [name]: value })
  }


  componentWillMount() {

  }


  componentDidMount() {
    let { subClient } = this.props
    this.setState({ ...subClient })
    this._allState()
  }

  _allState = () => {
    let stateArr = lodash.clone(AllStates)
    this.setState({ arr: stateArr })

  }

  async save() {
    let objj = {
      name: this.state.name,
      mobile: this.state.mobile,
      email: this.state.email,
      state: this.state.state,
      stateId: this.state.stateId,
      address: this.state.address,
      stateCode: this.state.stateCode,
      itPan: this.state.itPan,
      aadharNo: this.state.aadharNo
    }
    if (!objj.name) {
      notification.warn({
        message: 'Enter Name'
      })
      return
    }

    if (!objj.mobile) {
      notification.warn({
        message: 'Enter Mobile No'
      })
      return
    }


    this.props.callbackSubClient(objj)
  }

  chooseState = (data) => {
    let obj = {
      state: '',
      stateId: '',
      stateCode: ''
    }
    if (data) {
      obj = {
        state: data.name,
        stateId: data.id,
        stateCode: data.stateCode
      }
    }
    this.setState({ ...obj })
  }

  render() {
    let { visible } = this.props
    let { arr } = this.state
    return (
      <Drawer
        width={800}
        closable={false}
        onClose={this.props.onCloseSubClient}
        visible={visible}>
        <Card title={'Sub CLient'}>
          <Row gutter={16}>
            <Col>
              <InputBox title={'Name'}>
                <Input name={'name'} value={this.state.name} onChange={this.setValue} placeholder={'Enter Name'}/>
              </InputBox>
            </Col>
            <Col>
              <InputBox title={'Address'}>
                <Input name={'address'} value={this.state.address} onChange={this.setValue}
                       placeholder={'Enter Address'}/>
              </InputBox>
            </Col>
            <Col>
              <InputBox title={'Choose State'}>
                <Select
                  showSearch={true}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  allowClear={true}
                  value={this.state.state}
                  onChange={this.chooseState}
                  placeholder="Choose state">
                  {arr.map((item) => {
                    return (
                      <Option key={item.uid} value={item}>{item.name}</Option>
                    )
                  })}
                </Select>
              </InputBox>
            </Col>
            <Col span={12}>
              <InputBox title={'Mobile No'}>
                <Input name={'mobile'} value={this.state.mobile} onChange={this.setValue} placeholder={'Enter Mobile'}
                       type={'number'}/>
              </InputBox>
            </Col>
            <Col span={12}>
              <InputBox title={'Email'}>
                <Input name={'email'} value={this.state.email} onChange={this.setValue} placeholder={'Enter Email'}
                       type={'email'}/>
              </InputBox>
            </Col>
            <Col span={12}>
              <InputBox title={'IT PAN'}>
                <Input name={'itPan'} value={this.state.itPan} onChange={this.setValue} placeholder={'Enter IT Pan'}/>
              </InputBox>
            </Col>
            <Col span={12}>
              <InputBox title={'Aadhar Number'}>
                <Input name={'aadharNo'} value={this.state.aadharNo} onChange={this.setValue}
                       placeholder={'Enter Aadhar Number'}/>
              </InputBox>
            </Col>
            <Col span={24}>
              <InputBox title={''} className={'mt20'}>
                <Button type={'primary'} onClick={() => this.save()}>Add Sub Client</Button>
              </InputBox>
            </Col>
          </Row>
        </Card>

      </Drawer>
    )
  }
}

export default addSubClient
