import React from 'react'
import { Card, Col, DatePicker, Drawer, Row, Table } from 'antd'
import { InputBox } from '../webComponent/appDes'
import { TableComp } from 'sz-react-utils-lite'
import { connect } from 'react-redux'
import Request from '../../request'
import moment from 'moment'
import { showLoader, hideLoader } from '../../modules/actions'
import { DateInString, fixed2Digit } from '../../components/_utils/_utils'

class SingleBankDayBook extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      fromDate: moment().startOf('month'),
      toDate: moment(),
      userType: ''
    }
    this.table = React.createRef()
  }

  componentDidMount() {

  }


  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      const { bankId, bankObj } = this.props
      params = {
        from: this.state.fromDate,
        to: this.state.toDate,
        count: this.state.count ? this.state.count : 75,
        bankId: bankObj.uid,
        total: this.state.total
      }
      this.setState({
        bankId: bankObj.uid, bankObj
      })
      let { result, total, totalDebit, totalCredit } = await Request.AllBankTransactions(params)
      resolve({ data: result })
    })
  }

  onChooseDate(date, stringDate, type) {
    this.setState({ [type]: date }, () => {
      this.table.current.reload()
    })
  }

  render() {
    let { dispatch, visible, bankObj, onBankClose, arrData } = this.props
    let { fromDate, toDate } = this.state
    let columns = [
      {
        title: 'DATE',
        key: 'dateString',
        dataIndex: 'dateString',
        render: (item, record) => {
          return (
            <React.Fragment>
              {item}
              <div>
                {record.type == 'CONTRA_ENTRY' ? 'CNTR' : record.type} {' '}
                ({record.invoiceNo})
              </div>
              {record.username ? <div
              >By {record.username}</div> : null}
            </React.Fragment>
          )
        }
      },
      {
        title: 'CLIENT',
        key: 'clientVendorName',
        dataIndex: 'clientVendorName'
      },
      {
        title: 'PARTICULARS',
        key: 'paymentType',
        dataIndex: 'paymentType',
        render: (value, item) => {
          return (
            <React.Fragment>
              {item.type == 'CONTRA_ENTRY' ? item.infoMessage : item.paymentType}
            </React.Fragment>
          )
        }
      },
      {
        title: 'DEBIT',
        key: 'debit',
        dataIndex: 'debit',
        render: (value, item) => {
          return (
            <React.Fragment>
              {item.transactionType == 'Credit' ? fixed2Digit(item.amount) : null}
            </React.Fragment>
          )
        }
      },
      {
        title: 'CREDIT',
        key: 'credit',
        dataIndex: 'credit',
        render: (value, item) => {
          return (
            <React.Fragment>
              {item.transactionType == 'Debit' ? fixed2Digit(item.amount) : null}
            </React.Fragment>
          )
        }
      },
      {
        title: 'BAL',
        key: 'balance',
        dataIndex: 'balance',
        render: (value, item) => {
          return (
            <React.Fragment>
              {value ? fixed2Digit(value) : null}
            </React.Fragment>
          )
        }
      }
    ]
    return (
      <Drawer
        visible={visible}
        title={`${bankObj && bankObj.name} Day Book`}
        onClose={onBankClose}
        width={'90%'}
        closable={false}
      >

        <Card bordered={true}>
          <Row gutter={16}>
            <Col span={'8'}>
              <InputBox title={'FROM DATE'}>
                <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'fromDate')}
                            value={fromDate}
                            placeholder={'From'}/>
              </InputBox>
            </Col>
            <Col span={'8'}>
              <InputBox title={'TO DATE '}>
                <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'toDate')}
                            value={toDate}
                            placeholder={'To'}/>
              </InputBox>
            </Col>
          </Row>
          <TableComp columns={columns} apiRequest={this.apiRequest}
                     ref={this.table}
                     pagination={false}
          />
        </Card>
      </Drawer>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleBankDayBook)
