import React from 'react'
import { Button, Card, Col, DatePicker, Row } from 'antd'
import { InputBox } from '../../webComponent/appDes'
import moment from '../paymentAndReceipt'

class ChooseDateGstComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fromDate: props.fromDate,
      toDate: props.toDate
    }

  }

  onChooseFromDate = (date, dateString) => {
    this.setState({ fromDate: date })
  }
  onChooseToDate = (date, dateString) => {
    this.setState({ toDate: date })
  }

  search = () => {
    let { fromDate, toDate } = this.state
    this.props.filterData({ fromDate, toDate })
  }

  render() {
    let { fromDate, toDate } = this.state
    return (
      <div>
        <Row gutter={16}>
          <Col span={'8'}>
            <InputBox title={'From Date'} className={'rowFlex'}>
              <DatePicker onChange={this.onChooseFromDate} value={fromDate} placeholder={'Choose Date'}/>
            </InputBox>
          </Col>
          <Col span={'8'}>
            <InputBox title={'To Date'} className={'rowFlex'}>
              <DatePicker onChange={this.onChooseToDate} value={toDate} placeholder={'Choose Date'}/>
            </InputBox>
          </Col>
          <Col span={'8'} className={'alignRight'}>
            <InputBox title={''} className={'flexRow mt20'}>
              <Button onClick={() => {
                this.search()
              }}>
                Search
              </Button> &nbsp;
              <Button onClick={() => {

              }}>
                Print
              </Button>
            </InputBox>

          </Col>
        </Row>
      </div>
    )
  }
}

export default ChooseDateGstComponent
