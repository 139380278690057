import React from 'react'

import moment from 'moment'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Divider,
  Drawer,
  notification
} from 'antd'

import 'react-quill/dist/quill.snow.css'
import lodash from 'lodash'
import Request from '../../../request'
import AddGroup from '../../Setting/groups/Add'
import PageHeaderWrapper from '../../Setting/groups/All'
import { hideLoader, showLoader } from '../../../modules/actions'
import { AsyncStorage } from '../../../asyncStorage'
import AllStates from '../../../components/locationJson/state'
import AllCitites from '../../../components/locationJson/cities'
import { connect } from 'react-redux'

const { TextArea } = Input

const FormItem = Form.Item
const { Option } = Select


// inputSchema.fields


@Form.create()
class AddParty extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      groupArr: [],
      email: null,
      mobile: null,
      language: null,
      gstType: 'Unregistered Consumer',
      gstNumber: null,
      state: 'Select',
      address: null,
      createdOn: new Date(),
      createDate: moment(),
      currentBalance: null,
      arr: [],
      contactArr: [],
      query: '',
      typedName: '',
      choosenName: '',
      tempMobArr: [],
      isModalVisible: false,
      clientGroupId: null,
      addGroupModal: false,
      citiesArr: [],
      checkConnection: false,
      stateId: null,
      isDateTimePickerVisible: false,
      gstNumberCheck: false,
      cityChoosed: false,
      selectedItems: []
    }
  }

  componentDidMount() {
    this._groups()
    this._allState()
  }

  setValue = (e) => {
    let { name, value } = e.target
    this.setState({ [name]: value })
  }

  _allState = () => {
    let stateArr = lodash.clone(AllStates)
    this.setState({ arr: stateArr })

  }

  _groups = () => {
    let ggg = []
    Request.AllGroups()
      .then((testData) => {
        lodash.each(testData, (pp) => {
          ggg.push({ name: pp.name, uid: pp.uid })
        })
        this.setState({ groupArr: ggg, clientGroupId: ggg[0].uid, clientGroupName: ggg[0].name })
      })
      .catch((e) => {

      })
  }
  chooseGroup = (e) => {
    let obj = {
      clientGroupId: '',
      clientGroupName: ''
    }
    if (e) {
      obj.clientGroupId = e.uid ? e.uid : ''
      obj.clientGroupName = e.name ? e.name : ''
    }
    this.setState({ ...obj })
  }

  addGroupFxn = () => {
    this.setState({ addGroupVisible: true })
  }
  onGroupClose = () => {
    this.setState({ addGroupVisible: false })
    this._groups()
  }

  groupCallback = (data) => {
    if (data && data.uid) {
      let cloneGroupArr = lodash.clone(this.state.groupArr)
      cloneGroupArr.push({ name: data.name, uid: data.uid })
      cloneGroupArr = lodash.uniq(cloneGroupArr)
      this.setState({
        groupArr: cloneGroupArr,
        clientGroupId: data.uid,
        clientGroupName: data.name,
        addGroupVisible: false
      })
    }
  }

  onChooseDate = (date, dateString) => {
    this.setState({ createDate: date })
  }

  loadCities() {
    let { stateId, cityName } = this.state
    let findCities = lodash.filter(AllCitites, (item) => {
      return item.state_id == stateId
    })
    if (cityName) {
      findCities = lodash.filter(findCities, (item) => {
        return item.name.match(new RegExp(cityName, 'ig'))
      })
    }

    this.setState({ citiesArr: findCities, stateId })
  }

  async save() {
    let objj = {
      name: (this.state.name) ? this.state.name : (this.state.product) ? this.state.product : '',
      mobile: this.state.mobile,
      email: this.state.email,
      gstType: this.state.gstType,
      state: this.state.state,
      stateId: this.state.stateId,
      city: this.state.city,
      clientGroupId: this.state.clientGroupId,
      gstNumber: this.state.gstNumber,
      address: this.state.address,
      currentBalance: this.state.currentBalance,
      image: this.state.image,
      gstNumberCheck: this.state.gstNumberCheck,
      businessName: this.state.businessName,
      stateCode: this.state.stateCode,
      pinCode: this.state.pinCode,
      cityChoosed: this.state.cityChoosed,
      createDate: this.state.createDate ? new Date(this.state.createDate) : new Date()
    }
    if (!objj.name) {
      notification.warn({
        message: 'Enter Name'
      })
      return
    }
    if (objj.gstType == 'Registered') {
      if (!objj.gstNumber) {
        notification.warn({
          message: 'Enter GST Number'
        })
        return
      }
      if (objj.gstNumberCheck == false) {
        notification.warn({
          message: 'Please check GST Number first.'
        })
        return
      }

    }
    if (!objj.state || objj.state == 'Select') {
      notification.warn({
        message: 'Select State'
      })
      return
    }
    if (!objj.cityChoosed) {
      notification.warn({
        message: 'Select City'
      })
      return
    }
    if (!objj.pinCode) {
      notification.warn({
        message: 'Enter Pin Code'
      })
      return
    }
    if (!objj.address) {
      notification.warn({
        message: 'Enter Address'
      })
      return
    }

    AsyncStorage.getBusiness().then((value) => {
      if (value !== null) {
        if (value.uid) {
          objj.businessId = value.uid
          this.props.dispatch(showLoader())
          Request.AddClient(objj)
            .then((testData) => {
              if (testData.type) {
                notification.success({
                  message: 'Party Saved Successfully'
                })
                if (this.state.image && this.state.image.uri) {
                  AsyncStorage.getUserData().then((userResp) => {
                    if (userResp) {
                      let imageObj = {
                        businessId: value.uid,
                        linkId: testData.client.uid,
                        type: 'CLIENT',
                        date: new Date(),
                        userId: userResp.uid,
                        saleUid: testData.client.uid
                      }
                      Request.uploadDocument(imageObj, this.state.image).then((resp1) => {
                        console.log(resp1)
                      }).catch((err) => {
                        console.log(err)
                      })
                    }
                  })
                }


                this.props.dispatch(hideLoader())
                if (this.state.openType) {
                  this.setState({
                    name: null,
                    product: null,
                    email: null,
                    mobile: null,
                    language: null,
                    gstType: 'Unregistered',
                    gstNumber: null,
                    state: 'Select',
                    address: null,
                    stateCode: null,
                    pinCode: null,
                    createdOn: new Date(),
                    currentBalance: null

                  })

                  // this.closepop();

                } else {

                  this.props.clientCallback(testData.client)
                }

                return
              } else {
                notification.error({
                  message: testData.msg
                })
              }

            })
            .catch((e) => {
              notification.error({
                message: 'Try again'
              })
              this.props.dispatch(hideLoader())
              return
            })
        } else {
          notification.warn({
            message: 'Business Setting Not Set'
          })
          return
        }


      } else {
        notification.warn({
          message: 'Business Setting Not Set'
        })
        return
      }
    })
  }


  checkGstNumberFxn() {
    if (!this.state.gstNumber) {
      notification.warn({
        message: 'Enter GST Number.'
      })
      return
    }
    if (!this.state.gstNumberCheck) {
      this.props.dispatch(showLoader())
      Request.checkGstNumberNewApi(this.state.gstNumber).then((resp) => {
        if (resp && resp.type) {
          let { data } = resp
          this.setState({ ...data, gstNumberCheck: true, businessName: data.name, cityChoosed: false }, () => {
            this.loadCities()
          })
          this.props.dispatch(hideLoader())
          return
        } else {
          notification.error({
            message: resp.msg
          })
          this.props.dispatch(hideLoader())
        }
      })
    }
  }

  chooseState = (data) => {
    let obj = {
      state: '',
      stateId: '',
      stateCode: ''
    }
    if (data) {
      obj = {
        state: data.name,
        stateId: data.id,
        stateCode: data.stateCode
      }
    }
    this.setState({ ...obj }, () => {
      this.loadCities()
    })
  }
  chooseCity = (data) => {
    this.setState({ city: data, cityChoosed: true })
  }


  render() {
    let { visible } = this.props
    let { name, groupArr, mobile, email, addGroupVisible, createDate, arr, pinCode, address, openingBalance, gstNumber } = this.state
    return (
      <Drawer
        visible={visible}
        width={700}
        closable={false}
        onClose={this.props.onClientClose}>
        <Form>
          <Card title={'Add Party'} className={'newForm'}
                extra={<Button onClick={() => this.save()}>SAVE PARTY</Button>}>
            <Row>
              <Col>
                <label>Enter party</label>
                <Input name={'name'} onChange={this.setValue} value={name} placeholder={'Enter party name'}/>
              </Col>
              <Col>
                <label>Contact Number</label>
                <Input name={'mobile'} onChange={this.setValue} value={mobile} placeholder={'Enter mobile number'}/>
              </Col>
              <Col>
                <label>Email</label>
                <Input name={'email'} onChange={this.setValue} value={email} placeholder={'Enter email address'}/>
              </Col>
              <Col>
                <label>Party Group</label>
                <Row gutter={16}>
                  <Col span={20}>
                    <Select
                      showSearch={true}
                      allowClear={true}
                      filterOption={(input, options) => {
                        return options.props.children.match(new RegExp(input, 'ig'))
                      }}
                      value={this.state.clientGroupName}
                      onChange={this.chooseGroup}
                      placeholder="Choose Group">
                      {groupArr.map((item) => {
                        return (
                          <Option key={item.uid} value={item}>{item.name}</Option>
                        )
                      })}

                    </Select>
                  </Col>
                  <Col span={4} className={'textAlignR'}>
                    <Button onClick={this.addGroupFxn}>
                      <Icon type={'plus'}/>
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <label>Created Date</label>
                <div>
                  <DatePicker onChange={this.onChooseDate} value={createDate}/>
                </div>
              </Col>
              <Col>
                <label>Gst Type </label>
                <div>
                  <Select onChange={(e) => this.setState({ gstType: e })} value={this.state.gstType}>
                    <Option value={'Unregistered'}>Unregistered Consumer</Option>
                    <Option value={'Registered'}>Registered Consumer</Option>
                  </Select>
                </div>
              </Col>
              <Col>
                <label>Gst Type </label>
                <Row>
                  <Col span={20}>
                    <Input name={'gstNumber'} value={gstNumber}
                           disabled={this.state.gstNumberCheck}
                           onChange={(e) => this.setState({ gstNumber: e.target.value.toUpperCase() })}
                           placeholder={'GST Number'}/>
                  </Col>
                  <Col span={4} className={'textAlignR'}>
                    <Button onClick={() => this.checkGstNumberFxn()} disabled={this.state.gstNumberCheck}>
                      {this.state.gstNumberCheck ? 'Valid' : 'Check'}
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <label>Choose State</label>
                <Select
                  showSearch={true}
                  optionFilterProp="children"
                  filterOption={(input, options) => {
                    return options.props.children.match(new RegExp(input, 'ig'))
                  }}
                  allowClear={true}
                  value={this.state.state}
                  onChange={this.chooseState}
                  placeholder="Choose state">
                  {arr.map((item) => {
                    return (
                      <Option key={item.uid} value={item}>{item.name}</Option>
                    )
                  })}
                </Select>
              </Col>
              <Col>
                <label>Choose City</label>
                <Select
                  showSearch={true}
                  allowClear={true}
                  value={this.state.city}
                  onChange={this.chooseCity}
                  placeholder="Choose City">
                  {this.state.citiesArr.map((item) => {
                    return (
                      <Option key={item.uid} value={item.name}>{item.name}</Option>
                    )
                  })}
                </Select>
              </Col>
              <Col>
                <label>Pin Code</label>
                <Input name={'pinCode'} onChange={this.setValue} value={pinCode} type={'number'}
                       placeholder={'Enter Pincode'}/>
              </Col>
              <Col>
                <label>Address</label>
                <TextArea name={'address'} rows={2} onChange={this.setValue} value={address}
                          placeholder={'Enter Address'}/>
              </Col>
              <Col>
                <label>Opening Balance</label>
                <Input name={'openingBalance'} onChange={this.setValue} value={openingBalance} type={'number'}
                       placeholder={'Enter Opening Balance'}/>
              </Col>
            </Row>
            {addGroupVisible ?
              <AddGroup
                groupCallback={this.groupCallback}
                onGroupClose={this.onGroupClose}
                visible={addGroupVisible}
                {...this.props}
              />
              : null}
          </Card>

        </Form>
      </Drawer>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddParty)
