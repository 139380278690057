import { customAxios as axios, getBusinessId, getToken } from '../../../request'
import { addSubBrandUrl, singleSubBrandUrl, subBrandListUrl, updateSubBrandUrl } from './api'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import { notification } from 'antd'

export const addSubBrandFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addSubBrandUrl(), valData, await getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({
      message: data.message
    })
  } else {
    notification.warning({
      message: data.message
    })
  }
  return data
}

export const subBrandListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData, ...await getBusinessId() },
    ...await getToken()
  }

  let { data } = await axios.get(subBrandListUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const singleSubBrandFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  valData = { ...valData, ...await getBusinessId() }
  let { data } = await axios.post(singleSubBrandUrl(), valData, await getToken())
  dispatch(hidePageLoad())
  /*if (data.success) {
    notification.success({
      message: data.message
    })
  } else {
    notification.warning({
      message: data.message
    })
  }*/
  return data
}

export const updateSubBrandFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateSubBrandUrl(), valData, await getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({
      message: data.message
    })
  } else {
    notification.warning({
      message: data.message
    })
  }
  return data
}
