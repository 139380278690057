const warrantyBox = (data) => {
  return new Promise((resolve) => {
    let html = `<div class="row">
        ${data.warranty ?
      `<div class="col-md-12 marginTop10 marginBottom10 font12">
                <b>Warranty</b> : ${data.warranty ? data.warranty : ''}
            </div>` : ''}
    </div>`
    if (data.printType == 'Simple' || data.printType == 'saleWarranty' || data.printType == 'Hidden') {
      resolve(html)
    } else {
      resolve('')
    }
  })
}

export default warrantyBox

