import React, { Component } from 'react'
import { Route, Link, Redirect, Switch } from 'react-router-dom'
import memoizeOne from 'memoize-one'
import { Modal } from 'antd'

import menuData, { getUrlPath } from '../../routes'
import Exception from '../../components/Exception'
import AuthLayout from '../../layouts/AuthLayout'
import BasicLayout from '../../layouts/BasicLayout'
import Login from '../../containers/login'
import '../../index.css'
import _ from 'lodash'
import { pageTitle } from '../../settings'
import { GlobalHotKeys, configure } from 'react-hotkeys'
import { GetShortCut } from '../../components/AppShortCut/shortcuts'
import { connect } from 'react-redux'
import { Skeleton, Icon } from 'antd'
import AllBusiness from '../../containers/Business/all'
import { AsyncStorage } from '../../asyncStorage'

configure({
  ignoreEventsCondition: (event) => {

    return false
  }
})

const Exp = () => (<Exception
  type="404"
  desc={'You Seems lost !!'}
  linkElement={Link}
  redirect={'/dashboard'}
  backText={'Go To Homepage?'}
/>)
const Exp403 = () => (<Exception
  type="403"
  desc={'Sorry You Don\'t have access to this area !!'}
  linkElement={Link}
  redirect={'/dashboard'}
  backText={'Go To Homepage?'}
/>)

class BasicLayoutWrapper extends Component {

  checkBusiness(path) {
    AsyncStorage.getBusiness().then((resp) => {
      if (!resp) {
        if (path !== '/dashboard') {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })
  }


  render() {

    const { menuData, component, path } = this.props

    let user = (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null
    let business = (localStorage.getItem('business') != 'undefined') ? JSON.parse(localStorage.getItem('business')) : null

    if (!user) {
      return (<Redirect to="/login"/>)
    } else {
      if (!business) {
        if (path !== '/dashboard') {
          Modal.warning({
            title: 'You have to choose business to continue processing.',
            content: null,
            onOk: () => {
              this.props.callbackChooseBusiness()
            }
          })
          return (<Redirect to="/dashboard"/>)
        }
      }
    }

    let menuItem = _(menuData)
      .thru(function(coll) {
        return _.union(coll, _.map(coll, 'children'))
      })
      .flatten()
      .find({ 'path': path })

    if (menuItem.authority !== undefined && menuItem.authority.indexOf(user.userType) === -1) {
      console.log('this user should not be here ', path)
      return <Exp403/>
    }

    if (!menuItem.title) {
      menuItem.title = 'KAKA'
    }
    // console.log(menuItem)


    return (
      <BasicLayout
        location={window.location}
        title={pageTitle}
        pageTitle={`${menuItem.title} - GST WEB`}
        menuData={menuData}>
        {!!component ? <this.props.component/> : <Exp/>}
      </BasicLayout>)
  }
}

class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      token: localStorage.getItem('token'),
      user: (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null,
      visibleBusiness: false
    }
    const { dispatch } = props
    dispatch({
      type: 'HIDE_BUSINESS'
    })
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.clicked && this.props.visibleBusinessDrawer !== nextProps.visibleBusinessDrawer) {
      this.showBusiness()
    }
    // this.showBusiness()
  }

  showBusiness() {
    this.setState({ visibleBusiness: true })
  }


  onClose = () => {
    this.setState({ visibleBusiness: false })
  }

  render() {
    const { path, pageLoading } = this.props
    const { user, visibleBusiness } = this.state

    return (
      <div>
        {pageLoading &&
        <div className={'mainLoader'}>
          <Icon type="loading"/>
          Loading...
        </div>}
        <GlobalHotKeys
          {...GetShortCut(this.props.dispatch)}
          global
        />
        <Switch>
          <Route exact path="/form/basic-formsdf" render={(route) => {
            return (
              <div>
                {menuData.map((item, key) => {
                  // console.log(item.children)
                  if (item.children) {
                    return item.children.map((child, k) => {
                      console.log(child.path)
                      return (
                        <div key={item.children}>
                          {child.path}
                        </div>
                      )
                    })
                  }
                })}
              </div>
            )
          }}/>

          {menuData.map((item, key) => {

            if (!item.children) {
              return (<Route exact path={item.path} key={item.path} render={(route) => {
                return <BasicLayoutWrapper component={item.component} path={item.path} user={user}
                                           callbackChooseBusiness={() => this.showBusiness()}
                                           menuData={menuData}/>
              }}/>)
            }
          })}

          {menuData.map((item, key) => {
            if (item.children) {
              return item.children.map((child, k) => {
                return (<Route exact path={child.path} key={child.path} render={(route) => {
                  return <BasicLayoutWrapper component={child.component} path={child.path} user={user}
                                             callbackChooseBusiness={() => this.showBusiness()}
                                             menuData={menuData}/>
                }}/>)
              })
            }
          })}

          <Route exact path="/login" render={(route) => {
            return (
              <AuthLayout
                location={window.location}
                menuData={menuData}>
                <Login/>
              </AuthLayout>
            )
          }}/>

          <Route exact path="/" render={(route) => {
            return (
              <Redirect to="/dashboard"/>
            )
          }}/>

        </Switch>

        {visibleBusiness ? <AllBusiness visible={visibleBusiness} onClose={this.onClose}/> : ''}

      </div>)
  }
}


const mapStateToProps = ({ global, router }) => ({
  pageLoading: global.pageLoading,
  visibleBusinessDrawer: global.visibleBusinessDrawer,
  clicked: global.clicked
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

