import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Table, notification
} from 'antd'
import Request from '../../../request'
import { UnitCodeList } from '../../../components/_utils/_utils'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader } from '../../../modules/actions'
import { connect } from 'react-redux'
import { GlobalHotKeys, HotKeys } from 'react-hotkeys'
import AddGroup from './Add'
import { UnitHandle, UnitKey } from '../../../components/AppShortCut/units'


const FormItem = Form.Item
const { Option } = Select

@Form.create()
class All extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      addUnitVisible: false
    }
  }

  componentDidMount() {
    this.loadAllGroup()
  }

  async loadAllGroup() {
    let data = await Request.AllGroups()
    this.setState({ arr: data })
  }


  groupCallback = (data) => {
    this.setState({ addUnitVisible: false }, () => {
      this.loadAllGroup()
    })
  }
  onGroupClose = () => {
    this.setState({ addUnitVisible: false })
  }

  addGroupFxn = () => {
    let { dispatch } = this.props
    dispatch(hideLoader())
    this.setState({ addUnitVisible: true })
  }


  render() {
    let { arr, addUnitVisible } = this.state
    const columns = [
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name'
      },
      {
        title: 'Commission',
        key: 'commission',
        dataIndex: 'commission',
        render: (val) => {
          return `${val} %`
        }
      },
      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username'
      }
    ]

    return (
      <PageHeaderWrapper
        title={'Group Setting'}>
        <Card bordered={true} title={'Group Setting'}
              extra={<Button type="primary" onClick={this.addGroupFxn}>Add Group</Button>}>
          <Table columns={columns} bordered={true} size={'small'}
                 dataSource={arr}
                 pagination={false}/>
        </Card>
        {addUnitVisible ?
          <AddGroup
            groupCallback={this.groupCallback}
            onGroupClose={this.onGroupClose}
            visible={addUnitVisible}
            {...this.props}
          />
          : null}
      </PageHeaderWrapper>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(All)
