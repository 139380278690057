import React, { Component } from 'react'
import { Input, Drawer, Button, Modal, notification, Card, Form } from 'antd'
import { InputBox } from '../webComponent/appDes'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import lodash from 'lodash'
import { hideLoader, showLoader } from '../../modules/actions'
import Request from '../../request'

const FormItem = Form.Item

@Form.create()

class BusinessBankDetails extends React.Component {
  constructor() {
    super()
    this.state = {
      bankDetails: [],
      bankName: '',
      accountNo: '',
      ifsc: '',
      bankAddress: ''
    }
  }


  componentDidMount() {
    let { bankDetails } = this.props
    this.setState({ bankDetails })
  }


  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { bankName, accountNo, ifsc, bankAddress } = valData
        let { bankDetails } = this.state
        if (!bankName) {
          notification.warn({
            message: 'Please Enter Bank Name.'
          })
          return
        }
        if (!accountNo) {
          notification.warn({
            message: 'Please Enter Account No.'
          })
          return
        }
        if (!ifsc) {
          notification.warn({
            message: 'Please Enter IFSC Code.'
          })
          return
        }
        if (!bankAddress) {
          notification.warn({
            message: 'Please Enter Address.'
          })
          return
        }

        let findBank = lodash.find(bankDetails, (item) => {
          return item.accountNo == accountNo
        })
        if (findBank && findBank.accountNo) {
          notification.error({
            message: 'Account no already exists.'
          })
        } else {
          this.props.callbackBank({ bankName, accountNo, ifsc, bankAddress })
        }

      }
    })
  }


  render() {
    const { submitting } = this.props
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props

    const { editorState } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 }
      }
    }


    let inputTypes = {
      fields: [
        {
          label: 'Bank Name',
          key: 'bankName',
          required: true,
          placeholder: 'Bank Name'
        },
        {
          label: 'Account No',
          key: 'accountNo',
          required: true,
          placeholder: 'Account No'
        },
        {
          label: 'IFSC Code',
          key: 'ifsc',
          required: true,
          placeholder: 'IFSC Code'
        },
        {
          label: 'Bank Address',
          key: 'bankAddress',
          required: true,
          type: 'textArea',
          placeholder: 'Bank Address'
        }
      ]
    }

    let { visible } = this.props

    return (
      <Drawer
        title="Add Business Bank"
        width={800}
        closable={false}
        onClose={this.props.onBankClose}
        visible={visible}>
        <Form hideRequiredMark style={{ marginTop: 8 }}>
          <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator}/>

          <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
            <Button type="primary" htmlType="submit" loading={this.props.loading} onClick={this.handleSubmit}>
              SAVE
            </Button>
          </Form.Item>

        </Form>
      </Drawer>
    )
  }

}

export default BusinessBankDetails
