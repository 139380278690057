import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col
} from 'antd'
import styles from './styles.less'
import { getUrlPushWrapper } from '../../../routes'
import { connect } from 'react-redux'
import AllBrands from '../brands/All'

class Settings extends Component {
  constructor(props) {
    super(props)
  }

  openSettingPage(route) {
    const { dispatch } = this.props
    dispatch(getUrlPushWrapper(`setting.${route}`))
  }

  render() {


    return (
      <div className={'asdf'}>
        <Row gutter={16}>
          <Col span={6}>
            <Card className={styles.alignCenter}>
              <a onClick={() =>
                this.openSettingPage('AllUnits')}>
                UNITS SETTINGS
              </a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={styles.alignCenter}>
              <a onClick={() => this.openSettingPage('AllGroup')}>
                GROUPS SETTINGS
              </a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={styles.alignCenter}>
              <a onClick={() => this.openSettingPage('AllCategory')}>
                CATEGORY SETTINGS
              </a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={styles.alignCenter}>
              <a onClick={() => this.openSettingPage('AllWarranty')}>
                WARRANTY SETTINGS
              </a>
            </Card>
          </Col>
        </Row>
        <br/>
        <Row gutter={16}>
          <Col span={6}>
            <Card className={styles.alignCenter}>
              <a
                onClick={() => this.openSettingPage('AllSaleCategory')}>
                SALE CATEGORY SETTINGS
              </a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={styles.alignCenter}>
              <a
                onClick={() => this.openSettingPage('AllTransport')}>
                TRANSPORT SETTINGS
              </a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={styles.alignCenter}>
              <a onClick={() => this.openSettingPage('AllVehicle')}>
                VEHICLE SETTINGS
              </a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={styles.alignCenter}>
              <a onClick={() => this.openSettingPage('AllBrands')}>
                BRANDS SETTINGS
              </a>
            </Card>
          </Col>


        </Row>
        <br/>
        <Row gutter={16}>
          <Col span={6}>
            <Card className={styles.alignCenter}>
              <a onClick={() => this.openSettingPage('allSubBrands')}>
                SUB BRANDS SETTINGS
              </a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={styles.alignCenter}>
              <a onClick={() => this.openSettingPage('AllPrefix')}>
                PREFIX SETTINGS
              </a>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }

}

const mapStateToProps = ({ global, router }) => ({})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings)


