import React from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Divider,
  Spin, notification
} from 'antd'
import Request from '../../request'
import _ from 'lodash'
import debounce from 'lodash/debounce'
import AddClient from '../Party/add/index'
import AddGroup from '../Setting/groups/All'
import { InputBox } from './appDes'
import Styles from './styles.less'

const FormItem = Form.Item
const { Option } = Select

@Form.create()
class ChooseClientComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      arrData: [],
      clientName: '',
      clientGroupId: '',
      clientState: '',
      stateOfSupply: '',
      city: '',
      clientBalance: 0,
      clientId: '',
      subClient: {},
      clientGstNo: '',
      clientAddress: '',
      stateCode: '',
      pinCode: '',
      visibleAdd: false
    }
    this.fetchClient = debounce(this.fetchClient, 500)

  }


  fetchClient = (data) => {
    let arrData = []
    Request.AllClient({ name: data })
      .then((testData) => {
        _.each(testData, (item) => {
          arrData.push(item)
        })
        this.setState({ arrData })
        return
      })
      .catch((e) => {
        return
      })
  }


  handleChange = (uid) => {
    let { arrData } = this.state
    let item = _.find(arrData, (value) => {
      return value.uid == uid
    })
    let obj = {
      clientName: '',
      clientGroupId: '',
      clientState: '',
      stateOfSupply: '',
      city: '',
      clientBalance: 0,
      clientId: '',
      subClient: {},
      clientGstNo: '',
      clientAddress: '',
      stateCode: '',
      pinCode: ''
    }
    if (item) {
      obj = {
        clientName: item.name,
        clientGroupId: item.clientGroupId,
        clientState: item.state,
        stateOfSupply: item.state,
        city: item.city,
        clientBalance: item.currentBalance ? parseInt(item.currentBalance) : 0,
        clientId: item.uid,
        subClient: {},
        clientGstNo: item.gstNumber,
        clientAddress: item.address,
        stateCode: item.stateCode,
        pinCode: item.pinCode
      }
    }
    this.setState({ ...obj }, () => {
      this.callbackFxn()
    })
  }

  componentDidMount() {
    let { clientName, clientId, stateOfSupply } = this.props
    this.setState({ clientName, clientId, stateOfSupply })
  }

  addClient() {
    this.setState({ visibleAdd: true })
  }

  onClientClose = () => {
    this.setState({ visibleAdd: false })
  }

  clientCallback = (item) => {
    if (item && item.uid) {
      let obj = {
        clientName: item.name,
        clientGroupId: item.clientGroupId,
        clientState: item.state,
        stateOfSupply: item.state,
        city: item.city,
        clientBalance: item.currentBalance ? parseInt(item.currentBalance) : 0,
        clientId: item.uid,
        subClient: {},
        clientGstNo: item.gstNumber,
        clientAddress: item.address,
        stateCode: item.stateCode,
        pinCode: item.pinCode
      }
      this.setState({ ...obj, visibleAdd: false }, () => {
        this.callbackFxn()
      })
    }
  }

  callbackFxn() {
    let cloneState = _.clone(this.state)
    delete cloneState['arrData']
    this.props._callbackClient(cloneState)
  }

  render() {
    let { items, arrData, clientName, clientId, visibleAdd, stateOfSupply, clientBalance } = this.state


    return (
      <Row gutter={16}>
        <Col span={8}>
          <div>
            <label> Choose Client </label>
            {clientId ?
              <span className={Styles.balanceField}>
             Balance : Rs. {clientBalance ? clientBalance : 0}/-
            </span> : null}
          </div>

          <Select
            showSearch={true}
            onSearch={this.fetchClient}
            filterOption={false}
            autoClearSearchValue={true}
            allowClear={true}
            value={clientName}
            showArrow={true}
            onChange={this.handleChange}
            placeholder="Choose Client"
            dropdownRender={menu => {
              return (
                <div key={arrData}>
                  {menu}
                  <Divider style={{ margin: '4px 0' }}/>
                  <div
                    style={{ padding: '4px 8px', cursor: 'pointer' }}
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => this.addClient()}>
                    <Icon type="plus"/> Add New Client
                  </div>
                </div>
              )
            }}>
            {arrData.map((item, index) => {
              return (
                <Option key={item} value={item.uid}>{item.name}</Option>
              )
            })}

          </Select>
        </Col>
        <Col span={8}>
          <InputBox title={'State of Supply'} colSpan={8}>
            <div className={'ant-input'}>
              {this.state.stateOfSupply ? this.state.stateOfSupply : 'State of Supply'}
            </div>
          </InputBox>
        </Col>
        {visibleAdd ? <AddClient
          visible={visibleAdd}
          {...this.props}
          clientCallback={this.clientCallback}
          onClientClose={this.onClientClose}/> : ''}

      </Row>
    )
  }
}

export default ChooseClientComponent
