import { combineReducers } from 'redux'
import counter from './counter'
import theme from './theme'
import global from './global'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory as createHistory } from 'history'

export const history = createHistory()

export default combineReducers({
  counter,
  theme,
  global,
  router: connectRouter(history)

})
