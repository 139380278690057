import { customAxios as axios, getToken, getBusinessId } from '../../../request'
import { singleProductUrl, updateProductUrl } from '../apis'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import { notification } from 'antd'

export const getSingleProductFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(singleProductUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    /* notification.success({
       message: data.message
     })*/
  } else {
    notification.warning({
      message: data.message
    })
  }
  return data
}

export const updateProductFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  valData = { ...valData, ...await getBusinessId() }
  let { data } = await axios.post(updateProductUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.type) {
    notification.success({
      message: data.msg
    })
  } else {
    notification.warning({
      message: data.msg
    })
  }
  return data
}
