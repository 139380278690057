export const AsyncStorage = {
  setBusiness: (data) => {
    return new Promise((resolve, reject) => {
      localStorage.setItem('business', JSON.stringify(data))
      resolve('done')
    })
  },
  getBusiness: () => {
    return new Promise((resolve, reject) => {
      let business = (localStorage.getItem('business') != 'undefined') ? JSON.parse(localStorage.getItem('business')) : null
      resolve(business)
    })
  },
  getUserData: () => {
    return new Promise((resolve, reject) => {
      let user = (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null
      resolve(user)
    })
  },
  getGstAvailable: () => {
    return new Promise((resolve, reject) => {
      resolve(true)
    })
  }
}
