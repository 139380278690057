import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Table, notification
} from 'antd'
import Request from '../../../request'
import { UnitCodeList } from '../../../components/_utils/_utils'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader } from '../../../modules/actions'
import { connect } from 'react-redux'
import { GlobalHotKeys, HotKeys } from 'react-hotkeys'
import AddUnit from './AddUnit'
import { UnitHandle, UnitKey } from '../../../components/AppShortCut/units'


const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AllUnits extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      addUnitVisible: false
    }
  }

  componentDidMount() {
    this.loadAllUnit()
  }

  async loadAllUnit() {
    let data = await Request.AllUnits()
    this.setState({ arr: data })
  }


  unitCallback = (data) => {
    this.setState({ addUnitVisible: false }, () => {
      this.loadAllUnit()
    })
  }
  onUnitClose = () => {
    this.setState({ addUnitVisible: false })
  }

  addUnit = () => {
    let { dispatch } = this.props
    dispatch(hideLoader())
    this.setState({ addUnitVisible: true })
  }


  render() {
    let { arr, addUnitVisible } = this.state
    const columns = [
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name'
      },
      {
        title: 'Code',
        key: 'code',
        dataIndex: 'code'
      },
      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username'
      }
    ]

    return (
      <PageHeaderWrapper
        title={'Units'}>
        <HotKeys
          keyMap={UnitKey}
          handlers={UnitHandle({ addUnit: this.addUnit })}>
          <Card bordered={true} title={'All Units'}
                extra={<Button type="primary" onClick={this.addUnit}>Add
                  Unit</Button>}>
            <Table columns={columns} bordered={true} size={'small'}
                   dataSource={arr}
                   pagination={false}/>
          </Card>
          {addUnitVisible ?
            <AddUnit
              unitCallback={this.unitCallback}
              onUnitClose={this.onUnitClose}
              visible={addUnitVisible}
              {...this.props}
            />
            : null}
        </HotKeys>
      </PageHeaderWrapper>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllUnits)
