import React, { Component } from 'react'
import {
  Form,
  Select,
  Button,
  Card,
  notification,
  Drawer, Input, Icon, Row, Col
} from 'antd'
import Request, { getBusinessId } from '../../../request'
import _ from 'lodash'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { connect } from 'react-redux'
import { addSubBrandFxn } from './action'
import { InputBox } from '../../webComponent/appDes'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

const { Option } = Select

@Form.create()
class AddBrand extends Component {
  constructor(props) {
    super(props)
    this.state = {
      brandArr: [],
      image: {}
    }
    this.input = React.createRef()
  }

  componentDidMount() {
    this.loadAllBrands()
  }

  async loadAllBrands() {
    let { brandUid = '' } = this.props
    let data = await Request.loadBrands()
    this.setState({ brandArr: data.brandArr })
    if (brandUid) {
      this.props.form.setFieldsValue({
        brandUid: brandUid
      })
    }
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    let { brandArr, image } = this.state
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (!valData.brandUid) {
          notification.warn({
            message: 'Choose brand'
          })
          return
        }
        if (!valData.name) {
          notification.warn({
            message: 'Enter sub brand'
          })
          return
        }
        let findBrandUid = _.find(brandArr, (item) => {
          return item.uid && item.uid == valData.brandUid
        })
        if (findBrandUid) {
          valData.brandId = findBrandUid._id
        }
        let obj = {
          ...await getBusinessId(),
          ...valData
        }
        let form = new FormData()
        if (image && image.name) {
          form.append('image', image)
        }
        form.append('data', JSON.stringify(obj))
        let x = await dispatch(addSubBrandFxn(form))
        if (x.success) {
          this.props.form.setFieldsValue({})
          this.props.brandCallback(x.data)
        }
      }
    })
  }


  render() {
    const {
      form: { getFieldDecorator }
    } = this.props

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
        md: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 }
      }
    }


    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    let { brandArr } = this.state
    let { visible } = this.props


    let inputTypes = {
      fields: [
        {
          label: 'Brand',
          key: 'brandUid',
          required: true,
          type: 'select',
          allowClear: true,
          showSearch: true,
          valueAccessor: x => x.name,
          keyAccessor: x => x['uid'],
          options: brandArr,
          onChange: v => {
            this.props.form.setFieldsValue({
              brandUid: v
            })
          }
        },
        {
          label: 'Sub Brand',
          key: 'name',
          required: true,
          placeholder: 'Name'
        },
        {
          label: 'Image',
          key: 'image',
          required: false,
          type: 'file',
          placeholder: 'Name',
          onChange: (item) => {
            if (item.target.files && item.target.files.length) {
              this.setState({
                image: item.target.files[0]
              })
            }
          }
        }
      ]
    }

    return (
      <Drawer
        title="Add Sub Brand"
        width={800}
        closable={false}
        onClose={this.props.onBrandClose}
        visible={visible}>
        <Card bordered={true}>
          <Form hideRequiredMark style={{ marginTop: 8 }}>
            <Row gutter={24}>
              {inputTypes.fields.map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    <Col span={24}>
                      <GetEachFormFields
                        item={item}
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                      />
                    </Col>
                  </React.Fragment>
                )
              })}
            </Row>


            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit" loading={this.props.loading} onClick={this.handleSubmit}>
                SAVE
              </Button>
            </Form.Item>

          </Form>
        </Card>
      </Drawer>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBrand)
