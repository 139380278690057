import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Table, notification,
  Modal
} from 'antd'
import Request from '../../../request'
import { UnitCodeList } from '../../../components/_utils/_utils'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader } from '../../../modules/actions'
import { connect } from 'react-redux'
import { GlobalHotKeys, HotKeys } from 'react-hotkeys'
import AddPrefix from './Add'
import Styles from './styles.less'


const FormItem = Form.Item
const { Option } = Select


const prefixList = [
  { name: 'Sale Invoice', invoiceType: 'SALE' },
  { name: 'Credit Note', invoiceType: 'CREDIT_NOTE' },
  { name: 'Purchase Order', invoiceType: 'PURCHASE' },
  { name: 'Debit Note', invoiceType: 'DEBIT_NOTE' },
  { name: 'Payment In', invoiceType: 'PAYMENT_IN' },
  { name: 'Payment Out', invoiceType: 'PAYMENT_OUT' },
  { name: 'Quotation', invoiceType: 'ESTIMATE' },
  { name: 'Contra Entry', invoiceType: 'CONTRA_ENTRY' },
  { name: 'Journal Entry', invoiceType: 'JOURNAL_ENTRY' }
]


@Form.create()
class All extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      addPrefixModal: false
    }
  }

  componentDidMount() {
    this.AllPrefix()
  }

  AllPrefix = () => {
    Request.AllPrefixList()
      .then((testData) => {
        this.setState({ arr: testData })
        return
      })
      .catch((e) => {

        return
      })

  }
  prefixCallBack = (data) => {
    this.setState({ addPrefixModal: false }, () => {
      this.AllPrefix()
    })
  }
  onPrefixClose = () => {
    this.setState({ addPrefixModal: false })
  }
  addPrefixFxn = () => {
    this.setState({ addPrefixModal: true })
  }


  render() {
    let { arr, addPrefixModal } = this.state
    const columns = [
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name'
      },
      {
        title: 'Commission',
        key: 'commission',
        dataIndex: 'commission',
        render: (val) => {
          return `${val} %`
        }
      },
      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username'
      }
    ]

    return (
      <PageHeaderWrapper
        title={'Prefix Setting'}>
        <Card bordered={true}>

          <div className={Styles.listView}>
            {prefixList.map((item, index) => {
              return (
                <div className={Styles.inputBox} onClick={() =>
                  this.setState({ selectedPrefix: item }, () => {
                    this.addPrefixFxn()
                  })}>
                  <div>
                    <div className={Styles.boxLabel}>
                      {item.name}
                    </div>
                    <div className={Styles.inputBoxValue}>
                      <span>
                        {arr[item.invoiceType] && arr[item.invoiceType].currentPrefix ? arr[item.invoiceType].currentPrefix : 'None'}
                      </span>
                      <Icon type={'plus'}></Icon>
                    </div>


                  </div>
                </div>
              )
            })}
          </div>
        </Card>
        {addPrefixModal ?
          <AddPrefix
            visible={addPrefixModal}
            selectedPrefix={this.state.selectedPrefix}
            prefixCallBack={this.prefixCallBack}
            onPrefixClose={this.onPrefixClose}/>
          : null}
      </PageHeaderWrapper>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(All)
