import PrintStyle from '../style'
import PrintHeader from '../header'
import _ from 'lodash'
import { AsyncStorage } from '../../../asyncStorage'

const PrintHtml = async (obj) => {
  let businessDetails = await AsyncStorage.getBusiness()

  return new Promise(async (resolve) => {
    let itemTable = ''

    _.each(obj.expenses, (item, index) => {
      let aa = `class="${index % 2 == 0 ? 'evenCls' : ''}"`
      itemTable += `<tr style="height: 5px;" ${aa}>
                        <td class='capCase '>${item.name}</td>
                        <td>${item.balance}</td>
                        <td>${item.totalExpense ? item.totalExpense : ''}</td>
                    </tr>`
    })

    const getFieldRow = (arr) => {
      let rowA = ''
      _.each(arr, (item) => {
        rowA += `<tr> 
                    <td>${item.name}</td>
                    <td>${item.amount}</td>
                    <td>${item.total ? item.total : ''}</td>
                 </tr>`
      })
      return rowA
    }


    const html = `
    <!doctype html>
   <html>
   <head>
   <meta charset="utf-8">
   <title>Trading Sheet</title>
    ${PrintStyle}
</head>
<body class="container">
    <page size="A4">
        ${await PrintHeader(obj.title, `( For the period ending ${obj.currentDate})`)}
       
        <div class="row border-bottom font16 center padding20 letter-sp">
        <div class="col-md-6  border-right">
            DEBIT (Rs.)
        </div>
        <div class="col-md-6">
            CREDIT (Rs.)
        </div>
    </div>
    <div class="row ">
        <div class="col-md-6 border-right-dashed">
            <div class="padding10">
                <i class="b font14">
                    Capital Account :
                </i>
                <table class="sheetTable font10 marginTop10">
                        ${getFieldRow(obj.capitalAccount)}
                </table>
            </div>
            <div class="padding10">
                <i class="b font14">
                    Current Liabilities :
                </i>
                <table class="sheetTable font10 marginTop10">
                ${getFieldRow(obj.currentLiabilities)}
                </table>
            </div>
            <div class="padding10">
                <i class="b font14">
                    Loans (Liability) :
                </i>
                <table class="sheetTable font10 marginTop10">
                ${getFieldRow(obj.loansLiability)}
                    
                </table>
            </div>
            <div class="padding10">
                <i class="b font14">
                    Suspense Account :
                </i>
                <table class="sheetTable font10 marginTop10">
                ${getFieldRow(obj.suspenseAccount)}
                </table>
            </div>
            ${obj.totalProfit ?
      `<div class="padding10">
                     <table class="sheetTable font10">
                         <tr class="b">
                                <td>Profit to be adjusted</td>
                                <td></td>
                                <td>${obj.totalProfit}</td>
                            </tr>
                            </table>
                    </div>`
      : ''}
        </div>
        <div class="col-md-6">
            <div class="padding10">
                <i class="b font14">
                    Fixed Assets :
                </i>
                <table class="sheetTable font10 marginTop10">
                    ${getFieldRow(obj.fixedAssets)}
                </table>
            </div>
            <div class="padding10">
                <i class="b font14">
                    Current Assets :
                </i>
                <table class="sheetTable font10 marginTop10">
                    ${getFieldRow(obj.currentAssets)}        
                </table>
            </div>
             ${obj.totalLoss ?
      `<div class="padding10">
                     <table class="sheetTable font10">
                         <tr class="b">
                                <td>Loss to be adjusted</td>
                                <td></td>
                                <td>${obj.totalLoss}</td>
                            </tr>
                            </table>
                    </div>`
      : ''}
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 border-right-dashed">
            <table class="sheetTable font10  b">
                <tr>
                    <td></td>
                    <td>Total</td>
                    <td><span class="border-top-bottom">${obj.totalDebit}</span></td>
                </tr>
            </table>
        </div>
        <div class="col-md-6 border-right-dashed">
            <table class="sheetTable font10  b">
                <tr>
                    <td></td>
                    <td>Total</td>
                    <td><span class="border-top-bottom">${obj.totalCredit}</span></td>
                </tr>
            </table>
        </div>
    </div>
        </page>
    </body>
    </html>`
    resolve(html)
  })

}

export default PrintHtml
