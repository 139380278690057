import React, { Component } from 'react'
import moment from 'moment'
import Request from '../../../../request'
import { showLoader, hideLoader } from '../../../../modules/actions'
import PageHeaderWrapper from '../../../../components/PageHeaderWrapper'
import { Card, Row, Col, Menu, DatePicker, Dropdown, Button, Table, Tag } from 'antd'
import { connect } from 'react-redux'
import Styles from '../styles.less'
import ChooseDateComponent from '../chooseDateComponent'
import { fixed2Digit } from '../../../../components/_utils/_utils'

class Gstr2Report extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      userType: '',
      title: '',
      fromDate: moment().startOf('month'),
      toDate: moment().endOf('month')
    }

  }

  loadBalanceSheet = () => {
    let { dispatch } = this.props
    let { fromDate, toDate } = this.state
    dispatch(showLoader())
    Request.gstr2ReportData({ fromDate, toDate })
      .then((testData) => {
        this.setState({ arr: testData })
        dispatch(hideLoader())
        return
      })
      .catch((e) => {
        dispatch(hideLoader())
        return
      })

  }

  onChooseDate = (date, dateString) => {
    this.setState({ date: date }, () => {
      this.loadBalanceSheet()
    })
  }

  componentDidMount() {
    this.loadBalanceSheet()
  }

  createPdf = async () => {

  }

  filterData = (obj) => {
    this.setState({
      ...obj
    }, () => {
      this.loadBalanceSheet()
    })
  }

  _onPressR(item) {
    if (item.sectionName == 'B2B Invoices - 3 ,4A') {

    }
    if (item.sectionName == 'B2BUR Invoices - 4B') {

    }
    if (item.sectionName == 'Credit/Debit Notes - 6C') {

    }
    if (item.sectionName == 'Credit/Debit Notes UnRegister - 6C') {

    }
    if (item.sectionName == 'HSN Summary of Inward Supplies - 13') {

    }
  };

  render() {
    let { arr, fromDate, toDate } = this.state
    let columns = [
      {
        title: 'sectionName',
        key: 'sectionName',
        dataIndex: 'sectionName'
      },
      {
        title: 'No of Records',
        key: 'noOfRecords',
        dataIndex: 'noOfRecords'
      },
      {
        title: 'Total Invoice Amount',
        key: 'invoiceAmount',
        dataIndex: 'invoiceAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      },
      {
        title: 'Total Taxable Amount',
        key: 'taxableAmount',
        dataIndex: 'taxableAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      },
      {
        title: 'Total Cgst',
        key: 'cgstAmount',
        dataIndex: 'cgstAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      },
      {
        title: 'Total Sgst',
        key: 'sgstAmount',
        dataIndex: 'sgstAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      },
      {
        title: 'Total Igst',
        key: 'igstAmount',
        dataIndex: 'igstAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      },
      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        render: (item) => {
          return (
            <React.Fragment>
              <Button icon={'eye'} shape={'circle'} size={'small'}></Button>
            </React.Fragment>
          )
        }
      }
    ]

    return (
      <PageHeaderWrapper title={'GSTR 2 Report'}>
        <Card bordered={true}>
          <ChooseDateComponent fromDate={fromDate} toDate={toDate} filterData={this.filterData}/>
          <Table columns={columns} dataSource={arr} size={'small'} bordered={true}
                 pagination={false}
          />
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gstr2Report)
