import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Table,
  notification,
  Drawer
} from 'antd'
import Request from '../../../request'

import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader } from '../../../modules/actions'
import { connect } from 'react-redux'
import { TaxList, UnitCodeList } from '../../../components/_utils/_utils'
import lodash from 'lodash'

const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AddVehicle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      taxArr: []
    }
    this.input = React.createRef()
  }

  componentDidMount() {
    this.loadAllTransport()
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {

        Request.checkBusinessSelected(async (resp) => {
          let obj = {
            businessId: resp.uid,
            type: valData.type,
            vehicleNo: valData.vehicleNo,
            transportId: this.state.transportId,
            transportName: valData.transportName
          }
          console.log(obj)
          if (!obj.type) {
            notification.error({
              message: 'Select Transport Type'
            })
            return
          }
          if (obj.type == 'Transport') {
            if (!obj.transportName || !obj.transportId) {
              notification.error({
                message: 'Select Transport'
              })
              return
            }
          }
          if (!obj.vehicleNo) {
            notification.error({
              message: 'Enter Vehicle No'
            })
            return
          }
          if (!obj.businessId) {
            notification.error({
              message: 'Business Setting Not Set'
            })
            return
          }

          dispatch(showLoader())
          let x = await Request.AddVehicle(valData)
          dispatch(hideLoader())
          if (x.type) {
            notification.success({
              message: x.msg
            })
            this.props.form.setFieldsValue({})
            this.props.vehicleCallback(x.vehicle)
          } else {
            notification.error({
              message: 'Error Saving',
              description: x.msg
            })
          }

        })


      }
    })
  }


  async loadAllTransport() {
    let obj = {
      type: '',
      transportName: '',
      transportId: '',
      arr: [{ label: 'Choose Transport', value: '' }]
    }
    let { params } = this.props
    if (params) {
      if (params.transportType) {
        obj.type = params.transportType
      }
      if (params.transportId) {
        obj.transportName = params.transportName
        obj.transportId = params.transportId
        setTimeout(() => {
          this.props.form.setFieldsValue({
            ...obj
          })
        }, 500)
      }
    }

    Request.AllTransportList()
      .then((testData) => {
        lodash.each(testData, (item) => {
          obj.arr.push({ label: item.transport, value: item.uid })
        })
        if (!obj.transportId) {
          obj.transportId = obj.arr[0].value
        }
        this.setState({ ...obj })
        return
      })
      .catch((e) => {
        return
      })
  }


  render() {
    const { taxArr } = this.state
    const { submitting } = this.props
    const {
      form: { getFieldDecorator }
    } = this.props

    const { editorState, arr } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 }
      }
    }

    let { visible } = this.props


    let inputTypes = {
      fields: [
        {
          label: 'Type',
          key: 'type',
          type: 'select',
          showSearch: true,
          allowClear: true,
          options: ['Transport', 'Personal'],
          onChange: v => {
            this.props.form.setFieldsValue({
              type: v
            })
            this.setState({ type: v })
          }
        },
        {
          label: 'Transport',
          key: 'transportName',
          hidden: !(this.state.type == 'Transport'),
          type: 'select',
          showSearch: true,
          allowClear: true,
          valueAccessor: x => x.label,
          keyAccessor: x => x,
          options: arr,
          onChange: v => {
            let obj = {
              transportId: '',
              transportName: ''
            }
            if (v && v.value) {
              obj.transportId = v.value
              obj.transportName = v.label
            }
            this.props.form.setFieldsValue({
              ...obj
            }, () => {
              this.setState({ ...obj })
            })
          }
        },
        {
          label: 'Vehicle',
          key: 'vehicleNo',
          placeholder: 'Vehicle No',
          onChange: (v) => {
            this.props.form.setFieldsValue({
              vehicleNo: v.target.value.toUpperCase()
            })
          }
        }

      ]
    }

    return (
      <Drawer
        title="Add Vehicle"
        width={800}
        closable={false}
        onClose={this.props.onVehicleClose}
        visible={visible}>
        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>
            <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
                              getFieldDecorator={getFieldDecorator}/>

            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit" loading={this.props.loading}>
                SAVE
              </Button>
            </Form.Item>

          </Form>
        </Card>
      </Drawer>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVehicle)
