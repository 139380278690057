import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import {
  Card,
  Tooltip,
  Input, Button, Icon, Col, Row, Select, DatePicker
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import { getUrlPushWrapper, getPushPathWrapper } from '../../../routes'
import { InputBox } from '../../webComponent/appDes'

const { Option } = Select

class AllUsers extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fromDate: moment().startOf('year'),
      toDate: moment(),
      search: '',
      subClient: '',
      saleCatArr: []
    }
    this.table = React.createRef()
  }

  componentDidMount() {
    this.loadSaleCat()
  }

  reloadTab() {
    this.table.current.reload()
  }

  loadSaleCat() {
    let rrr = []
    Request.AllSaleCat()
      .then((testData) => {
        _.each(testData, (tt) => {
          if (tt.saleCategory) {
            rrr.push({ name: tt.saleCategory, uid: tt.uid })
          }
        })
        this.setState({ saleCatArr: rrr })
      })
      .catch((e) => {
        console.log(e)

      })
  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params = {
        from: this.state.fromDate,
        to: this.state.to,
        count: 50,
        search: this.state.search,
        subClient: this.state.subClient
      }
      let { result, total } = await Request.AllSale(params)
      resolve({ data: result })
    })
  }

  onChooseDate(date, stringDate, type) {
    this.setState({ [type]: date }, () => {
      this.reloadTab()
    })
  }

  render() {
    let { dispatch } = this.props
    let { saleCatArr, toDate, fromDate } = this.state
    const columns = [
      {
        title: 'Invoice No',
        key: 'invoiceNo',
        dataIndex: 'invoiceNo'
      },
      {
        title: 'Client',
        key: 'clientName',
        dataIndex: 'clientName'
      },
      {
        title: 'Client Group',
        key: 'groupName',
        dataIndex: 'groupName'
      },
      {
        title: 'State',
        key: 'stateOfSupply',
        dataIndex: 'stateOfSupply'
      },
      {
        title: 'Info',
        key: 'uid',
        dataIndex: 'uid',
        render: (val, item) => {
          return (
            <div>
              <ul>
                <li>Total Qty : {item.totalQuantity}</li>
                <li>Total Amt : {item.totalAmount ? (item.totalAmount).toFixed(2) : ''}</li>
                <li>Received Amt : {item.receivedAmount ? (item.receivedAmount).toFixed(2) : ''}</li>
                <li>Balance : {(item.balance).toFixed(2)}</li>
                <li>Sale Date : {moment(item.saleDate).format('DD/MMM/YYYY')}</li>
              </ul>
            </div>
          )
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (val, record) => {
          return (
            <div className={'actionGroup'}>
              <Tooltip title="Edit">
                <Button
                  shape="circle"
                  icon="edit"
                  onClick={() => {
                    dispatch(
                      getPushPathWrapper('sale.editSale', {
                        id: record.uid
                      })
                    )
                  }}
                />
              </Tooltip>
              <Tooltip title="Copy Sale">
                <Button
                  type="success"
                  shape="circle"
                  icon="copy"
                  href={`/sale/add?linkId=${record.uid}`}
                />
              </Tooltip>
            </div>
          )
        }
      }
    ]
    return (
      <PageHeaderWrapper
        title={'All Sales'}>
        <Card bordered={true}>
          <Row gutter={16}>
            <Col span={'5'}>
              <InputBox title={'FROM DATE'}>
                <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'fromDate')}
                            value={fromDate}
                            placeholder={'From'}/>
              </InputBox>
            </Col>
            <Col span={'5'}>
              <InputBox title={'TO DATE '}>
                <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'toDate')}
                            value={toDate}
                            placeholder={'To'}/>
              </InputBox>
            </Col>
            <Col span={'5'}>
              <InputBox title={'Filter By Category'}>
                <Select value={this.state.search}
                        allowClear={true}
                        onChange={(e) => this.setState({ search: e })}>
                  {saleCatArr && saleCatArr.length ? saleCatArr.map((item, key) => {
                    return (
                      <Option key={item.uid} value={item.name}>{item.name}</Option>
                    )
                  }) : null}
                </Select>
              </InputBox>
            </Col>
            <Col span={'7'}>
              <InputBox title={'filter By Sub Client'} className={'rowFlex'}>
                <Input placeholder={'filter by Sub Client'}
                       allowClear={true}
                       onChange={(e) => {
                         this.setState({ subClient: e.target.value })
                       }}
                       value={this.state.subClient}/>
                <Button onClick={() => this.reloadTab()}>
                  Search
                </Button>
              </InputBox>
            </Col>

          </Row>
          <TableComp columns={columns} apiRequest={this.apiRequest}
                     ref={this.table}
                     pagination={{
                       defaultPageSize: 50
                     }}
          />
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllUsers)
