export const GlobalKeyMap = {

  KONAMI: { name: 'Konami code', sequence: 'up+s' },
  LOG_DOWN: { name: 'Log Cmd Down', sequence: 'v+s', action: 'keyup' },
  LOG_UP: { name: 'Log Cmd Up', sequence: 'command', action: 'keyup' },
  SHOW_DIALOG: { name: 'Display keyboard shortcuts', sequence: 'shift+?', action: 'keyup' }
}


export const GlobalHandlers = {
  KONAMI: () => alert('KONAMI'),
  LOG_DOWN: () => alert('V+S'),
  LOG_UP: () => console.log('LOG_UP'),
  SHOW_DIALOG: () => alert('SHOW_DIALOG')
}




