import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Table, notification
} from 'antd'
import Request from '../../../request'
import { UnitCodeList } from '../../../components/_utils/_utils'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader } from '../../../modules/actions'
import { connect } from 'react-redux'
import { GlobalHotKeys, HotKeys } from 'react-hotkeys'
import AddCategory from './Add'
import { UnitHandle, UnitKey } from '../../../components/AppShortCut/units'


const FormItem = Form.Item
const { Option } = Select

@Form.create()
class All extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      addUnitVisible: false
    }
  }

  componentDidMount() {
    this.loadAllCategory()
  }

  async loadAllCategory() {
    let data = await Request.AllCategory()
    this.setState({ arr: data })
  }


  categoryCallback = (data) => {
    this.setState({ addUnitVisible: false }, () => {
      this.loadAllCategory()
    })
  }
  onCategoryClose = () => {
    this.setState({ addUnitVisible: false })
  }

  AddCategoryFxn = () => {
    let { dispatch } = this.props
    dispatch(hideLoader())
    this.setState({ addUnitVisible: true })
  }


  render() {
    let { arr, addUnitVisible } = this.state
    const columns = [
      {
        title: 'Name',
        key: 'name',
        // sorter: true,
        dataIndex: 'name'
        // searchTextName: 'name'
      },
      {
        title: 'HSN Code',
        key: 'hsnCode',
        dataIndex: 'hsnCode'
      },
      {
        title: 'GST',
        key: 'gst',
        dataIndex: 'gst',
        render: (val, record) => {
          return `${record.taxExempted ? 'Exempted' : `${val + '%'}`}`
        }
      },
      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username'
      }
    ]

    return (
      <PageHeaderWrapper
        title={'Category Setting'}>
        <Card bordered={true} title={'Category Setting'}
              extra={<Button type="primary" onClick={this.AddCategoryFxn}>Add Category</Button>}>
          <Table columns={columns} bordered={true} size={'small'}
                 dataSource={arr}
                 pagination={false}/>
        </Card>
        {addUnitVisible ?
          <AddCategory
            categoryCallback={this.categoryCallback}
            onCategoryClose={this.onCategoryClose}
            visible={addUnitVisible}
            {...this.props}
          />
          : null}
      </PageHeaderWrapper>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(All)
