import React, { Component } from 'react'
import { Col, Divider, Drawer, Icon, Select, Row, Card, Form, notification, Button, Input, Checkbox } from 'antd'
import { hideLoader, showLoader } from '../../modules/actions'
import AddItem from '../Items/add'
import Request from '../../request'
import _ from 'lodash'
import async from 'async'
import debounce from 'lodash/debounce'
import { fixed2Digit, AmountInDigit, TaxTypeList, filterOption } from '../../components/_utils/_utils'
import AddUnit from '../Setting/units/AddUnit'
import { v4 as uuid } from 'uuid'
import { InputBox } from './appDes'

const FormItem = Form.Item
const { Option } = Select
const { TextArea } = Input

@Form.create()

class AddItems extends React.Component {
  constructor(props) {
    super()
    let { params } = props

    this.state = {
      addItemVisible: false,
      arrData: [],
      unitArr: [],
      catArr: [],
      warrArr: [],
      productName: '',
      productUID: '',
      itemWarranty: '',
      categoryId: '',
      categoryName: '',
      quantity: '',
      actualPurchasePrice: '',
      actualSalePrice: '',
      salePrice: '',
      hsnCode: '',

      mrp: null,
      wholesalePrice: null,
      subTotal: null,
      discount: '',
      discountAmount: null,
      gst: null,
      gstAmount: null,
      netAmount: null,
      addProductModal: false,
      addUnitModal: false,
      addTaxModal: false,
      addCategoryModal: false,
      taxArr: [],
      disType: 'disInPer',
      brandId: null,
      brandName: null,
      taxExempted: false,
      refInvoices: [],
      showRef: false,
      textEditAble: params && params.textEditAble ? params.textEditAble : false,
      newNetAmount: null,
      newTaxAbleAmount: null,
      gstInclusive: false
    }
    this.fetchProduct = debounce(this.fetchProduct, 500)
    this.productNameRef = React.createRef()
    this._save = debounce(this._save, 500)
  }


  fetchProduct = (text) => {
    let obj = {
      name: text
    }
    let { saleRef } = this.state
    let { isCreditNote = false, isDebitNote = false, pageType, clientVendorId } = this.props.params
    if (saleRef) {
      obj.saleRef = saleRef
    }
    if (isCreditNote) {
      obj.isCreditNote = true
    }
    if (isDebitNote) {
      obj.isDebitNote = true
    }
    if (pageType) {
      obj.pageType = pageType
    }
    if (clientVendorId) {
      obj.clientVendorId = clientVendorId
    }
    Request.AllItemsList(obj)
      .then((testData) => {
        console.log(testData)

        this.setState({ arrData: testData.arr })
        return
      })
      .catch((e) => {
        return
      })

  }

  addProduct() {
    this.setState({ addItemVisible: true })
  }

  onItemClose = () => {
    this.setState({ addItemVisible: false })
  }

  addItemCallback = (data) => {
    this.loadUnits()
    let { pageType } = this.props.params

    let priceToSet = data.salePrice ? data.salePrice.toString() : null
    if (pageType == 'Purchase') {
      priceToSet = data.purchasePrice ? data.purchasePrice.toString() : null
    }
    let gst = data.taxExempted ? null : data.gst ? data.gst.toString() : null

    this.setState({
      productName: data.name,
      hsnCode: data.hsnCode,
      productUID: data.uid,
      categoryId: data.categoryId,
      categoryName: data.categoryName,
      taxExempted: data.taxExempted,
      gstInclusive: data.gstInclusive,
      quantity: null,
      subTotal: null,
      salePrice: priceToSet,
      mrp: data.mrp ? data.mrp.toString() : null,
      wholesalePrice: data.wholesalePrice ? data.wholesalePrice.toString() : null,
      actualSalePrice: data.salePrice ? data.salePrice.toString() : null,
      actualPurchasePrice: data.purchasePrice.toString() ? data.purchasePrice.toString() : null,
      gst: gst,
      unitId: data.unitId,
      unitName: data.unitName,
      unitCode: data.unitCode,
      itemWarranty: data.warranty,
      brandName: data.brandName,
      brandId: data.brandId,
      secondDiscountPercent: data.secondDiscountPercent,
      secondDiscountAmount: data.secondDiscountAmount,
      taxableAmount: data.taxableAmount,
      saleRefNo: data.saleRefNo ? data.saleRefNo : null,
      saleRef: data.saleRef ? data.saleRef : null,
      addItemVisible: false

    }, () => {
      this.calculation()
    })


  }
  handleChange = (data) => {

  }

  increment = () => {
    let qty = this.state.quantity
    if (qty) {
      qty = parseFloat(qty)
      qty++
      this.setState({ quantity: qty.toString() })
      /*setTimeout(() => {
          this.calculation();
      }, 50);*/

      this.calculation()
    } else {
      this.setState({ quantity: '1' })
      /* setTimeout(() => {
           this.calculation();
       }, 50);*/
      this.calculation()
    }
  }


  loadCategory = () => {
    Request.AllCategory()
      .then((testData) => {
        let catArr = [{ categoryId: '', categoryName: 'Choose' }]
        _.each(testData, (ff) => {
          catArr.push({ categoryId: ff.uid, categoryName: ff.name })
        })
        this.setState({ catArr: catArr })
      })
      .catch((e) => {
        return
      })
  }

  loadUnits = () => {
    Request.AllUnits()
      .then((testData) => {
        let unitArr = []
        _.each(testData, (ff) => {
          unitArr.push({ unitId: ff.uid, name: ff.name, code: ff.code })
        })
        this.setState({ unitArr: unitArr, unitId: unitArr[0].unitId })
      })
      .catch((e) => {
        return
      })
  }

  events = {
    unitCallback: (data) => {
      this.loadUnits()
      if (data && data.uid) {
        setTimeout(() => {
          this.setState({
            unitId: data.uid,
            unitName: data.name,
            unitCode: data.code,
            visibleAddUnit: false
          })
        }, 500)
      }
    },
    onUnitClose: () => {
      this.setState({ visibleAddUnit: false })
    }
  }

  loadTaxes = async () => {
    return new Promise((resolve) => {
      Request.AllTaxes()
        .then((testData) => {
          let qqq = []
          _.each(testData, (ff) => {
            qqq.push(ff.tax.toString())
          })
          // this.setState({taxArr: qqq, gst: qqq[0]});
          this.setState({ taxArr: qqq, gst: 0 })
          return resolve('true')
        })
        .catch((e) => {
          return resolve('true')
        })
    })

  }

  decrement = () => {
    let qty = this.state.quantity ? this.state.quantity : 0
    if (qty) {
      qty = parseFloat(qty)
      if (qty) {
        qty--
        this.setState({ quantity: qty.toString() })
        /* setTimeout(() => {
             this.calculation();
         }, 50);*/
        this.calculation()
      }

    }
  }

  goback = () => {
    this.props.navigation.goBack()
  }

  calculation = () => {

    setTimeout(() => {
      let quantity = parseFloat(this.state.quantity ? parseFloat(this.state.quantity).toFixed(3) : 0)
      let salePrice = parseFloat(this.state.salePrice ? parseFloat(this.state.salePrice).toFixed(2) : 0)

      let discount = parseFloat(this.state.discount ? parseFloat(this.state.discount).toFixed(2) : 0)
      let secondDiscountPercent = parseFloat(this.state.secondDiscountPercent ? parseFloat(this.state.secondDiscountPercent).toFixed(2) : 0)
      let gst = this.state.gst == 'Exempted' ? 0 : parseFloat(this.state.gst ? parseInt(this.state.gst) : 0)
      let subTotal = 0

      let discountAmount = 0
      let secondDiscountAmount = 0
      let afterDisc = 0
      let gstAmount = 0
      let netAmount = 0
      let taxableAmount = 0

      if (this.state.disType == 'disInPer') {
        discountAmount = parseFloat(parseFloat((discount / 100) * salePrice).toFixed(2))
      } else {
        discountAmount = fixed2Digit(this.state.discountAmount)
      }
      afterDisc = parseFloat(salePrice) - (discountAmount ? parseFloat(discountAmount) : 0)
      if (this.state.disType == 'disInPer') {
        secondDiscountAmount = parseFloat(parseFloat((secondDiscountPercent / 100) * afterDisc).toFixed(2))

      } else {
        secondDiscountAmount = fixed2Digit(this.state.secondDiscountAmount)
      }

      if (secondDiscountAmount) {
        afterDisc = parseFloat(afterDisc) - (secondDiscountAmount ? parseFloat(secondDiscountAmount) : 0)
      }

      if (this.state.gstInclusive) {
        netAmount = fixed2Digit(afterDisc * quantity)
        if (gst !== 'Exempted') {
          gstAmount = parseFloat((((netAmount / (100 + gst)) * gst)).toFixed(2))
        }
        taxableAmount = parseFloat((((netAmount / (100 + gst)) * 100)).toFixed(2))
        subTotal = parseFloat((((netAmount / (100 + gst)) * 100)).toFixed(2))
      } else {
        subTotal = parseFloat((parseFloat(quantity * afterDisc)).toFixed(2))
        if (gst !== 'Exempted') {
          gstAmount = parseFloat(parseFloat((gst / 100) * (subTotal)).toFixed(2))
        }
        taxableAmount = parseFloat(quantity * afterDisc).toFixed(2)
        netAmount = parseFloat(subTotal + gstAmount).toFixed(2)
      }

      let objToSet = {
        subTotal: subTotal.toString(),
        gstAmount: gstAmount.toString(),
        netAmount: netAmount.toString(),
        taxableAmount: taxableAmount.toString()
      }

      objToSet.discountAmount = discountAmount ? discountAmount.toString() : 0
      objToSet.secondDiscountAmount = secondDiscountAmount ? secondDiscountAmount.toString() : 0
      this.setState({ ...objToSet })

    }, 100)
  }

  calculationFxn2(callback) {
    setTimeout(() => {
      let quantity = parseFloat(this.state.quantity ? parseFloat(this.state.quantity).toFixed(3) : 0)
      let salePrice = parseFloat(this.state.salePrice ? parseFloat(this.state.salePrice).toFixed(2) : 0)

      let discount = parseFloat(this.state.discount ? parseFloat(this.state.discount).toFixed(2) : 0)
      let secondDiscountPercent = parseFloat(this.state.secondDiscountPercent ? parseFloat(this.state.secondDiscountPercent).toFixed(2) : 0)
      let gst = this.state.gst == 'Exempted' ? 0 : parseFloat(this.state.gst ? parseInt(this.state.gst) : 0)

      let subTotal = parseFloat(parseFloat(quantity * salePrice).toFixed(2))
      let discountAmount = 0
      let secondDiscountAmount = 0
      let afterDisc = 0
      let gstAmount = 0
      let netAmount = 0
      let taxableAmount = 0

      if (this.state.disType == 'disInPer') {
        discountAmount = parseFloat(parseFloat((discount / 100) * salePrice).toFixed(2))
      } else {
        discountAmount = fixed2Digit(this.state.discountAmount)
      }
      afterDisc = parseFloat(salePrice) - (discountAmount ? parseFloat(discountAmount) : 0)
      if (this.state.disType == 'disInPer') {
        secondDiscountAmount = parseFloat(parseFloat((secondDiscountPercent / 100) * afterDisc).toFixed(2))

      } else {
        secondDiscountAmount = fixed2Digit(this.state.secondDiscountAmount)
      }

      if (secondDiscountAmount) {
        afterDisc = parseFloat(afterDisc) - (secondDiscountAmount ? parseFloat(secondDiscountAmount) : 0)
      }

      if (this.state.gstInclusive) {
        netAmount = fixed2Digit(afterDisc * quantity)
        if (gst !== 'Exempted') {
          gstAmount = parseFloat((((netAmount / (100 + gst)) * gst)).toFixed(2))
        }
        taxableAmount = parseFloat((((netAmount / (100 + gst)) * 100)).toFixed(2))
        subTotal = parseFloat((((netAmount / (100 + gst)) * 100)).toFixed(2))

      } else {
        subTotal = parseFloat((parseFloat(quantity * afterDisc)).toFixed(2))
        if (gst !== 'Exempted') {
          gstAmount = parseFloat(parseFloat((gst / 100) * (subTotal)).toFixed(2))
        }
        taxableAmount = parseFloat(quantity * afterDisc).toFixed(2)
        netAmount = parseFloat(subTotal + gstAmount).toFixed(2)
      }


      let objToSet = {
        subTotal: subTotal.toString(),
        gstAmount: gstAmount.toString(),
        netAmount: netAmount.toString(),
        taxableAmount: taxableAmount.toString()
      }

      objToSet.discountAmount = discountAmount ? discountAmount.toString() : 0
      objToSet.secondDiscountAmount = secondDiscountAmount ? secondDiscountAmount.toString() : 0
      this.setState({ ...objToSet }, () => {
        return callback()
      })

    }, 100)
  }

  addprod = () => {


  }

  _toggleModal(modalName) {
    if (modalName) {
      this.setState({
        [modalName]: !this.state[modalName]
      })
    }
  }

  async loadInvoicesRef() {
    let noteType = ''
    let { isCreditNote = false, isDebitNote = false, clientVendorId, showRef = false } = this.props.params
    if (showRef) {
      let refInvoices = []
      if (isCreditNote) {
        noteType = 'Credit_note'
      }
      if (isDebitNote) {
        noteType = 'Debit_note'
      }
      let resp = await Request.loadInvoicesRef({ noteType, clientId: clientVendorId })
      _.each(resp, (item) => {
        let obj = {
          invoiceNo: item.invoiceNo,
          uid: item.uid
        }
        if (noteType == 'Credit_note') {
          obj.saleRefDate = item.saleDate
        } else {
          obj.saleRefDate = item.purchaseDate
        }
        refInvoices.push({ ...obj })
      })
      this.setState({
        refInvoices,
        showRef,
        saleRef: refInvoices && refInvoices.length ? refInvoices[0].uid : null,
        saleRefDate: refInvoices && refInvoices.length ? refInvoices[0].saleRefDate : null,
        saleRefNo: refInvoices && refInvoices.length ? refInvoices[0].invoiceNo : null
      })
    }
  }


  loadWarr() {
    Request.AllWarranty()
      .then((testData) => {

        let rrr = ['None']
        _.each(testData, (tt) => {
          if (tt.warranty) {
            rrr.push(tt.warranty)
          }

        })
        this.setState({ warrArr: rrr })
      })
      .catch((e) => {


      })
  }

  openwar = () => {


  }

  async componentDidMount() {

    await this.loadTaxes()
    this.loadUnits()
    this.loadWarr()
    this.loadInvoicesRef()
    let { params } = this.props
    const itemobjj = params.editItem
    console.log(itemobjj)
    let clientVendorId = params.clientVendorId
    if (itemobjj) {
      if (itemobjj.productName) {

        let tempTid = null

        if (itemobjj._id) {
          tempTid = itemobjj._id
          itemobjj.tid = itemobjj._id
        } else {

          if (itemobjj.tid) {
            tempTid = itemobjj.tid
          }
        }


        this.setState({
          tid: tempTid,
          productName: itemobjj.productName,
          productUID: itemobjj.productUid,
          categoryId: itemobjj.categoryId,
          hsnCode: itemobjj.hsnCode,
          categoryName: itemobjj.categoryName,
          // itemWarranty: itemobjj.itemWarranty,
          itemWarranty: itemobjj.itemWarranty,
          quantity: itemobjj.quantity ? itemobjj.quantity.toString() : null,
          subTotal: itemobjj.subTotal ? itemobjj.subTotal.toString() : null,
          discount: itemobjj.discount ? itemobjj.discount.toString() : null,
          discountAmount: itemobjj.discountAmount ? itemobjj.discountAmount.toString() : null,
          secondDiscountPercent: itemobjj.secondDiscountPercent ? itemobjj.secondDiscountPercent.toString() : null,
          secondDiscountAmount: itemobjj.secondDiscountAmount ? itemobjj.secondDiscountAmount.toString() : null,
          unitCode: itemobjj.unitCode,
          gstAmount: itemobjj.gstAmount ? itemobjj.gstAmount.toString() : null,
          netAmount: itemobjj.netAmount ? itemobjj.netAmount.toString() : null,
          salePrice: itemobjj.salePrice ? itemobjj.salePrice.toString() : null,
          mrp: itemobjj.mrp ? itemobjj.mrp.toString() : null,
          wholesalePrice: itemobjj.wholesalePrice ? itemobjj.wholesalePrice.toString() : null,
          actualSalePrice: itemobjj.actualSalePrice ? itemobjj.actualSalePrice.toString() : null,
          actualPurchasePrice: itemobjj.actualPurchasePrice ? itemobjj.actualPurchasePrice.toString() : null,
          brandId: itemobjj.brandId,
          brandName: itemobjj.brandName,
          typpp: itemobjj.typpp,
          gstInclusive: itemobjj.gstInclusive,
          taxExempted: itemobjj.taxExempted,
          taxableAmount: itemobjj.taxableAmount,
          disType: itemobjj.disType,
          saleRefNo: itemobjj.saleRefNo ? itemobjj.saleRefNo : null,
          saleRef: itemobjj.saleRef ? itemobjj.saleRef : null,
          saleRefDate: itemobjj.saleRefDate ? itemobjj.saleRefDate : null,
          lastPurchaseId: itemobjj.lastPurchaseId ? itemobjj.lastPurchaseId : null,
          lastPurchasePrice: itemobjj.lastPurchasePrice ? itemobjj.lastPurchasePrice : null

        })
        setTimeout(() => {
          this.setState({
            unitName: itemobjj.unitName,
            unitId: itemobjj.unitId,
            gst: itemobjj.gst ? itemobjj.gst.toString() : null
          })
        }, 1000)
      }
    }


  }

  _filterData(data) {

    let aa = []

    aa = _.clone(this.state.arrData)
    aa.unshift({ name: 'add Product', link: true })
    return _.slice(aa, 0, 10)
  }

  _save = (typpp) => {
    if (!this.state.productUID) {
      notification.warn({
        message: 'Select Product'
      })
      return
    }

    this.props.dispatch(showLoader())
    async.series([
      (cb) => {
        if (!this.state.quantity) {
          this.setState({ quantity: '1' }, () => {
            this.calculationFxn2((resp) => {
              cb()
            })
          })
        } else {
          cb()
        }
      }
    ], () => {

      let objn = {
        tid: this.state.tid ? this.state.tid : uuid(),
        productName: this.state.productName,
        hsnCode: this.state.hsnCode ? this.state.hsnCode : '',
        productUid: this.state.productUID ? this.state.productUID : null,
        categoryId: this.state.categoryId ? this.state.categoryId : null,
        categoryName: this.state.categoryName ? this.state.categoryName : null,
        quantity: this.state.quantity ? this.state.quantity : 0,
        subTotal: this.state.subTotal ? this.state.subTotal : 0,
        discount: this.state.discount ? this.state.discount : 0,
        discountAmount: this.state.discountAmount,
        secondDiscountPercent: this.state.secondDiscountPercent ? this.state.secondDiscountPercent : 0,
        secondDiscountAmount: this.state.secondDiscountAmount,
        gst: this.state.gst ? this.state.gst : 0,
        gstInclusive: this.state.gstInclusive ? this.state.gstInclusive : false,
        unitCode: this.state.unitCode ? this.state.unitCode : '',
        unitName: this.state.unitName ? this.state.unitName : '',
        unitId: this.state.unitId ? this.state.unitId : '',
        gstAmount: this.state.gstAmount ? this.state.gstAmount : 0,
        netAmount: this.state.netAmount ? this.state.netAmount : 0,
        salePrice: this.state.salePrice ? this.state.salePrice : 0,
        // itemWarranty: this.state.itemWarranty,
        itemWarranty: this.state.itemWarranty,
        mrp: this.state.mrp ? this.state.mrp : 0,
        wholesalePrice: this.state.wholesalePrice ? this.state.wholesalePrice : 0,
        actualSalePrice: this.state.actualSalePrice ? this.state.actualSalePrice : 0,
        actualPurchasePrice: this.state.actualPurchasePrice ? this.state.actualPurchasePrice : 0,
        brandId: this.state.brandId,
        brandName: this.state.brandName,
        taxExempted: this.state.taxExempted,
        taxableAmount: this.state.taxableAmount,
        typpp: typpp,
        disType: this.state.disType,
        saleRefNo: this.state.saleRefNo ? this.state.saleRefNo : null,
        saleRef: this.state.saleRef ? this.state.saleRef : null,
        saleRefDate: this.state.saleRefDate ? this.state.saleRefDate : null,
        lastPurchaseId: this.state.lastPurchaseId ? this.state.lastPurchaseId : null,
        lastPurchasePrice: this.state.lastPurchasePrice ? this.state.lastPurchasePrice : null,
        saveType: typpp

      }
      this.props.chooseItemCallback(objn)

      if (typpp == 'new') {
        this.setState({
          productName: null,
          productUID: null,
          quantity: null,
          subTotal: null,
          discount: null,
          hsnCode: null,
          discountAmount: null,
          secondDiscountPercent: null,
          secondDiscountAmount: null,
          gst: null,
          unitCode: null,
          unitName: null,
          unitId: null,
          gstAmount: null,
          netAmount: null,
          salePrice: null,
          mrp: null,
          wholesalePrice: null,
          actualSalePrice: null,
          itemWarranty: null,
          actualPurchasePrice: null,
          tid: null,
          brandId: null,
          brandName: null,
          lastPurchaseId: null,
          lastPurchasePrice: null,
          showRef: false,
          newNetAmount: null,
          newTaxAbleAmount: null,
          gstInclusive: false,
          taxableAmount: null

        })

        this.props.dispatch(hideLoader())


      } else {
        this.props.dispatch(hideLoader())

      }
      notification.success({
        message: 'Added'
      })
      return
    })
  }

  _getPrice(amount, item) {
    amount = parseFloat(amount)

    return amount.toFixed(2)
  }

  chooseProduct = (uid) => {
    let { arrData } = this.state
    let item = _.find(arrData, (value) => {
      return value.uid == uid
    })
    let pageType = ''
    let defaultTaxType = ''
    let { params } = this.props
    if (params) {
      if (params.pageType) {
        pageType = params.pageType

      }
      if (params.defaultTaxType) {
        defaultTaxType = params.defaultTaxType
      }
    }
    let finalPrice = ''
    let gstInclusive = ''

    if (item) {
      if (pageType == 'Purchase') {
        finalPrice = item.purchasePrice ? item.purchasePrice.toString() : null
        if (defaultTaxType && defaultTaxType !== 'Default') {
          if (defaultTaxType == 'Inclusive GST') {
            gstInclusive = true
          } else {
            gstInclusive = false
          }
        } else {
          gstInclusive = item.gstPurchaseInclusive ? item.gstPurchaseInclusive : false
        }
      } else {
        finalPrice = item.salePrice ? item.salePrice.toString() : null
        if (defaultTaxType && defaultTaxType !== 'Default') {
          if (defaultTaxType == 'Inclusive GST') {
            gstInclusive = true
          } else {
            gstInclusive = false
          }
        } else {
          gstInclusive = item.gstInclusive ? item.gstInclusive : false
        }
      }
      this.setState({
        productUID: item.uid,
        productName: item.name,
        hsnCode: item.hsnCode,
        categoryId: item.categoryId,
        categoryName: item.categoryName,
        quantity: '',
        unitId: item.unitId,
        unitName: item.unitName,
        unitCode: item.unitCode,
        gst: item.gst ? item.gst.toString() : null,
        salePrice: finalPrice,
        gstInclusive: gstInclusive,
        taxExempted: item.taxExempted,
        mrp: item.mrp,
        wholesalePrice: item.wholesalePrice,
        actualSalePrice: item.salePrice,
        actualPurchasePrice: item.purchasePrice,
        brandId: item.brandId,
        brandName: item.brandName,
        itemWarranty: item.warranty,
        lastPurchasePrice: item.lastPurchasePrice ? item.lastPurchasePrice : null,
        lastPurchaseId: item.lastPurchaseId ? item.lastPurchaseId : null,
        discount: item.discount ? item.discount.toString() : null,
        secondDiscountPercent: item.secondDiscountPercent ? item.secondDiscountPercent.toString() : null

      }, () => {
        this.calculation()
      })
    } else {
      this.setState({
        productUID: '',
        productName: '',
        hsnCode: '',
        categoryId: '',
        categoryName: '',
        quantity: '',
        unitId: '',
        unitName: '',
        unitCode: '',
        gst: '',
        salePrice: '',
        gstInclusive: '',
        taxExempted: '',
        mrp: '',
        wholesalePrice: '',
        actualSalePrice: '',
        actualPurchasePrice: '',
        brandId: '',
        brandName: '',
        itemWarranty: '',
        lastPurchasePrice: '',
        lastPurchaseId: '',
        discount: '',
        secondDiscountPercent: ''

      }, () => {

        this.calculation()
      })
    }

  }

  selectDisType(value) {
    this.setState({
      disType: value,
      discount: null,
      discountAmount: null,
      secondDiscountPercent: null,
      secondDiscountAmount: null
    })
    this.calculation()

  }

  _setDigitValue(value, type) {
    if (value) {
      let splitX = value.split('.')
      if (splitX.length > 2) {
        return true
      }
      if (splitX[1]) {
        if (splitX[1].length > 2) {
          return true
        }
      }
      this.setState({ [type]: value }, () => {
        this.calculation()
      })
    } else {
      this.setState({ [type]: null }, () => {
        this.calculation()
      })
    }
  }

  _set3DigitValue(value, type) {
    if (value) {
      this.setState({ [type]: AmountInDigit(value, 3) }, () => {
        this.calculation()
      })
    } else {
      this.setState({ [type]: null }, () => {
        this.calculation()
      })
    }
  }

  _setNumberValue(value, type) {
    if (value) {
      let splitX = value.split('.')
      if (splitX.length > 1) {
        return true
      }
      this.setState({ [type]: value }, () => {
        this.calculation()
      })
    } else {
      this.setState({ [type]: null }, () => {
        this.calculation()
      })
    }

  }

  chooseSaleRef(value) {
    this.setState({
      saleRef: value,
      saleRefNo: value.invoiceNo ? value.invoiceNo : null,
      saleRefDate: value.reportDate ? value.reportDate : null
    }, () => {
      this.singleProductByInvoiceRef()
    })
  }

  async singleProductByInvoiceRef() {
    let { saleRef, productUID } = this.state
    if (productUID) {
      let obj = {
        productUid: productUID
      }
      let { isCreditNote = false, isDebitNote = false, clientVendorId } = this.props.params
      if (saleRef) {
        obj.saleRef = saleRef
      }
      if (isCreditNote) {
        obj.isCreditNote = true
      }
      if (isDebitNote) {
        obj.isDebitNote = true
      }
      if (clientVendorId) {
        obj.clientId = clientVendorId
      }

      let resp = await Request.singleProductByInvoiceRef(obj)
      if (resp && resp.type) {
        let { sale } = resp
        this.setState({
          salePrice: sale.price ? sale.price.toString() : null,
          actualSalePrice: sale.actualSalePrice,
          actualPurchasePrice: sale.actualPurchasePrice,
          gstInclusive: sale.gstInclusive,
          saleRefNo: sale.invoiceNo ? sale.invoiceNo : null,
          saleRefDate: sale.reportDate ? sale.reportDate : null,
          discount: sale.discount ? sale.discount.toString() : null,
          secondDiscountPercent: sale.secondDiscountPercent ? sale.secondDiscountPercent.toString() : null
        }, () => {
          this.calculation()
        })
      }
    }
  }

  updateTaxableAmount(data) {
    let { quantity, salePrice } = this.state
    let newPerUnit = parseFloat(data) / quantity
    let orgPerUnit = parseFloat(salePrice)
    let discount = (orgPerUnit - newPerUnit) * 100 / salePrice
    discount = discount.toFixed(2)
    this.setState({
      discount,
      disType: 'disInPer',
      newTaxAbleAmount: data,
      taxAbleAmount: data,
      secondDiscountPercent: null
    }, () => {
      this.calculation()
    })
  }

  updateNetAmount(data) {
    let { quantity, salePrice } = this.state
    let newPerUnit = parseFloat(data) / quantity
    let orgPerUnit = parseFloat(salePrice)
    let discount = (orgPerUnit - newPerUnit) * 100 / salePrice
    discount = discount.toFixed(2)
    this.setState({
      discount,
      disType: 'disInPer',
      newNetAmount: data,
      secondDiscountPercent: null,
      netAmount: data
    }, () => {
      this.calculation()
    })
  }

  setValue = (e) => {
    let { name, value } = e.target
    this.setState({ [name]: value })
  }


  render() {
    let { visible, chooseItemClose, chooseItemCallback } = this.props
    let {
      productName, addItemVisible, arrData, visibleAddUnit,
      gstInclusive, discountAmount, subTotal, refInvoices, salePrice, textEditAble
    } = this.state

    return (
      <Drawer
        width={800}
        visible={visible}
        onClose={chooseItemClose}>
        <Card title="Choose Item" actions={[
          <Button onClick={debounce(() => {
            this._save('save')
          }, 200)} type="primary">ADD ITEM</Button>,
          <Button onClick={debounce(() => {
            this._save('new')
          }, 200)} type="primary">ADD ITEM & NEW</Button>
        ]} className={'customCard'}>
          <Form className={'newForm'}>
            <Row gutter={16}>
              <Col>
                <InputBox title={'Choose Item'}>
                  <Select
                    showSearch={true}
                    onSearch={this.fetchProduct}
                    filterOption={false}
                    allowClear={true}
                    value={productName}
                    ref={node => this.productName = node}
                    autoFocus={true}
                    onChange={this.chooseProduct}
                    placeholder="Choose Client"
                    dropdownRender={menu => (
                      <div key={arrData}>
                        {menu}
                        <Divider style={{ margin: '4px 0' }}/>
                        <div
                          style={{ padding: '4px 8px', cursor: 'pointer' }}
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => this.addProduct()}>
                          <Icon type="plus"/> Add New Item
                        </div>
                      </div>
                    )}>

                    {arrData && arrData.length ? arrData.map((item) => {
                      return (
                        <Option key={item.uid} value={item.uid}>{item.name}</Option>
                      )
                    }) : null}

                  </Select>
                </InputBox>
              </Col>
              <Col>
                <InputBox title={'Unit'} className={'rowFlex'}>
                  <Select value={this.state.unitName}
                          allowClear={true}
                          showSearch={true}
                          filterOption={filterOption}
                          optionFilterProp="children"
                          onChange={(e) => {
                            this.setState({
                              'unitId': e && e.unitId ? e.unitId : '',
                              'unitName': e && e.name ? e.name : '',
                              'unitCode': e && e.code ? e.code : ''
                            }, () => {
                              console.log(this.state)
                            })
                          }}>
                    {this.state.unitArr.map((item) => {
                      return (
                        <Option key={item.unitId} value={item}>{item.name}</Option>
                      )
                    })}
                  </Select>
                  <Button onClick={() => this.events.showUnitDrawer()}>
                    <Icon type={'plus'}></Icon>
                  </Button>
                </InputBox>
              </Col>
              <Col>
                <div className={'rowFlex'}>
                  <div className={'d1'}>
                    <label>Price/Unit</label>
                    <Input onChange={(e) => {
                      this._setDigitValue(e.target.value, 'salePrice')
                    }} value={this.state.salePrice}/>
                  </div>
                  <div className={'d2'}>
                    <label>Tax</label>
                    <Select value={this.state.gstInclusive}
                            onChange={(e) => this.setState({ gstInclusive: e })}>
                      {TaxTypeList.map((item) => {
                        return (
                          <Option value={item.value} key={item.label}>{item.label}</Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
              </Col>
              {this.state.itemWarranty ?
                <Col>
                  <InputBox title={'Warranty'}>
                    <div className={'ant-input'}>{this.state.itemWarranty}
                    </div>
                  </InputBox>
                </Col> : null}
              <Col className={'rowFlex  mt10 mb10'}>
                <label onClick={(value) => this.selectDisType('disInPer')} className={'d1'}>
                  <Checkbox checked={this.state.disType == 'disInPer' ? true : false}/> Disc. In %
                </label>
                <label onClick={(value) => this.selectDisType('disInAmt')} className={'d2'}>
                  <Checkbox checked={this.state.disType == 'disInAmt' ? true : false}/> Disc. In Amount
                </label>
              </Col>
              <Col>
                <label>Discount </label>
                {this.state.salePrice ? <label>({this.state.salePrice})</label> : null}
                <div className={'rowFlex'}>

                  {this.state.disType == 'disInPer' ?
                    <div className={'d1 mr10'}><Input
                      onChange={(e) => {
                        this._setDigitValue(e.target.value, 'discount')
                      }}
                      value={this.state.discount}
                    /></div> : null}

                  <div className={'d1'}>
                    <Input
                      disabled={this.state.disType == 'disInAmt' ? false : true}
                      value={this.state.discountAmount}
                      onChange={(e) => {
                        this._setDigitValue(e.target.value, 'discountAmount')
                      }}/>
                  </div>
                </div>
              </Col>
              <Col>
                <label>Discount Second </label>
                {discountAmount ?
                  <label>({(salePrice - discountAmount).toFixed(2)})</label> : null}
                <div className={'rowFlex'}>
                  {this.state.disType == 'disInPer' ?
                    <div className={'d1 mr10'}><Input
                      onChange={(e) => {
                        this._setDigitValue(e.target.value, 'secondDiscountPercent')
                      }}
                      value={this.state.secondDiscountPercent}
                    /></div> : null}
                  <div className={'d1'}>
                    <Input
                      disabled={this.state.disType == 'disInAmt' ? false : true}
                      value={this.state.secondDiscountAmount}
                      onChange={(e) => {
                        this._setDigitValue(e.target.value, 'secondDiscountAmount')
                      }}/>
                  </div>
                </div>
              </Col>
              <Col>
                <InputBox title={'Quantity'}>
                  <div className={'rowFlex'}>
                    <Input
                      onChange={(e) => {
                        this._set3DigitValue(e.target.value, 'quantity')
                      }}
                      value={this.state.quantity}/>
                    <Button onClick={() => this.increment()}>
                      <Icon type={'plus'}/>
                    </Button>
                    <Button onClick={() => this.decrement()}>
                      <Icon type={'minus'}/>
                    </Button>
                  </div>
                </InputBox>
              </Col>
              <Col>
                <InputBox title={'Taxable Amt.'}>
                  <div className={'ant-input'}>
                    {this.state.taxableAmount}
                  </div>
                </InputBox>
              </Col>
              <Col>
                <InputBox title={'Sub total'}>
                  <div className={'ant-input'}>
                    {this.state.subTotal}
                  </div>
                </InputBox>
              </Col>
              <Col>
                <InputBox title={'GST'}>
                  <div className={'rowFlex'}>
                    <div className={'ant-input d1'}>
                      {this.state.taxExempted ? 'Exempted' : `${this.state.gst ? `${this.state.gst}%` : ''}`}
                    </div>
                    <div className={'ant-input d2'}>
                      {this.state.gstAmount}
                    </div>
                  </div>
                </InputBox>
              </Col>
              <Col>
                <InputBox title={'Total Amount'}>
                  <div className={'ant-input'}>
                    {this.state.netAmount}
                  </div>
                </InputBox>
              </Col>
              {this.state.showRef ? <Col>
                <InputBox title={'Invoice Ref'}>
                  <Select value={this.state.saleRefNo}
                          allowClear={true}
                          showSearch={true}
                          filterOption={filterOption}
                          optionFilterProp="children"
                          onChange={(e) => {
                            this.chooseSaleRef(e)
                          }}>
                    {this.state.refInvoices.map((item) => {
                      return (
                        <Option key={item.uid} value={item}>{item.invoiceNo}</Option>
                      )
                    })}
                  </Select>
                </InputBox>
              </Col> : null}
            </Row>
          </Form>
        </Card>

        {visibleAddUnit && <AddUnit visible={visibleAddUnit} {...this.props}
                                    unitCallback={this.events.unitCallback}
                                    onUnitClose={this.events.onUnitClose}/>}
        {addItemVisible ?
          <AddItem visible={addItemVisible}
                   onItemClose={this.onItemClose}
                   addItemCallback={this.addItemCallback}/> : null}
      </Drawer>
    )
  }
}

export default AddItems
