import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input,
  Button,
  Icon,
  Row,
  Col,
  Tag, Divider, Select, Form
} from 'antd'
import Request from '../../../request'
import { connect } from 'react-redux'
import { hideLoader, showLoader } from '../../../modules/actions'
import AddBank from '../add/index'
import EditBank from '../edit/index'
import SingleBankDayBook from '../SingleBankDayBook'

const FormItem = Form.Item
const { Option } = Select


class AllBanks extends Component {

  events = {
    onBankClose: () => {
      this.setState({ addBankVisible: false, editBankVisible: false, singleBankDrawer: false })
    },
    addBankCallback: () => {
      this.setState({ addBankVisible: false, editBankVisible: false }, () => {
        this.AllBankFxn()
      })
    },
    editBank: (selectedBank) => {
      this.setState({ editBankVisible: true, selectedBank })
    },
    viewBank: (selectedBank) => {
      this.setState({ singleBankDrawer: true, bankId: selectedBank.uid, selectedBank: selectedBank })
    }
  }


  componentDidMount() {
    let searchParams = new URLSearchParams(window.location.search)
    let pageType = searchParams.get('pageType') ? searchParams.get('pageType') : 'ledger'
    let title = searchParams.get('title') ? searchParams.get('title') : 'LEDGER BOOK'
    this.setState({ pageType, title }, () => {
      this.AllBankFxn()
    })

  }

  AllBankFxn = () => {
    let obj = {}
    if (this.state.pageType == 'cash') {
      obj.type = 'Cash'
    }
    Request.BankList(obj)
      .then((testData) => {
        this.setState({ arr: testData })
        return
      })
      .catch((e) => {

        return
      })

  }

  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      singleBankDrawer: false
    }
    this.table = React.createRef()
  }

  render() {
    let { pageType, title, arr, addBankVisible, editBankVisible, selectedBank, singleBankDrawer } = this.state
    const columns = [
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type'
      },
      {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
        render: (val, record) => {
          return (
            <div>
              {val} {record.accountNo ? `\n(${record.accountNo})` : null}
            </div>
          )
        }
      },

      {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        render: (val) => {
          return (
            <div>
              Rs. {val.toFixed(2)}
            </div>
          )
        }
      },
      {
        title: 'By',
        dataIndex: 'username',
        key: 'username',
        render: (val) => {
          return (
            <div>
              By {val}
            </div>
          )
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (val, record) => {
          return (
            <div>
              {pageType == 'dayBook' ?
                <Button onClick={() => this.events.editBank(record)} className={'mr10'}>
                  Edit
                </Button> : null}

              <Button onClick={() => this.events.viewBank(record)}>
                View
              </Button>
            </div>
          )
        }
      }
    ]

    /*  if (pageType == 'dayBook') {
        columns.push({
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
          render: (val, record) => {
            return (
              <div>

                <Button onClick={() => this.events.editBank(record)}>
                  Edit
                </Button>

                <Button onClick={() => this.events.viewBank(record)}>
                  View
                </Button>
              </div>
            )
          }
        })

      }
  */
    return (
      <PageHeaderWrapper
        title={`BANKS ${title}`}>
        <Card bordered={true} extra={pageType == 'dayBook' ?
          <Button onClick={() => this.setState({ addBankVisible: true })}>Add Bank</Button> : null}>

          <Table columns={columns} size={'small'} bordered
                 ref={this.table}
                 dataSource={arr}
                 pagination={false}
          />
        </Card>

        {addBankVisible ?
          <AddBank
            addBankCallback={this.events.addBankCallback}
            onBankClose={this.events.onBankClose}
            visible={addBankVisible}
            {...this.props}
          />
          : null}

        {editBankVisible ?
          <EditBank
            addBankCallback={this.events.addBankCallback}
            onBankClose={this.events.onBankClose}
            bankObj={selectedBank}
            visible={editBankVisible}
            {...this.props}
          />
          : null}


        {singleBankDrawer ?
          <SingleBankDayBook
            addBankCallback={this.events.addBankCallback}
            onBankClose={this.events.onBankClose}
            bankObj={selectedBank}
            visible={singleBankDrawer}
            {...this.props}
          />
          : null}

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllBanks)
