import React from 'react'
import {
  Form, Divider, Select, Icon, Row, Card, Col, Input, DatePicker, notification, Button
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import debounce from 'lodash/debounce'
import { InputBox } from '../../webComponent/appDes'
import { FilterChooseClient } from '../../webComponent/appDes'
import ChooseBank from '../../webComponent/ChooseBank'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import moment from 'moment'
import InvoiceReference from '../../webComponent/invoiceReference'
import { hideLoader, showLoader } from '../../../modules/actions'
import { AsyncStorage } from '../../../asyncStorage'
import { connect } from 'react-redux'

let saleObj = {}

class AddPaymentIn extends React.Component {
  constructor() {
    super()
    this.state = {
      bankKey: moment(),
      clientKey: moment() + 1,
      refKey: moment() + 2,
      clientName: '',
      clientGroupId: '',
      clientBalance: '',
      clientId: '',
      date: moment(),
      hideTypeaheadList: true,
      arrData: [],
      client: '',
      addClientModal: false,
      receiptNo: '',
      amount: '',
      payMethod: 'Cash',
      refNo: '',
      description: '',
      image: {},
      imageSelect: false,
      checkConnection: false,
      entryTime: moment().format('LLL'),
      showReference: false,
      referenceArr: []
    }
  }

  componentDidMount() {
    saleObj = {}
  }

  chooseClient = (item) => {
    this.setState({
      clientGroupId: item.clientGroupId,
      clientName: item.name,
      clientState: item.state,
      stateOfSupply: item.state,
      city: item.city,
      clientBalance: item.currentBalance ? parseInt(item.currentBalance) : 0,
      clientId: item.uid,
      clientGstNo: item.gstNumber,
      clientAddress: item.address
    })
  }

  _callBackBank = (data) => {
    this.setState({ ...data })
  }

  setLocalState = (e) => {
    let { value, name } = e.target
    this.setState({ [name]: value })
  }

  onChooseDate = (date, dateString) => {
    this.setState({ date: date })
  }
  _submitRef = (data) => {
    let { referenceArr } = this.state
    referenceArr.push(data)
    this.setState({ referenceArr })
  }

  resetAfterSave(type) {
    if (type == 'new') {

    }
    this.setState({
      bankKey: moment(),
      clientKey: moment() + 1,
      refKey: moment() + 2,
      clientName: '',
      clientGroupId: '',
      clientBalance: '',
      clientId: '',
      date: moment(),
      hideTypeaheadList: true,
      arrData: [],
      client: '',
      addClientModal: false,
      receiptNo: '',
      amount: '',
      payMethod: 'Cash',
      refNo: '',
      description: '',
      image: {},
      imageSelect: false,
      checkConnection: false,
      entryTime: moment().format('LLL'),
      showReference: false,
      referenceArr: [],

      paymentMode: '',
      station: null,
      eWayBillNo: null,
      grNo: null,
      receivedAmount: null,
      bankId: null,
      paymentType: null,
      bankType: null,
      paymentDetails: null,
      clientInfo: {},
      clientState: '',
      stateOfSupply: '',
      city: '',
      clientGstNo: '',
      clientAddress: '',
      stateCode: '',
      pinCode: ''
    })
    /*if (type == 'share') {
      this.getItems(sale.uid, type)
      return
    }*/
  }

  _save(type) {
    AsyncStorage.getBusiness().then((value) => {
      if (value !== null) {

        let cloneState = _.clone(this.state)
        delete cloneState.arr
        delete cloneState.arrData
        delete cloneState.bankArr

        cloneState.businessId = value.uid
        if (cloneState.date) {
          cloneState.dateString = moment(cloneState.date).format('DD-MMM-YYYY').toString()
        }
        cloneState = { ...cloneState, ...cloneState.clientInfo }

        if (!cloneState.clientName || !cloneState.clientId) {
          notification.warn({
            message: 'Choose Party'
          })
          return
        }


        if (!cloneState.bankId) {
          notification.warn({
            message: 'Please choose bank'
          })
          this.props.dispatch(hideLoader())
          return
        }
        if (cloneState.bankType == 'Bank' && !cloneState.paymentType) {
          notification.warn({
            message: 'Please choose payment type'
          })
          this.props.dispatch(hideLoader())
          return
        }

        if (!cloneState.referenceArr || (cloneState.referenceArr && cloneState.referenceArr.length == 0)) {
          notification.warn({
            message: 'Choose Invoices'
          })
          this.props.dispatch(hideLoader())
          return
        }

        if (!cloneState.amount || cloneState.amount == 0) {
          notification.warn({
            message: 'Enter Amount'
          })
          return
        }
        this.props.dispatch(showLoader())
        Request.AddPaymentIn(cloneState)
          .then((testData) => {
            saleObj = testData.sale

            notification.success({
              message: 'Payment-In Saved Successfully'
            })
            this.props.dispatch(hideLoader())

            /*let smsobj = {
              uid: testData.uid,
              type: "PAYMENT_IN",
              name: cloneState.clientName,
              msg: `Add Payment In:  \nClient = ${cloneState.clientName} \nTotal Amount = ${cloneState.amount}`
            };*/

            // Request.SendSms(smsobj)


            if (this.state.image && this.state.image.uri) {

              AsyncStorage.getUserData().then((userResp) => {
                if (userResp) {

                  let imageObj = {
                    businessId: value.uid,
                    linkId: testData.sale.uid,
                    type: 'PAYMENT_IN',
                    date: new Date(),
                    userId: userResp.uid
                  }

                  Request.uploadDocument(imageObj, this.state.image).then((resp1) => {
                    console.log(resp1)
                  }).catch((err) => {
                    console.log(err)
                  })
                }
              })
            }


            /*  if (type == 'share') {
                this.createPdf(type)
              }*/
            this.resetAfterSave('new')
            return

            // this.loadInvoiceCount()
          })
          .catch((e) => {
            console.log(e, '+++')
            notification.error({
              message: 'Try again'
            })
            this.props.dispatch(hideLoader())
            return
          })

      } else {
        notification.error({
          message: 'Business Setting Not Set'
        })
        return
      }
    })


  }


  render() {
    let { date, referenceArr, clientId } = this.state
    return (
      <PageHeaderWrapper
        title={'Add Payment In'}
        content={''}>
        <Card extra={<div className={'actionGroup'}>
          <Button type="primary" onClick={
            debounce(() => {
              this._save('share')
            }, 1000)
          }>SAVE</Button>

        </div>}>
          <Row gutter={16}>
            <Col span={8}>
              <InputBox>
                <FilterChooseClient allowNew={true} _callbackClient={this.chooseClient} showBalance={true}
                                    key={this.state.clientKey}/>
              </InputBox>
            </Col>
            <Col span={24}>
              <ChooseBank {...this.props} key={this.state.bankKey}
                          callBackBank={this._callBackBank} {...this.state}/>
            </Col>
            <Col span={8}>
              <InputBox title={'Received Amount'}>
                <Input onChange={this.setLocalState} type={'number'} value={this.state.amount} name={'amount'}
                       placeholder={'Received Amount'}/>
              </InputBox>
            </Col>
            <Col span={8}>
              <InputBox title={'Received Amount'}>
                <DatePicker onChange={this.onChooseDate} value={date} placeholder={'Date of sale'}/>
              </InputBox>
            </Col>
            <Col span={24} className={'mb20'}>
              <InvoiceReference key={this.state.refKey}
                                referenceArr={referenceArr}
                                pageType={'Payment_in'}
                                clientId={clientId}
                                submitRef={this._submitRef}
              />
            </Col>
            <Col span={16}>
              <InputBox title={'Description (Optional)'}>
                <Input onChange={this.setLocalState} name={'description'} value={this.state.description}></Input>
              </InputBox>
            </Col>
            <Col span={8}><InputBox title={'Entry Time'}>
              <Input onChange={this.setValue} name={'entryTime'} placeholder={'Entry Time'}
                     value={this.state.entryTime}/>
            </InputBox>
            </Col>
            <Col span={8}>
              <InputBox title={'Choose Picture'} className={'rowFlex'}>
                <Input type={'file'} accept="image/*" onChange={(e) => {
                  if (e.target.files && e.target.files.length) {
                    this.setState({ image: e.target.files[0] })
                  }

                }}/>
                {this.state.image && this.state.image.uri ?
                  <a className={'linkBtn'} target={'_blank'} href={this.state.image.uri}>
                    <Icon type={'eye'}></Icon>
                  </a> : null}
              </InputBox>
            </Col>
          </Row>
        </Card>
      </PageHeaderWrapper>
    )
  }
}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPaymentIn)
