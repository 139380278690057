import { push } from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'

import Dashboard from './containers/dashboard'
import Undercons from './containers/undercons'
import AllUsers from './containers/users/all'
import AddUsers from './containers/users/add'
import AllSales from './containers/Sales/all'
import AllPurchase from './containers/Purchase/all'
import AddPurchase from './containers/Purchase/add'
import EditPurchase from './containers/Purchase/edit'
import AddSale from './containers/Sales/add'
import EditSale from './containers/Sales/edit'
import AllParty from './containers/Party/all'
import AllProduct from './containers/Items/all'
import AllSettings from './containers/Setting/settings'
import AllUnits from './containers/Setting/units/AllUnits'
import AllGroup from './containers/Setting/groups/All'
import AllCategory from './containers/Setting/category/All'
import AllWarranty from './containers/Setting/warranty/All'
import AllSaleCategory from './containers/Setting/saleCategory/All'
import AllTransport from './containers/Setting/transport/All'
import AllVehicle from './containers/Setting/vehicle/All'
import AllBrands from './containers/Setting/brands/All'
import AllPrefix from './containers/Setting/prefix/All'
import EditBusiness from './containers/Business/edit'

import AddCreditNote from './containers/CreditNote/add'
import EditCreditNote from './containers/CreditNote/edit'
import AddEstimate from './containers/Estimate/add'
import EditEstimate from './containers/Estimate/edit'
import AddDebitNote from './containers/DebitNote/add'
import EditDebitNote from './containers/DebitNote/edit'
import AllReport from './containers/Reports/AllReport'
import DayBook from './containers/Reports/DayBook'
import CustomReport from './containers/Reports/CustomReport'
import ProfitAndLoss from './containers/Reports/profitAndLoss'
import BalanceSheet from './containers/Reports/balanceSheet'
import PaymentAndReceipt from './containers/Reports/paymentAndReceipt'
import AllBanks from './containers/Banks/all'
import AddContraEntry from './containers/ContraEntry/add'
import EditContraEntry from './containers/ContraEntry/edit'
import AllContraEntry from './containers/ContraEntry/all'
import ClientActivity from './containers/Party/clientActivity'
import Gstr1 from './containers/Reports/gstReports/gstr1'
import Gstr2 from './containers/Reports/gstReports/gstr2'
import Gstr3 from './containers/Reports/gstReports/gstr3'
import AllJournalEntry from './containers/journalEntry/all'
import AddPaymentIn from './containers/paymentIn/add'
import AllSubBrand from './containers/Setting/subBrand/All'
import EditProduct from './containers/Items/edit'

const menu = [
  {
    'path': '/update-business',
    'name': 'Update Business',
    'icon': 'dashboard',
    'key': 'updateBusiness',
    'homepage': true,
    'component': EditBusiness,
    'authority': [
      'admin',
      'user'
    ]
  },
  {
    'path': '/dashboard',
    'name': 'Dashboard',
    'icon': 'dashboard',
    'key': 'dashboard',
    'homepage': true,
    'component': Dashboard,
    'authority': [
      'admin',
      'user'
    ]
  },
  /* {
     'path': '/users',
     'name': 'Users',
     'icon': 'user',
     'key': 'users',
     'component': Undercons,
     'authority': [
       'admin',
       'user'
     ],
     'children': [
       {
         'path': '/users/add',
         'name': 'Add User',
         'title': 'Add User',
         'component': AddUsers
       },
       {
         'path': '/users/all',
         'name': 'All Users',
         'title': 'All Users',
         'component': AllUsers
       }
     ]
   },*/
  {
    'path': '/sale',
    'name': 'Sale',
    'icon': 'user',
    'key': 'sale',
    'component': Undercons,
    'authority': [
      'admin',
      'user'
    ],
    'children': [
      {
        'path': '/sale/add',
        'name': 'Add Sale',
        'title': 'Add Sale',
        'key': 'addSale',
        'component': AddSale
      },
      {
        'path': '/sale/edit/:id',
        'name': 'Edit Sale',
        'title': 'Edit Sale',
        'key': 'editSale',
        'component': EditSale,
        'dontShowOnMenu': true
      },
      {
        'path': '/sale/all',
        'name': 'All Sale',
        'title': 'All Sale',
        'key': 'allSales',
        'component': AllSales
      }
    ]
  },
  {
    'path': '/purchase',
    'name': 'Purchase',
    'icon': 'user',
    'key': 'purchase',
    'component': Undercons,
    'authority': [
      'admin',
      'user'
    ],
    'children': [
      {
        'path': '/purchase/add',
        'name': 'Add Purchase',
        'title': 'Add Purchase',
        'key': 'addPurchase',
        'component': AddPurchase
      },
      {
        'path': '/purchase/edit/:id',
        'name': 'Edit purchase',
        'title': 'Edit purchase',
        'key': 'editPurchase',
        'component': EditPurchase,
        'dontShowOnMenu': true
      },
      {
        'path': '/purchase/all',
        'name': 'All Purchases',
        'title': 'All Purchases',
        'key': 'allPurchases',
        'component': AllPurchase
      }
    ]
  },
  {
    'path': '/paymentIn',
    'name': 'Payment IN',
    'icon': 'user',
    'key': 'paymentIn',
    'component': Undercons,
    'authority': [
      'admin',
      'user'
    ],
    'dontShowOnMenu': true,
    'children': [
      {
        'path': '/paymentIn/add',
        'name': 'Add Payment In',
        'title': 'Add Payment In',
        'key': 'addPaymentIn',
        'component': AddPaymentIn
      }
      /*{
        'path': '/purchase/edit/:id',
        'name': 'Edit purchase',
        'title': 'Edit purchase',
        'key': 'editPurchase',
        'component': EditPurchase,
        'dontShowOnMenu': true
      }*/
    ]
  },
  {
    'path': '/creditNote',
    'name': 'Credit Note',
    'icon': 'user',
    'key': 'creditNote',
    'component': Undercons,
    'authority': [
      'admin',
      'user'
    ],
    'dontShowOnMenu': true,
    'children': [
      {
        'path': '/creditNote/add',
        'name': 'Add Credit Note',
        'title': 'Add Credit Note',
        'key': 'addCreditNote',
        'component': AddCreditNote
      },
      {
        'path': '/creditNote/edit/:id',
        'name': 'Edit Credit Note',
        'title': 'Edit Credit Note',
        'key': 'editCreditNote',
        'component': EditCreditNote,
        'dontShowOnMenu': true
      }
    ]
  },
  {
    'path': '/debitNote',
    'name': 'Debit Note',
    'icon': 'user',
    'key': 'debitNote',
    'component': Undercons,
    'authority': [
      'admin',
      'user'
    ],
    'dontShowOnMenu': true,
    'children': [
      {
        'path': '/debitNote/add',
        'name': 'Add Debit Note',
        'title': 'Add Debit Note',
        'key': 'addDebitNote',
        'component': AddDebitNote
      },
      {
        'path': '/debitNote/edit/:id',
        'name': 'Edit Debit Note',
        'title': 'Edit Debit Note',
        'key': 'editDebitNote',
        'component': EditDebitNote,
        'dontShowOnMenu': true
      }
    ]
  },
  {
    'path': '/estimate',
    'name': 'Estimate',
    'icon': 'user',
    'key': 'estimate',
    'component': Undercons,
    'authority': [
      'admin',
      'user'
    ],
    'dontShowOnMenu': true,
    'children': [
      {
        'path': '/estimate/add',
        'name': 'Add Estimate',
        'title': 'Add Estimate',
        'key': 'addEstimate',
        'component': AddEstimate
      },
      {
        'path': '/addEstimate/edit/:id',
        'name': 'Edit Estimate',
        'title': 'Edit Estimate',
        'key': 'editEstimate',
        'component': EditEstimate
      }
    ]
  },
  {
    'path': '/contraEntry',
    'name': 'Contra Entry',
    'icon': 'user',
    'key': 'contraEntry',
    'component': Undercons,
    'authority': [
      'admin',
      'user'
    ],
    'dontShowOnMenu': true,
    'children': [
      {
        'path': '/contraEntry/add',
        'name': 'Add Contra Entry',
        'title': 'Add Contra Entry',
        'key': 'addContraEntry',
        'component': AddContraEntry
      },
      {
        'path': '/contraEntry/edit/:id',
        'name': 'Edit Contra Entry',
        'title': 'Edit Contra Entry',
        'key': 'editContraEntry',
        'component': EditContraEntry
      },
      {
        'path': '/contraEntry/all',
        'name': 'All Contra Entry',
        'title': 'All Contra Entry',
        'key': 'allContraEntry',
        'component': AllContraEntry
      }
    ]
  },
  {
    'path': '/journalEntry',
    'name': 'journalEntry',
    'icon': 'user',
    'key': 'journalEntry',
    'component': Undercons,
    'authority': [
      'admin',
      'user'
    ],
    'dontShowOnMenu': true,
    'children': [
      {
        'path': '/journalEntry/all',
        'name': 'All Journal Entry',
        'title': 'All Journal Entry',
        'key': 'allJournalEntry',
        'component': AllJournalEntry
      }
      /*{
        'path': '/contraEntry/edit/:id',
        'name': 'Edit Contra Entry',
        'title': 'Edit Contra Entry',
        'key': 'editContraEntry',
        'component': EditContraEntry
      },
      {
        'path': '/contraEntry/all',
        'name': 'All Contra Entry',
        'title': 'All Contra Entry',
        'key': 'allContraEntry',
        'component': AllContraEntry
      }*/
    ]
  },
  {
    'path': '/all-product',
    'name': 'All Item',
    'icon': 'user',
    'key': 'AllProduct',
    'component': AllProduct,
    'authority': [
      'admin',
      'user'
    ]
  },
  {
    'path': '/edit-product/:id',
    'name': 'Edit Item',
    'icon': 'user',
    'key': 'editProduct',
    'dontShowOnMenu': true,
    'component': EditProduct,
    'authority': [
      'admin',
      'user'
    ]
  },
  {
    'path': '/reports',
    'name': 'All Reports',
    'icon': 'user',
    'key': 'AllReport',
    'component': AllReport,
    'authority': [
      'admin',
      'user'
    ]
  },
  {
    'name': 'Reports',
    'icon': 'user',
    'key': 'reports',
    'authority': [
      'admin',
      'user'
    ],
    'dontShowOnMenu': true,
    'children': [
      {
        'path': '/reports/daybook',
        'name': 'Day Book',
        'title': 'Day Book',
        'key': 'dayBook',
        'component': DayBook
      },
      {
        'path': '/reports/customReport',
        'name': 'Custom Report',
        'title': 'Custom Report',
        'key': 'customReport',
        'component': CustomReport
      },
      {
        'path': '/reports/allBank',
        'name': 'All Bank',
        'title': 'All Bank',
        'key': 'allBank',
        'component': AllBanks
      },
      {
        'path': '/bankDayBook',
        'name': 'Bank Day Book',
        'title': 'Bank Day Book',
        'key': 'bankDayBook',
        'component': AllBanks
      },
      {
        'path': '/reports/profitAndLoss',
        'name': 'Profit and Loss',
        'title': 'Profit and Loss',
        'key': 'profitAndLoss',
        'component': ProfitAndLoss
      },
      {
        'path': '/reports/balanceSheet',
        'name': 'Balance Sheet',
        'title': 'Balance Sheet',
        'key': 'balanceSheet',
        'component': BalanceSheet
      },
      {
        'path': '/reports/paymentAndReceipt',
        'name': 'Payment and Receipt',
        'title': 'Payment and Receipt',
        'key': 'paymentAndReceipt',
        'component': PaymentAndReceipt
      },
      {
        'path': '/reports/gstr1Report',
        'name': 'GSTR 1 Report',
        'title': 'GSTR 1 Report',
        'key': 'gstr1Report',
        'component': Gstr1
      },
      {
        'path': '/reports/gstr2Report',
        'name': 'GSTR 2 Report',
        'title': 'GSTR 2 Report',
        'key': 'gstr2Report',
        'component': Gstr2
      },
      {
        'path': '/reports/gstr3Report',
        'name': 'GSTR 3 Report',
        'title': 'GSTR 3 Report',
        'key': 'gstr3Report',
        'component': Gstr3
      }
    ]
  },
  {
    'path': '/all-party',
    'name': 'All Party',
    'icon': 'user',
    'key': 'AllParty',
    'component': AllParty,
    'authority': [
      'admin',
      'user'
    ]
  },
  {
    'path': '/settings',
    'name': 'All Settings',
    'icon': 'setting',
    'key': 'settings',
    'homepage': true,
    'component': AllSettings,
    'authority': [
      'admin',
      'user'
    ]
  },
  {
    'path': '/setting',
    'name': 'setting',
    'icon': 'setting',
    'key': 'setting',
    'component': Undercons,
    'authority': [
      'admin',
      'user'
    ],
    dontShowOnMenu: true,
    'children': [
      {
        'path': '/setting/all-unit',
        'name': 'All Unit',
        'title': 'All Unit',
        'key': 'AllUnits',
        'component': AllUnits
      },
      {
        'path': '/setting/all-group',
        'name': 'All Group',
        'title': 'All Group',
        'key': 'AllGroup',
        'component': AllGroup
      },
      {
        'path': '/setting/all-category',
        'name': 'All Category',
        'title': 'All Category',
        'key': 'AllCategory',
        'component': AllCategory
      },
      {
        'path': '/setting/all-warranty',
        'name': 'All Warranty',
        'title': 'All Warranty',
        'key': 'AllWarranty',
        'component': AllWarranty
      },
      {
        'path': '/setting/all-saleCategory',
        'name': 'All Sale Category',
        'title': 'All Sale Category',
        'key': 'AllSaleCategory',
        'component': AllSaleCategory
      },
      {
        'path': '/setting/all-transport',
        'name': 'All Transport',
        'title': 'All Transport',
        'key': 'AllTransport',
        'component': AllTransport
      },
      {
        'path': '/setting/all-vehicle',
        'name': 'All Vehicle',
        'title': 'All Vehicle',
        'key': 'AllVehicle',
        'component': AllVehicle
      },
      {
        'path': '/setting/all-brands',
        'name': 'All Brands',
        'title': 'All Brands',
        'key': 'AllBrands',
        'component': AllBrands
      },
      {
        'path': '/setting/all-prefix',
        'name': 'All Prefix',
        'title': 'All Prefix',
        'key': 'AllPrefix',
        'component': AllPrefix
      },

      {
        'path': '/setting/all-sub-brands',
        'name': 'All Sub Brands',
        'title': 'All Sub Brands',
        'key': 'allSubBrands',
        'component': AllSubBrand
      }

      /*,
      {
        'path': '/setting/bankDayBook',
        'name': 'Bank Day Book',
        'title': 'Bank Day Book',
        'key': 'BankDayBook',
        'component': AllBanks
      }*/

    ]
  },
  {
    'path': '/setting',
    'name': 'hiddenPath',
    'icon': 'setting',
    'key': 'hiddenPath',
    'dontShowOnMenu': true,
    'component': Undercons,
    'authority': [
      'admin',
      'user'
    ],
    'children': [
      {
        'path': '/clientActivity/:id',
        'name': 'Client Activity',
        'title': 'Client Activity',
        'key': 'clientActivity',
        'component': ClientActivity
      }
      /* {
         'path': '/purchase/add',
         'name': 'Add Purchase',
         'title': 'Add Purchase',
         'key': 'addPurchase',
         'component': AddPurchase
       },
       {
         'path': '/purchase/edit/:id',
         'name': 'Edit purchase',
         'title': 'Edit purchase',
         'key': 'editPurchase',
         'component': EditPurchase,
         'dontShowOnMenu': true
       },*/
    ]
  }


]

//<editor-fold desc="Functions Exports">

export const getUrlObject = keyString => {
  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}

export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlQueryPushWrapper = (keyString, query) => {
  return push(getUrlQuery(keyString, query))
}

export const getUrlPath = (keyString, params) => {
  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&')
  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {
  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {
  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)
    return path.test(route)
  }
  return { error: true }
}

export const getUrlQuery = (keyString, params) => {
  if (!params) params = {}
  let keyArr = keyString.split('.')
  let val = _.find(menu, p => p.key === keyArr[0])
  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }
  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&')

  return `${val.path}?${queryString}`
}


//</editor-fold>


export default menu

