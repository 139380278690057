import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col
} from 'antd'
import styles from './styles.less'
import { getUrlPushWrapper } from '../../routes'
import { connect } from 'react-redux'

class Settings extends Component {
  constructor(props) {
    super(props)
  }

  openPage(route) {
    const { dispatch } = this.props
    dispatch(getUrlPushWrapper(route))
  }

  openBankRoute(route) {
    const { dispatch } = this.props
    dispatch(getUrlPushWrapper(route, { pageType: 'report', title: 'REPORT' }))
  }

  render() {


    return (
      <div className={'asdf'}>
        <Row gutter={16}>
          <Col span={6}>
            <Card className={'alignCenter'} onClick={() => this.openPage('sale.allSales')}>
              <a>
                ALL SALES
              </a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={'alignCenter'} onClick={() => this.openPage('purchase.allPurchases')}>
              <a>ALL PURCHASE</a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={'alignCenter'} onClick={() => this.openPage('reports.customReport')}>
              <a>CUSTOM REPORT</a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={'alignCenter'} onClick={() => this.openPage('reports.dayBook')}>
              <a>
                DAY BOOK
              </a>
            </Card>
          </Col>
        </Row>
        <br/>
        <Row gutter={16}>
          <Col span={6}>
            <Card className={'alignCenter'} onClick={() => this.openBankRoute('reports.allBank')}>
              <a>
                ALL BANKS
              </a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={'alignCenter'} onClick={() => this.openPage('reports.balanceSheet')}>
              <a>
                BALANCE SHEET
              </a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={'alignCenter'} onClick={() => this.openPage('reports.profitAndLoss')}>
              <a>
                PROFIT AND LOSS
              </a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={'alignCenter'} onClick={() => this.openPage('reports.gstr1Report')}>
              <a>
                GSTR 1 REPORT
              </a>
            </Card>
          </Col>

        </Row>
        <br/>
        <Row gutter={16}>
          <Col span={6}>
            <Card className={'alignCenter'} onClick={() => this.openPage('reports.gstr2Report')}>
              <a>
                GSTR 2 REPORT
              </a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={'alignCenter'} onClick={() => this.openPage('reports.gstr3Report')}>
              <a>
                GSTR 3 REPORT
              </a>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={'alignCenter'} onClick={() => this.openPage('reports.paymentAndReceipt')}>
              <a>
                PAYMENT & RECEIPT ACCOUNT
              </a>
            </Card>
          </Col>

        </Row>
      </div>
    )
  }

}

const mapStateToProps = ({ global, router }) => ({})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings)


