import React, { Component } from 'react'
import _ from 'lodash'
import { Select, Input, Button, Icon, Col, Row } from 'antd'
import Request from '../../request'
import { InputBox } from '../webComponent/appDes'
import AddBank from '../Banks/add'

const { TextArea } = Input
const { Option } = Select

class ChooseBank extends React.Component {
  constructor() {
    super()
    this.state = {
      bankArr: [{ name: 'None', uid: '' }],
      bankId: '',
      paymentTypesArr: []
    }

  }

  componentDidMount() {
    this.loadBanks()
    this.loadPaymentTypes()
  }

  loadPaymentTypes() {
    let paymentTypesArr = []
    Request.AllPaymentTypes().then((resp) => {
      _.each(resp, (item) => {
        if (item.label !== 'None') {
          paymentTypesArr.push(item)
        }
      })
      this.setState({ paymentTypesArr })
    })
  }

  loadBanks() {
    let bankArr = [{ name: 'None', uid: '' }]
    let bankName = ''
    let { bankId = '', bankType = '', paymentType = '', paymentDetails = '' } = this.props
    Request.AllBank().then((resp) => {
      _.each(resp, (item) => {
        bankArr.push(item)
      })
      bankName = _.find(bankArr, (item) => {
        return item && item.uid == bankId
      })
      let obj = {
        bankId, bankType, paymentType, paymentDetails, bankArr
      }
      if (bankName && bankName.uid) {
        obj.bankName = bankName.name
      }
      this.setState({ ...obj })
    })
  }


  openAddBank() {
    /* this.props.navigation.navigate('AddBank', {
       war_call_back: (nextProps) => {
         this.loadBanks()
         setTimeout(() => {
           this.setState({ bankId: nextProps.bank.uid, bankType: nextProps.bank.type, paymentType: '' }, () => {
             this.bankCallBack()
           })
         }, 1500)
       }
     })*/
  };

  bankCallBack() {
    this.props.callBackBank(this.state)
  }

  filterOption = (input, options) => {
    return options.props.children.match(new RegExp(`^${input}`, 'ig'))
  }


  setBankId = (uid) => {
    let { bankArr } = this.state
    let item = _.find(bankArr, (value) => {
      return value.uid == uid
    })

    this.setState({
      bankId: item && item.uid ? item.uid : '',
      bankName: item && item.name ? item.name : '',
      bankType: item && item.type ? item.type : '',
      paymentType: ''
    }, () => {
      this.bankCallBack()
    })
  }

  choosePaymentType = (itemValue) => {
    this.setState({ paymentType: itemValue }, () => {
      this.bankCallBack()
    })
  }

  AddBankFxn = () => {
    let { dispatch } = this.props
    this.setState({ addBankVisible: true })
  }

  onBankClose = () => {
    this.setState({ addBankVisible: false })
  }
  addBankCallback = (data) => {
    this.setState({ addBankVisible: false })
    this.loadBanks()
    setTimeout(() => {
      this.setState({
        bankId: data && data.uid ? data.uid : '',
        bankType: data && data.type ? data.type : '',
        bankName: data && data.name ? data.name : '',
        paymentType: ''
      }, () => {
        this.bankCallBack()
      })
    }, 500)
  }


  render() {
    let { bankArr, paymentTypesArr, addBankVisible, bankId } = this.state
    return (
      <Row gutter={16}>
        <Col span={8}>
          <InputBox title={'Bank'} className={'rowFlex'}>
            <Select showSearch={true}
                    value={this.state.bankName}
                    filterOption={this.filterOption}
                    onChange={this.setBankId}
                    optionFilterProp="children">
              {bankArr.map((item, key) => {
                return (
                  <Option key={item.uid} value={item.uid}>{item.name}</Option>
                )
              })}
            </Select>
            <Button onClick={() => this.setState({ addBankVisible: true })}>
              <Icon type={'plus'}/>
            </Button>
          </InputBox>
        </Col>
        {this.state.bankType && this.state.bankType == 'Bank' ?
          <Col span={8}>
            <InputBox title={'Payment Type'}>
              <Select showSearch={true}
                      filterOption={this.filterOption}
                      value={this.state.paymentType}
                      onChange={this.choosePaymentType}
                      optionFilterProp="children">
                {paymentTypesArr.map((item, key) => {
                  return (
                    <Option key={item.label} value={item.label}>{item.label}</Option>
                  )
                })}
              </Select>
            </InputBox>
          </Col> :
          null}

        <Col span={8}>
          <InputBox title={'Payment Details (Optional)'}>
            <Input value={this.state.paymentDetails}
                   onChange={(e) => this.setState({ paymentDetails: e.target.value }, () => {
                     this.bankCallBack()
                   })}>
            </Input>
          </InputBox>
        </Col>
        {addBankVisible ?
          <AddBank
            addBankCallback={this.addBankCallback}
            onBankClose={this.onBankClose}
            visible={addBankVisible}
            {...this.props}
          />
          : null}
      </Row>
    )
  }

}

export default ChooseBank
