import GlobalShortCut from './globalShortCut'

export const GetShortCut = (dispatch) => {
  let obj = {
    keyMap: GlobalShortCut.GlobalKeyMap,
    handlers: GlobalShortCut.GlobalHandlers(dispatch)
  }
  let { pathname } = window.location
  pathname = pathname.split('/').pop()
  return { ...obj }
}
