import { API_URL } from '../../../request'

export const addSubBrandUrl = () => {
  return `${API_URL}/api/addSubBrand`
}
export const subBrandListUrl = () => {
  return `${API_URL}/api/subBrandList`
}

export const singleSubBrandUrl = () => {
  return `${API_URL}/api/singleSubBrand`
}

export const updateSubBrandUrl = () => {
  return `${API_URL}/api/updateSubBrand`
}
