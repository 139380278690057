import _ from 'lodash'

const itemsList = (data, isCgst, showDiscount) => {
  return new Promise((resolve) => {
    let getAmount = (value) => {
      if (typeof value == 'number') {
        return value ? value.toFixed(2) : ''
      } else {
        return value
      }

    }

    let quantityValue = (value) => {
      if (Number.isInteger(value)) {
        return value
      } else {
        return value ? value.toFixed(3) : ''
      }
    }

    let gstField = (item) => {
      if (item.taxExempted) {
        return isCgst ?
          `<td colspan="2">Exempted</td><td colspan="2">Exempted</td>` : `<td colspan="2">Exempted</td>`
      } else {
        return isCgst ?
          `<td style="text-align:right"> ${getAmount(item.gst / 2)}%</td><td style="text-align:right">${getAmount(item.gstAmount / 2)} </td>
                    <td style="text-align:right"> ${getAmount(item.gst / 2)}%</td><td style="text-align:right">${getAmount(item.gstAmount / 2)} </td>`
          : `<td style="text-align:right"> ${getAmount(item.gst)}%</td><td style="text-align:right">${getAmount(item.gstAmount)}</td>`
      }
    }

    let discountField = (item) => {
      if (item.discountAmount && item.secondDiscountAmount) {
        return `${getAmount(item.discountAmount)} ${item.discount ? `(${item.discount}%)` : ''}+ ${'\n'}${getAmount(item.secondDiscountAmount)} ${item.secondDiscountPercent ? ` (${item.secondDiscountPercent}%)` : ''}`
      } else if (item.discountAmount) {
        return item.discountAmount ? `${getAmount(item.discountAmount)} ${item.discount ? ` (${item.discount}%)` : ''}` : ''
      } else if (item.secondDiscountAmount) {
        return item.secondDiscountAmount ? `${getAmount(item.secondDiscountAmount)} ${item.secondDiscountPercent ? ` (${item.secondDiscountPercent}%)` : ''}` : ''
      } else {
        return ''
      }
    }

    let gstHeader = (
      isCgst ? '<th colspan=\'2\' class=\'center\'>CGST (%)</th><th colspan=\'2\' class=\'center\'>SGST (%)</th>' : '<th colspan=\'2\' class=\'center\'>IGST (%)</th>'
    )

    let gstRow = (
      isCgst ? '<th>Rate%</th><th>Amt</th><th>Rate%</th><th>Amt</th>' : '<th>Rate%</th><th>Amt</th>'
    )

    let itemTable = ''

    _.each(data.items, (item, index) => {
      if (!item.price) {
        item.price = item.salePrice
      }
      let aa = `class="${index % 2 == 0 ? 'evenCls' : ''}"`
      itemTable += `<tr style="height: 5px;" ${aa}>
                        <td class='capCase flexStart'>${index + 1}</td>
                        <td style="width:150px">
                            ${item.productName} <br/>
                             <small>${item.itemWarranty && (data.printType == 'Simple' || data.printType == 'Hidden' || data.printType == 'itemWarranty') ? `(${item.itemWarranty})` : ''}</small>
                        </td>
                        <td style="width:50px">${item.hsnCode}</td>
                        <td style="text-align:right">${quantityValue(item.quantity)}</td>
                        <td style="width:30px">${item.unitName}</td>
                        ${data.pageType == 'PURCHASE' ? `<td style="text-align:right">${item.lastPurchasePrice ? getAmount(item.lastPurchasePrice) : ''}</td>` : ''}
                        <td style="text-align:right">${getAmount(item.price)}</td>
                        ${showDiscount ? `<td style="text-align:right">${discountField(item) ? discountField(item) : ''}</td>` : ''}
                        ${gstField(item)}
                        <td style="text-align:right; width:120px">${getAmount(item.netAmount)} (${item.gstInclusive ? 'Inc' : 'Ex'})</td>
                    </tr>`
    })

    _.times(12 - data.items.length, (item) => {
      itemTable += `<tr style="height: 5px">
                        <td class='capCase'></td>
                        <td style="width:150px"></td>
                        <td style="width:50px"></td>
                        <td></td>
                        <td></td>
                        ${data.pageType == 'PURCHASE' ? `<td></td>` : ''}
                        <td></td>
                        ${showDiscount ? `<td></td>` : ''}
                        
                        ${isCgst ? `<td colspan="2"></td><td colspan="2"></td>` : `<td colspan="2"></td>`}
                        <td></td>
                    </tr>`
    })

    let html = `<div class="row itemList">
        <table class="itemTable font10">
            <tr>
                <th rowspan="2">S.No</th>
                <th style="width:150px" rowspan="2">Description</th>
                <th rowspan="2" style="width:50px">HSN/SAC Code</th>
                <th rowspan="2">Qty.</th>
                <th rowspan="2">Unit</th>
                ${data.pageType ? '<th rowspan=\'2\'>Last Price</th>' : ''}
                <th rowspan="2">Price</th>
                ${showDiscount ? '<th rowspan=\'2\' style=\'width: 100px;\'>Discount</th>' : ''}
                
                ${gstHeader}
                <th rowspan="2" style="text-align:right;">Amount</th>
            </tr>
            <tr>
                ${gstRow}
            </tr>
            ${itemTable}
            <tr>
                <td>
                   
                </td>
                <td style="width:200px">
                   
                </td>
                <td class="b right">
                    Grand Total
                </td>
                <td class="border-bottom">
                    ${data.totalQuantity}
                </td>
                <td>
                    Unit
                </td>
                ${data.pageType == 'PURCHASE' ? `<td class="right"></td>` : ``}
                ${isCgst ? '<td colspan=\'3\'></td>' : '<td colspan=\'1\'></td>'}
                ${showDiscount ? `<td colspan="3" class="right">₹</td>` : `<td colspan="2" class="right">₹</td>`}
                <td>
                    ${data.totalAmount}
                </td>
            </tr>
        </table>
    </div>`
    resolve(html)
  })
}

export default itemsList
