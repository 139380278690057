import PrintStyle from './style'
import PrintHeader from './header'
import CustomerInfo from './customerInfo'
import ItemsList from './itemList'
import TaxList from './taxList'
import Warranty from './warranty'
import Footer from './footer'
import { TaxCal } from './_utils'
import _ from 'lodash'
import { AsyncStorage } from '../../asyncStorage'

const PrintHtml = async (data) => {
  let businessDetails = await AsyncStorage.getBusiness()
  let isCgst = data.stateOfSupply == 'Haryana'
  let showDiscount = true
  if (data.showDiscount !== undefined) {
    showDiscount = data.showDiscount
  }
  if (showDiscount) {
    let showFieldDis = _.find(data.items, (item) => {
      if (item.discountAmount || item.secondDiscountAmount) {
        return true
      }
    })
    if (!showFieldDis || (showFieldDis && showFieldDis.length == 0)) {
      showDiscount = false
    }
  }
  let singObj = {
    sign: data.signature ? data.signature : '',
    signDate: data.signDate ? data.signDate : '',
    signMobile: data.signMobile ? data.signMobile : '',
    signName: data.signName ? data.signName : ''
  }

  return new Promise(async (resolve) => {
    const html = `
    <!doctype html>
   <html>
   <head>
   <meta charset="utf-8">
   <title>Sale</title>
    ${PrintStyle}
</head>
<body class="container">
    <page size="A4">
    ${await PrintHeader(data.title)}
    ${await CustomerInfo(data.invoice)}
    ${await ItemsList(data, isCgst, showDiscount)}
    ${await Warranty(data)}
    ${await TaxList(TaxCal(data.items), data, isCgst)}
    ${await Footer(singObj, businessDetails)}
    </page>
</body>
</html>`

    resolve(html)
  })

}

export default PrintHtml
