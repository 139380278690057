import React from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Divider,
  Tooltip,
  Checkbox, Descriptions
} from 'antd'
import _ from 'lodash'
import Async from 'async'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader, showPageLoad, hidePageLoad } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { AsyncStorage } from '../../../asyncStorage'
import { createMatchSelector } from 'connected-react-router'
import { StateList, ReloadSaleItems, UnitCodeList } from '../../../components/_utils/_utils'
import ChooseClientComponent from '../../webComponent/ChooseClientComponent'
import AddSaleCategory from '../../Setting/saleCategory/Add'
import ChooseTransport from '../../webComponent/ChooseTransport'
import ItemListComponent from '../../webComponent/ItemListComponent'
import ChooseBank from '../../webComponent/ChooseBank'
import { InputBox } from '../../webComponent/appDes'
import { getPushPathWrapper, getUrlParams } from '../../../routes'
import debounce from 'lodash/debounce'
import { v4 as uuid } from 'uuid'
import PrintHtml from '../../html/index'
import { HtmlPrint } from '../../html/print'
import GenerateEwayBill from '../generateEwayBill'
import AddSubClient from '../addSubClient'
const { TextArea } = Input

let printSaleObj = {}
// inputSchema.fields


const FormItem = Form.Item
const { Option } = Select


let html = null
let oldItems = []
let oldBalance = 0
let saleObj = {}
let saleData = {} // for store after save sale data
let fff = ''
let removeId = null
let editItem = {}
let printType = 'Simple'


@Form.create()
class AddSale extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      qmrp: false,
      qws: false,
      qsp: true,
      state: 'Select',
      clientBalance: '0',
      date: moment().format('DD-MMM-YYYY'),
      arr: [],
      isDateTimePickerVisible: false,
      paymentMode: '',
      hideTypeahead: true,
      warmodal: false,
      accordionOpen: true,
      addClientModal: false,
      addSubClientModal: false,
      invoiceDiscount: null,
      removeItemModal: false,
      totalQuantity: '0',
      subTotal: '0',
      saleCategory: '',
      // items: [],
      invoiceNo: null,
      arrData: [],
      warrArr: [],
      saleCatArr: [],
      clientId: null,
      clientName: null,
      refNo: null,
      cashSale: false,
      image: {},
      imageSelect: false,
      checkConnection: false,
      warranty: 'None',
      checkedBy: null,
      packedBy: null,
      deliveredBy: null,
      cashBy: null,
      reverseCharge: false,
      clientInfo: {},
      bankArr: [{ name: 'None', uid: '' }],
      entryTime: moment().format('LLL'),
      vehicleKey: moment(),
      bankKey: moment() + 1,
      itemListKey: moment() + 2,
      clientKey: moment() + 3,
      showEwayBillNote: false,
      displayInvoiceNo: '',
      saveType: '',
      items: [],
      generateEwayBillObj: {},
      showEwayBillDrawer: false

    }


  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {

        dispatch(showLoader())

        let x = await Request.addUser(valData)

        dispatch(hideLoader())

        if (!x.error) {
          notification.success({
            message: 'Users added successfully'
          })
          this.props.form.setFieldsValue({})

        } else {
          notification.error({
            message: 'Error Saving sale',
            description: x.message
          })
        }

      }
    })
  }

  componentDidMount() {

    this.loadWarr()
    this.loadSaleCat()

  }

  componentWillMount() {
    this.fetchInfo()
  }


  async fetchInfo() {
    let data = await getUrlParams(
      'sale.editSale',
      this.props.pathname
    )
    if (data && data.id) {
      this.getSaleItems(data.id)
    }

  }


  calculation = () => {


    let totalQt = 0
    let totalAMt = 0
    let taxableAmount = 0
    _.each(this.state.items, (item) => {
      if (item.quantity) {
        totalQt = totalQt + parseFloat(item.quantity)
      }
      if (item.netAmount) {
        totalAMt = totalAMt + parseFloat(item.netAmount)
      }
      if (item.taxableAmount) {
        taxableAmount = taxableAmount + parseFloat(item.taxableAmount)
      }
    })

    this.setState({
      totalQuantity: totalQt ? totalQt.toFixed(3) : null,
      subTotal: totalAMt ? parseFloat(totalAMt).toFixed(2) : null,
      totalAmount: totalAMt ? parseFloat(totalAMt).toFixed(2) : null,
      taxableAmount: taxableAmount ? parseFloat(taxableAmount).toFixed(2) : null
      /* receivedAmount: this.state.cashSale ? parseFloat(totalAMt).toFixed(2) : null,
       balance: this.state.cashSale ? "0" : parseFloat(totalAMt).toFixed(2)*/
    }, () => {
      this.calBalance()
    })


  }


  choosemrp = () => {
    this.setState({ qmrp: true, qws: false, qsp: false })
    this.reloadItems('mrp')
  }
  removeItem = (val) => {
    if (val._id) {
      removeId = val._id
      val.tid = val._id
    }
    if (val.tid) {
      removeId = val.tid
    }
    this.setState({ removeItemModal: true })
  }
  choosews = () => {
    this.setState({ qmrp: false, qws: true, qsp: false })
    this.reloadItems('ws')
  }
  choosesp = () => {
    this.setState({ qmrp: false, qws: false, qsp: true })
    this.reloadItems('sp')
  }

  reloadItems = (rr) => {
    this.props.dispatch(showLoader())
    let allIt = this.state.items
    ReloadSaleItems(rr, allIt, (resp) => {
      this.setState({ items: resp }, () => {
        this.calculation()
        this.props.dispatch(hideLoader())
      })

    })


  }


  getSaleItems(tempId) {
    this.props.dispatch(showPageLoad())
    Request.GetItemsForEditSale({ linkId: tempId })
      .then((saleData) => {
        if (saleData && saleData.type) {
          saleData = saleData.saleData
          _.each(saleData, (val, k) => {
            saleObj[k] = val
          })

          if (saleObj.saleDate) {
            saleObj.saleDate = moment(saleObj.saleDate).format('DD-MMM-YYYY')
          }
          saleObj.showDisc = false

          printSaleObj = _.cloneDeep(saleObj)

          _.each(saleData, (value, key) => {
            if (typeof value == 'number') {
              value = value.toString()
            }
          })

          _.each(saleData.items, (sdfs) => {
            sdfs.tid = uuid()
            oldItems.push({
              productUid: sdfs.productUid,
              quantity: sdfs.quantity,
              uid: sdfs.uid,
              linkId: sdfs.saleId
            })
          })
          oldBalance = saleData.balance

          setTimeout(() => {
            this.setState({
              uid: saleData.uid,
              stateOfSupply: saleData.stateOfSupply,
              clientBalance: saleData.clientBalance,
              date: saleData.saleDate ? moment(saleData.saleDate).format('DD-MMM-YYYY') : null,
              isDateTimePickerVisible: false,
              paymentMode: saleData.paymentMode,
              hideTypeahead: false,
              accordionOpen: true,
              hsnCode: saleData.hsnCode,
              addClientModal: false,
              qsp: saleData.qsp,
              qmrp: saleData.qmrp,
              qws: saleData.qws,
              items: saleData.items,
              saleCategory: saleData.saleCategory,
              totalQuantity: saleData.totalQuantity,
              totalAmount: saleData.totalAmount ? saleData.totalAmount.toString() : null,
              subTotal: saleData.subTotal,
              jsonCreated: saleData.eWayBillFile ? true : false,
              receivedAmount: saleData.receivedAmount ? saleData.receivedAmount.toString() : null,
              discPer: saleData.discPer ? saleData.discPer.toString() : null,
              invoiceDiscount: saleData.invoiceDiscount ? saleData.invoiceDiscount.toString() : null,
              invoiceNo: saleData.invoiceNo ? saleData.invoiceNo.toString() : null,
              balance: saleData.balance,
              clientId: saleData.clientId,
              clientName: saleData.clientName,
              stateCode: saleData.stateCode,
              pinCode: saleData.pinCode,
              warranty: saleData.warranty,
              cashSale: saleData.cashSale,
              description: saleData.description,
              imageSelect: saleData.document ? true : false,
              image: {
                uri: saleData.document ? saleData.document : null
              },
              checkedBy: saleData.checkedBy,
              packedBy: saleData.packedBy,
              deliveredBy: saleData.deliveredBy,
              cashBy: saleData.cashBy,
              entryTime: saleData.entryTime,
              transportType: saleData.transportType,
              transportId: saleData.transportId,
              transportName: saleData.transportName,
              vehicleId: saleData.vehicleId,
              vehicleNo: saleData.vehicleNo,
              invoicePrefix: saleData.invoicePrefix,
              invoiceSrNo: saleData.invoiceSrNo,
              grNo: saleData.grNo,
              station: saleData.station,
              reverseCharge: saleData.reverseCharge,
              eWayBillNo: saleData.eWayBillNo,
              ewayBillDate: saleData.ewayBillDate,
              cancelled: saleData.cancelled,
              bankId: saleData.bankId,
              paymentType: saleData.paymentType,
              bankType: saleData.bankType,
              paymentDetails: saleData.paymentDetails,
              extraCharges: saleData.extraCharges ? saleData.extraCharges.toString() : null,
              bankKey: moment(),
              eWayBillInvoice: saleData.eWayBillInvoice,
              taxableAmount: saleData.taxableAmount,
              subClient: saleData.subClient,
              defaultTaxType: saleData.defaultTaxType,
              signature: saleData.signature ? saleData.signature : null,
              signName: saleData.signName ? saleData.signName : null,
              signMobile: saleData.signMobile ? saleData.signMobile : null,
              vehicleKey: moment() + 4,
              itemListKey: moment() + 2,
              clientKey: moment() + 3
            })

            // return callback("done");

            // this.props.hideLoader();

            this.props.dispatch(hidePageLoad())

          }, 1000)
        }

      }).catch((e) => {
      this.props.dispatch(hidePageLoad())
    })
  }

  getItemsForEstimate(tempId) {
    this.props.dispatch(showLoader())
    Request.GetEstimateItems({ linkId: tempId })
      .then((saleData) => {

        if (saleData) {
          this.props.dispatch(hideLoader())
          _.each(saleData, (value, key) => {
            if (typeof value == 'number') {
              value = value.toString()
            }
          })

          setTimeout(() => {
            this.setState({
              uid: saleData.uid,
              stateOfSupply: saleData.stateOfSupply,
              clientBalance: saleData.clientBalance,
              date: moment().format('DD-MMM-YYYY'),
              isDateTimePickerVisible: false,
              hideTypeahead: false,
              accordionOpen: true,
              addClientModal: false,
              addSubClientModal: false,
              removeItemModal: false,
              items: saleData.items,
              totalQuantity: saleData.totalQuantity,
              saleCategory: saleData.saleCategory,
              totalAmount: saleData.totalAmount.toString(),
              subTotal: saleData.subTotal ? saleData.subTotal : saleData.totalAmount,
              balance: saleData.balance,
              clientId: saleData.clientId,
              clientName: saleData.clientName,
              imageSelect: saleData.document ? true : false,
              image: {
                uri: saleData.document ? saleData.document : null
              },
              taxableAmount: saleData.taxableAmount
            })

          }, 1000)
        }

      }).catch((e) => {
      this.props.dispatch(hideLoader())
    })
  }


  loadWarr() {
    Request.AllWarranty()
      .then((testData) => {

        let rrr = ['None']
        _.each(testData, (tt) => {
          if (tt.warranty) {
            rrr.push(tt.warranty)
          }

        })
        this.setState({ warrArr: rrr })
      })
      .catch((e) => {
        console.log(e)

      })
  }

  loadSaleCat() {
    let rrr = []
    Request.AllSaleCat()
      .then((testData) => {
        _.each(testData, (tt) => {
          if (tt.saleCategory) {
            rrr.push({ name: tt.saleCategory, uid: tt.uid })
          }
        })
        this.setState({ saleCatArr: rrr })
      })
      .catch((e) => {
        console.log(e)

      })
  }

  removeImage() {
    this.setState({
      image: {},
      imageSelect: false
    })
  }


  createPdf = async (typestr) => {
    let { subClient } = printSaleObj
    let shortGst = printSaleObj.clientGstNo ? printSaleObj.clientGstNo.substring(0, 2) : ''
    if (shortGst) {
      shortGst = `(${shortGst})`
    }
    let data = {
      ...printSaleObj,
      title: 'TAX INVOICE',
      printType: printType,
      showDiscount: printType == 'Hidden' ? false : true,
      invoice: {
        address: {
          shopName: subClient && subClient.name ? subClient.name : printSaleObj.clientName,
          address1: subClient && subClient.address ? subClient.address : printSaleObj.clientAddress
        },
        customer: {
          'gst/in': printSaleObj.clientGstNo ? printSaleObj.clientGstNo : ''
          /* "name": "",
           "address": ""*/
        },
        invoiceLeftInfo: {
          'gr/rr no': printSaleObj.grNo,
          'E-way bill no': printSaleObj.eWayBillNo
        },
        invoiceInfo: {
          'invoice no': printSaleObj.invoiceNo,
          'dated': printSaleObj.saleDate ? moment(printSaleObj.saleDate).format('DD-MMM-YYYY') : '',
          'entry time': printSaleObj.entryTime,
          'place of supply': `${subClient && subClient.state ? subClient.state : printSaleObj.stateOfSupply} ${shortGst}`,
          'station': printSaleObj.station ? printSaleObj.station : '',
          'reverse charge': printSaleObj.reverseCharge ? 'Y' : 'N',
          'transport by': printSaleObj.transportType ? printSaleObj.transportType : '',
          'transport': printSaleObj.transportName ? printSaleObj.transportName : '',
          'vehicle no': printSaleObj.vehicleNo

        }
      }
    }


    html = await PrintHtml(data)
    let printedData = html
    let doubleHtml = html + `<p style="page-break-after: always;">&nbsp;</p>
<p style="page-break-before: always;">&nbsp;</p>` + html

    /*if (typestr == 'print') {




    } else {

      const options = {
        html: html,
        fileName: 'Sale',
        directory: 'Document'
      }

    }*/

    HtmlPrint(doubleHtml)


  }

  calDiscount(type) {
    if (type == 'per') {
      setTimeout(() => {
        let dp = this.state.discPer ? this.state.discPer : 0
        let st = this.state.subTotal ? this.state.subTotal : 0

        let invd = (dp / 100) * st
        if (invd) {
          invd = parseFloat(invd).toFixed(2)
          this.setState({ invoiceDiscount: invd, totalAmount: parseFloat(st - invd).toFixed(2) }, () => {
            this.calBalance()
          })

        } else {

          this.setState({ invoiceDiscount: null, totalAmount: st.toString() }, () => {
            this.calBalance()
          })
        }
      }, 240)
    } else {
      setTimeout(() => {

        let st = this.state.subTotal ? this.state.subTotal : 0
        let invd = this.state.invoiceDiscount ? this.state.invoiceDiscount : 0
        let discPer = (invd / st) * 100
        if (discPer) {
          discPer = parseInt(discPer)
          this.setState({ discPer: discPer.toString(), totalAmount: parseFloat(st - invd).toFixed(2) }, () => {
            this.calBalance()
          })

        } else {
          this.setState({ discPer: null, totalAmount: st.toString() }, () => {
            this.calBalance()
          })

        }
      }, 240)
    }

  }

  calBalance() {

    let ta = 0
    _.each(this.state.items, (item) => {
      ta = ta + parseFloat(item.netAmount)
    })


    let ra = this.state.receivedAmount ? this.state.receivedAmount : 0
    let echr = this.state.extraCharges ? this.state.extraCharges : 0

    if (ra) {
      ra = parseFloat(ra)
    }
    if (echr) {
      echr = parseFloat(echr)
    }

    /* if (!this.state.cashSale) {
         this.setState({
             balance: parseFloat(ta - ra).toFixed(2),
             totalAmount: (parseFloat(ta) + echr).toFixed(2),
         });
     } else {
         this.setState({balance: null, receivedAmount: parseFloat(ta).toFixed(2)});
     }*/

    let totalAmount = parseFloat(ta) + parseFloat(echr)
    totalAmount = totalAmount ? Math.round(totalAmount).toString() : null

    this.setState({
      balance: parseFloat(totalAmount - ra).toFixed(2),
      // totalAmount: totalAmount.toFixed(2),
      totalAmount: totalAmount
    })

  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.clientData) {

      this.setState({
        clientId: nextProps.clientData.uid,
        clientGroupId: nextProps.clientData.clientGroupId,
        clientName: nextProps.clientData.name,
        clientBalance: nextProps.currentBalance,
        clientState: nextProps.clientData.state,
        stateOfSupply: nextProps.clientData.state,
        city: nextProps.clientData.city,
        subClient: {},
        clientInfo: {
          clientGstNo: nextProps.clientData.gstNumber,
          clientAddress: nextProps.clientData.address,
          stateCode: nextProps.clientData.stateCode,
          pinCode: nextProps.clientData.pinCode
        }
      })
    }
    this.setState({ addClientModal: false, hideTypeahead: true })

  }

  save(type) {

    saleData = {}
    this.props.dispatch(showPageLoad())

    AsyncStorage.getBusiness().then((value) => {
      if (value !== null) {

        this.setState({ businessId: value.uid })
        let cloneState = _.clone(this.state)
        delete cloneState.arr
        delete cloneState.warrArr
        delete cloneState.arrData
        delete cloneState.bankArr
        cloneState.businessId = value.uid


        if (!cloneState.clientName || !cloneState.clientId) {
          notification.warn({
            message: 'Client name can not be left empty'
          })
          this.props.dispatch(hidePageLoad())
          return
        }
        /* if (!cloneState.stateOfSupply || cloneState.stateOfSupply == "Select") {
             notification.warn({
                 message: "Choose state of supply"
             });
             this.props.dispatch(hideLoader());
             return;
         }
         if (cloneState.stateOfSupply !== cloneState.clientState) {
             notification.warn({
                 message: `Choose state of supply : ${cloneState.clientState}`
             });
             this.props.dispatch(hideLoader());
             return;
         }*/

        if (cloneState.transportType) {
          /*notification.warn({
              message: "Choose transport type"
          });
          this.props.dispatch(hideLoader());
          return;*/

          if (cloneState.transportType == 'Transport' && !cloneState.transportId) {
            notification.warn({
              message: 'Choose transport'
            })
            this.props.dispatch(hidePageLoad())
            return
          }
          /* if (!cloneState.vehicleId) {
               notification.warn({
                   message: "Choose Vehicle no"
               });
               this.props.dispatch(hideLoader());
               return;
           }*/
        }


        if (!cloneState.invoiceNo) {
          notification.warn({
            message: 'Enter invoice no'
          })
          this.props.dispatch(hidePageLoad())
          return
        }


        /* if (!cloneState.grNo) {
            notification.warn({
                message: "GR/RR no"
            });
            this.props.dispatch(hideLoader());
            return;
        }


        if (!cloneState.eWayBillNo) {
            notification.warn({
                message: "E-way bill no"
            });
            this.props.dispatch(hideLoader());
            return;
        }*/

        if (!cloneState.totalAmount) {
          notification.warn({
            message: 'Enter Total Amount'
          })
          this.props.dispatch(hidePageLoad())
          return
        }
        if (cloneState.receivedAmount && parseFloat(cloneState.receivedAmount) > 0) {
          if (!cloneState.bankId) {
            notification.warn({
              message: 'Please choose bank'
            })
            this.props.dispatch(hidePageLoad())
            return
          }
          if (cloneState.bankType == 'Bank' && !cloneState.paymentType) {
            notification.warn({
              message: 'Please choose payment type'
            })
            this.props.dispatch(hidePageLoad())
            return
          }
        } else {
          cloneState.bankId = ''
          cloneState.bankType = ''
        }

        Request.UpdateSale(cloneState)
          .then(async (testData) => {
            if (testData.type) {
              notification.success({
                message: testData.msg
              })

              saleData = {
                sale: testData.sale,
                type: type
              }

              this.props.dispatch(hidePageLoad())

              let smsobj = {
                uid: testData.sale.uid,
                type: 'SALE',
                name: cloneState.clientName,
                msg: `New Sale: \n Client = ${cloneState.clientName} \n Total Amount = ${cloneState.totalAmount}`

              }
              if (this.state.image && this.state.image.name) {
                AsyncStorage.getUserData().then((userResp) => {
                  if (userResp) {
                    let imageObj = {
                      businessId: value.uid,
                      linkId: testData.sale.uid,
                      type: 'SALE',
                      date: new Date(),
                      userId: userResp.uid,
                      saleUid: testData.sale.uid
                    }
                    Request.uploadDocument(imageObj, this.state.image).then((resp1) => {
                      console.log(resp1)
                    }).catch((err) => {
                      console.log(err)
                    })
                  }
                })
              }


            } else {
              notification.error({
                message: testData.msg
              })
              this.props.dispatch(hidePageLoad())
              return
            }
          })
          .catch((e) => {
            notification.error({
              message: 'Try again'
            })
            this.props.dispatch(hidePageLoad())
            return
          })
      } else {
        this.props.dispatch(hidePageLoad())
        notification.error({
          message: 'Business Setting Not Set'
        })
        return
      }
    })


  }

  resetAfterSave() {
    let { type, sale } = saleData
    if (type == 'new') {
      this.setState({
        state: 'Select',
        clientBalance: '0',
        date: moment().format('DD-MMM-YYYY'),
        paymentMode: '',
        items: [],
        clientId: '',
        clientName: '',
        refNo: '',
        cashSale: false,
        image: {},
        imageSelect: false,
        saleCategory: '',
        checkedBy: null,
        packedBy: null,
        deliveredBy: null,
        cashBy: null,
        station: null,
        eWayBillNo: null,
        grNo: null,
        receivedAmount: null,
        bankId: null,
        paymentType: null,
        bankType: null,
        paymentDetails: null,
        transportType: null,
        transportId: null,
        transportName: null,
        vehicleId: null,
        vehicleNo: null,
        vehicleKey: moment(),
        bankKey: moment() + 1,
        clientKey: moment() + 3,
        itemListKey: moment() + 2,
        entryTime: moment().format('LLL'),
        reverseCharge: false,
        clientInfo: {},
        bankArr: [{ name: 'None', uid: '' }],
        showEwayBillNote: false,
        displayInvoiceNo: '',
        saveType: '',
        signature: '',
        signName: '',
        signMobile: ''
      })
      saleData = {}
      setTimeout(() => {
        this.calculation()
      }, 1000)
      return
    }
    if (type == 'save') {

    }
    if (type == 'share') {

      return this.getItems(sale.uid, type)
    }
  }

  getItems(tempId, pptype) {
    saleObj = {}
    oldItems = []
    oldBalance = 0
    fff = ''
    this.props.dispatch(showLoader())
    Request.GetItems({ linkId: tempId })
      .then((saleResp) => {
        let { saleData } = saleResp
        _.each(saleData, (val, k) => {
          saleObj[k] = val
        })
        if (saleObj.saleDate) {
          saleObj.saleDate = moment(saleObj.saleDate).format('DD-MMM-YYYY')
        }

        saleObj.showDisc = false
        let showDisc = _.findIndex(saleObj.items, function(o) {
          return o.discountAmount > 0
        })
        this.props.dispatch(hideLoader())
        return this.createPdf(pptype, saleObj)


      }).catch((e) => {
      this.props.dispatch(hideLoader())
      return
    })
  }


  fieldUpdate = (localState) => {
    this.setState({ ...localState })
  }

  setInvoiceNo(invoiceNo) {
    let { invoicePrefix } = this.state
    let value = invoiceNo.replace(invoicePrefix, '')
    this.setState({ invoiceNo, invoiceSrNo: parseFloat(value) })
  }

  _setDiscountValue(value, type, discountType) {
    if (value) {
      let splitX = value.split('.')
      if (splitX.length > 2) {
        return true
      }
      if (splitX[1]) {
        if (splitX[1].length > 2) {
          return true
        }
      }
      this.setState({ [type]: value }, () => {
        this.calDiscount(discountType)
      })
    } else {
      this.setState({ [type]: null }, () => {
        this.calDiscount(discountType)
      })
    }

  }

  _setDigitValue(value, type) {
    if (value) {
      let splitX = value.split('.')
      if (splitX.length > 2) {
        return true
      }
      if (splitX[1]) {
        if (splitX[1].length > 2) {
          return true
        }
      }
      this.setState({ [type]: value }, () => {
        this.calBalance()
      })
    } else {
      this.setState({ [type]: null }, () => {
        this.calBalance()
      })
    }

  }

  choosePaymentMode(itemValue) {
    this.setState({ paymentMode: itemValue })
  }

  setBankId(itemValue, itemIndex) {
    let { bankArr } = this.state
    let chooseBank = bankArr[itemIndex]
    this.setState({ bankId: itemValue, bankType: chooseBank.type, paymentType: '' })
  }

  getBackItems = (obj) => {
    let { items, defaultTaxType } = obj
    this.setState({ items, defaultTaxType }, () => {
      this.calculation()
    })
  }

  choosePaymentType() {
    let { totalAmount, paymentType } = this.state
    let extraCharges = 0
    if (paymentType == 'Credit Card') {
      extraCharges = (totalAmount * 0.02).toFixed(2)
    }
    totalAmount = parseFloat(totalAmount) + parseFloat(extraCharges)
    this.setState({ extraCharges }, () => {
      this.calculation()
    })
  }

  saleCategoryCallback = (data) => {
    this.loadSaleCat()
    this.setState({ addUnitVisible: false, saleCategory: data.saleCategory })
  }
  onSaleCategoryClose = () => {
    this.setState({ addUnitVisible: false })
  }

  onChooseDate = (date, dateString) => {
    this.setState({ saleDate: date })
  }
  _callBackChooseVehicle = (localState) => {
    this.setState({ ...this.state, ...localState })
  }

  setValue = (e) => {
    let { name, value } = e.target
    this.setState({ [name]: value })
  }

  _callBackBank = (data) => {
    this.setState({ ...data }, () => {
      this.choosePaymentType()
    })
  }

  _callbackClient = (data) => {
    this.setState({ ...data })
  }

  printPLFxn(v) {

    if (v == 'Full Print') {
      printType = 'Simple'
    }
    if (v == 'Discount Hidden Print') {
      printType = 'Hidden'
    }
    if (v == 'Sale Warranty') {
      printType = 'saleWarranty'
    }
    if (v == 'Item Warranty') {
      printType = 'itemWarranty'
    }


    this.createPdf('share')

  }


  openEay = async () => {
    let { saleData } = await Request.GetItemsForEditSale({ linkId: this.state.uid })

    let notExists = false
    let wrongUnitItem = {}
    const {
      transportId,
      vehicleNo,
      vehicleId,
      stateOfSupply,
      transportName, totalAmount,
      uid,
      pinCode,
      stateCode, jsonCreated, eWayBillNo, cancelled, transportType
    } = this.state

    if (!saleData.transportType) {
      notification.warn({
        message: 'Please Choose Transport Type and save'
      })
      return
    }
    if (saleData.transportType == 'Transport' && !saleData.transportId) {
      notification.warn({
        message: 'Please Choose Transport Id and save'
      })
      return
    }
    if (saleData.transportType == 'Personal' && !saleData.vehicleNo) {
      notification.warn({
        message: 'Please Choose Vehicle No and save'
      })
      return
    }

    Async.each(saleData.items, (item, cb) => {
      let findUnit = _.find(UnitCodeList, (val) => {
        return val.toLowerCase() == item.unitCode.toLowerCase()
      })
      if (!findUnit && notExists == false) {
        notExists = true
        wrongUnitItem = item
      }
      cb()
    }, () => {
      if (notExists) {
        notification.warn({
          message: `${wrongUnitItem.productName} have a invalid Unit Code.`
        })
        return
      }
      let obj = {
        generateEwayBillObj: {
          transportId,
          vehicleNo,
          vehicleId,
          stateOfSupply,
          transportName,
          totalAmount,
          uid,
          pinCode,
          stateCode,
          jsonCreated,
          eWayBillNo,
          cancelled,
          transportType
        },
        showEwayBillDrawer: true
      }
      this.setState({ ...obj })
    })
  }

  onCloseEwayBill = () => {
    this.setState({
      showEwayBillDrawer: false,
      generateEwayBillObj: {}
    })
  }

  callbackEwayBill = () => {
    this.setState({
      showEwayBillDrawer: false,
      generateEwayBillObj: {}
    }, () => {
      this.fetchInfo()
    })
  }

  ewayBillPdf = async (typestr) => {
    let { eWayBillNo } = this.state
    let eWayBillInvoice = `http://gst.soundlink.in/ewayPdf/${eWayBillNo}.pdf`
    window.open(eWayBillInvoice, '_blank')
  }
  onCloseSubClient = () => {
    this.setState({ addSubClientVisible: false })
  }

  callbackSubClient = (subClient) => {
    this.setState({ subClient, addSubClientVisible: false })
  }

  showAddSubClient = () => {
    this.setState({ addSubClientVisible: true })
  }


  render() {
    const handleChange = () => {

    }
    let {
      items, saleCatArr, addUnitVisible, saleDate, vehicleKey, image, addSubClientVisible,
      showEwayBillDrawer, generateEwayBillObj, subClient, clientId, clientName
    } = this.state

    const formData = (
      <Row gutter={16}>
        <Col span={8}>
          <InputBox title={'Description (Optional)'}>
            <Input onChange={this.setValue} name={'description'} value={this.state.description}></Input>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Sale Category'}>
            <div className={'rowFlex'}>
              <Select showSearch={true}
                      value={this.state.saleCategory}
                      optionFilterProp="children"
                      filterOption={(input, options) => {
                        return options.props.children.match(new RegExp(input, 'ig'))
                      }}
                      onChange={(e) => this.setState({ saleCategory: e })}>
                {saleCatArr.map((item, key) => {
                  return (
                    <Option key={item.uid} value={item.uid}>{item.name}</Option>
                  )
                })}
              </Select>
              <Button onClick={() => this.setState({ addUnitVisible: true })}>
                <Tooltip>
                  <Icon type={'plus'} name={'plus'}/>
                </Tooltip>
              </Button>
            </div>

          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Date of sale'}>
            <DatePicker onChange={this.onChooseDate} value={saleDate} placeholder={'Date of sale'}/>
          </InputBox>
        </Col>
      </Row>
    )
    const formData1 = (
      <Row gutter={16}>
        <Col span={8}>
          <InputBox title={'GR/RR No'}>

            <Input onChange={this.setValue} name={'grNo'} placeholder={'GR/RR no'} value={this.state.grNo}/>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Reverse Charge'}>
            <Select value={this.state.reverseCharge}>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Eway bill no'} className={'rowFlex'}>
            <div className={'ant-input'}>
              {this.state.eWayBillNo ? this.state.eWayBillNo : 'Eway Bill No'}
            </div>

            {this.state.ewayBillDate ?
              <Tooltip title={'Download'}>
                <a className={'linkBtn'} onClick={() => this.ewayBillPdf('print')}>
                  <Icon type="download"/>
                </a>
              </Tooltip> : null}
            <Tooltip title={'Generate/Cancel'}>
              <a className={'linkBtn'} onClick={this.openEay}>
                <Icon type="alert"/>
              </a>
            </Tooltip>
            {this.state.cancelled ?
              <Tooltip title={'Cancelled'}><Button type={'danger'}>Cancelled</Button></Tooltip> : null}
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Station'}>
            <Input onChange={this.setValue} name={'station'} placeholder={'Station'}
                   value={this.state.station}/>
          </InputBox>
        </Col>


      </Row>
    )


    const amountFields = (
      <Row gutter={16}>
        <Col span={8}>
          <InputBox title={'Total Amount'}>
            <div className={'ant-input'}>
              {this.state.totalAmount}
            </div>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Received Amount'}>
            <Input onChange={(e) => {
              this._setDigitValue(e.target.value, 'receivedAmount')
            }} name={'receivedAmount'} placeholder={'Received Amount'}
                   value={this.state.receivedAmount}/>
          </InputBox>
        </Col>
        {!this.state.cashSale ?
          <Col span={8}>
            <InputBox title={'Balance'}>
              <div className={'ant-input'}>
                {this.state.balance}
              </div>
            </InputBox>
          </Col> : null}
      </Row>
    )


    const otherFields = (
      <Row gutter={16}>

        {this.state.paymentType == 'Credit Card' &&
        <Col span={8}><InputBox title={'Extra Charges'}>
          <Input
            onChangeText={(e) => {
              this._setDigitValue(e.target.value, 'extraCharges')
            }}
            value={this.state.extraCharges}
          />
        </InputBox></Col>}

        <Col span={8}> <InputBox title={'Checked By '}>
          <Input onChange={this.setValue} name={'checkedBy'} placeholder={'Checked By'}
                 value={this.state.checkedBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'PackedBy By '}>
          <Input onChange={this.setValue} name={'packedBy'} placeholder={'Packed By'}
                 value={this.state.packedBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'Delivered By '}>
          <Input onChange={this.setValue} name={'deliveredBy'} placeholder={'Delivered By'}
                 value={this.state.deliveredBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'Cash By '}>
          <Input onChange={this.setValue} name={'cashBy'} placeholder={'Cash By'}
                 value={this.state.cashBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'Entry Time'}>
          <Input onChange={this.setValue} name={'entryTime'} placeholder={'Entry Time'}
                 value={this.state.entryTime}/>
        </InputBox></Col>
      </Row>
    )


    return (
      <PageHeaderWrapper
        title={'Edit Sale'}
        content={''}>
        <Card bordered={true}
              extra={<div className={'actionGroup'}>
                <Button onClick={
                  debounce(() => {
                    printType = 'Simple'
                    this.createPdf('print')
                  }, 1000)
                }>Print</Button>

                <Button onClick={
                  debounce(() => {
                    printType = 'Simple'
                    this.save('share')
                  }, 1000)
                }>Save</Button>

                <Button onClick={() => this.printPLFxn('Full Print')
                }>SAVE <small> (Full Print)</small></Button>

                <Button onClick={() => this.printPLFxn('Discount Hidden Print')
                }>SAVE <small> (Hidden Print)</small></Button>

                <Button onClick={() => this.printPLFxn('Sale Warranty')
                }>SAVE <small> (Sale Warranty)</small></Button>

                <Button onClick={() => this.printPLFxn('Item Warranty')}>SAVE <small> (Item Warranty)</small></Button>

              </div>}>
          <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }} className={'newForm'}>
            <Row>
              <Col span={8}>
                <InputBox title={''}>
                  <Checkbox checked={this.state.qmrp}
                            onChange={this.choosemrp}>MRP</Checkbox>

                  <Checkbox checked={this.state.qws}
                            onChange={this.choosews}>WS</Checkbox>

                  <Checkbox checked={this.state.qsp}
                            onChange={this.choosesp}>SP</Checkbox>
                </InputBox>
              </Col>
              <Col span={16} className={'alignRight'}>
                {clientName == 'Cash' && clientId ?
                  <Button type={'basic'} onClick={this.showAddSubClient}>
                    Add/Update Sub Client
                  </Button>
                  : null}
              </Col>
            </Row>


            <ChooseClientComponent {...this.props} _callbackClient={this._callbackClient} key={this.state.clientKey}
                                   clientName={this.state.clientName} clientId={this.state.clientId}
                                   stateOfSupply={this.state.stateOfSupply}/>


            {clientName == 'Cash' && clientId && subClient && subClient.name ?
              <Descriptions title="Sub Client Details" bordered size={'small'} className={'mb20'}
                            extra={<Button type="basic" onClick={this.showAddSubClient}>Edit</Button>}>
                <Descriptions.Item label="Name">{subClient.name}</Descriptions.Item>
                <Descriptions.Item label="Address">{subClient.address}</Descriptions.Item>
                <Descriptions.Item label="State">{subClient.state}</Descriptions.Item>
                <Descriptions.Item label="Mobile">{subClient.mobile}</Descriptions.Item>
                <Descriptions.Item label="Email">{subClient.email}</Descriptions.Item>
                <Descriptions.Item label="ITPAN">{subClient.itPan}</Descriptions.Item>
                <Descriptions.Item label="Aadhar No">{subClient.aadharNo}</Descriptions.Item>
              </Descriptions>
              : null}


            {formData}
            <ChooseTransport _callBackChooseVehicle={this._callBackChooseVehicle} {...this.props}
                             fieldUpdate={this.fieldUpdate}
                             key={this.state.vehicleKey} {...this.state} />

            {formData1}
            <ItemListComponent
              key={this.state.itemListKey}
              {...this.props}
              items={this.state.items}
              clientId={this.state.clientId}
              subTotal={this.state.subTotal}
              totalQuantity={this.state.totalQuantity}
              getBackItems={this.getBackItems}
              pageParams={{
                pageType: 'Sale',
                defaultTaxType: this.state.defaultTaxType
              }}
            />
            {amountFields}
            <ChooseBank {...this.props} key={this.state.bankKey}
                        callBackBank={this._callBackBank} {...this.state}/>
            <Row gutter={16}>
              <Col span={8}>
                <InputBox title={'Warranty'}>
                  <Select value={this.state.warranty} onChange={(e) => this.setState({ warranty: e })}>
                    {this.state.warrArr.map((item) => {
                      return (
                        <Option value={item} key={item}>{item}</Option>
                      )
                    })}
                  </Select>
                </InputBox>
              </Col>
              <Col span={8}>
                <InputBox title={'Choose Picture'} className={'rowFlex'}>
                  <Input type={'file'} accept="image/*" onChange={(e) => {
                    if (e.target.files && e.target.files.length) {
                      this.setState({ image: e.target.files[0] })
                    }
                  }}/>
                  {image && image.uri ?
                    <a className={'linkBtn'} target={'_blank'} href={image.uri}>
                      <Icon type={'eye'}></Icon>
                    </a> : null}

                </InputBox>
              </Col>
            </Row>
            {otherFields}
          </Form>
          {addUnitVisible ?
            <AddSaleCategory
              saleCategoryCallback={this.saleCategoryCallback}
              onSaleCategoryClose={this.onSaleCategoryClose}
              visible={addUnitVisible}
              {...this.props}
            />
            : null}
          {showEwayBillDrawer ?
            <GenerateEwayBill
              callbackEwayBill={this.callbackEwayBill}
              generateEwayBillObj={generateEwayBillObj}
              onCloseEwayBill={this.onCloseEwayBill}
              visible={showEwayBillDrawer}
              {...this.props}
            />
            : null}

          {addSubClientVisible ?
            <AddSubClient
              callbackSubClient={this.callbackSubClient}
              onCloseSubClient={this.onCloseSubClient}
              visible={addSubClientVisible}
              subClient={subClient}
              {...this.props}
            />
            : null}
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSale)
