import React from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Divider,
  Spin,
  notification
} from 'antd'
import Request from '../../request'
import lodash from 'lodash'
import AddTransport from '../Setting/transport/Add'
import AddVehicle from '../Setting/vehicle/Add'
import { InputBox } from './appDes'
import { filterOption } from '../../components/_utils/_utils'

const FormItem = Form.Item
const { Option } = Select

@Form.create()
class ChooseClientComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      transportType: '',
      transportId: '',
      transportName: '',
      vehicleArr: [],
      vehicleId: '',
      vehicleNo: '',
      arr: []
    }
  }

  componentDidMount() {
    this.loadAllTransport()
    let { transportType, transportId, transportName, vehicleId, vehicleNo } = this.props
    this.setState({ transportType, transportId, transportName, vehicleId, vehicleNo }, () => {
      if (transportType) {
        if (transportType == 'Transport' && transportId) {
          this.loadVehicle(transportId)
        }
        if (transportType == 'Personal') {
          this.loadVehicle(null)
        }
      }
    })
  }

  async loadAllTransport() {
    Request.AllTransportList()
      .then((testData) => {
        let arr = [{ name: 'Choose Transport', uid: '' }]
        lodash.each(testData, (item) => {
          arr.push({ name: item.transport, uid: item.uid })
        })
        this.setState({ arr })
      })
      .catch((e) => {
        return
      })
  }

  loadVehicle() {
    let { transportType, transportId } = this.state
    let obj = {
      type: transportType
    }
    if (transportId) {
      obj.transportId = transportId
    }
    Request.GetVehicleByTransport(obj).then((resp) => {
      resp.unshift({ vehicleNo: 'Choose Vehicle', uid: '' })
      this.setState({ vehicleArr: resp })
      this.callBackChooseVehicle()
    })
  }

  callBackChooseVehicle() {
    let { transportType, transportId, transportName, vehicleId, vehicleNo } = this.state
    if (!transportId) {
      transportName = ''
    }
    this.props._callBackChooseVehicle({ transportType, transportId, transportName, vehicleId, vehicleNo })
  }


  openAddVehicle() {
    let { transportId, transportType, transportName } = this.state
    if (transportType == 'Transport' && !transportId) {
      notification.error({
        message: 'Please choose transport'
      })
      return
    }
    this.setState({ addVehicleVisible: true })
  };

  transportCallback = (data) => {
    this.loadAllTransport()
    setTimeout(() => {
      this.setState({ addTransportVisible: false, transportId: data.uid, transportName: data.transport })
    }, 1000)

  }

  onTransportClose = () => {
    this.setState({ addTransportVisible: false })
  }

  vehicleCallback = (data) => {
    this.loadVehicle()
    setTimeout(() => {
      this.setState({ addVehicleVisible: false, vehicleId: data.uid, vehicleNo: data.vehicleNo })
    }, 1000)

  }

  onVehicleClose = () => {
    this.setState({ addVehicleVisible: false })
  }

  chooseTransport(uid) {
    let { arr } = this.state
    let item = lodash.find(arr, (value) => {
      return value.uid == uid
    })
    if (item && item.uid) {
      this.setState({
        transportId: item.uid,
        transportName: item.name,
        vehicleId: '',
        vehicleNo: ''
      }, () => {
        this.loadVehicle()
      })
    } else {
      this.setState({
        transportId: '',
        transportName: '',
        vehicleId: '',
        vehicleNo: ''
      }, () => {
        this.loadVehicle()
      })
    }
  }

  chooseVehicleFxn(uid) {
    let { vehicleArr } = this.state
    let item = lodash.find(vehicleArr, (value) => {
      return value.uid == uid
    })
    if (item) {
      this.setState({
        vehicleId: item.uid ? item.uid : '',
        vehicleNo: item.vehicleNo ? item.vehicleNo : ''
      }, () => {
        this.callBackChooseVehicle()
      })
    }
  }

  render() {
    let {
      transportType, visibleAdd, transportName,
      transportId, arr, vehicleArr, addTransportVisible, addVehicleVisible
    } = this.state
    return (
      <Row gutter={16}>
        <Col span={8}>
          <InputBox title={'Transport Type'}>
            <Select
              allowClear={true}
              value={transportType}
              onChange={(itemValue) => {
                this.setState({ transportType: itemValue }, () => {
                  if (itemValue == 'Personal') {
                    this.loadVehicle(null)
                  } else {
                    this.setState({ vehicleId: '', transportId: '' }, () => {
                      this.callBackChooseVehicle()
                    })

                  }
                })
              }}
              placeholder="Choose Transport Type">
              <Option value={''}>Choose Transport Type</Option>
              <Option value={'Transport'}>Transport</Option>
              <Option value={'Personal'}>Personal</Option>

            </Select>
          </InputBox>
        </Col>
        {transportType == 'Transport' && <Col span={8}><InputBox title={'Transport'} className={'rowFlex'}>
          <Select
            value={this.state.transportName}
            showSearch={true}
            allowClear={true}
            filterOption={filterOption}
            onChange={(itemValue) => {
              this.chooseTransport(itemValue)
            }}
            placeholder="Choose Transport Type">

            {arr && arr.length && arr.map((item) => {
              return (
                <Option key={item.uid} value={item.uid}>{item.name}</Option>
              )
            })}


          </Select>
          <Button onClick={() => this.setState({ addTransportVisible: true })}>
            <Icon name={'plux'} type={'plus'}/>
          </Button>
        </InputBox></Col>}
        {transportType == 'Transport' || transportType == 'Personal' ?
          <Col span={8}><InputBox title={'Vehicle No'} className={'rowFlex'}>
            <Select
              value={this.state.vehicleNo}
              showSearch={true}
              allowClear={true}
              filterOption={filterOption}
              onChange={(itemValue) => {
                this.chooseVehicleFxn(itemValue)

              }}
              placeholder="Choose vehicle">

              {vehicleArr && vehicleArr.length && vehicleArr.map((item) => {
                return (
                  <Option key={item.uid} value={item.uid}>{item.vehicleNo}</Option>
                )
              })}


            </Select>
            <Button onClick={() => this.openAddVehicle()}>
              <Icon name={'plux'} type={'plus'}/>
            </Button>
          </InputBox></Col>
          : null}

        {addTransportVisible ?
          <AddTransport
            transportCallback={this.transportCallback}
            onTransportClose={this.onTransportClose}
            visible={addTransportVisible}
            {...this.props}
          />
          : null}

        {addVehicleVisible ?
          <AddVehicle
            vehicleCallback={this.vehicleCallback}
            onVehicleClose={this.onVehicleClose}
            params={{ transportType, transportId, transportName }}
            visible={addVehicleVisible}
            {...this.props}
          />
          : null}

      </Row>
    )
  }
}

export default ChooseClientComponent
