import React, { Component } from 'react'
import { Row, Col } from 'antd'
import { AsyncStorage } from '../../asyncStorage'

class InputBox extends Component {
  constructor(props) {
    super()
    this.state = {}
  }

  render() {
    let { title = '', className = '', type = 'row' } = this.props
    return (
      <Row style={{ marginBottom: 10 }}>
        <Col span={type == 'row' ? 24 : 8}>
          <label className={type == 'inline' ? 'inputLabel' : ''}>{title}</label>
        </Col>
        <Col span={type == 'row' ? 24 : 12}>
          <div className={className}>
            {this.props.children}
          </div>
        </Col>
      </Row>
    )
  }
}

export default InputBox

