import PrintStyle from '../style'
import PrintHeader from '../header'
import _ from 'lodash'
import { AsyncStorage } from '../../../asyncStorage'

const PrintHtml = async (obj) => {
  return new Promise(async (resolve) => {
    let businessDetails = await AsyncStorage.getBusiness()

    let itemTable = ''

    _.each(obj.expenses, (item, index) => {
      let aa = `class="${index % 2 == 0 ? 'evenCls' : ''}"`
      itemTable += `<tr style="height: 5px;" ${aa}>
                        <td class='capCase '>${item.name}</td>
                        <td>${item.balance}</td>
                        <td>${item.totalExpense ? item.totalExpense : ''}</td>
                    </tr>`
    })


    const html = `
    <!doctype html>
   <html>
   <head>
   <meta charset="utf-8">
   <title>Trading Sheet</title>
    ${PrintStyle}
</head>
<body class="container">
        <page size="A4">
        ${await PrintHeader(obj.title, `( For the period ending ${obj.currentDate})`)}
            <div class="row border-bottom font16 center padding20 letter-sp">
                <div class="col-md-6  border-right">
                    DEBIT (Rs.)
                </div>
                <div class="col-md-6">
                    CREDIT (Rs.)
                </div>
            </div>
            <div class="row ">
                <div class="col-md-6 border-right-dashed">
                    <div class="padding10">
                        <table class="sheetTable font10 marginTop10">
                            <tr class="b">
                                <td>To Opening Stock</td>
                                <td></td>
                                <td>${obj.openingStock}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="padding10">
                        <p class="b font10 head1">
                            To Purchase :
                        </p>
                        <table class="sheetTable font10 paddingLeft15">
                            <tr>
                                <td>Purchase</td>
                                <td>${obj.purchasePrice}</td>
                                <td><b>${obj.purchasePrice}</b></td>
                            </tr>
                        </table>
                    </div>
                    <div class="padding10">
                        <table class="sheetTable font10">
                            ${obj.totalProfit ? `<tr class="b">
                                    <td>To Gross Profit</td>
                                    <td></td>
                                    <td>${(obj.totalProfit).toFixed(2)}</td>
                                </tr>` : ''}
                            
                        </table>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="padding10">
                        <p class="b font10 marginTop10 head1">
                            By Sale :
                        </p>
                        <table class="sheetTable font10 paddingLeft15">
                            <tr>
                                <td>Sales</td>
                                <td>${obj.salePrice}</td>
                                <td><b>${obj.salePrice}</b></td>
                            </tr>
                           
                        </table>
                    </div>
                    <div class="padding10">
                        <table class="sheetTable font10">
                         <tr class="b">
                                <td>By Closing Stock</td>
                                <td></td>
                                <td>${obj.closingStock}</td>
                            </tr>
                            </table>
                    </div>
                    <div class="padding10">
                        <table class="sheetTable font10 marginTop10">
                             ${obj.totalLoss ? `<tr class="b">
                                    <td>To Gross Loss</td>
                                    <td></td>
                                    <td>${(obj.totalLoss).toFixed(2)}</td>
                                </tr>` : ''}
                     
                        </table>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 border-right-dashed">
                    <table class="sheetTable font10  b">
                        <tr>
                            <td></td>
                            <td>Total</td>
                            <td><span class="border-top-bottom">${(obj.totalDebit).toFixed(2)}</span></td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-6 border-right-dashed">
                    <table class="sheetTable font10  b">
                        <tr>
                            <td></td>
                            <td>Total</td>
                            <td><span class="border-top-bottom">${(obj.totalCredit).toFixed(2)}</span></td>
                        </tr>
                    </table>
                </div>
            </div>
        
            <div class="row border-bottom font16 center padding20 letter-sp marginTop20">
                <div class="col-md-12 b font18">
                    PROFIT & LOSS ACCOUNT
                </div>
            </div>
            <div class="row border-bottom font16 center padding20 letter-sp">
                <div class="col-md-6  border-right">
                    DEBIT (Rs.)
                </div>
                <div class="col-md-6">
                    CREDIT (Rs.)
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 border-right-dashed">
                    <div class="padding10">
                        <p class="b font10 head1">
                            To Expenses (Indirect/Admn.) :
                            </p>
                        <table class="sheetTable font10 paddingLeft15">
                           ${itemTable}
                        </table>
                     </div>
                    <div class="padding10">
                        <table class="sheetTable font10 marginTop10">
                           ${obj.netProfit ? `
                                <tr class="b">
                                        <td>To Nett Profit</td>
                                        <td></td>
                                        <td>${obj.netProfit}</td>
                                    </tr>` : ''}
                            </table>
                     </div>
                </div>
                <div class="col-md-6">
                        <div class="padding10">
                            <table class="sheetTable font10 b">
                            ${obj.totalProfit ? `<tr class="b">
                                    <td>By Gross Profit</td>
                                    <td></td>
                                    <td>${obj.totalProfit}</td>
                                </tr>` : ''}
                            
                            </table>
                        </div>
            
                        <div class="padding10">
                            <table class="sheetTable font10 marginTop10 b">
                            
                            ${obj.netLoss ? `<tr class="b">
                                    <td>By Nett Loss</td>
                                    <td></td>
                                    <td>${obj.netLoss}</td>
                                </tr>` : ''}
                            </table>
                        </div>
            
                </div>
            </div>
            <div class="row">
                    <div class="col-md-6 border-right-dashed">
                        <table class="sheetTable font10 paddingV10  b">
                            <tr>
                                <td></td>
                                <td>Total</td>
                                <td>
                                    <span class="border-top-bottom">${obj.profitLossDebit}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-6 border-right-dashed">
                        <table class="sheetTable font10 paddingV10  b">
                            <tr>
                                <td></td>
                                <td>Total</td>
                                <td><span class="border-top-bottom">${obj.profitLossCredit}</span></td>
                            </tr>
                        </table>
                    </div>
                </div> 
            
        </page>
    </body>
</html>`
    resolve(html)
  })

}

export default PrintHtml
