import React from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Divider,
  Tooltip,
  Checkbox, Modal, Alert, Descriptions
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader, showPageLoad, hidePageLoad } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { AsyncStorage } from '../../../asyncStorage'
import { createMatchSelector } from 'connected-react-router'
import { StateList, ReloadSaleItems } from '../../../components/_utils/_utils'
import ChooseClientComponent from '../../webComponent/ChooseClientComponent'
import ItemListComponent from '../../webComponent/ItemListComponent'
import ChooseBank from '../../webComponent/ChooseBank'
import { InputBox } from '../../webComponent/appDes'
import debounce from 'lodash/debounce'
import PrintHtml from '../../html/index'
import { HtmlPrint } from '../../html/print'
import ChooseTransport from '../../webComponent/ChooseTransport'
import { getUrlParams } from '../../../routes'
import { v4 as uuid } from 'uuid'

const { TextArea } = Input

// inputSchema.fields


const FormItem = Form.Item
const { Option } = Select


let html = null
let oldItems = []
let oldBalance = 0
let saleObj = {}
let saleData = {} // for store after save sale data
let fff = ''
let removeId = null
let editItem = {}
let printType = 'Simple'


@Form.create()
class EditDebitNote extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      qmrp: false,
      qws: false,
      qsp: true,
      state: 'Select',
      clientBalance: '0',
      purchaseDate: moment(),
      arr: [],
      isDateTimePickerVisible: false,
      paymentMode: '',
      hideTypeahead: true,
      warmodal: false,
      accordionOpen: true,
      addClientModal: false,
      addSubClientModal: false,
      invoiceDiscount: null,
      totalQuantity: '0',
      subTotal: '0',
      // items: [],
      invoiceNo: null,
      arrData: [],
      clientId: null,
      clientName: null,
      refNo: null,
      cashSale: false,
      image: {},
      imageSelect: false,
      checkConnection: false,
      warranty: 'None',
      checkedBy: null,
      packedBy: null,
      deliveredBy: null,
      cashBy: null,
      reverseCharge: false,
      clientInfo: {},
      bankArr: [{ name: 'None', uid: '' }],
      entryTime: moment().format('LLL'),
      vehicleKey: moment(),
      bankKey: moment() + 1,
      itemListKey: moment() + 2,
      clientKey: moment() + 3,
      displayInvoiceNo: '',
      saveType: '',
      items: []

    }


  }


  componentDidMount() {
    this.loadInvoiceCount()
  }


  calculation = () => {


    let totalQt = 0
    let totalAMt = 0
    let taxableAmount = 0
    _.each(this.state.items, (item) => {
      if (item.quantity) {
        totalQt = totalQt + parseFloat(item.quantity)
      }
      if (item.netAmount) {
        totalAMt = totalAMt + parseFloat(item.netAmount)
      }
      if (item.taxableAmount) {
        taxableAmount = taxableAmount + parseFloat(item.taxableAmount)
      }
    })

    this.setState({
      totalQuantity: totalQt ? totalQt.toFixed(3) : null,
      subTotal: totalAMt ? parseFloat(totalAMt).toFixed(2) : null,
      totalAmount: totalAMt ? parseFloat(totalAMt).toFixed(2) : null,
      taxableAmount: taxableAmount ? parseFloat(taxableAmount).toFixed(2) : null
    }, () => {
      this.calBalance()
    })


  }


  loadInvoiceCount = () => {
    Request.getInventoryPrefix('CREDIT_NOTE').then((testData) => {
      this.setState({ ...testData })
    }).catch((e) => {

    })
  }


  reloadItems = (rr) => {
    this.props.dispatch(showLoader())
    let allIt = this.state.items
    ReloadSaleItems(rr, allIt, (resp) => {
      this.setState({ items: resp }, () => {
        this.calculation()
        this.props.dispatch(hideLoader())
      })

    })


  }

  removeImage() {
    this.setState({
      image: {},
      imageSelect: false
    })
  }

  async createPdf(typestr) {
    let shortGst = saleObj.clientGstNo ? saleObj.clientGstNo.substring(0, 2) : ''
    if (shortGst) {
      shortGst = `(${shortGst})`
    }
    let data = {
      ...saleObj,
      title: 'debit note',
      printType: typestr,
      invoice: {
        address: {
          shopName: saleObj.clientName,
          address1: saleObj.clientAddress
        },
        customer: {
          'gst/in': saleObj.clientGstNo ? saleObj.clientGstNo : ''
        },
        invoiceLeftInfo: {
          'gr/rr no': saleObj.grNo,
          'E-way bill no': saleObj.eWayBillNo
        },
        invoiceInfo: {
          'invoice no': saleObj.invoiceNo,
          'dated': moment(saleObj.purchaseDate).format('DD-MMM-YYYY'),
          'entry time': saleObj.entryTime,
          'place of supply': `${saleObj.stateOfSupply} ${shortGst}`,
          'reverse charge': saleObj.reverseCharge ? 'Y' : 'N',
          'transport by': saleObj.transportType ? saleObj.transportType : '',
          'transport': saleObj.transportName ? saleObj.transportName : '',
          'vehicle no': saleObj.vehicleNo

        }
      }
    }

    html = await PrintHtml(data)

    let doubleHtml = html + `<p style="page-break-after: always;">&nbsp;</p>
<p style="page-break-before: always;">&nbsp;</p>` + html


    if (typestr == 'share') {
      HtmlPrint(doubleHtml)
    } else {
      HtmlPrint(doubleHtml, '/dashboard')
    }
  };

  calDiscount(type) {
    if (type == 'per') {
      setTimeout(() => {
        let dp = this.state.discPer ? this.state.discPer : 0
        let st = this.state.subTotal ? this.state.subTotal : 0

        let invd = (dp / 100) * st
        if (invd) {
          invd = parseFloat(invd).toFixed(2)
          this.setState({ invoiceDiscount: invd, totalAmount: parseFloat(st - invd).toFixed(2) }, () => {
            this.calBalance()
          })

        } else {

          this.setState({ invoiceDiscount: null, totalAmount: st.toString() }, () => {
            this.calBalance()
          })
        }
      }, 240)
    } else {
      setTimeout(() => {

        let st = this.state.subTotal ? this.state.subTotal : 0
        let invd = this.state.invoiceDiscount ? this.state.invoiceDiscount : 0
        let discPer = (invd / st) * 100
        if (discPer) {
          discPer = parseInt(discPer)
          this.setState({ discPer: discPer.toString(), totalAmount: parseFloat(st - invd).toFixed(2) }, () => {
            this.calBalance()
          })

        } else {
          this.setState({ discPer: null, totalAmount: st.toString() }, () => {
            this.calBalance()
          })

        }
      }, 240)
    }

  }

  calBalance() {
    setTimeout(() => {
      let ta = this.state.totalAmount ? this.state.totalAmount : 0
      let ra = this.state.paidAmount ? this.state.paidAmount : 0
      this.setState({ balance: parseFloat(ta - ra).toFixed(2) })
    }, 100)
  }

  componentWillMount() {
    this.initFxn()
  }


  async initFxn() {
    let data = await getUrlParams(
      'debitNote.editDebitNote',
      this.props.pathname
    )
    if (data && data.id) {
      this.getItems(data.id)
    }
  }


  save(type) {


    AsyncStorage.getBusiness().then((value) => {
      if (value !== null) {

        this.setState({ businessId: value.uid })

        let cloneState = _.clone(this.state)


        delete cloneState.arr
        delete cloneState.arrData

        cloneState.businessId = value.uid
        cloneState.purchaseDate = cloneState.date ? new Date(cloneState.date) : null
        cloneState.billDate = cloneState.billDate ? new Date(cloneState.billDate) : null
        cloneState = { ...cloneState, ...cloneState.clientInfo }

        if (!cloneState.businessId) {
          notification.error({
            message: 'Business Setting Not Set '
          })
          return
        }

        if (!cloneState.clientName || !cloneState.clientId) {
          notification.warn({
            message: 'Client name can not be left empty'
          })
          return
        }

        if (cloneState.transportType) {
          if (cloneState.transportType == 'Transport' && !cloneState.transportId) {
            notification.warn({
              message: 'Choose transport'
            })
            this.props.dispatch(hidePageLoad())
            return
          }
          if (!cloneState.vehicleId) {
            notification.warn({
              message: 'Choose Vehicle no'
            })
            this.props.dispatch(hidePageLoad())
            return
          }
        }

        if (!cloneState.totalAmount) {
          notification.warn({
            message: 'Enter Total Amount'
          })
          return
        }


        if (cloneState.receivedAmount && parseFloat(cloneState.receivedAmount) > 0) {
          if (!cloneState.bankId) {
            notification.warn({
              message: 'Please choose bank'
            })
            this.props.dispatch(hidePageLoad())
            return
          }
          if (cloneState.bankType == 'Bank' && !cloneState.paymentType) {
            notification.warn({
              message: 'Please choose payment type'
            })
            this.props.dispatch(hidePageLoad())
            return
          }
        } else {
          cloneState.bankId = ''
          cloneState.bankType = ''
        }

        cloneState.oldItems = oldItems
        cloneState.oldBalance = oldBalance

        this.props.dispatch(showPageLoad())
        Request.UpdateDebitNote(cloneState)

          .then((testData) => {

            this.props.dispatch(hidePageLoad())
            if (testData && testData.type) {
              notification.success({
                message: 'Updated Successfully'
              })


              let smsobj = {
                uid: cloneState.uid,
                type: 'DEBIT_NOTE',
                name: cloneState.clientName,
                msg: `Edit Debit Note :  \n Client = ${cloneState.clientName} \n Total Amount = ${cloneState.totalAmount}`
              }

              // Request.SendSms(smsobj);


              if (this.state.image && this.state.image.name) {
                AsyncStorage.getUserData().then((userResp) => {
                  if (userResp) {
                    let imageObj = {
                      businessId: value.uid,
                      linkId: testData.sale.uid,
                      type: 'DEBIT_NOTE',
                      date: new Date(),
                      userId: userResp.uid
                    }
                    Request.uploadDocument(imageObj, this.state.image).then((resp1) => {

                    }).catch((err) => {


                    })
                  }
                })
              }

              if (type == 'save') {
                window.location.href = '/dashboard'
              } else {
                this.createPdf('print')
              }

              return
            } else {

              notification.error({
                message: 'Try again'
              })
            }


          })
          .catch((e) => {
            notification.error({
              message: 'Try again'
            })
            this.props.dispatch(hidePageLoad())
            return
          })

      } else {
        notification.error({
          message: 'Business Setting Not Set'
        })
        return
      }
    })


  }


  resetAfterSave() {
    saleData = {}
    this.setState({
      state: 'Select',
      vendorBalance: '0',
      purchaseDate: moment(),
      billDate: moment(),
      arr: [],
      dateVisible: false,
      billDateVisible: false,
      paymentMode: '',
      hideTypeahead: true,
      accordionOpen: false,
      addItemModal: false,
      addVendorModal: false,
      totalQuantity: 0,
      totalAmount: 0,
      returnNo: null,
      items: [],
      arrData: [],
      vendorId: null,
      vendorName: null,
      refNo: null,
      image: {},
      imageSelect: false,
      checkConnection: false,
      reverseCharge: false,
      vehicleKey: moment(),
      bankKey: moment() + 1,
      itemListKey: moment() + 2,
      clientKey: moment() + 3,
      entryTime: moment().format('LLL'),
      clientName: '',
      clientGroupId: '',
      clientState: '',
      stateOfSupply: '',
      city: '',
      clientBalance: '',
      clientId: '',
      clientGstNo: '',
      clientAddress: '',
      stateCode: '',
      pinCode: '',
      transportType: '',
      transportId: '',
      transportName: '',
      vehicleId: '',
      vehicleNo: '',
      bankId: '',
      bankType: '',
      paymentDetails: '',
      paymentType: '',
      grNo: '',
      checkedBy: null,
      packedBy: null,
      deliveredBy: null,
      cashBy: null,
      eWayBillNo: null

    })
    setTimeout(() => {
      this.calculation()
    }, 1000)
  }

  getItems(tempId) {
    saleObj = {}
    html = {}
    oldItems = []
    oldBalance = 0
    this.props.dispatch(showPageLoad())
    Request.GetDebitItems({ linkId: tempId })
      .then((saleData) => {


        if (saleData.type) {

          saleData = saleData.saleData

          _.each(saleData, (val, k) => {
            saleObj[k] = val
          })
          if (saleObj.billDate) {
            saleObj.billDate = moment(saleObj.billDate)
          }
          if (saleObj.purchaseDate) {
            saleObj.purchaseDate = moment(saleObj.purchaseDate)
          }

          saleObj.showDisc = false

          _.each(saleData, (value, key) => {
            if (typeof value == 'number') {
              value = value.toString()
            }
          })
          _.each(saleData.items, (sdfs) => {
            sdfs.tid = uuid()
            oldItems.push({
              productUid: sdfs.productUid,
              quantity: sdfs.quantity,
              uid: sdfs.uid,
              linkId: sdfs.saleId
            })
          })

          oldBalance = saleData.balance

          setTimeout(() => {
            this.setState({
              uid: saleData.uid,
              stateOfSupply: saleData.stateOfSupply,
              clientBalance: saleData.clientBalance,
              purchaseDate: saleData.purchaseDate ? moment(saleData.purchaseDate) : null,
              billDate: saleData.billDate ? moment(saleData.billDate) : null,
              isDateTimePickerVisible: false,
              paymentMode: saleData.paymentMode,
              accordionOpen: true,
              items: saleData.items,
              totalQuantity: saleData.totalQuantity,
              totalAmount: saleData.totalAmount.toString(),
              totalTempAmount: saleData.totalAmount.toString(),
              receivedAmount: saleData.receivedAmount ? saleData.receivedAmount.toString() : null,
              invoiceNo: saleData.invoiceNo ? saleData.invoiceNo : null,
              returnNo: saleData.returnNo ? saleData.returnNo.toString() : null,
              balance: saleData.balance ? saleData.balance.toString() : null,
              clientId: saleData.clientId,
              clientName: saleData.clientName,
              refNo: saleData.refNo,
              description: saleData.description,
              imageSelect: saleData.document ? true : false,
              image: {
                uri: saleData.document ? saleData.document : null
              },
              checkedBy: saleData.checkedBy,
              packedBy: saleData.packedBy,
              deliveredBy: saleData.deliveredBy,
              cashBy: saleData.cashBy,
              entryTime: saleData.entryTime,

              transportType: saleData.transportType,
              transportId: saleData.transportId,
              transportName: saleData.transportName,
              vehicleId: saleData.vehicleId,
              vehicleNo: saleData.vehicleNo,
              invoicePrefix: saleData.invoicePrefix,
              invoiceSrNo: saleData.invoiceSrNo,
              grNo: saleData.grNo,
              reverseCharge: saleData.reverseCharge,
              eWayBillNo: saleData.eWayBillNo,
              bankId: saleData.bankId,
              paymentType: saleData.paymentType,
              bankType: saleData.bankType,
              paymentDetails: saleData.paymentDetails,
              bankKey: moment(),
              taxableAmount: saleData.taxableAmount,
              defaultTaxType: saleData.defaultTaxType,
              vehicleKey: moment(),
              itemListKey: moment() + 2,
              clientKey: moment() + 3

            })
            this.props.dispatch(hidePageLoad())
          }, 1000)


        }


      }).catch((e) => {

    })
  }

  fieldUpdate = (localState) => {
    this.setState({ ...localState })
  }

  setInvoiceNo(invoiceNo) {
    let { invoicePrefix } = this.state
    let value = invoiceNo.replace(invoicePrefix, '')
    this.setState({ invoiceNo, invoiceSrNo: parseFloat(value) })
  }

  _setDiscountValue(value, type, discountType) {
    if (value) {
      let splitX = value.split('.')
      if (splitX.length > 2) {
        return true
      }
      if (splitX[1]) {
        if (splitX[1].length > 2) {
          return true
        }
      }
      this.setState({ [type]: value }, () => {
        this.calDiscount(discountType)
      })
    } else {
      this.setState({ [type]: null }, () => {
        this.calDiscount(discountType)
      })
    }

  }

  _setDigitValue(value, type) {
    if (value) {
      let splitX = value.split('.')
      if (splitX.length > 2) {
        return true
      }
      if (splitX[1]) {
        if (splitX[1].length > 2) {
          return true
        }
      }
      this.setState({ [type]: value }, () => {
        this.calBalance()
      })
    } else {
      this.setState({ [type]: null }, () => {
        this.calBalance()
      })
    }

  }

  getBackItems = (obj) => {
    let { items, defaultTaxType } = obj
    this.setState({ items, defaultTaxType }, () => {
      this.calculation()
    })
  }

  onChooseDate = (date, dateString, fieldName) => {
    this.setState({ [fieldName]: date })
  }

  setValue = (e) => {
    let { name, value } = e.target
    this.setState({ [name]: value })
  }

  _callBackBank = (data) => {
    this.setState({ ...data })
  }

  _callbackClient = (data) => {
    this.setState({ ...data })
  }

  _callBackChooseVehicle = (localState) => {
    this.setState({ ...this.state, ...localState })
  }


  render() {
    let { items, purchaseDate, vehicleKey, clientName, clientId } = this.state
    const formData = (
      <Row gutter={16}>
        <Col span={8}>
          <InputBox title={'Date'}>
            <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'purchaseDate')}
                        value={purchaseDate} placeholder={'Date'}/>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Bill Date'}>
            <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'billDate')}
                        value={this.state.billDate} placeholder={'Bill Date'}/>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'GR/RR No'}>

            <Input onChange={this.setValue} name={'grNo'} placeholder={'GR/RR no'} value={this.state.grNo}/>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Reverse Charge'}>
            <Select value={this.state.reverseCharge}>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Eway bill no'}>
            <Input onChange={this.setValue} name={'eWayBillNo'} placeholder={'Eway bill no'}
                   value={this.state.eWayBillNo}/>
          </InputBox>
        </Col>


      </Row>
    )

    const amountFields = (
      <Row gutter={16}>
        <Col span={8}>
          <InputBox title={'Total Amount'}>
            <div className={'ant-input'}>
              {this.state.totalAmount}
            </div>
          </InputBox>
        </Col>
        <Col span={8}>
          <InputBox title={'Paid Amount'}>
            <Input onChange={(e) => {
              this._setDigitValue(e.target.value, 'receivedAmount')
            }} name={'receivedAmount'} placeholder={'Received Amount'}
                   value={this.state.receivedAmount}/>
          </InputBox>
        </Col>
        {!this.state.cashSale ?
          <Col span={8}>
            <InputBox title={'Balance'}>
              <div className={'ant-input'}>
                {this.state.balance}
              </div>
            </InputBox>
          </Col> : null}
      </Row>
    )

    const otherFields = (
      <Row gutter={16}>
        <Col span={8}> <InputBox title={'Description (Optional)'}>
          <Input onChange={this.setValue} name={'description'} placeholder={'Description'}
                 value={this.state.description}/>
        </InputBox></Col>
        <Col span={8}>
          <InputBox title={'Choose Picture'} className={'rowFlex'}>
            <Input type={'file'} accept="image/*" onChange={(e) => {
              if (e.target.files && e.target.files.length) {
                this.setState({ image: e.target.files[0] })
              }
            }}/>
            {this.state.image && this.state.image.uri ?
              <a className={'linkBtn'} target={'_blank'} href={this.state.image.uri}>
                <Icon type={'eye'}></Icon>
              </a> : null}
          </InputBox>
        </Col>
        <Col span={8}> <InputBox title={'Checked By '}>
          <Input onChange={this.setValue} name={'checkedBy'} placeholder={'Checked By'}
                 value={this.state.checkedBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'PackedBy By '}>
          <Input onChange={this.setValue} name={'packedBy'} placeholder={'Packed By'}
                 value={this.state.packedBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'Delivered By '}>
          <Input onChange={this.setValue} name={'deliveredBy'} placeholder={'Delivered By'}
                 value={this.state.deliveredBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'Cash By '}>
          <Input onChange={this.setValue} name={'cashBy'} placeholder={'Cash By'}
                 value={this.state.cashBy}/>
        </InputBox></Col>
        <Col span={8}><InputBox title={'Entry Time'}>
          <Input onChange={this.setValue} name={'entryTime'} placeholder={'Entry Time'}
                 value={this.state.entryTime}/>
        </InputBox></Col>
      </Row>
    )


    return (
      <PageHeaderWrapper
        title={'Edit Debit Note'}
        content={''}>
        <Card bordered={true}
              extra={<div className={'actionGroup'}>
                <Button type="primary" onClick={
                  debounce(() => {
                    this.save('save')
                  }, 1000)
                }>SAVE DEBIT NOTE</Button>

                <Button type="success" onClick={
                  debounce(() => {
                    this.save('share')
                  }, 1000)
                }>SAVE & PRINT</Button>
                <Button type="success" onClick={
                  debounce(() => {
                    this.createPdf('share')
                  }, 1000)
                }>PRINT</Button>


              </div>}>
          <Form hideRequiredMark style={{ marginTop: 8 }} className={'newForm'}>

            <ChooseClientComponent {...this.props} _callbackClient={this._callbackClient} key={this.state.clientKey}
                                   clientName={this.state.clientName} clientId={this.state.clientId}
                                   stateOfSupply={this.state.stateOfSupply}/>


            {formData}

            <ChooseTransport _callBackChooseVehicle={this._callBackChooseVehicle} {...this.props}
                             fieldUpdate={this.fieldUpdate}
                             key={vehicleKey} {...this.state} />

            <ItemListComponent
              key={this.state.itemListKey}
              {...this.props}
              items={this.state.items}
              isDebitNote={true}
              showRef={true}
              clientId={this.state.clientId}
              subTotal={this.state.totalAmount}
              totalQuantity={this.state.totalQuantity}
              getBackItems={this.getBackItems}
              pageParams={{
                pageType: 'Purchase',
                defaultTaxType: this.state.defaultTaxType
              }}
            />
            {amountFields}
            <ChooseBank {...this.props} key={this.state.bankKey}
                        callBackBank={this._callBackBank} {...this.state}/>

            {otherFields}
          </Form>


        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDebitNote)
