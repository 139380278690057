import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Table,
  notification,
  Drawer
} from 'antd'
import Request from '../../../request'

import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader, showPageLoad, hidePageLoad } from '../../../modules/actions'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { AsyncStorage } from '../../../asyncStorage'
import { filterOption } from '../../../components/_utils/_utils'

const FormItem = Form.Item
const { Option } = Select

@Form.create()
class EditContraEntry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bankArr: [],
      fromBankId: '',
      toBankId: ''
    }
    this.input = React.createRef()
  }

  componentDidMount() {
    this.loadBanks()

  }

  setFieldFxn() {
    let { contraEntryObj } = this.props
    this.props.form.setFieldsValue({
      fromBankName: contraEntryObj.fromBankName,
      toBankName: contraEntryObj.toBankName,
      amount: contraEntryObj.amount,
      date: moment(contraEntryObj.date),
      description: contraEntryObj.description,
      entryTime: contraEntryObj.entryTime
    })
    this.setState({
      toBankId: contraEntryObj.toBankId ? contraEntryObj.toBankId : '',
      toBankType: contraEntryObj.toBankType ? contraEntryObj.toBankType : '',
      toBankName: contraEntryObj.toBankName ? contraEntryObj.toBankName : '',
      fromBankId: contraEntryObj.fromBankId ? contraEntryObj.fromBankId : '',
      fromBankType: contraEntryObj.fromBankType ? contraEntryObj.fromBankType : '',
      fromBankName: contraEntryObj.fromBankName ? contraEntryObj.fromBankName : ''
    }, () => {
      this.props.dispatch(hidePageLoad())
    })

  }


  loadBanks() {
    let { dispatch } = this.props
    dispatch(showPageLoad())
    let bankArr = [{ name: 'None', bankId: '', bankType: '' }]
    Request.AllBank().then((resp) => {
      _.each(resp, (item) => {
        let { name, type, uid } = item
        bankArr.push({
          name, type, uid
        })
      })

      let { contraEntryObj } = this.props
      this.setState({
        bankArr,
        toBankId: contraEntryObj.toBankId ? contraEntryObj.toBankId : '',
        toBankType: contraEntryObj.toBankType ? contraEntryObj.toBankType : '',
        toBankName: contraEntryObj.toBankName ? contraEntryObj.toBankName : '',
        fromBankId: contraEntryObj.fromBankId ? contraEntryObj.fromBankId : '',
        fromBankType: contraEntryObj.fromBankType ? contraEntryObj.fromBankType : '',
        fromBankName: contraEntryObj.fromBankName ? contraEntryObj.fromBankName : ''
      }, () => {

      })

      setTimeout(() => {
        this.props.form.setFieldsValue({
          fromBankName: contraEntryObj.fromBankName,
          toBankName: contraEntryObj.toBankName,
          amount: contraEntryObj.amount,
          date: moment(contraEntryObj.date),
          description: contraEntryObj.description,
          entryTime: contraEntryObj.entryTime
        })
        this.props.dispatch(hidePageLoad())
      }, 500)
    })
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        AsyncStorage.getBusiness().then(async (resp) => {
          let { contraEntryObj } = this.props
          valData = { ...valData, ...this.state }
          delete valData['bankArr']

          if (resp && resp.uid) {
            valData.uid = contraEntryObj.uid
            valData.businessId = resp.uid

            if (valData.date) {
              valData.dateString = moment(valData.date).format('DD-MMM-YYYY').toString()
            }

            if (!valData.fromBankId) {
              notification.warn({
                message: 'Choose from bank'
              })
              return
            }

            if (!valData.toBankId) {
              notification.warn({
                message: 'Choose to bank'
              })
              return
            }
            if (valData.fromBankId == valData.toBankId) {
              notification.warn({
                message: 'Please choose valid banks'
              })
              return
            }
            if (!valData.amount || valData.amount <= 0) {
              notification.warn({
                message: 'Enter amount'
              })
              return
            }
            dispatch(showPageLoad())

            let x = await Request.UpdateContraEntry(valData)
            dispatch(hidePageLoad())
            if (x.type) {
              notification.success({
                message: x.msg
              })
              this.props.form.setFieldsValue({})
              this.props.addContraEntryCallback()
            } else {
              notification.error({
                message: 'Error Saving',
                description: x.msg
              })
            }
          } else {
            notification.error({
              message: 'Choose Business',
              description: ''
            })
          }
        })
      }
    })
  }


  render() {
    const { submitting } = this.props
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props

    const { editorState, bankArr } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 }
      }
    }

    let { visible } = this.props


    let inputTypes = {
      fields: [
        {
          label: 'From Bank (Debit)',
          key: 'toBankName',
          required: true,
          type: 'select',
          allowClear: true,
          showSearch: true,
          valueAccessor: x => x.name,
          keyAccessor: x => x.uid,
          options: bankArr,
          onChange: v => {
            let findArr = _.find(bankArr, (item) => {
              return item.uid == v
            })
            if (findArr) {
              this.props.form.setFieldsValue({
                toBankName: findArr.name ? findArr.name : ''
              })
              this.setState({
                toBankId: findArr.uid ? findArr.uid : '',
                toBankType: findArr.type ? findArr.type : '',
                toBankName: findArr.name ? findArr.name : ''
              })
            }
          }
        },
        {
          label: 'To Bank (Credit)',
          key: 'fromBankName',
          required: true,
          type: 'select',
          allowClear: true,
          showSearch: true,
          valueAccessor: x => x.name,
          keyAccessor: x => x.uid,
          options: bankArr,
          onChange: v => {
            let findArr = _.find(bankArr, (item) => {
              return item.uid == v
            })
            if (findArr) {
              this.props.form.setFieldsValue({
                fromBankName: findArr.name ? findArr.name : ''
              })
              this.setState({
                fromBankId: findArr.uid ? findArr.uid : '',
                fromBankType: findArr.type ? findArr.type : '',
                fromBankName: findArr.name ? findArr.name : ''
              })
            }
          }
        },
        {
          label: 'Amount',
          key: 'amount',
          required: true,
          type: 'number',
          placeholder: 'Amount'
        },
        {
          label: 'Date',
          key: 'date',
          required: true,
          type: 'date',
          placeholder: 'Date'
        },
        {
          label: 'Description (Optional)',
          key: 'description',
          type: 'textArea',
          placeholder: 'description'
        },
        {
          label: 'Entry Time',
          key: 'entryTime',
          placeholder: 'Entry Time'
        }
      ]
    }

    return (
      <Drawer
        title="Edit Contra Entry"
        width={800}
        closable={false}
        onClose={this.props.onContraEntryClose}
        visible={visible}>
        <Card bordered={true}>
          <Form hideRequiredMark style={{ marginTop: 8 }}>
            <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
                              getFieldDecorator={getFieldDecorator}/>

            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit" loading={this.props.loading} onClick={this.handleSubmit}>
                UPDATE
              </Button>
            </Form.Item>

          </Form>
        </Card>
      </Drawer>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContraEntry)
