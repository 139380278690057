const printStyle = (
  `<style>

        body {
            font-size: 12px;
            line-height: 1;
              -webkit-print-color-adjust: exact !important;

        }

        body:after, .panel-body:before, .row:after, .row:before {
            display: table;
            content: " ";
            clear: both;

        }

        .container {
            padding: 10px 0 0;
            border: 1px solid #6f6f6d;
        }

        * {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }

        div {
            display: block;
        }

        .row {
            /*  margin-right: -15px;
              margin-left: -15px;*/
        }


        .col-md-4 {
            width: 33.33333333%;
        }
        .col-md-5 {
            width: 40%;
        }

        .col-md-6 {
            width: 50%;
        }

        .col-md-12 {
            width: 100%;

        }

        [class*="col-"] {
            float: left;
            position: relative;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
        }

        .border {
            /*border: 1px solid #ddd;*/
            border: 1px solid #6f6f6d;
        }

        .border-bottom {
            border-bottom: 1px solid #6f6f6d;
        }

        .border-right {
            border-right: 1px solid #6f6f6d;
        }

        .center {
            text-align: center !important;
        }

        .right {
            text-align: right !important;
        }

        .b {
            font-weight: bold;
        }


        .header {
            line-height: 1.8em;
        }

        .title {
            font-size: 24px;
            font-weight: bold;
        }

        .taxInvoice {
            font-size: 12px;
            padding-bottom: 5px;
            text-decoration: underline;
            text-transform: uppercase;
        }

        .padding20 {
            padding: 10px;
        }
        .padding10 {
            padding: 10px;
        }

        .padding40 {
            padding: 40px;
        }
        .p5 {
            padding: 5px;
        }

        table {
            width: 100%;
            font-size: 18px;
        }

        .upperCase {
            text-transform: uppercase;
        }

        .capCase {
            text-transform: capitalize;
        }
        
        .box1 .col-md-6 {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        
        .box2 .col-md-6:first-child{
            padding: 0!important;
        }
        

        .box2 .col-md-6 {
            min-height: 150px;
            padding-top: 10px;
        }

        .paymentBox .col-md-6 {
            min-height: 100px;
            padding-top: 10px;
        }

        .itemTable {
        }

        .itemTable tr {
            /* border: 1px solid #6f6f6d; */
        }

        .itemTable tr th, .itemTable tr td {
            text-align: left;
            padding: 2px 5px;
        }

        .itemTable tr th {
            border: 1px solid #6f6f6d;
            border-right: 0;
            border-top: 0;
        }

        .itemTable tr td {
            border-left: 1px solid #6f6f6d;
        }

        .itemTable tr td:last-child, .itemTable tr th:last-child {
            border-right: 1px solid #6f6f6d;
        }

        .itemTable tr:last-child td {
            border-top: 1px solid #6f6f6d;
            border-left: 0;
        }

        .itemTable tr:last-child td:last-child {
            border: 1px solid #6f6f6d;
        }


        .itemTable tr td:nth-child(1), .itemTable tr th:nth-child(1) {
            border-left: 0;
        }

        .itemTable tr td:last-child, .itemTable tr th:last-child {
            border-right: 0;
        }

        .bankInfo div {
            display: inline-flex;
        }


        .font8 {
            font-size: 8px;
        }

        .font10 {
            font-size: 10px;
        }
        .font12 {
            font-size: 12px;
        }

        .font14 {
            font-size: 14px;
        }

        .font16 {
            font-size: 16px;
        }


        .font18 {
            font-size: 18px;
        }

        .footerBlock {
            min-height: 60px;
        }
        
        .paymentFooterBlock {
            min-height: 100px;
        }

        .footerUl {
            margin-top:0px;
            list-style: decimal;
        }

        .receiverSignature {
            height: 80px;
            padding: 0 15px;
        }
        .receiverSignature {
            height: 80px;
            padding: 0 15px;
        }

        .authSignature {
            padding: 15px 15px 0;
            text-align: right;
            clear: both;
        }

        .shopName {
            height: 80px;
        }

        .padding0 {
            padding: 0;
        }

        .taxTable tr th {
            border-bottom: 1px solid black;
        }

        .taxTable tr th, .taxTable tr td {
            text-align: center;
        }

        .taxTable tr th:first-child, .taxTable tr td:first-child {
            text-align: left;
        }

        .taxTable tr th:last-child, .taxTable tr td:last-child {
            text-align: right;
        }

        .marginTop10 {
            margin-top: 10px;
        }
        
        .marginBottom10 {
            margin-bottom: 10px;
        }
        
        .paddingV10{
            padding-top: 5px;
            padding-Bottom: 5px;
        }
        .paddingH0{
            padding-left: 0;
            padding-right: 0;
        }
        
        
        page[size="A4"] {  
             width: 21cm;
             height: 29.7cm; 
        }
        
        .flexStart{
            display: flex;
            justify-content: center;
            align-items: flex-start;
        }

        
        .itemTable tr.evenCls {
            background-color: #f2f2f2;
        }

        
        .alternate_table tr:nth-child(even), .alternate_table tr:nth-child(even) td {
            background-color: #f2f2f2!important;
        }
        .alternate_table tr:nth-child(old), .alternate_table tr:nth-child(old) td  {
            background-color: #fff!important;
        }

        .zIndex99{
             z-index: 99!important;
        }

        .signClas{
            height:80px;
            width:150px;
            backgroundColor:yellow;
        }
        .lastbox{
                   min-height:105px;  
                   padding-top:10px;
                   
                   text-align:right;
        }

        .alignRight:{
            text-align:right;
        }
       
        @media print {
            @page {
               margin: 10px;
            }
        
            table td {
               line-height: 1!important;
            }
            .headerBox{
                line-height: 1.4!important;
            }
            body{
                line-height: 1!important;
            }
            .col-md-4 {
                width: 50%;
            }
            
            .col-md-5 {
                width: 70%;
             }
              body, page {
                 margin: 0;
                 box-shadow: none;
             }
            
        }

            .letter-sp {
                letter-spacing: 8px;
            }
        
            .sheetTable tr td {
                text-align: right;
                width: 25%;
            }
            .sheetTable tr td:first-child {
                width: 50%;
            }
        
            .sheetTable tr td:first-child {
                text-align: left;
                padding-left: 0!important;
            }
        
            .border-right-dashed {
                border-right: 1px #6f6f6d dashed;
            }
        
            .height100pr {
                height: 100%;
            }
        
            .marginTop20 {
                margin-top: 20px;
            }
        
            .border-top-bottom {
                border-top: 1px solid black;
                border-Bottom: 1px solid black;
            }
            .border-top {
                border-top: 1px solid black;
                
            }
            .border-left {
                border-left: 1px solid black;
                
            }
            
            .paddingLeft15{
              padding-left: 12px;
            }
            .head1{
                padding-left: 3px;
            }
            .bankList div{
               margin-right: 20px;
            }
            
            @media print {
   
          
       .invoice-box table thead.heading th {
           background: #eee;
           border-bottom: 1px solid #ddd;
           font-weight: bold;
       }
     .tableBody tr:nth-child(even){
            background-color: #f2f2f2;
       }
}

    </style>`
)

export default printStyle
