import React, { Component } from 'react'
import { Col, Divider, Drawer, Icon, Select, Row, Card, Form, notification, Button, Input, Checkbox, Table } from 'antd'
import { hideLoader, showLoader } from '../../modules/actions'
import AddItem from '../Items/add'
import Request from '../../request'
import lodash from 'lodash'
import async from 'async'
import debounce from 'lodash/debounce'
import { fixed2Digit, AmountInDigit, TaxTypeList, filterOption } from '../../components/_utils/_utils'
import { InputBox } from './appDes'
import { v4 as uuid } from 'uuid'

const FormItem = Form.Item
const { Option } = Select
const { TextArea } = Input

let categoryId = ''
let clientVendorId = ''
let pageType = ''
let catArr = []
let tempArr = []

@Form.create()

class AddItems extends React.Component {
  constructor(props) {
    super()
    let { params } = props

    this.state = {
      arr: [],
      total: 0,
      temp: null,
      isModalVisible: false,
      search: '',
      userType: '',
      catArr: [],
      categoryId: '',
      categoryName: '',
      cArr: [],
      selected: new Map(),
      // selected: [],
      brandArr: [],
      brandId: '',
      brandName: ''
    }

  }


  componentDidMount() {
    this.loadCategory()
    this.loadBrands()
    // this.AllProducts();

    clientVendorId = this.props.params.clientVendorId
    pageType = this.props.params.pageType


  }

  loadCategory = () => {
    Request.AllCategory()
      .then((testData) => {
        /*catArr = [{categoryId: "", categoryName: "--Category--"}];
        lodash.each(testData, (ff) => {
            catArr.push({categoryId: ff.uid, categoryName: ff.name});
        });*/

        catArr = []
        lodash.each(testData, (ff) => {
          catArr.push({ categoryId: ff.uid, categoryName: ff.name, id: ff.uid, name: ff.name })
        })

        this.setState({ catArr: catArr })
      })
      .catch((e) => {
        return
      })
  }

  AllProducts = () => {
    this.setState({ total: 0 })
    this.props.dispatch(showLoader())
    let { isCreditNote = false, isDebitNote = false } = this.props.params
    if (this.state.categoryId || this.state.brandId) {
      Request.AllItemsList({
        categoryId: this.state.categoryId,
        brandId: this.state.brandId,
        clientVendorId: clientVendorId,
        isCreditNote: isCreditNote,
        isDebitNote: isDebitNote

      })
        .then((testData) => {

          const userType = this.state.userType
          let cloneArr = lodash.clone(testData.arr)


          lodash.each(cloneArr, (rtt) => {


            rtt.userType = userType

          })
          this.setState({ arr: cloneArr, total: testData.total })
          this.props.dispatch(hideLoader())
          return
        })
        .catch((e) => {
          this.props.dispatch(hideLoader())
          return
        })
    } else {
      this.setState({ arr: [], total: 0 })
      this.props.dispatch(hideLoader())
      return
    }
  }


  loadBrands = () => {
    let brandArr = []
    Request.loadBrands()
      .then((testData) => {
        if (testData.success) {
          brandArr = []
          lodash.each(testData.brandArr, (ff) => {
            brandArr.push({ brandId: ff.uid, brandName: ff.name, id: ff.uid, name: ff.name })
          })
          this.setState({ brandArr })
        }

      })
      .catch((e) => {
        return
      })

  }


  _getPrice(amount, item) {
    amount = parseFloat(amount)
    if (item.gst == 'Exempted') {
      item.gst = 0
    } else {
      item.gst = parseFloat(item.gst)
    }
    if (item.gstInclusive) {
      amount = amount * 100 / (100 + item.gst)
      return amount.toFixed(2)
    } else {
      return amount.toFixed(2)
    }
  }


  finalChoose() {

    let { visible, chooseItemClose, chooseItemCallback } = this.props
    let { cArr } = this.state
    console.log(cArr)
    async.each(cArr, (val, cb) => {
      let findIn = lodash.find(tempArr, (ff) => {
        return ff.uid == val.uid
      })

      if (findIn) {

        try {


          if (pageType && pageType == 'Purchase') {
            val.salePrice = val.purchasePrice ? val.purchasePrice.toString() : null
            val.salePrice = val.salePrice ? parseInt(val.salePrice) : null
          }
          val.salePrice = this._getPrice(val.salePrice, val) // get price base on tax include or exclude by rk

          val.quantity = parseInt(findIn.qty)
          if (val.salePrice && val.quantity) {
            val.subTotal = parseFloat(val.quantity * val.salePrice) // replace parse int to parse float by rk
            val.gstAmount = 0
            if (val.gst) {
              val.gstAmount = parseFloat(parseFloat((val.gst / 100) * (val.subTotal)).toFixed(2))  // relace parse int to parse float by rk
            }
            val.netAmount = parseFloat((val.subTotal + val.gstAmount).toFixed(2)) // value parse by rk
          }
          cb()
        } catch (e) {
          console.log(e)
          cb()()
        }
      }
    }, () => {
      this.props.chooseItemCallback(cArr)
    })
  }


  notAllow() {
    notification.error({
      message: 'You are not allowed.'
    })
    return
  }


  _selectedCategory(item) {
    categoryId = item && item.categoryId ? item.categoryId : ''
    this.setState({
      categoryName: item && item.categoryName ? item.categoryName : '',
      categoryId: item && item.categoryId ? item.categoryId : ''
    }, () => {
      this.AllProducts()
    })
  }

  _selectBrand(item) {
    this.setState({
      brandId: item && item.brandId ? item.brandId : '',
      brandName: item && item.brandName ? item.brandName : ''
    }, () => {
      this.AllProducts()
    })
  }

  _onPressItem = (val) => {
    this.setState(state => {
      const selected = new Map(state.selected)
      selected.set(val.uid, !selected.get(val.uid))
      return { selected }
    })

    let tempArr = this.state.cArr
    let findIn = lodash.find(tempArr, (it) => {
      return it.uid == val.uid
    })

    if (findIn) {
      tempArr = lodash.reject(tempArr, (it) => {
        return it.uid == val.uid
      })
      this.setState({ cArr: tempArr })
    } else {
      tempArr.push(val)
      this.setState({ cArr: tempArr })
    }
  }


  render() {
    let { visible, chooseItemClose, chooseItemCallback } = this.props
    let { catArr, brandArr, arr, selected } = this.state
    let columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        render: (val, record) => {
          return (
            <div style={{ display: 'flex' }}>
              <Checkbox selected={!!selected.get(record.uid)} key={record.uid}
                        onChange={(e) => this._onPressItem(record)}/>
              <div style={{ marginLeft: 10 }}>
                {val}
                {record.code ? <small>({record.code})</small> : null}

              </div>
            </div>
          )
        }
      },
      {
        title: 'Info',
        key: 'uid',
        dataIndex: 'uid',
        render: (val, record) => {
          return (
            <div style={{ width: 500 }}>
              <Row gutter={16} border={true}>
                <Col span={6}>Stock</Col>
                <Col span={6}><b>{record.stock}</b></Col>
                <Col span={6}>Purchase Price</Col>
                <Col span={6}><b>{record.purchasePrice ? record.purchasePrice : ''}</b></Col>
              </Row>
              <Row gutter={16} style={{ backgroundColor: '#f9f9f9' }}>
                <Col span={6}>Sale Price</Col>
                <Col span={6}><b>{record.salePrice ? record.salePrice : ''}</b></Col>
                <Col span={6}>Mrp</Col>
                <Col span={6}><b>{record.mrp ? record.mrp : ''}</b></Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>Wholesale</Col>
                <Col span={6}><b>{record.wholesalePrice ? record.wholesalePrice : ''}</b></Col>
                <Col span={6}>Avg Price</Col>
                <Col span={6}><b>{record.avgPrice ? record.avgPrice : ''}</b></Col>
              </Row>
            </div>
          )
        }
      },
      {
        title: 'Qty',
        key: 'quantity',
        dataIndex: 'quantity',
        render: (val, record) => {
          return (
            <div key={record.uid}>
              <InputBox title={''}>
                <Input type={'number'} onChange={(e) => {
                  let findIn = lodash.find(tempArr, (val) => {
                    return val.uid == record.uid
                  })
                  if (findIn) {
                    findIn.qty = e.target.value
                  } else {
                    tempArr.push({ qty: e.target.value, uid: record.uid })
                  }
                }}/>
              </InputBox>
            </div>
          )
        }
      }
    ]

    return (
      <Drawer
        width={1000}
        visible={visible}
        onClose={chooseItemClose}>
        <Card title="Choose Item" className={'customCard'}>
          <Form className={'newForm'}>

            <Row gutter={16}>
              <Col span={8}>
                <InputBox title={'Choose Category'}>
                  <Select value={this.state.categoryName}
                          onChange={(category) => this._selectedCategory(category)}
                          showSearch={true} filterOption={filterOption} allowClear={true}>
                    {catArr.map((item, index) => {
                      return (
                        <Option value={item} key={item.id}>{item.name}</Option>
                      )
                    })}
                  </Select>
                </InputBox>
              </Col>
              <Col span={8}>
                <InputBox title={'Choose Brand'}>
                  <Select value={this.state.brandName}
                          onChange={(brand) => this._selectBrand(brand)}
                          showSearch={true} filterOption={filterOption} allowClear={true}>
                    {brandArr.map((item, index) => {
                      return (
                        <Option value={item} key={item.id}>{item.name}</Option>
                      )
                    })}
                  </Select>
                </InputBox>
              </Col>
              <Col span={8}>
                <InputBox title={'Choose Brand'}>
                  <Button onClick={() => this.finalChoose()}>
                    Choose {this.state.cArr.length} Item
                  </Button>
                </InputBox>
              </Col>
            </Row>

            <Table dataSource={arr} bordered={true}
                   pagination={false} size={'small'}
                   columns={columns}
            />
          </Form>
        </Card>
      </Drawer>
    )
  }
}

export default AddItems
