import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Table,
  notification,
  Drawer,
  Tag,
  Popconfirm
} from 'antd'
import Request from '../../../request'
import lodash from 'lodash'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader } from '../../../modules/actions'
import { connect } from 'react-redux'
import { AsyncStorage } from '../../../asyncStorage'
import { InputBox } from '../../webComponent/appDes'


const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AddGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transport: '',
      type: '',
      currentPrefix: '',
      prefixDoc: {}
    }
    //this.props.dispatch(showLoader());
  }


  componentDidMount() {
    this.loadAllPrefixByInvoiceType()
  }

  async loadAllPrefixByInvoiceType() {
    let { selectedPrefix } = this.props
    Request.PrefixByInvoiceType({ invoiceType: selectedPrefix.invoiceType })
      .then((testData) => {
        console.log(testData)
        this.setState({ prefixDoc: testData })
        return
      })
      .catch((e) => {
        return
      })
  }

  deletePrefixItem(deleteItem) {
    let { prefixDoc } = this.state
    let obj = {
      currentPrefix: deleteItem,
      prefixId: prefixDoc.uid,
      invoiceType: prefixDoc.invoiceType
    }
    this.props.dispatch(showLoader())
    Request.deletePrefix(obj).then((testData) => {
      console.log(testData)
      if (testData && testData.type) {
        notification.success({
          message: testData.msg
        })
        this.loadAllPrefixByInvoiceType()
        this.props.dispatch(hideLoader())
        this.props.onPrefixClose()
      } else {
        notification.error({
          message: testData.msg
        })
        this.loadAllPrefixByInvoiceType()
        this.props.dispatch(hideLoader())
        this.props.onPrefixClose()
      }

    })
      .catch((e) => {
        notification.error({
          message: e
        })
        this.props.dispatch(hideLoader())
        return
      })

  };

  selectCurrentPrefix(item) {
    let { prefixDoc } = this.state
    let obj = {
      prefixId: prefixDoc.uid,
      currentPrefix: item,
      invoiceType: prefixDoc.invoiceType
    }
    this.props.dispatch(showLoader())
    Request.setCurrentPrefix(obj).then((testData) => {
      notification.success({
        message: testData.msg
      })
      this.loadAllPrefixByInvoiceType()
      this.props.dispatch(hideLoader())
      this.props.onPrefixClose()
    })
      .catch((e) => {
        notification.error({
          message: e
        })
        this.props.dispatch(hideLoader())
        return
      })
  }

  save() {
    AsyncStorage.getBusiness().then((value) => {

      if (value !== null) {

        let obj = {
          businessId: value.uid,
          currentPrefix: this.state.currentPrefix,
          invoiceType: this.props.selectedPrefix.invoiceType
        }
        if (!obj.currentPrefix) {
          notification.error({
            message: 'Enter prefix'
          })
          return
        }

        this.props.dispatch(showLoader())
        Request.AddPrefix(obj)
          .then((testData) => {
            // this.props.addPrefix(testData);
            this.setState({
              currentPrefix: ''
            })
            if (testData.type) {
              notification.success({
                message: testData.msg
              })
              this.loadAllPrefixByInvoiceType()
              this.props.dispatch(hideLoader())
            } else {
              notification.error({
                message: testData.msg
              })
              this.props.dispatch(hideLoader())
            }


          })
          .catch((e) => {
            notification.error({
              message: e
            })
            this.props.dispatch(hideLoader())
            return
          })
      } else {
        notification.warn({
          message: 'Business Setting Not Set'
        })
        return
      }
    })
  }

  render() {
    let { prefixDoc } = this.state

    let { visible, selectedPrefix } = this.props

    let columns = [
      {
        title: 'Prefix',
        key: 'prefix',
        dataIndex: 'prefix',
        render: (val, record) => {
          return (
            <div>
              <Button onClick={() => this.selectCurrentPrefix(record)} type={'link'}>
                {record}
              </Button>
            </div>
          )
        }
      },
      {
        title: '',
        key: 'status',
        dataIndex: 'status',
        render: (val, record) => {
          return (
            <div>
              {record == prefixDoc.currentPrefix ? <Tag>
                Active
              </Tag> : ''}
            </div>
          )
        }
      },
      {
        title: '',
        key: 'action',
        dataIndex: 'action',
        render: (val, record) => {
          return (
            <Popconfirm placement="top" title={'Are you sure to remove Prefix?'}
                        onConfirm={() => this.deletePrefixItem(record)}
                        okText="Yes" cancelText="No">
              <Button>
                <Icon type="delete"/>
              </Button>
            </Popconfirm>
          )
        }
      }

    ]

    return (
      <Drawer
        title={`Add ${selectedPrefix.name} Prefix`}
        width={800}
        closable={false}
        onClose={this.props.onPrefixClose}
        visible={visible}>
        <Card bordered={true}>
          <InputBox title={'Prefix'} className={'rowFlex'}>
            <Input name={'name'} value={this.state.currentPrefix}
                   onChange={(e) => this.setState({ currentPrefix: e.target.value })}/>
            <Button block onClick={
              lodash.debounce(this.save.bind(this), 200)
            }>
              Save
            </Button>
          </InputBox>

          <Table dataSource={prefixDoc.allPrefix} size={'small'} pagination={false}
                 columns={columns}
          />
        </Card>
      </Drawer>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddGroup)
