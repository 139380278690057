import _ from 'lodash'

const warranty = (
  `<div class="row border-bottom">
        <div class="col-md-12 center padding20 font8">
            <div class="taxInvoice b ">WARRANTY POLICY</div>
            <div class="upperCase">
                all complaints should be attend by companies tollfree number only <br/>
                no responsibility of shopkeeper if company refuse to attend customers complaint
            </div>

        </div>
    </div>`
)
const bankInfo = (bankDetails) => {
  let bankList = ''
  _.each(bankDetails, (item) => {
    bankList += `<div class="upperCase">
                ${item.bankName}, ${item.bankAddress}
                <br/>
                a/c no. ${item.accountNo} ifsc code :- ${item.ifsc}
            </div>`
  })
  let info = `<div class="row border-bottom">
        <div class="col-md-12 padding20 bankInfo font8">
            <div class="b">Bank Details :</div>
           <div class="bankList">
                    ${bankList}
            </div>
        </div>
    </div>`
  return info
}
const getCondition = (conditions) => {
  let conditionsList = ''
  _.each(conditions, (item) => {
    conditionsList += `<li>${item}</li>`
  })
  return conditionsList
}

const signature = (singObj, businessDetails) => {
    let { sign, signMobile, signName, date } = singObj
    if (!date) {
      date = ''
    }
    if (!sign) {
      sign = ''
    }

    if (!signMobile) {
      signMobile = ''
    }

    if (!signName) {
      signName = ''
    }


    return (`<div class="row border-bottom">
    <div class="col-md-12 footerBlock border-right paddingV10 font8">
        <div class="">Terms & Condition</div>
        <div>E.& O.E</div>
        <ul class="footerUl">
           <!-- <li>Goods once sold will not be taken back.</li>
            <li>Interest @18% p.a. will be charged if the payment is not made with in the stipulated time.</li>
            <li>Subject to 'KARNAL' Jurisdiction only.</li>-->
            ${businessDetails && businessDetails.conditions ? getCondition(businessDetails.conditions) : ''}
        </ul>
    </div>

    <div class="row ">
        <div class="col-md-6 border-top paddingV10">
            ${sign ? `<img src="${sign}" class="signClas"/>` : `<div  class="signClas"></div>`}
            ${signMobile || signName ? `<div class="font10" style="margin-bottom: 5px">
                ${signName} ${signName && signMobile ? `(${signMobile})` : signMobile ? signMobile : ''}
            </div>` : ''}
            <div class="font10">Receiver's Signature: ${date}</div>
        </div>
        <div class="border-left col-md-6 border-top paddingV10 lastbox">
            <div class="shopName font10 upperCase">
                ${businessDetails.name}
            </div>
            <div class="font10">
                Authorised Signatory
            </div>

        </div>
    </div>
</div>`
    )
  }


const footer = (singObj, businessDetails) => {
  return (`${warranty} ${bankInfo(businessDetails.bankDetails)} ${signature(singObj, businessDetails)}`)
}


export default footer
