import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Table,
  notification,
  Drawer, Divider
} from 'antd'
import Request from '../../../request'

import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader, showPageLoad, hidePageLoad } from '../../../modules/actions'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import debounce from 'lodash/debounce'

import { AsyncStorage } from '../../../asyncStorage'
import { filterOption } from '../../../components/_utils/_utils'
import { FilterChooseClient } from '../../webComponent/appDes'
import { InputBox } from '../../webComponent/appDes'

const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AddJournalEntry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: moment(),
      arrData: [],
      refClientName: null,
      refClientId: null,
      refClient: null,
      newClient: '',
      invoiceRefArr: [],
      refInvoice: []
    }
    this.input = React.createRef()
    this.fetchClient = debounce(this.fetchClient, 500)
    setTimeout(() => {
      props.form.setFieldsValue({ entryTime: moment().format('LLL') })
    }, 1000)
  }

  componentDidMount() {
  }

  AllClient = (parrm) => {
    Request.AllClient(parrm)
      .then((testData) => {
        let clientArr = []
        _.each(testData, (item) => {
          clientArr.push(item)
        })
        this.setState({ arrData: clientArr })
        return
      })
      .catch((e) => {
        return
      })

  }


  fetchClient = (data) => {
    let arrData = []
    Request.AllClient({ name: data })
      .then((testData) => {
        _.each(testData, (item) => {
          arrData.push(item)
        })
        this.setState({ arrData })
        return
      })
      .catch((e) => {
        return
      })
  }


  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        AsyncStorage.getBusiness().then(async (resp) => {
          valData = { ...valData, ...this.state }
          delete valData['arrData']
          console.log(valData)
          if (resp && resp.uid) {
            valData.businessId = resp.uid

            if (valData.date) {
              valData.dateString = moment(valData.date).format('DD-MMM-YYYY').toString()
            }

            if (!valData.fromClientId) {
              notification.warn({
                message: 'Choose from client'
              })
              return
            }

            if (!valData.toClientId) {
              notification.warn({
                message: 'Choose to client'
              })
              return
            }
            if (valData.fromClientId == valData.toClientId) {
              notification.warn({
                message: 'Please choose valid client'
              })
              return
            }
            if (!valData.amount || valData.amount <= 0) {
              notification.warn({
                message: 'Enter amount'
              })
              return
            }
            dispatch(showPageLoad())

            let x = await Request.AddJournalEntry(valData)
            dispatch(hidePageLoad())
            if (x.type) {
              notification.success({
                message: x.msg
              })
              this.props.form.setFieldsValue({})
              this.props.addJournalEntryCallback()
            } else {
              notification.error({
                message: 'Error Saving',
                description: x.msg
              })
            }
          } else {
            notification.error({
              message: 'Choose Business',
              description: ''
            })
          }
        })
      }
    })
  }

  chooseFromClient = (item) => {
    this.setState({
      fromClientName: item.name,
      fromClientId: item.uid,
      fromClient: {
        clientGroupId: item.clientGroupId,
        clientName: item.name,
        clientState: item.state,
        stateOfSupply: item.state,
        city: item.city,
        clientBalance: item.currentBalance ? parseInt(item.currentBalance) : 0,
        clientId: item.uid,
        clientGstNo: item.gstNumber,
        clientAddress: item.address
      }
    })
  }
  chooseToClient = (item) => {
    console.log(item)
    this.setState({
      toClientName: item.name,
      toClientId: item.uid,
      toClient: {
        clientGroupId: item.clientGroupId,
        clientName: item.name,
        clientState: item.state,
        stateOfSupply: item.state,
        city: item.city,
        clientBalance: item.currentBalance ? parseInt(item.currentBalance) : 0,
        clientId: item.uid,
        clientGstNo: item.gstNumber,
        clientAddress: item.address
      }
    }, () => {
      console.log(this.state)
    })
  }
  chooseRefClient = (item) => {
    this.setState({
      refClientName: item.name,
      refClientId: item.uid,
      refClient: {
        clientGroupId: item.clientGroupId,
        clientName: item.name,
        clientState: item.state,
        stateOfSupply: item.state,
        city: item.city,
        clientBalance: item.currentBalance ? parseInt(item.currentBalance) : 0,
        clientId: item.uid,
        clientGstNo: item.gstNumber,
        clientAddress: item.address
      },
      invoiceRefArr: [],
      refInvoice: [],
      hideToTypeahead: true
    }, () => {
      this.LoadRefInvoices()
    })
  }


  onSelectedItemsChange = (refInvoice) => {
    console.log(refInvoice)
    this.setState({ refInvoice })
  }

  async LoadRefInvoices() {
    let clientId = this.state.refClientId
    let { fromClientName, toClientName } = this.state
    let resp = await Request.loadJournalInvoicesRef({ clientId, fromClientName, toClientName })
    console.log(resp)
    let invoiceRefArr = []
    _.each(resp, (item) => {
      let date = item.saleDate ? item.saleDate : item.purchaseDate ? item.purchaseDate : null
      if (date) {
        date = moment(date)
      }
      item.label = `${item.invoiceNo} (Rs. ${item.totalAmount} | ${date})`
      invoiceRefArr.push({ ...item })
    })
    this.setState({ invoiceRefArr })
  }


  render() {
    const { submitting } = this.props
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props

    const { editorState, arrData, fromClientName, toClientName, invoiceRefArr } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 }
      }
    }

    let { visible } = this.props


    let inputTypes = {
      fields: [

        {
          label: 'Amount',
          key: 'amount',
          required: true,
          type: 'number',
          placeholder: 'Amount'
        },
        {
          label: 'Date',
          key: 'date',
          required: true,
          type: 'date',
          placeholder: 'Date'
        },
        {
          label: 'Description (Optional)',
          key: 'description',
          type: 'textArea',
          placeholder: 'description'
        },
        {
          label: 'Entry Time',
          key: 'entryTime',
          placeholder: 'Entry Time'
        }
      ]
    }

    return (

      <Drawer
        title="Add Journal Entry"
        width={800}
        closable={false}
        onClose={this.props.onJournalEntryClose}
        visible={visible}>
        <Card bordered={true}>
          <Form hideRequiredMark style={{ marginTop: 8 }}>
            <Row gutter={16}>
              <Col className="mb30">
                <InputBox title={'From Client (Debit)'} type={'inline'}>
                  <FilterChooseClient allowNew={true} _callbackClient={this.chooseToClient}/>
                </InputBox>
              </Col>
              <Col className="mb30">
                <InputBox title={'To Client (Credit)'} type={'inline'}>
                  <FilterChooseClient allowNew={true} _callbackClient={this.chooseFromClient}/>
                </InputBox>
              </Col>
              {fromClientName == 'Discount A/c' || toClientName == 'Discount A/c' ?
                <Col className="mb30">
                  <InputBox title={'Ref Client'} type={'inline'} className="mb30">
                    <FilterChooseClient allowNew={true} _callbackClient={this.chooseRefClient}/>
                  </InputBox>
                  <InputBox title={'Invoice Ref'} type={'inline'}>
                    <Select value={this.state.refInvoice}
                            optionFilterProp="children"
                            mode={'multiple'}
                            filterOption={(input, options) => {
                              return options.props.children.match(new RegExp(input, 'ig'))
                            }}
                            onChange={(e) => this.onSelectedItemsChange(e)}>
                      {invoiceRefArr && invoiceRefArr.length ? invoiceRefArr.map((item, key) => {
                        return (
                          <Option key={item.uid} value={item.uid}>{item.label}</Option>
                        )
                      }) : null}
                    </Select>

                  </InputBox>

                </Col>
                : null}
            </Row>

            <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
                              getFieldDecorator={getFieldDecorator}/>

            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit" loading={this.props.loading} onClick={this.handleSubmit}>
                SAVE
              </Button>
            </Form.Item>

          </Form>
        </Card>
      </Drawer>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddJournalEntry)
