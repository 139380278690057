import _ from 'lodash'
import { AmountInWords } from './_utils'

const taxList = (product, data, isCgst) => {
  return new Promise((resolve) => {
    let { totalAmount, receivedAmount, extraCharges } = data
    let gstField = (amount) => {
      return isCgst ?
        `<td>${(amount / 2).toFixed(2)}</td>
                 <td>${(amount / 2).toFixed(2)}</td>`
        : `<td>${(amount).toFixed(2)}</td>`
    }
    let taxRow = ''
    _.each(product, (item, key) => {
      taxRow += `<tr>
                        <td>${key}%</td>
                        <td>${product[key].taxableAmount ? (product[key].taxableAmount).toFixed(2) : 0}</td>
                        ${gstField(product[key].gstAmount)}
                        <td>${product[key].netAmount ? (product[key].netAmount).toFixed(2) : 0}</td>
                    </tr>`
    })

    _.times(4 - Object.values(product).length, (item) => {
      taxRow += `<tr style="height: 10px">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>`
    })

    let html = `<div class="row border-bottom">
        <div class="col-md-12">
            <div class="font12 capCase b">
            ${extraCharges ? `Extra Charges : ${extraCharges}` : ''}
            </div>
        </div>
        <div class="col-md-3">
            <table class="taxTable font10">
                <tr>
                    <th>Tax Rate</th>
                    <th>Taxable Amt.</th>
                    ${isCgst ? '<th>CGST Amt.</th><th>SGST Amt.</th>' : '<th>IGST Amt.</th>'}
                    <th>Total Amt.</th>
                </tr>
               ${taxRow}
            </table>
        </div>
        <div class="col-md-12 marginTop10">
            <div class="font12 capCase b">
                ${AmountInWords(data.totalAmount)} only
            </div>
            <div class="font10 capCase b">
                Party - ${totalAmount} ${receivedAmount ? ` - ${receivedAmount} = ${(totalAmount - receivedAmount).toFixed(0)}` : ''}
            </div>
            <br/>
        </div>
    </div>`

    resolve(html)
  })
}

export default taxList

