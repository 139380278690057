import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon, Row, Col, DatePicker
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper, getPushPathWrapper } from '../../../routes'
import { InputBox } from '../../webComponent/appDes'

class AllUsers extends Component {

  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      fromDate: moment().startOf('month'),
      toDate: moment(),
      userType: ''
    }
    this.table = React.createRef()
  }

  componentDidMount() {
  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params = {
        from: this.state.fromDate,
        to: this.state.toDate,
        count: this.state.count ? this.state.count : 75
      }

      let { result, total } = await Request.AllPurchase(params)
      resolve({ data: result })
    })
  }

  onChooseDate(date, stringDate, type) {
    this.setState({ [type]: date }, () => {
      this.table.current.reload()
    })
  }


  render() {
    let { dispatch } = this.props
    let { fromDate, toDate } = this.state

    const columns = [
      {
        title: 'Invoice No',
        key: 'invoiceNo',
        dataIndex: 'invoiceNo'
      },
      {
        title: 'Vendor Invoice No',
        key: 'vendorInvoiceNo',
        dataIndex: 'vendorInvoiceNo'
      },
      {
        title: 'Vendor',
        key: 'clientName',
        dataIndex: 'clientName'
      },
      {
        title: 'Client Group',
        key: 'groupName',
        dataIndex: 'groupName'
      },
      {
        title: 'State',
        key: 'stateOfSupply',
        dataIndex: 'stateOfSupply'
      },
      {
        title: 'Image',
        key: 'document',
        dataIndex: 'document',
        render: (val) => {
          return (
            <div>
              {val ? 'Yes' : 'No'}
            </div>
          )
        }
      },
      {
        title: 'Info',
        key: 'uid',
        dataIndex: 'uid',
        render: (val, item) => {
          return (
            <div>
              <ul>
                <li>Total Qty : {item.totalQuantity}</li>
                <li>Total Amt : {item.totalAmount ? (item.totalAmount).toFixed(2) : ''}</li>
                <li>Paid Amt : {item.paidAmount ? (item.paidAmount).toFixed(2) : 0}</li>
                <li>Balance : {(item.balance).toFixed(2)}</li>
                <li>Purchase Date : {moment(item.purchaseDate).format('DD/MMM/YYYY')}</li>
              </ul>
            </div>
          )
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (val, record) => {
          return (
            <div className={'actionGroup'}>
              <Tooltip title="Edit">
                <Button
                  shape="circle"
                  icon="edit"
                  onClick={() => {
                    dispatch(
                      getPushPathWrapper('purchase.editPurchase', {
                        id: record.uid
                      })
                    )
                  }}
                />
              </Tooltip>

            </div>
          )
        }
      }
    ]
    return (
      <PageHeaderWrapper
        title={'All Purchase'}>

        <Card bordered={true}>
          <Row gutter={16}>
            <Col span={'8'}>
              <InputBox title={'FROM DATE'}>
                <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'fromDate')}
                            value={fromDate}
                            placeholder={'From'}/>
              </InputBox>
            </Col>
            <Col span={'8'}>
              <InputBox title={'TO DATE '}>
                <DatePicker onChange={(date, stringDate) => this.onChooseDate(date, stringDate, 'toDate')}
                            value={toDate}
                            placeholder={'To'}/>
              </InputBox>
            </Col>
          </Row>
          <TableComp columns={columns} apiRequest={this.apiRequest}
                     ref={this.table}
                     pagination={false}
          />
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllUsers)
