import React, { Component } from 'react'
import {
  notification, Collapse, Card, Button,
  Table, Row, Col, Popconfirm, Select, Icon, Input
} from 'antd'
import _ from 'lodash'
import Async from 'async'
import { ItemsReCalculate, fixed2Digit, TaxTypeList } from '../../components/_utils/_utils'
import AddItems from './AddItems'
import ChooseItems from './ChooseItems'
import { v4 as uuid } from 'uuid'
import Styles from './styles.less'
import { InputBox } from './appDes'
import { showPageLoad, hidePageLoad } from '../../modules/actions'

const { Panel } = Collapse
const { Option } = Select

let removeId = ''

class ItemListComponent extends Component {
  constructor(props) {
    super()
    this.state = {
      accordionOpen: true,
      items: [],
      cloneItems: [],
      defaultTaxType: 'Default',
      updateTaxType: null,
      updateTaxModal: false,
      editItem: {}
    }
  }

  componentDidMount() {
    let { items, defaultTaxType = 'Default' } = this.props
    items = _.sortBy(items, (item, key) => {
      return item.index
    })
    ItemsReCalculate(items, (resp) => {
      this.setState({ items: resp, cloneItems: resp, defaultTaxType }, () => {
        this.call_back_item()
      })
    })

    // this.setState({items, defaultTaxType});

  }

  /*  openAddIt = (val) => {
      this.props.navigation.navigate('AddIt', {
        clientVendorId: this.props.clientId,
        editItem: val,
        pageType: this.props.pageType,
        textEditAble: this.props.textEditAble,
        isCreditNote: this.props.isCreditNote,
        isDebitNote: this.props.isDebitNote,
        showRef: this.props.showRef,
        defaultTaxType: this.state.defaultTaxType,
        item_call_back: (nextProps) => {


        }
      })
    }*/


  closeremovePop = () => {
    this.setState({ removeItemModal: false })
  }

  deleteItem = (removeId) => {
    this.setState({ removeItemModal: false })
    if (removeId) {
      let irr = this.state.cloneItems

      irr = _.reject(irr, (gg) => {
        return gg.tid == removeId
      })

      this.setState({ items: irr, cloneItems: irr }, () => {
        this.call_back_item()
      })


      notification.success({
        message: 'Item Deleted Successfully'
      })
      return

    } else {
      notification.warn({
        message: 'Try again to delete'
      })
      return
    }
  }

  call_back_item() {
    let { cloneItems, defaultTaxType } = this.state
    _.each(cloneItems, (item, key) => {
      item.index = key
    })
    this.props.getBackItems({ items: cloneItems, defaultTaxType })
  }

  openAllProduct() {
    this.props.navigation.navigate('AllProductsForChoose', {
      clientVendorId: this.props.clientId,
      /*isCreditNote: this.props.isCreditNote,
      isDebitNote: this.props.isDebitNote,*/
      onGoBack: (itArr) => {

        let alArr = []
        _.each(itArr, (val) => {
          let tempobj = {
            productName: val.name,
            productUid: val.uid,
            hsnCode: val.hsnCode,

            discount: null,
            discountAmount: null,

            secondDiscountPercent: null,
            secondDiscountAmount: null,

            unitCode: val.unitCode,
            unitName: val.unitName,
            unitId: val.unitId,

            salePrice: val.salePrice,
            mrp: val.mrp,
            wholesalePrice: val.wholesalePrice,
            actualSalePrice: val.salePrice,
            actualPurchasePrice: val.purchasePrice,
            categoryId: val.categoryId,
            categoryName: val.categoryName,
            quantity: val.quantity ? val.quantity : null,
            gst: val.gst,
            gstAmount: val.gstAmount,
            netAmount: val.netAmount ? val.netAmount : null,
            subTotal: val.subTotal ? val.subTotal : null,
            itemWarranty: val.itemWarranty ? val.itemWarranty : null,
            brandName: val.brandName ? val.brandName : null,
            brandId: val.brandId ? val.brandId : null,
            subBrandName: val.subBrandName ? val.subBrandName : null,
            subBrandId: val.subBrandId ? val.subBrandId : null

          }
          alArr.push(tempobj)
        })
        let tempAr = this.state.items
        _.each(alArr, (rtt) => {
          tempAr.push(rtt)
        })
        this.setState({ items: tempAr, cloneItems: tempAr }, () => {
          this.call_back_item()
        })
      }
    })
  };

  allItemsTaxUpdate() {
    let { cloneItems, updateTaxType } = this.state
    if (!updateTaxType) {
      notification.warn({
        message: 'Please Choose Update Tax Type? '
      })
      return
    }
    this.props.dispatch(showPageLoad())
    Async.each(cloneItems, (item, cb) => {
      if (updateTaxType == 'Exclusive GST') {
        item.gstInclusive = false
      }
      if (updateTaxType == 'Inclusive GST') {
        item.gstInclusive = true
      }
      cb()
    }, () => {
      ItemsReCalculate(cloneItems, (resp) => {
        this.setState({ items: resp, cloneItems: resp, updateTaxModal: false }, () => {
          this.props.dispatch(hidePageLoad())
          this.call_back_item()
        })
      })
    })
  }

  showTaxUpdateModal() {
    let { updateTaxType } = this.state
    if (!updateTaxType) {
      notification.warn({
        message: 'Please Choose Update Tax Type? '
      })
      return
    }
    this.setState({ updateTaxModal: true })
  }

  searchProduct() {
    let { searchPro, cloneItems, items } = this.state
    items = _.filter(cloneItems, (value) => {
      return value.productName.match(new RegExp(searchPro, 'ig'))
    })
    this.setState({ items })
  }

  clearSearch() {
    let { searchPro, cloneItems, items } = this.state
    this.setState({ items: cloneItems, searchPro: null })
  }


  addItemClose = () => {
    this.setState({ visibleAddItem: false })
  }
  chooseItemClose = () => {
    this.setState({ visibleChooseItem: false })
  }
  addItemCallback = (data) => {
    console.log(data)
    let cloneData = this.state.cloneItems
    let foundIndex = cloneData.findIndex((x) => {
      return (x.tid == data.tid)
    })

    if (foundIndex != -1) {
      cloneData[foundIndex] = data
    } else {
      cloneData.push(data)
    }


    let obj = {
      items: cloneData,
      cloneItems: cloneData
    }
    if (data.saveType !== 'new') {
      obj.visibleAddItem = false
    }
    this.setState({ ...obj }, () => {
      this.call_back_item()
    })

  }
  chooseItemCallback = (itArr) => {

    let alArr = []
    _.each(itArr, (val) => {
      let tempobj = {
        tid: uuid(),
        productName: val.name,
        productUid: val.uid,
        hsnCode: val.hsnCode,

        discount: null,
        discountAmount: null,

        secondDiscountPercent: null,
        secondDiscountAmount: null,

        unitCode: val.unitCode,
        unitName: val.unitName,
        unitId: val.unitId,

        salePrice: val.salePrice,
        mrp: val.mrp,
        wholesalePrice: val.wholesalePrice,
        actualSalePrice: val.salePrice,
        actualPurchasePrice: val.purchasePrice,
        categoryId: val.categoryId,
        categoryName: val.categoryName,
        quantity: val.quantity ? val.quantity : null,
        gst: val.gst,
        gstAmount: val.gstAmount,
        netAmount: val.netAmount ? val.netAmount : null,
        subTotal: val.subTotal ? val.subTotal : null,
        itemWarranty: val.itemWarranty ? val.itemWarranty : null,
        brandName: val.brandName ? val.brandName : null,
        brandId: val.brandId ? val.brandId : null,
        subBrandName: val.subBrandName ? val.subBrandName : null,
        subBrandId: val.subBrandId ? val.subBrandId : null
      }
      alArr.push(tempobj)
    })
    let tempAr = this.state.items
    _.each(alArr, (rtt) => {
      tempAr.push(rtt)
    })
    this.setState({ items: tempAr, cloneItems: tempAr, visibleChooseItem: false }, () => {
      this.call_back_item()
    })

  }

  render() {
    let { items, visibleAddItem, visibleChooseItem } = this.state
    let { totalQuantity, subTotal, showRef = false, title = 'Items' } = this.props
    let columns = [
      {
        title: 'Product',
        key: 'productName',
        dataIndex: 'productName',
        render: (val, record) => {
          return (
            <div onClick={() => this.setState({ visibleAddItem: true, editItem: record })}>
              <span className={Styles.bSize}>{record.productName} </span>
              <span className={Styles.sSize}>({record.gstInclusive ? 'Inclusive' : 'Exclusive'})</span>
              <span className={Styles.sSize}> (Per Pcs : Rs. {(record.netAmount / record.quantity).toFixed(2)})</span>
            </div>
          )
        }
      },
      {
        title: 'Price',
        key: 'salePrice',
        dataIndex: 'salePrice',
        render: (val) => {
          return `Rs.${val}`
        }
      },
      {
        title: 'Disc',
        key: 'discount',
        dataIndex: 'discount',
        render: (val, record) => {
          return (
            <div>

              {
                record.discountAmount && record.discountAmount > 0 ? <div>
                  {record.disType == 'disInPer' ? `Rs.${record.salePrice} X ${record.discount}% = Rs.${record.discountAmount}` : record.discountAmount ? `Rs.${record.discountAmount}` : null}
                </div> : null
              }


            </div>
          )
        }
      },
      {
        title: 'Sec Disc',
        key: 'secondDiscountPercent',
        dataIndex: 'secondDiscountPercent',
        render: (val, record) => {
          return (
            <div>
              {/*  {record.secondDiscountAmount ? <div>
                <span>{record.salePrice - record.discountAmount} X </span>
                <span>{record.disType == 'disInPer' ? record.secondDiscountPercent ? record.secondDiscountPercent : 0 : null} = </span>
                <span>{record.secondDiscountAmount ? record.secondDiscountAmount : 0}</span>
              </div> : 0}*/}


              {
                record.secondDiscountAmount && record.secondDiscountAmount > 0 ? <div>
                  {record.disType == 'disInPer' ? `Rs.${record.salePrice - record.discountAmount} X ${record.secondDiscountPercent}% = Rs.${record.secondDiscountAmount}` : record.secondDiscountAmount ? `Rs.${record.secondDiscountAmount}` : null}
                </div> : null
              }

            </div>
          )
        }
      },
      {
        title: 'Sub Total',
        key: 'quantity',
        dataIndex: 'quantity',
        render: (val, record) => {
          return (
            <div>
              <span>{record.quantity} {record.unitCode ? (record.unitCode) : null} X Rs.{(record.subTotal / record.quantity).toFixed(2)} = </span>
              <span>
                Rs.{fixed2Digit(parseFloat(record.taxableAmount))}
              </span>
            </div>
          )
        }
      },
      {
        title: 'GST',
        key: 'gst',
        dataIndex: 'gst',
        render: (val, record) => {
          return (
            <div>
              <span>Rs.{fixed2Digit(parseFloat(record.taxableAmount))} X </span>
              <span>{record.gst ? `${record.gst}%` : 0} =  </span>
              <span>Rs.{record.gstAmount}</span>
            </div>
          )
        }
      },
      {
        title: 'Total',
        key: 'netAmount',
        dataIndex: 'netAmount',
        render: (val) => {
          return `Rs.${val}`
        }
      },
      {
        title: 'Action',
        key: 'tid',
        dataIndex: 'tid',
        render: (item, record) => {
          return (
            <Popconfirm placement="top" title={'Are you sure to remove item? '}
                        onConfirm={() => this.deleteItem(record.tid)}
                        okText="Yes" cancelText="No">
              <Button type={'danger'} size={'small'}>
                <Icon type={'cross'}/>
              </Button>
            </Popconfirm>
          )
        }
      }
    ]
    return (
      <Row style={{ marginTop: 10, marginBottom: 10 }} gutter={16}>
        <Col span={8}>
          <InputBox title={'Search Items'} className={'rowFlex'}>
            <Input name={'searchPro'} value={this.state.searchPro} placeholder={'Search Items'}
                   onChange={(e) => {
                     this.setState({ searchPro: e.target.value }, () => {
                       this.searchProduct()
                     })
                   }}/>
            <a className={'linkBtn'} onClick={() => this.clearSearch()}>
              <Icon type={'cross'}/>
            </a>
          </InputBox>

        </Col>
        <Col span={8}>
          <InputBox title={'Default Tax Type'}>
            <Select
              onChange={(e) => this.setState({ defaultTaxType: e }, () => {
                this.call_back_item()
              })}
              value={this.state.defaultTaxType}>
              {TaxTypeList.map((item, index) => {
                return (
                  <Option key={item.label} value={item.label}>{item.label}</Option>
                )
              })}
            </Select>
          </InputBox>

        </Col>
        <Col span={24}>
          <Collapse defaultActiveKey={['1']} className={'padding0'}>
            <Panel header={`${title} (${items.length})`} key="1">
              <div style={{ marginTop: 0 }} className={'alignCenter'}>
                <Button onClick={() => this.setState({ visibleAddItem: true })}>
                  Add Items
                </Button>{' '}
                <Button onClick={() => this.setState({ visibleChooseItem: true })}>
                  Choose Items
                </Button>
              </div>
              <div className={Styles.outerDiv}>
                <div className={Styles.headerDiv}>
                  <div>
                    <b>Total Quantity : </b>
                  </div>
                  <div>{totalQuantity}</div>
                  <div>
                    <b>Sub Total : </b>
                  </div>
                  <div>
                    {subTotal}
                  </div>
                </div>
              </div>
              <Table dataSource={items} columns={columns} size={'small'} className={'alternateTable'}
                     pagination={false} bordered={true}/>


            </Panel>
          </Collapse>
        </Col>
        <Col span={8} style={{ marginTop: 10 }}>
          <InputBox title={'Change Tax Type'} className={'rowFlex'}>
            <Select
              onChange={(e) => this.setState({ updateTaxType: e })}
              value={this.state.updateTaxType}>
              {TaxTypeList.map((item, index) => {
                return (
                  <Option key={item.label} value={item.label}>{item.label}</Option>
                )
              })}
            </Select>
            <Popconfirm title={'Update Tax'} onConfirm={() => this.allItemsTaxUpdate()}
                        okText="Yes" cancelText="No">
              <Button>
                Update
              </Button>
            </Popconfirm>
          </InputBox>
        </Col>
        {visibleAddItem &&
        <AddItems
          chooseItemClose={this.addItemClose}
          chooseItemCallback={this.addItemCallback}
          params={{
            ...this.props.pageParams,
            clientVendorId: this.props.clientId,
            editItem: this.state.editItem,
            isCreditNote: this.props.isCreditNote,
            isDebitNote: this.props.isDebitNote,
            showRef: this.props.showRef
          }}
          visible={visibleAddItem} {...this.props}/>}

        {visibleChooseItem &&
        <ChooseItems
          chooseItemClose={this.chooseItemClose}
          chooseItemCallback={this.chooseItemCallback}
          params={{
            ...this.props.pageParams,
            clientVendorId: this.props.clientId,
            editItem: this.state.editItem,
            isCreditNote: this.props.isCreditNote,
            isDebitNote: this.props.isDebitNote,
            showRef: this.props.showRef
          }}
          visible={visibleChooseItem} {...this.props}/>}
      </Row>
    )
  }
}

export default ItemListComponent
