import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input,
  Button,
  Icon,
  Row,
  Col,
  Tag, Divider, Select, Form
} from 'antd'
import Request from '../../../request'
import { connect } from 'react-redux'
import { hideLoader, showLoader, showPageLoad, hidePageLoad } from '../../../modules/actions'
import AddContraEntry from '../add/index'
import EditContraEntry from '../edit/index'

const FormItem = Form.Item
const { Option } = Select


class AllContraEntry extends Component {

  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      contraEntryObj: {}
    }
    this.table = React.createRef()
  }


  componentDidMount() {
    this.AllContraEntry()

  }

  AllContraEntry = () => {
    this.props.dispatch(showPageLoad())
    Request.GetContraEntry({})
      .then((testData) => {
        this.setState({ arr: testData.result })
        this.props.dispatch(hidePageLoad())
        return
      })
      .catch((e) => {
        this.props.dispatch(hidePageLoad())
        return
      })

  }
  events = {
    onContraEntryClose: () => {
      this.setState({ addContraEntryVisible: false, editContraEntryVisible: false })
    },
    addContraEntryCallback: () => {
      this.setState({ addContraEntryVisible: false, editContraEntryVisible: false }, () => {
        this.AllContraEntry()
      })
    },
    eddContraEntry: (contraEntryObj) => {
      this.setState({ editContraEntryVisible: true, contraEntryObj })
    }
  }


  render() {
    let { pageType, title, arr, addContraEntryVisible, editContraEntryVisible, contraEntryObj } = this.state
    const columns = [
      {
        title: 'Voucher',
        key: 'invoiceNo',
        dataIndex: 'invoiceNo'
      },
      {
        title: 'Debit (From)',
        dataIndex: 'toBankName',
        key: 'toBankName',
        render: (val, record) => {
          return (
            <div>
              {val} {record.toBankType ? `\n(${record.toBankType})` : null}
            </div>
          )
        }
      },

      {
        title: 'Credit (To)',
        dataIndex: 'fromBankName',
        key: 'fromBankName',
        render: (val, record) => {
          return (
            <div>
              {val} {record.fromBankType ? `\n(${record.fromBankType})` : null}
            </div>
          )
        }
      },

      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (val) => {
          return (
            <div>
              Rs. {val ? val.toFixed(2) : null}
            </div>
          )
        }
      },
      {
        title: 'By',
        dataIndex: 'username',
        key: 'username',
        render: (val) => {
          return (
            <div>
              By {val}
            </div>
          )
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (val, record) => {
          return (
            <Button onClick={() => this.events.eddContraEntry(record)}>
              Edit
            </Button>
          )
        }
      }
    ]


    return (
      <PageHeaderWrapper
        title={`Contra Entry`}>
        <Card bordered={true}
              extra={<Button onClick={() => this.setState({ addContraEntryVisible: true })}>Add Contra Entry</Button>}>

          <Table columns={columns} size={'small'} bordered
                 ref={this.table}
                 dataSource={arr}
                 pagination={false}
          />
        </Card>

        {addContraEntryVisible ?
          <AddContraEntry
            addContraEntryCallback={this.events.addContraEntryCallback}
            onContraEntryClose={this.events.onContraEntryClose}
            visible={addContraEntryVisible}
            {...this.props}
          />
          : null}

        {editContraEntryVisible ?
          <EditContraEntry
            addContraEntryCallback={this.events.addContraEntryCallback}
            onContraEntryClose={this.events.onContraEntryClose}
            contraEntryObj={contraEntryObj}
            visible={editContraEntryVisible}
            {...this.props}
          />
          : null}
      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllContraEntry)
