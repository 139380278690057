import React, { Component } from 'react'

class Dashboard extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={'asdf'}>
        <h1>This page is under construction </h1>
      </div>
    )
  }

}

export default Dashboard

