import axios from 'axios'

import { apiUrl, verifyGstKey } from './settings'
import { AsyncStorage } from './asyncStorage'
import lodash from 'lodash'

export const API_URL = apiUrl

let authAxios = axios.create({
  baseURL: apiUrl
})
export let customAxios = axios.create({
  baseURL: apiUrl
})


export let getToken = () => {
  return ({ 'headers': { 'authorization': localStorage.getItem('token') } })
}

export let getBusinessId = () => {
  let resp = localStorage.getItem('business')
  if (resp) {
    resp = JSON.parse(resp)
  }
  return ({ businessId: resp.uid })
}


class Request {

  constructor() {

  }

  error = (err) => {
    try {
      if (err.response.status === 401) {
        localStorage.clear()
      }
    } catch (e) {
    }
  }

  /* login(data) {
     return new Promise((next, error) => {
       authAxios
         .post('/api/loginDirect', data)
         .then((d) => {
           next(d.data)
         })
         .catch((err) => {
           next({ error: true, err })
           this.error(err)
         })

     })
   }*/

  login(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/login', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  getAllUser(data) {
    return new Promise((next) => {
      authAxios
        .get('/users', { params: { ...data } }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  addUser(data) {
    return new Promise((next) => {
      authAxios
        .post('/users', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }


  //<editor-fold desc="Business">

  AddBusiness(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/business', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  UpdateBusiness(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/updateBusiness', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  singleBusiness(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/getSingleBusiness', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  AllBusiness() {
    return new Promise((next, error) => {
      authAxios
        .get('/api/getAllBusiness')
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  disableApp() {
    return new Promise((next, error) => {
      authAxios
        .get('/api/disablePost')
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  //</editor-fold>

  //<editor-fold desc="Party Groups">

  AddGroup(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/addGroup', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  AllGroups(data = {}) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .get('/api/getAllGroup', { params: { ...data } }, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })

    })

  }

  AllGroupsList(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/allGroupsList', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })

    })
  }

  //</editor-fold>

  //<editor-fold desc="Client">

  AddClient(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/addClient', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  AllClientList(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/getAllClientList', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })

    })
  }

  GetSingleClient(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/getSingleClient', { clientId: data })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  ClientActivityList(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/clientActivityList', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  calBalan(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/clientBalanceCalculation', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  openingQuery(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/openingQuery', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  EditClient(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/editClient', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  AllGroupParty(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/allGroupParty', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  AllClient(data) {
    return new Promise((next, error) => {

      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid

          /*  authAxios
                .post("/api/getAllClient", data)
                .then((d) => {
                    next(d.data);
                })
                .catch((err) => {
                    error(err);
                });
*/


          let makeRequestCreator = () => {
            var call
            return function() {
              if (call) {
                call.cancel()
              }
              call = axios.CancelToken.source()
              return authAxios.post('/api/getAllClient', { ...data },
                { cancelToken: call.token }).then((d) => {
                next(d.data)
              }).catch(function(thrown) {

                if (axios.isCancel(thrown)) {

                  console.log('First request canceled', thrown.message)
                } else {

                }

                error('err')
              })
            }
          }

          var get = makeRequestCreator()
          get()
        } else {

        }
      })

    })


  }


  archiveClient(uid) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/archiveClient', { uid }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  //</editor-fold>

  //<editor-fold desc="Tax">

  AddTax(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/tax', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  AllTaxes(data) {
    return new Promise((next, error) => {
      let taxList = [
        { tax: 'Exempted' },
        { tax: 5 },
        { tax: 12 },
        { tax: 18 },
        { tax: 28 }
      ]
      next(taxList)

    })
  }


  AllTaxesList(data) { // new tax api without Exempted obj for tax list by rk
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/getAllTax', { businessId: resp.uid })
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }


  //</editor-fold>

  //<editor-fold desc="Unit">

  AddUnit(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/unit', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  AllUnits(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/getAllUnit', { businessId: resp.uid })
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  //</editor-fold>

  //<editor-fold desc="Category">

  AddCategory(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/category', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  AllCategory(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/getAllCategory', { businessId: resp.uid })
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }

  distinctCat(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/distinctCat', { businessId: resp.uid })
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }


  deleteCategory(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/deleteCategory', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  UpdateCategory(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/updateCategory', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  //</editor-fold>

  //<editor-fold desc="Products">

  AddProduct(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/addProduct', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  AllItemsList(data) {


    return new Promise((next, error) => {


      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid

          /* authAxios
               .post("/api/allProducts", data)
               .then((d) => {
                   next(d.data);
               })
               .catch((err) => {
                   error(err);
               });
*/

          let makeRequestCreator = () => {
            var call
            return function() {

              if (call) {
                call.cancel()
              }
              call = axios.CancelToken.source()

              return authAxios.post('/api/allProducts', { ...data }, { cancelToken: call.token }).then((d) => {
                next(d.data)
              }).catch(function(thrown) {
                if (axios.isCancel(thrown)) {
                } else {
                }
                error('err')
              })
            }
          }
          var getFxn = makeRequestCreator()
          getFxn()

          // lodash.debounce(makeRequestCreator(), 1000)
        }
      })


    })
  }

  AllItemsOnly(data) {


    return new Promise((next, error) => {


      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/allItems', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })


        }
      })


    })
  }

  EditProduct(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/updateProduct', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  DeleteProduct(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/deleteProduct', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  GetItems(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/getItems', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  GetItemsForEditSale(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/getItemsForEditSale', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  GetEstimateItems(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/getEstimateItems', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  AllProductReport(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/allProductReport', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }

  SingleProductProfitLoss(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/singleProductProfitLoss', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  //</editor-fold>

  //<editor-fold desc="Warranty">

  AddWarranty(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/warranty', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  AllWarranty() {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/getAllWarranties', { businessId: resp.uid })
            .then((d) => {

              next(d.data)
            })
            .catch((err) => {
              console.log(err)
              error(err)
            })
        }
      })

    })
  }

  //</editor-fold>


  // <editor-fold desc="Warranty">

  AddSaleCat(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/addSaleCat', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })
    })
  }

  UpdateSaleCategory(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/UpdateSaleCategory', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  AllSaleCat() {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/getAllSaleCat', { businessId: resp.uid })
            .then((d) => {

              next(d.data)
            })
            .catch((err) => {
              console.log(err)
              error(err)
            })
        }
      })

    })
  }

  //</editor-fold>


  //<editor-fold desc="Home">
  GetHomeData = () => {


    return new Promise((next, error) => {
      try {

        let obj = {
          receive: 0,
          pay: 0
        }

        AsyncStorage.getBusiness().then((value) => {
          if (value !== null) {

            authAxios
              .post('/api/homeStat', { businessId: value.uid }, getToken())
              .then((d) => {
                next(d.data)
              })
              .catch((err) => {
                error(err)
              })


            /*async.parallel([
                (c) => {
                    let DeletedArr = realm.objects("Client").filtered(" businessId = \"" + value.uid + "\"").sum("currentBalance");
                    if (DeletedArr) {
                        obj.receive = parseInt(DeletedArr);
                        c();
                    } else {
                        c();
                    }
                },
                (c) => {
                    let DeletedArr = realm.objects("Client").filtered(" businessId = \"" + value.uid + "\"AND currentBalance < \"" + 0 + "\"").sum("currentBalance");
                    if (DeletedArr) {
                        obj.pay = parseInt(DeletedArr);
                        c();
                    } else {
                        c();
                    }
                },
            ], () => {
                next(obj);
            });*/

          } else {

            next(obj)

          }
        })

      } catch (e) {
        console.log('er', e)
        error(e)
      }
    })
  }

  AllRece = (parrm) => {
    return new Promise((next, error) => {
      try {


        AsyncStorage.getBusiness().then((value) => {
          if (value !== null) {

            parrm.businessId = value.uid
            authAxios
              .post('/api/allReceivable', { ...parrm }, getToken())
              .then((d) => {
                next(d.data)
              })
              .catch((err) => {
                error(err)
              })


          } else {
            next([])
          }
        })


      } catch (e) {
        error(e)
      }
    })

  }
  AllPay = (parrm) => {
    return new Promise((next, error) => {
      try {

        AsyncStorage.getBusiness().then((value) => {
          if (value !== null) {

            parrm.businessId = value.uid
            authAxios
              .post('/api/allPayable', { ...parrm }, getToken())
              .then((d) => {
                next(d.data)
              })
              .catch((err) => {
                error(err)
              })


          } else {
            next([])
          }
        })


      } catch (e) {
        error(e)
      }
    })

  }


  //</editor-fold>
  //<editor-fold desc="Sale">

  AddSale(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/addSale', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  UpdateSale(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/updateSale', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  deleteSale(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/deleteSale', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  AllSale(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/allSale', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })


    })
  }


  GetSaleItems(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/getSaleItems', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  GetSalePL(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/GetSalePL', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  //</editor-fold>


  //<editor-fold desc="Estimate">

  AddEstimate(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/addEstimate', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  UpdateEstimate(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/updateEstimate', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  deleteEstimate(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/deleteEstimate', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  //</editor-fold>


  // <editor-fold desc="CreditNote">

  AddCreateNote(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/addNote', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  UpdateNote(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/UpdateNote', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  deleteNote(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/deleteNote', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  GetCreditItems(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/getCreditItems', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  //</editor-fold>


  //<editor-fold desc="Purchase">


  AddPurchase(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/addPurchase', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  UpdatePurchase(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/updatePurchase', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  GetPurchaseItems(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/getPurchaseItems', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  AllPurchase(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/allPurchase', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })


    })
  }

  deletePurchase(data) {

    return new Promise((next, error) => {
      authAxios
        .post('/api/deletePurchase', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  //</editor-fold>


  // <editor-fold desc="PayIn">


  AddPaymentIn(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/addPayIn', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          console.log(err, '+sdsdks')
          error(err)
        })
    })
  }

  GetPayInDetails(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/GetPayInDetails', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  UpdatePaymentIn(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/UpdatePaymentIn', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  deletePaymentIn(data) {

    console.log('sjdfjsdj')
    return new Promise((next, error) => {
      authAxios
        .post('/api/deletePaymentIn', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  //</editor-fold>


  // <editor-fold desc="PayOut">


  AddPaymentOut(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/addPayOut', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  GetPayOutDetails(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/GetPayOutDetails', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  UpdatePaymentOut(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/UpdatePaymentOut', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  deletePaymentOut(data) {

    return new Promise((next, error) => {
      authAxios
        .post('/api/deletePaymentOut', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  //</editor-fold>

  //<editor-fold desc="Transactions">

  AllTransactions(data) {
    console.log(data)
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/transactions', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })


    })
  }

  DetailedDayBook(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/dayBook', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })


    })
  }

  clientPLReport(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/client/pl-report', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })


    })
  }


  //</editor-fold>


  //<editor-fold desc="Transport">

  AddTransport(data) {  // add transport by rk
    return new Promise((next, error) => {
      this.checkBusinessSelected((resp) => {
        data.businessId = resp.uid
        authAxios
          .post('/api/transport', data, getToken())
          .then((d) => {
            next(d.data)
          })
          .catch((err) => {
            error(err)
          })
      })

    })
  }

  AllTransportList(data) { // all transport by rk
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/getAllTransport', { businessId: resp.uid })
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }

  UpdateTransport(data) {  //  transport by rk
    return new Promise((next, error) => {
      authAxios
        .post('/api/updateTransport', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  DeleteTransport(data) {  //  transport by rk
    return new Promise((next, error) => {
      authAxios
        .post('/api/deleteTransport', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  //</editor-fold>


  //<editor-fold desc="Vehicle">

  AddVehicle(data) {  // add vehicle by rk
    return new Promise((next, error) => {
      authAxios
        .post('/api/vehicle', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  AllVehicleList(data) { // vehicle list by rk
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/getAllVehicle', { businessId: resp.uid })
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }


  UpdateVehicle(data) {  //  vehicle by rk
    return new Promise((next, error) => {
      authAxios
        .post('/api/updateVehicle', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  DeleteVehicle(data) {  //  vehicle by rk
    return new Promise((next, error) => {
      authAxios
        .post('/api/deleteVehicle', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  GetVehicleByTransport(data) { // vehicle list by rk
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/getVehicleByTransport', { ...data, businessId: resp.uid })
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }


  //</editor-fold>


  //<editor-fold desc="Brand">
  AddBrand(data) {  // add vehicle by rk
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid

          authAxios
            .post('/api/brand', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }

  loadBrands() {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          let data = {
            businessId: resp.uid
          }

          authAxios
            .post('/api/list/brand', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }

  //</editor-fold>


  //<editor-fold desc="Prefix">

  AddPrefix(data) {  // add vehicle by rk
    return new Promise((next, error) => {
      authAxios
        .post('/api/prefix', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  AllPrefixList(data) { // vehicle list by rk
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/getAllPrefix', { businessId: resp.uid }, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }


  PrefixByInvoiceType(data) { // vehicle list by rk
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/getPrefixByInvoiceType', { ...data, businessId: resp.uid }, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }

  setCurrentPrefix(data) { // vehicle list by rk
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/setCurrentPrefix', { ...data, businessId: resp.uid }, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }

  deletePrefix(data) { // vehicle list by rk
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/deletePrefix', { ...data, businessId: resp.uid }, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }


  //</editor-fold>


  //<editor-fold desc="Bank">

  AddBank(data) {  // add bank by rk
    return new Promise((next, error) => {
      this.checkBusinessSelected((resp) => {
        data.businessId = resp.uid
        authAxios
          .post('/api/addBank', data, getToken())
          .then((d) => {
            next(d.data)
          })
          .catch((err) => {
            error(err)
          })
      })
    })
  }

  AllBank(data) { // all bank by rk
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/allBanks', { ...data, businessId: resp.uid })
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }

  BankList(data) { // all bank by rk
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/banksList', { ...data, businessId: resp.uid })
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }

  UpdateBank(data) {  //  bank by rk
    return new Promise((next, error) => {
      this.checkBusinessSelected((resp) => {
        data.businessId = resp.uid
        authAxios
          .post('/api/updateBank', data, getToken())
          .then((d) => {
            next(d.data)
          })
          .catch((err) => {
            error(err)
          })
      })
    })
  }

  DeleteBank(data) {  //  bank by rk
    return new Promise((next, error) => {
      authAxios
        .post('/api/deleteBank', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  AllBankTransactions(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/bankTransactions', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })


    })
  }

  //</editor-fold>


  //<editor-fold desc="Prefix for inventory">

  getInventoryPrefix(data) {  // by rk
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          authAxios
            .post('/api/getInventoryPrefix', { invoiceType: data, businessId: resp.uid }, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }

  //</editor-fold>

  //<editor-fold desc="Check GST IN">

  checkGstNumber(gstNo) {  // by rk
    return new Promise((next, error) => {
      authAxios
        .post('/api/checkGstNumberExists', { gstNumber: gstNo })
        .then((d) => {
          if (d.data.type) {
            next({ error: true, msg: d.data.msg })
          } else {
            let url = `https://appyflow.in/api/verifyGST?key_secret=${verifyGstKey}&gstNo=${gstNo}`
            axios({
              method: 'get',
              url: url
            }).then((d) => {
              next(d.data)
            }).catch((err) => {
              error(err)
            })
          }
        })
        .catch((err) => {
          error(err)
        })


    })
  }

  checkGstNumberNewApi(gstNo) {  // by rk
    return new Promise((next, error) => {
      authAxios
        .post('/api/checkGstNumber', { gstNumber: gstNo })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })


    })
  }

  //</editor-fold>


  // <editor-fold desc="DebitNote">

  AddDebitNote(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/addDebitNote', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  UpdateDebitNote(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/UpdateDebitNote', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  deleteDebitNote(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/deleteDebitNote', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  GetDebitItems(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/getDebitItems', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  //</editor-fold>

  // <editor-fold desc="Contra Entry">

  AddContraEntry(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/addContraEntry', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  GetSingleContraEntry(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/singleContraEntry', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }

  UpdateContraEntry(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/UpdateContraEntry', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  deleteContraEntry(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/deleteContraEntry', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  GetContraEntry(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/getContraEntry', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }


  //</editor-fold>

  // <editor-fold desc="Journal Entry">

  AddJournalEntry(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/addJournalEntry', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  GetSingleJournalEntry(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/singleJournalEntry', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })

    })
  }

  UpdateJournalEntry(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/UpdateJournalEntry', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  deleteJournalEntry(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/deleteJournalEntry', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  GetJournalEntry(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/getJournalEntry', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }


  //</editor-fold>


  uploadDocument(data, image) {
    let form = new FormData()
    form.append('photos', image)
    form.append('data', JSON.stringify(data))
    return new Promise((next, error) => {
      authAxios
        .post('/api/uploadDocument', form)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          console.log(err)
          error(err)
        })
    })
  }


  getTransactionByCategory(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/getTransactionByCategory', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }


  uploadCategoryDocument(data, image) {
    let form = new FormData()
    form.append('photos', image)
    form.append('data', JSON.stringify(data))
    return new Promise((next, error) => {
      authAxios
        .post('/api/uploadCategoryDocument', form)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          console.log(err)
          error(err)
        })
    })
  }


  getClientInvoices(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((value) => {
        if (value !== null) {
          authAxios
            .post('/api/clientInvoices', { ...data, businessId: value.uid }, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })

        } else {
          next()
        }
      })
    })

  }

  loadInvoicesRef(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((value) => {
        if (value !== null) {
          authAxios
            .post('/api/clientInvoicesRef', { ...data, businessId: value.uid }, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })

        } else {
          next()
        }
      })
    })

  }

  singleProductByInvoiceRef(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((value) => {
        if (value !== null) {
          authAxios
            .post('/api/singleProductByInvoiceRef', { ...data, businessId: value.uid }, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })

        } else {
          next()
        }
      })
    })

  }


  AllPaymentTypes() {
    return new Promise((next, error) => {
      authAxios
        .get('/api/getPaymentTypes')
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

  saleMultiCopy(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/multiCopySale', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })


    })
  }

  generateEWayBill(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/generateEWayBill', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })


    })
  }

  findDistance(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/calc/distance', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })


    })
  }


  ProfitAndLossStatement(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/tradingAccount', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })

    })
  }

  BalanceSheetAjax(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/balanceSheet', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })

    })
  }

  gstReportData(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/gstList', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  gstr2ReportData(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/gstr2List', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  cancelEWayBill(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/cancelEWayBill', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })


    })
  }

  AllCities(data) {
    return new Promise((next, error) => {

      authAxios.post('/api/getAllCities', { ...data }).then((d) => {
        next(d.data)
      }).catch(function(thrown) {
        error('err')
      })

    })


  }

  singleGstReport(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/singleGstReport', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  gstExcelData(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/getGstExcelData', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  singleGstR2Report(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/singleGstR2Report', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  gstR2ExcelData(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/getGstR2ExcelData', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  gstr3ReportData(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/gstr3List', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  singleGstr3SaleCreditNote(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/gstr3SaleCreditNote', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  singleGstr3PurchaseDebitNote(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/gstr3PurchaseDebitNote', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  FullBalanceSheetAjax(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/balanceSheetFull', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })

    })
  }


  getGstr1Json(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/gstr1Json', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  getGstr3Json(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/gstr3Json', data, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        }
      })
    })
  }

  uploadSignature(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/uploadSignature', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }


  loadJournalInvoicesRef(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((value) => {
        if (value !== null) {
          authAxios
            .post('/api/journalInvoiceRef', { ...data, businessId: value.uid }, getToken())
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })

        } else {
          next()
        }
      })
    })

  }

  // <editor-fold desc="Gallery">


  loadGalleryYear(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/getGalleryYear', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })

    })
  }

  loadGalleryMonth(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/getGalleryMonth', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })

    })
  }

  loadGalleryDay(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/getGalleryDay', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })

    })
  }


  loadGalleryImages(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/getGalleryImages', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })

    })
  }

  assignGallery(data) {
    return new Promise((next, error) => {
      AsyncStorage.getBusiness().then((resp) => {
        if (resp) {
          data.businessId = resp.uid
          authAxios
            .post('/api/assignGallery', data)
            .then((d) => {
              next(d.data)
            })
            .catch((err) => {
              error(err)
            })
        } else {

        }
      })

    })
  }


  //</editor-fold>

  checkBusinessSelected(callback) {
    AsyncStorage.getBusiness().then((resp) => {
      if (resp && resp.uid) {
        return callback(resp)
      } else {
        alert('Please choose business.')
      }
    })
  }

  submitLoginOtp(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/submitOtp', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          error(err)
        })
    })
  }

}


export default new Request()
