import React, { Component } from 'react'
import moment from 'moment'
import Request from '../../../../request'
import { showLoader, hideLoader } from '../../../../modules/actions'
import PageHeaderWrapper from '../../../../components/PageHeaderWrapper'
import { Card, Row, Col, Menu, DatePicker, Dropdown, Button, Table, Tag, List } from 'antd'
import { connect } from 'react-redux'
import Styles from '../styles.less'
import ChooseDateComponent from '../chooseDateComponent'
import { fixed2Digit } from '../../../../components/_utils/_utils'

class Gstr3Report extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      arr: {},
      userType: '',
      title: '',
      fromDate: moment().startOf('month'),
      toDate: moment().endOf('month'),
      purchaseData: []
    }

  }

  loadBalanceSheet = () => {
    let { dispatch } = this.props
    let { fromDate, toDate } = this.state
    dispatch(showLoader())
    Request.gstr3ReportData({ fromDate, toDate })
      .then((testData) => {
        let purchaseData = []
        let obj = {
          arr: testData
        }
        if (testData.purchaseData && testData.purchaseData.taxableAmount) {
          purchaseData.push({ title: 'All other ITC', ...testData.purchaseData })
          obj.purchaseData = purchaseData
        }
        this.setState(obj)
        dispatch(hideLoader())
        return
      })
      .catch((e) => {
        dispatch(hideLoader())
        return
      })

  }

  onChooseDate = (date, dateString) => {
    this.setState({ date: date }, () => {
      this.loadBalanceSheet()
    })
  }

  componentDidMount() {
    this.loadBalanceSheet()
  }

  createPdf = async () => {

  }

  filterData = (obj) => {
    this.setState({
      ...obj
    }, () => {
      this.loadBalanceSheet()
    })
  }

  _onPressR(item) {
    if (item.title == '(a)Outward txbl. supplies(other than zero rated, nil rated and exempted)') {
    }
    if (item.title == '(c)Other outward supp.(Nil rated,exmptd)') {
    }
    if (item.title == 'All other ITC') {
    }
  };

  render() {
    let { arr, fromDate, toDate, purchaseData } = this.state
    let columns = [
      {
        title: 'Section Name',
        key: 'title',
        dataIndex: 'title'
      },
      {
        title: 'Total Taxable Amount',
        key: 'taxableAmount',
        dataIndex: 'taxableAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      },
      {
        title: 'Total Cgst',
        key: 'cgstAmount',
        dataIndex: 'cgstAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      },
      {
        title: 'Total Igst',
        key: 'igstAmount',
        dataIndex: 'igstAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      },
      {
        title: 'Total Sgst',
        key: 'sgstAmount',
        dataIndex: 'sgstAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      },

      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        render: (item) => {
          return (
            <React.Fragment>
              <Button icon={'eye'} shape={'circle'} size={'small'}></Button>
            </React.Fragment>
          )
        }
      }
    ]

    let columns2 = [
      {
        title: 'Place of Supply(State/UT)',
        key: '_id',
        dataIndex: '_id'
      },
      {
        title: 'Total Taxable Value',
        key: 'taxableAmount',
        dataIndex: 'taxableAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      },
      {
        title: 'Amount of IGST',
        key: 'igstAmount',
        dataIndex: 'igstAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      }
    ]

    let columns3 = [
      {
        title: '',
        key: 'title',
        dataIndex: 'title'
      },
      {
        title: 'Total Taxable Amount',
        key: 'taxableAmount',
        dataIndex: 'taxableAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      },
      {
        title: 'Total Cgst',
        key: 'igstAmount',
        dataIndex: 'cgstAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      },
      {
        title: 'Total Sgst',
        key: 'igstAmount',
        dataIndex: 'sgstAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      },
      {
        title: 'Total Igst',
        key: 'igstAmount',
        dataIndex: 'igstAmount',
        render: (item) => {
          return item ? fixed2Digit(item) : 0
        }
      }
    ]

    return (
      <PageHeaderWrapper title={'GSTR 3 Report'}>
        <Card bordered={true}>
          <ChooseDateComponent fromDate={fromDate} toDate={toDate} filterData={this.filterData}/>
          <Table columns={columns} dataSource={arr.arrData} size={'small'} bordered={true}
                 pagination={false}
          />
          <Table columns={columns2} dataSource={arr.stateData} size={'small'} bordered={true} className={'mt10'}
                 pagination={false}
          />

          <Table columns={columns3} dataSource={purchaseData} size={'small'} bordered={true} className={'mt10'}
                 pagination={false}
          />
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gstr3Report)
