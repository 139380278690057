import React, { Component } from 'react'
import moment from 'moment'
import Request from '../../request'
import { showLoader, hideLoader } from '../../modules/actions'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { Card, Row, Col, Menu, DatePicker, Dropdown, Button } from 'antd'
import { connect } from 'react-redux'
import Styles from './styles.less'
import { InputBox } from '../webComponent/appDes'
import PrintHtml from '../html/sheet_report/profitAndLoss'
import PrintHeader from '../html/header'
import { HtmlPrint } from '../html/print'

class ProfitAndLoss extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      obj: {},
      isModalVisible: false,
      userType: '',
      title: '',
      date: moment(),
      isDateTimePickerVisible: false
    }

  }

  loadProfitAndLoss = () => {
    let { dispatch } = this.props
    dispatch(showLoader())
    let { date } = this.state
    Request.ProfitAndLossStatement({ date })
      .then((testData) => {
        this.setState({ obj: testData })
        dispatch(hideLoader())
        return
      })
      .catch((e) => {
        dispatch(hideLoader())
        return
      })

  }

  onChooseDate = (date, dateString) => {
    this.setState({ date: date }, () => {
      this.loadProfitAndLoss()
    })
  }

  componentDidMount() {
    this.loadProfitAndLoss()
  }

  createPdf = async () => {
    let { obj } = this.state
    let html = await PrintHtml(obj)
    HtmlPrint(html)
  }

  render() {
    let { obj, date } = this.state


    return (
      <PageHeaderWrapper title={'Profit & Loss Report'}>
        <Card bordered={true}>
          <Row gutter={16}>
            <Col span={'8'}>
              <InputBox title={'Filter By Date'} className={'rowFlex'}>
                <DatePicker onChange={this.onChooseDate} value={date} placeholder={'Choose Date'}/>
              </InputBox>
            </Col>
            <Col span={'16'} className={'alignRight'}>
              <InputBox title={''} className={'mt20'}>
                <Button onClick={() => {
                  this.createPdf()
                }}>
                  Print
                </Button>
              </InputBox>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Card title={'TRADING ACCOUNT'}>
                <div className={'box'}>
                  <h2 className={'evenColor'}>D E B I T ( Rs. )</h2>
                  <Row>
                    <Col span={8}>To Opening Stock</Col>
                    <Col span={8}></Col>
                    <Col span={8}>{obj.openingStock}</Col>
                  </Row>
                  <Row>
                    <Col span={8}>To Purchase</Col>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                  </Row>
                  <Row className={'grayRow'}>
                    <Col span={8}>Purchase</Col>
                    <Col span={8}>{obj.purchasePrice}</Col>
                    <Col span={8}>{obj.purchasePrice}</Col>
                  </Row>
                  {obj.totalProfit ? <Row>
                    <Col span={8}>To Gross Profit</Col>
                    <Col span={8}></Col>
                    <Col span={8}>{obj.totalProfit}</Col>
                  </Row> : ''}
                  <Row>
                    <Col span={8}></Col>
                    <Col span={8}>Total</Col>
                    <Col span={8}>
                      <div className={'borderV'}>
                        {obj.totalDebit ? (obj.totalDebit).toFixed(2) : 0}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className={'box mt20'}>
                  <h2 className={'oddColor'}>
                    C R E D I T ( Rs. )
                  </h2>
                  <Row>
                    <Col span={8}>By Sale</Col>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                  </Row>
                  <Row className={'grayRow'}>
                    <Col span={8}>Sale</Col>
                    <Col span={8}>{obj.salePrice}</Col>
                    <Col span={8}>{obj.salePrice}</Col>
                  </Row>
                  <Row>
                    <Col span={8}>By Closing Stock</Col>
                    <Col span={8}></Col>
                    <Col span={8}>{obj.closingStock}</Col>
                  </Row>
                  {obj.totalLoss ? <Row>
                    <Col span={8}>By Gross Loss</Col>
                    <Col span={8}></Col>
                    <Col span={8}>{obj.totalLoss}</Col>
                  </Row> : null}
                  <Row>
                    <Col span={8}></Col>
                    <Col span={8}>Total</Col>
                    <Col span={8}>
                      <div className={'borderV'}>
                        {obj.totalCredit ? (obj.totalCredit).toFixed(2) : 0}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <Card title={'PROFIT & LOSS ACCOUNT'}>
                <div className={'box'}>
                  <h2 className={'evenColor'}>
                    D E B I T ( Rs. )
                  </h2>
                  <Row>
                    <Col span={8}>To Expenses (Indirect/Admn.)</Col>
                    <Col span={8}></Col>
                    <Col span={8}>{obj.openingStock}</Col>
                  </Row>
                  {obj.expenses && obj.expenses.length ? obj.expenses.map((item, index) => {
                    return (
                      <Row className={'grayRow'}>
                        <Col span={8}>{item.name}</Col>
                        <Col span={8}>{item.balance}</Col>
                        <Col span={8}>{item.totalExpense ? item.totalExpense : null}</Col>
                      </Row>
                    )
                  }) : null}

                  {obj.netProfit ? <Row>
                    <Col span={8}>To Nett Profit</Col>
                    <Col span={8}></Col>
                    <Col span={8}>{obj.netProfit}</Col>
                  </Row> : null}
                  <Row>
                    <Col span={8}></Col>
                    <Col span={8}>Total</Col>
                    <Col span={8}>{obj.profitLossDebit}</Col>
                  </Row>
                </div>
                <div className={'box'}>
                  <h2 className={'oddColor'}>C R E D I T ( Rs. )</h2>
                  {obj.totalProfit ?
                    <Row>
                      <Col span={8}>By Gross Profit</Col>
                      <Col span={8}></Col>
                      <Col span={8}>{obj.totalProfit}</Col>
                    </Row>
                    : null}

                  {obj.netLoss ?
                    <Row>
                      <Col span={8}>By Nett Loss</Col>
                      <Col span={8}></Col>
                      <Col span={8}>{obj.netLoss}</Col>
                    </Row>
                    : null}

                  <Row>
                    <Col span={8}></Col>
                    <Col span={8}>Total</Col>
                    <Col span={8}>{obj.profitLossCredit}</Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfitAndLoss)
