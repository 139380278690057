import React, { Component } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon,
  Row,
  Col,
  Table, notification
} from 'antd'
import Request from '../../../request'
import { UnitCodeList } from '../../../components/_utils/_utils'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { hideLoader, showLoader } from '../../../modules/actions'
import { connect } from 'react-redux'
import { GlobalHotKeys, HotKeys } from 'react-hotkeys'
import AddPage from './Add'
import { UnitHandle, UnitKey } from '../../../components/AppShortCut/units'


const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AllWarranty extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arr: [],
      addPageVisible: false
    }
  }

  componentDidMount() {
    this.loadAllWarranty()
  }

  async loadAllWarranty() {
    let data = await Request.AllWarranty()
    this.setState({ arr: data })
  }


  warrantyCallback = (data) => {
    this.setState({ addPageVisible: false }, () => {
      this.loadAllWarranty()
    })
  }
  onWarrantyClose = () => {
    this.setState({ addPageVisible: false })
  }

  addWarrantyFxn = () => {
    let { dispatch } = this.props
    dispatch(hideLoader())
    this.setState({ addPageVisible: true })
  }


  render() {
    let { arr, addPageVisible } = this.state
    const columns = [
      {
        title: 'Warranty',
        key: 'warranty',
        sorter: true,
        dataIndex: 'warranty'
      },

      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username'
      }
    ]

    return (
      <PageHeaderWrapper
        title={'Warranty Setting'}>
        <Card bordered={true} title={'Warranty Setting'}
              extra={<Button type="primary" onClick={this.addWarrantyFxn}>Add Warranty</Button>}>
          <Table columns={columns} bordered={true} size={'small'}
                 dataSource={arr}
                 pagination={false}/>
        </Card>
        {addPageVisible ?
          <AddPage
            warrantyCallback={this.warrantyCallback}
            onWarrantyClose={this.onWarrantyClose}
            visible={addPageVisible}
            {...this.props}
          />
          : null}
      </PageHeaderWrapper>)

  }

}


const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllWarranty)
