import React, { Component } from 'react'
import { AsyncStorage } from '../../asyncStorage'

class CheckGstAvailable extends Component {
  constructor() {
    super()
    this.state = {
      gstAvailable: false
    }
  }

  async componentDidMount() {
    let gstAvailable = await AsyncStorage.getGstAvailable()
    this.setState({ gstAvailable })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.gstAvailable && <React.Fragment>{this.props.children}</React.Fragment>}
      </React.Fragment>
    )
  }
}

export default CheckGstAvailable
